import { useState, createContext } from "react";
import { bearingTypes } from "../GlobalData/bearingTypes";
import { partTypes } from "../GlobalData/partTypes";
import {
  DrawenObject,
  DrawenObjects,
  FinishedPendingObject,
  Index,
  ObjectIndex,
  OnlyPlacedObjects,
} from "./types/DrawenObjectModel";

export const DrawenContext = createContext<DrawenContextType | null>(null);
export type DrawenContextType = {
  createNewDrawenObject: (pendingObj: FinishedPendingObject) => void; //TODO finish here
  removeObject: (ind: ObjectIndex) => void;
  addChild: (obj: { index: Index; childIndex: ObjectIndex }) => void;
  drawenObjects: DrawenObjects;
  drawenIndex: number;
  deleteCounter: number;
  zIndex: number;
  moveOneUp: (index: ObjectIndex) => void;
  moveOneDown: (index: ObjectIndex) => void;
  getZIndexSortedList: () => Array<ObjectIndex>;
  moveToTop: (index: ObjectIndex) => void;
  moveToBottom: (index: ObjectIndex) => void;
  getAllBearings: () => Array<DrawenObject>;
  getAllParts: () => Array<DrawenObject>;
  setIsHighlighted: (index: ObjectIndex, newIsHighlighted: boolean) => void;
  setRootCoordinateSystemVisibility: (isVisible: boolean) => void;
};
export function DrawenProvider(props: any) {
  const [drawenIndex, setDrawenIndex] = useState(0);
  const [deleteCounter, setDeleteCounter] = useState(0); // counts how many items were deleted
  const [bearingPositionName, setBearingPositionName] = useState("A"); //A,B,C,D...
  const [zIndex, setZIndex] = useState(0);
  const [drawenObjects, setDrawenObjects] = useState<DrawenObjects>({
    root: {
      children: [],
      index: "root",
      type: "root",
      x: 0,
      y: 0,
      angle: 0,
      coordinateSystemIsHidden: false,
    },
  });

  const createNewDrawenObject = ({
    parentIndex,
    type,
    mechanicalType,
    x,
    y,
    angle,
    color = "black",
    scale,
    size,
    controlType,
    angleReferenceIndex,
    relativeAngle,
    posReferenceIndex,
    posKOS,
    relativePos,
  }: FinishedPendingObject) => {
    const isBearing = mechanicalType === "bearing";
    const refPoint = isBearing
      ? bearingTypes[type].refPoint
      : partTypes[type].refPoint;
    setDrawenObjects((prevDrawenObjects) => ({
      ...prevDrawenObjects,
      [drawenIndex]: {
        zIndex: zIndex, // set zIndex to current zIndex
        index: drawenIndex,
        parentIndex: parentIndex,
        children: [],
        type: type,
        mechanicalType: mechanicalType,
        x: x,
        y: y,
        posReferenceIndex: posReferenceIndex,
        posKOS: posKOS,
        relativePos: relativePos, //object
        xRefRatio: refPoint?.xRatio,
        yRefRatio: refPoint?.yRatio,
        angle: angle,
        angleReferenceIndex: angleReferenceIndex,
        relativeAngle: relativeAngle,
        scale: scale,
        size: size,
        color: color,
        controlType: controlType,
        isHighlighted: false,
        bearingPositionName: isBearing ? bearingPositionName : null,
      },
    }));
    /**
     * update the according parent Node with the childIndex
     */
    addChild({
      index: parentIndex,
      childIndex: drawenIndex,
    });
    setDrawenIndex((prev) => prev + 1); // add new Object and then increment the index
    setZIndex((prev) => prev + 1); // add new Object and then increment the zindex
    if (isBearing) {
      setBearingPositionName((prev) => {
        // increment the bearingpositionNames
        return String.fromCharCode(prev.charCodeAt(0) + 1); // this function increments A to B and so on
      });
    }
  };
  const addChild = ({
    index,
    childIndex,
  }: {
    index: Index;
    childIndex: ObjectIndex;
  }) => {
    setDrawenObjects((prevDrawenObjects) => {
      if (!prevDrawenObjects[index]) {
        return prevDrawenObjects;
      }
      return {
        ...prevDrawenObjects,
        [index]: {
          ...prevDrawenObjects[index],
          children: [
            ...(prevDrawenObjects[index] as DrawenObject).children,
            childIndex,
          ],
        },
      };
    });
  };
  const returnDeepChildren = (index: ObjectIndex) => {
    let children = [...drawenObjects[index].children];
    children.forEach((childIndex) => {
      if (childIndex) {
        children = [...children, ...returnDeepChildren(childIndex)];
      }
    });
    return children;
  };
  const removeObject = (index: ObjectIndex) => {
    let childrenToRemove = [index, ...returnDeepChildren(index)];
    const obj: DrawenObject = drawenObjects[index];
    const parent = drawenObjects[obj.parentIndex];
    const remainingParentChildren = parent?.children.filter((child) => {
      return child !== index;
    }); // get remainder except index
    let workerObject = { ...drawenObjects };
    childrenToRemove.forEach((indexToRemove) => {
      //helper to Remove indices step by step
      // @ts-ignore
      const { [indexToRemove]: numberAlternative, ...remainderObject } =
        workerObject;
      // @ts-ignore
      workerObject = { ...remainderObject };
    });
    if (!parent) {
      return;
    }
    if (remainingParentChildren?.length) {
      setDrawenObjects({
        ...workerObject,
        [parent.index]: {
          ...parent,
          children: [...remainingParentChildren],
        },
      });
    } else {
      // parent hat jz keine children mehr
      setDrawenObjects({
        ...workerObject,
        [parent.index]: {
          ...parent,
          children: [],
        },
      });
    }
    setDeleteCounter((prev) => prev + 1);
  };
  const getZIndexSortedList = () => {
    const tmpObjs = { ...drawenObjects };
    const { root, ...rest } = tmpObjs;
    const placedObjects: OnlyPlacedObjects = rest;

    // Object.values(tmpObj).filter((drawenObj, index) => {
    //   return (
    //     (drawenObj.zIndex || drawenObj.zIndex === 0) && !isNaN(drawenObj.zIndex)
    //   );
    // });
    function sortByZIndex(a: number, b: number) {
      return drawenObjects[a].zIndex - drawenObjects[b].zIndex;
    }
    const sortedIndexArray = Object.values(placedObjects)
      .map((obj) => {
        return obj.index;
      })
      .sort(sortByZIndex);

    return sortedIndexArray;
  };
  const moveOneUp = (index: ObjectIndex) => {
    const placedList = getZIndexSortedList();
    const indexPlacement = placedList.indexOf(index);
    if (indexPlacement === placedList.length - 1) {
      return;
    }
    const oneUp = placedList[indexPlacement + 1];
    setDrawenObjects((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        zIndex: prev[oneUp].zIndex, //
      },
      [oneUp]: {
        ...prev[oneUp],
        zIndex: prev[index].zIndex, // exchange zIndex with oneUp element
      },
    }));
  };
  const moveOneDown = (index: ObjectIndex) => {
    const placedList = getZIndexSortedList();
    const indexPlacement = placedList.indexOf(index);

    if (indexPlacement === 0) {
      return;
    }
    const oneDown = placedList[indexPlacement - 1];

    setDrawenObjects((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        zIndex: prev[oneDown].zIndex, //
      },
      [oneDown]: {
        ...prev[oneDown],
        zIndex: prev[index].zIndex, // exchange zIndex with oneUp element
      },
    }));
  };
  const moveToTop = (index: ObjectIndex) => {
    setDrawenObjects((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        zIndex: zIndex, //set zIndex to biggest index there is
      },
    }));
    setZIndex((prev) => prev + 1);
  };
  const moveToBottom = (index: ObjectIndex) => {
    setDrawenObjects((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        zIndex: -zIndex, //set zIndex to negative biggest index there is
      },
    }));
    setZIndex((prev) => prev + 1);
  };
  const getAllBearings = () => {
    const bearings = Object.values(drawenObjects)
      .filter((drawenObj): drawenObj is DrawenObject => {
        return drawenObj.index !== "root";
      })
      .filter((drawenObj) => {
        return drawenObj.mechanicalType === "bearing";
      });
    return bearings;
  };
  const getAllParts = () => {
    const parts = Object.values(drawenObjects)
      .filter((drawenObj): drawenObj is DrawenObject => {
        return drawenObj.index !== "root";
      })
      .filter((drawenObj) => {
        return drawenObj.mechanicalType === "part";
      });
    return parts;
  };
  const setIsHighlighted = (
    index: ObjectIndex,
    newIsHighlighted: boolean
  ): void => {
    setDrawenObjects((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        isHighlighted: newIsHighlighted,
      },
    }));
  };
  const setRootCoordinateSystemVisibility = (isVisible: boolean) => {
    setDrawenObjects((prev) => {
      if (!prev) {
        return {};
      }
      return {
        ...prev,
        root: {
          ...prev["root"],
          coordinateSystemIsHidden: !isVisible,
        },
      };
    });
  };
  return (
    <DrawenContext.Provider
      value={{
        createNewDrawenObject: createNewDrawenObject,
        removeObject: removeObject,
        addChild: addChild,
        drawenObjects: drawenObjects,
        drawenIndex: drawenIndex,
        deleteCounter: deleteCounter,
        zIndex: zIndex,
        moveOneUp: moveOneUp,
        moveOneDown: moveOneDown,
        getZIndexSortedList: getZIndexSortedList,
        moveToTop: moveToTop,
        moveToBottom: moveToBottom,
        getAllBearings: getAllBearings,
        getAllParts: getAllParts,
        setIsHighlighted: setIsHighlighted,
        setRootCoordinateSystemVisibility: setRootCoordinateSystemVisibility,
      }}
    >
      {props.children}
    </DrawenContext.Provider>
  );
}

import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../Context/PendingContext";
import { StatusContext, StatusContextType } from "../../Context/StatusContext";
import {
  FakeRunContext,
  FakeRunContextType,
} from "features/Context/FakeRunContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { useTranslation } from "react-i18next";

export function useNextDisabled(): [boolean, string] {
  const { t } = useTranslation();
  const { status } = useContext(StatusContext) as StatusContextType;
  const { isPending } = useContext(PendingContext) as PendingContextType;
  const { loadObjects, isPendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { lastFakeRunCompleted } = useContext(
    FakeRunContext
  ) as FakeRunContextType;
  let disabled = true;
  let hoverTitle = "";
  switch (status.status) {
    case "system":
      if (!isPending && lastFakeRunCompleted) {
        disabled = false;
      } else if (isPending) {
        hoverTitle = t(
          "Finish the configuration of the current object to go to the next stage!"
        );
      } else if (!lastFakeRunCompleted) {
        hoverTitle = t(
          "The system must be statically determinate to go to the next stage!"
        );
      }
      break;
    case "load":
      if (
        loadObjects &&
        Object.values(loadObjects).length > 0 &&
        !isPendingLoad
      ) {
        disabled = false;
      } else if (isPendingLoad) {
        hoverTitle = t("Finish the pending Load before continuing");
      } else {
        hoverTitle = t(
          "Add at least one load to the system! \n You can add loads on the left pane."
        );
      }
      break;
    case "measurements":
      disabled = false;
      break;
    case "freischnitt":
      disabled = false;
      break;
    case "simulation":
      break;

    default:
      disabled = true;
      break;
  }
  return [disabled, hoverTitle];
}

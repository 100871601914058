import { Paper, Typography, makeStyles } from "@material-ui/core";

const useStyles = (props: any) =>
  makeStyles(() => ({
    flex: {
      display: "flex",
      justifyContent: "start",
      alignItems: "start",
    },
    imgContainer: {
      maxWidth: props.width,
      objectFit: "contain",
    },
    bold: {
      fontWeight: "bold",
    },
    paper: {
      padding: "5px",
      margin: "5px",
    },
  }));
export function ImgWithHeader({
  imgSource,
  header = "",
  width = 200,
}: {
  imgSource: string;
  header: string;
  width?: number;
}) {
  const classes = useStyles({ width })();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Typography className={classes.bold}>{header}</Typography>
      <img
        className={classes.imgContainer}
        src={imgSource}
        alt="img"
        title={header}
      ></img>
    </Paper>
  );
}

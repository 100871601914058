import {
  PendingContext,
  PendingContextType,
} from "features/Context/PendingContext";
import { pendingColor, primaryColor } from "./Color";
import { Tag } from "./Tag";
import { useContext, useState } from "react";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { useTranslation } from "react-i18next";
export function PendingTag() {
  const { t } = useTranslation();
  const { savePendingObject } = useContext(
    PendingContext
  ) as PendingContextType;
  const { savePendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { status } = useContext(StatusContext) as StatusContextType;
  const [showSave, setShowSave] = useState(false);
  const handleMouseEnter = () => {
    setShowSave(true);
  };
  const handleMouseLeave = () => {
    setShowSave(false);
  };
  const handleSave = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (status.status === "system") {
      savePendingObject();
    } else if (status.status === "load") {
      savePendingLoad();
    }
  };
  return (
    <span
      style={{ cursor: "pointer" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleSave}
    >
      {showSave && (
        <Tag
          title={t("Save Pending")}
          text={t("Save Me")}
          color={primaryColor}
          height={14}
        ></Tag>
      )}
      {!showSave && (
        <Tag
          title={t("Save Pending to continue!")}
          text={t("Pending")}
          color={pendingColor}
          height={14}
        ></Tag>
      )}
    </span>
  );
}

import { makeStyles } from "@material-ui/core";
import { useBBox } from "features/Components/BearingEditor/Hooks/useBBox";
import {
  BearingStyleContext,
  BearingStyleContextType,
} from "features/Context/BearingStyleContext";
import {
  MouseMoveContext,
  MouseMoveContextType,
} from "features/Context/MouseMoveContext";
import {
  DrawenObject,
  PendingObject,
} from "features/Context/types/DrawenObjectModel";
import React, { useContext, useEffect } from "react";
import useDragRotate from "../playgroundObjectHooks/useDragRotate";
import Rotater from "./Rotater";

const useStyles = makeStyles({
  resizer: {
    cursor: "pointer",
    "&:hover": {
      outline: "solid 3px red",
    },
  },
});
export default function Rescaler({
  object,
  objRef,
  updater,
}: {
  object: DrawenObject | PendingObject;
  objRef: React.MutableRefObject<any>;
  updater: any;
}) {
  const isPending = object.index === "pending";
  const isPart = object.mechanicalType === "part";
  const xx = object.x ? object.x : 0;
  const yy = object.y ? object.y : 0;
  const angle = object.angle ? object.angle : 0;
  // const { strokeWidth: strokeOfObject } = useContext(
  //   BearingStyleContext
  // ) as BearingStyleContextType;

  const bbox = useBBox(objRef, xx + yy + angle);
  let { width, height } = bbox;
  const { setPendingBBox } = useContext(
    MouseMoveContext
  ) as MouseMoveContextType;

  useEffect(() => {
    if (isPending) {
      setPendingBBox(width, height);
    }
  }, [width, height, setPendingBBox]);

  let { width: dist } = useContext(
    BearingStyleContext
  ) as BearingStyleContextType;
  dist = dist * 0.7;
  // const strokeColor = "grey";
  // const strokeWidth = 2;
  // const strokeLineCap = "round";
  // const offset = strokeOfObject;

  const { handleRotateStart } = useDragRotate();
  return (
    <g>
      {isPending && !isPart && (
        <Rotater
          handleRotateStart={handleRotateStart}
          x={xx + dist * Math.sin((-angle * 2 * Math.PI) / 360)}
          y={yy + dist * Math.cos((-angle * 2 * Math.PI) / 360)}
          xCenter={xx}
          yCenter={yy}
          object={object}
        />
      )}
      {/* {isPending && isPart && (
        <PartRescaler
          {...{ object, handleResizeStart, offset, x, y, width, height }}
        />
      )} */}
      {/* <g id="Lines">
        <line
          className={classes.resizer}
          id="TopLine"
          x1={x - offset}
          y1={y - offset}
          x2={x + width + offset}
          y2={y - offset}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLineCap}
        />
        <line
          id="BottomLine"
          x1={x - offset}
          y1={y + height + offset}
          x2={x + width + offset}
          y2={y + height + offset}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLineCap}
        />
        <line
          id="LeftLine"
          x1={x - offset}
          y1={y - offset}
          x2={x - offset}
          y2={y + height + offset}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLineCap}
        />
        <line
          id="RightLine"
          x1={x + width + offset}
          y1={y - offset}
          x2={x + width + offset}
          y2={y + height + offset}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap={strokeLineCap}
        />
      </g> */}
    </g>
  );
}

import { getLineDir } from "features/LinearAlgebra/getLineDir";
import {
  Line,
  Point,
  Vector2,
} from "features/LinearAlgebra/linearAlgebraModel";
import {
  addV,
  rotateByDeg,
  scalarMultiply,
} from "features/LinearAlgebra/vectorUtils";
import { deg2Rad } from "../../util/angleConversion";

export function getAllLinesFromPart(part: {
  x: number;
  y: number;
  angle: number;
  size: { width: number; height: number };
}) {
  const {
    x: xStart,
    y: yStart,
    angle,
    size: { width: length, height: thickness },
  } = part;

  const xEnd = xStart + Math.cos(deg2Rad(angle)) * length;
  const yEnd = yStart + Math.sin(deg2Rad(angle)) * length;
  const start: Point = [xStart, yStart];
  const end: Point = [xEnd, yEnd];
  const line: Line = [start, end];
  const lineDir: Vector2 = getLineDir(line);
  const normalDir: Vector2 = rotateByDeg(lineDir, 90);
  const outerLine1: Line = [
    addV(start, scalarMultiply(thickness / 2, normalDir)),
    addV(end, scalarMultiply(thickness / 2, normalDir)),
  ];
  const outerLine2: Line = [
    addV(start, scalarMultiply(-thickness / 2, normalDir)),
    addV(end, scalarMultiply(-thickness / 2, normalDir)),
  ];
  //inner, left,       right
  return [line, outerLine1, outerLine2];
}

import { Typography, makeStyles } from "@material-ui/core";
import { panelBackground } from "features/Components/StyleOnlyComponents/Color";
import { LinkToCalculator } from "../mainContent/LinkToCalculator";
import { ParagraphAccordion } from "../mainContent/Layout/ParagraphAccordion";
import { Capabilities } from "../mainContent/Capabilities";
import { Abstract } from "../mainContent/Abstract";
import { Title } from "featuresLandingPage/StyleComps/Title";
import { CalculatorLimits } from "../mainContent/CalculatorLimits";
import { Walkthrough } from "featuresLandingPage/mainContent/Walkthrough";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    background: "white",
    minHeight: "70vh",
  },

  mainColumn: {
    width: "100%",
    overflowX: "hidden",
  },
  calculatorButton: {
    width: "300px",
    height: "190px",
  },
}));
export function MainContentMobile() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.flexOuter}>
      <main className={classes.mainColumn}>
        <ParagraphAccordion
          titleMainElement={<Title>How To</Title>}
          mainContent={<Walkthrough />}
          expanded={true}
        ></ParagraphAccordion>
        <ParagraphAccordion
          titleMainElement={
            <Title>{t("Capabilities of the Calculator")}</Title>
          }
          mainContent={<Capabilities showLink={false} />}
          expanded={true}
        ></ParagraphAccordion>
        <ParagraphAccordion
          titleMainElement={<Title>{t("Limitations of the calculator")}</Title>}
          mainContent={<CalculatorLimits />}
          expanded={true}
        ></ParagraphAccordion>
      </main>
    </div>
  );
}

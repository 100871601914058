import {
  BearingType,
  PartType,
} from "features/Context/types/ConstructionElementModel";

export function getPartSize(partType: PartType) {
  if (partType === "stab") {
    return {
      width: 300, // currentWidth
      originalWidth: 300,
      height: 10,
      originalHeight: 10,
      originalStrokeWidth: 2,
    };
  } else {
    return null;
  }
}
//------------------------------------------------------------------------------Bearings----------------------------------
export function getBearingSize(bearingType: BearingType) {
  if (bearingType === "losLager") {
    return {
      width: 100,
      height: (100 * 582) / 701,
      wOverH: 701 / 582,
      originalWidth: 701,
      originalHeight: 582,
    };
  } else if (bearingType === "festLager") {
    return {
      width: 100,
      height: (100 * 585) / 701,
      wOverH: 701 / 585,
      originalWidth: 701,
      originalHeight: 585,
    };
  } else if (bearingType === "einspannung") {
    return {
      width: 100,
      height: (100 * 127) / 701,
      wOverH: 701 / 127,
      originalWidth: 701,
      originalHeight: 127,
    };
  } else if (bearingType === "schiebehuelse") {
    return {
      width: 100,
      height: (100 * 201) / 700,
      wOverH: 700 / 204,
      originalWidth: 701,
      originalHeight: 204,
    };
  } else if (bearingType === "gelenk") {
    return {
      radius: 30,
      originalRadius: 85,
      originalStrokeWidth: 2,
    };
  }
}

import {
  LoadStyleContext,
  LoadStyleContextType,
} from "features/Context/LoadStyleContext";
import { Load as LoadType } from "features/Context/types/LoadModel";
import { useContext } from "react";
import { Force } from "./Loads/Force";
import { Moment } from "./Loads/Moment";
import Streckenlast from "./Loads/Streckenlast";
import useDragLoad from "./playgroundObjectHooks/useDragLoad";

export default function Load({ loadObject }: { loadObject: LoadType }) {
  const loadType = loadObject?.loadType;
  let load;
  const { handleDragLoadStart } = useDragLoad();

  const { forceLength, fontSize, strokeWidth } = useContext(
    LoadStyleContext
  ) as LoadStyleContextType;
  if (loadObject.isFake) {
    return null;
  }
  const isHighlighted = loadObject.isHighlighted || loadObject.isPending;
  if (loadType === "force") {
    load = (
      <Force
        x={loadObject.x}
        y={loadObject.y}
        angle={loadObject.angle}
        color={loadObject.color1}
        textSuffix={"" + loadObject.name}
        length={forceLength}
        fontSize={fontSize}
        strokeWidth={strokeWidth}
        magnitude={loadObject.magnitude}
        textOffsetX={loadObject.textOffsetX}
        textOffsetY={loadObject.textOffsetY}
        loadObject={loadObject}
        isSymbolic={loadObject.isSymbolic}
        isHighlighted={isHighlighted}
      />
    );
  } else if (loadType === "moment") {
    load = (
      <Moment
        x={loadObject.x}
        y={loadObject.y}
        size={loadObject.size}
        color={loadObject.color1}
        textSuffix={"" + loadObject.name}
        orientation={loadObject.loadSpec.orientation}
        angleSpan={loadObject.loadSpec.angleSpan}
        magnitude={loadObject.magnitude}
        fontSize={fontSize}
        strokeWidth={strokeWidth}
        textOffsetX={loadObject.textOffsetX}
        textOffsetY={loadObject.textOffsetY}
        isSymbolic={loadObject.isSymbolic}
        loadObject={loadObject}
        isHighlighted={isHighlighted}
      />
    );
  } else if (loadType === "streckenlast") {
    load = (
      <Streckenlast
        loadObject={loadObject}
        startUnitSymbol={loadObject.loadSpec.startUnitSymbol}
        endUnitSymbol={loadObject.loadSpec.endUnitSymbol}
        startUnitFactor={loadObject.loadSpec.startUnitFactor}
        endUnitFactor={loadObject.loadSpec.endUnitFactor}
        startMagnitude={loadObject.loadSpec.startMagnitude}
        endMagnitude={loadObject.loadSpec.endMagnitude}
        startHeight={loadObject.loadSpec.startHeight}
        endHeight={loadObject.loadSpec.endHeight}
        startPreFactor={loadObject.loadSpec.startPreFactor}
        endPreFactor={loadObject.loadSpec.endPreFactor}
        startIsSymbolic={loadObject.loadSpec.startIsSymbolic}
        endIsSymbolic={loadObject.loadSpec.endIsSymbolic}
        startPos={loadObject.loadSpec.startPos}
        endPos={loadObject.loadSpec.endPos}
        startMeasureSymbol={loadObject.loadSpec.startMeasureSymbol}
        lengthMeasureSymbol={loadObject.loadSpec.lengthMeasureSymbol}
        startMeasureMagnitude={loadObject.loadSpec.startMeasureMagnitude}
        lengthMeasureMagnitude={loadObject.loadSpec.lengthMeasureMagnitude}
        startMeasureIsSymbolic={loadObject.loadSpec.startMeasureIsSymbolic}
        lengthMeasureIsSymbolic={loadObject.loadSpec.lengthMeasureIsSymbolic}
        startMeasureUnitSymbol={loadObject.loadSpec.startMeasureUnitSymbol}
        lengthMeasureUnitSymbol={loadObject.loadSpec.lengthMeasureUnitSymbol}
        startMeasureUnitFactor={loadObject.loadSpec.startMeasureUnitFactor}
        lengthMeasureUnitFactor={loadObject.loadSpec.lengthMeasureUnitFactor}
        roughDistBetweenArrows={loadObject.loadSpec.roughDistBetweenArrows}
        startSuffix={loadObject.loadSpec.startSuffix}
        endSuffix={loadObject.loadSpec.endSuffix}
        startMeasureSuffix={loadObject.loadSpec.startMeasureSuffix}
        lengthMeasureSuffix={loadObject.loadSpec.endSuffix}
        startTextOffsetX={loadObject.loadSpec.startTextOffsetX}
        startTextOffsetY={loadObject.loadSpec.startTextOffsetY}
        endTextOffsetX={loadObject.loadSpec.endTextOffsetX}
        endTextOffsetY={loadObject.loadSpec.endTextOffsetY}
        startMeasureTextOffsetX={loadObject.loadSpec.startMeasureTextOffsetX}
        startMeasureTextOffsetY={loadObject.loadSpec.startMeasureTextOffsetY}
        lengthMeasureTextOffsetX={loadObject.loadSpec.startMeasureTextOffsetX}
        lengthMeasureTextOffsetY={loadObject.loadSpec.startMeasureTextOffsetY}
        color1={loadObject.color1}
        color2={loadObject.color2}
        textColor={loadObject.textColor}
        startMeasurementIndex={loadObject.loadSpec.startMeasurementIndex}
        lengthMeasurementIndex={loadObject.loadSpec.lengthMeasurementIndex}
        isHighlighted={isHighlighted}
      />
    );
  }
  return (
    <>
      {!loadObject.isHiddenByUser && (
        <g
          id="Load"
          onMouseDown={(e) => {
            handleDragLoadStart(e, loadObject?.isPending ? "pending" : false);
          }}
        >
          {load}
        </g>
      )}
    </>
  );
}

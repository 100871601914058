import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { intersectOfTwoLines } from "features/LinearAlgebra/intersectOfTwoLines";
import {
  addV,
  distance,
  rotateByDeg,
  scalarProd,
  sM,
  subV,
} from "features/LinearAlgebra/vectorUtils";
import { getLineFromPart } from "../util/getLineFromPart";

export function tShapePointFinder(
  biggerPart,
  smallerPart,
  startOfBiggerInSmaller,
  startOfSmallerInBigger,
  endOfBiggerInSmaller,
  endOfSmallerInBigger,
  intersects
) {
  const lineBigger = getLineFromPart(biggerPart);
  const lineSmaller = getLineFromPart(smallerPart);
  // @ts-ignore
  const [middleIntersect] = intersectOfTwoLines(lineBigger, lineSmaller);
  let lineT = [];
  let otherLine = [];
  if (startOfBiggerInSmaller) {
    lineT = lineBigger;
    otherLine = lineSmaller;
  } else if (startOfSmallerInBigger) {
    lineT = lineSmaller;
    otherLine = lineBigger;
  } else if (endOfBiggerInSmaller) {
    lineT = [lineBigger[1], lineBigger[0]];
    otherLine = lineSmaller;
  } else if (endOfSmallerInBigger) {
    lineT = [lineSmaller[1], lineSmaller[0]];
    otherLine = lineBigger;
  }
  let straightPoints = [];
  let cornerPoints = [];
  const projectionOfLineT = scalarProd(
    // @ts-ignore
    rotateByDeg(getLineDir(otherLine), 90),
    subV(lineT[1], lineT[0])
  );
  const normalToOtherPointT = addV(
    // @ts-ignore
    sM(projectionOfLineT, rotateByDeg(getLineDir(otherLine), 90)),
    middleIntersect
  );
  const distancetoMiddle = distance(middleIntersect, normalToOtherPointT);
  intersects.forEach((intersectionPoint) => {
    const distanceFromOtherSide = Math.abs(
      scalarProd(
        subV(normalToOtherPointT, intersectionPoint),
        getLineDir([normalToOtherPointT, middleIntersect])
      )
    );

    if (distanceFromOtherSide > distancetoMiddle && straightPoints.length < 2) {
      straightPoints.push(intersectionPoint);
    } else {
      cornerPoints.push(intersectionPoint);
    }
  });
  if (straightPoints.length === 0) {
    straightPoints = [intersects[0], intersects[1]];
  } else if (straightPoints.length === 1) {
    straightPoints[1] = intersects[1];
  }
  return { straightPoints, cornerPoints, lineT };
}

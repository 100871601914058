export function UnhappyLosLagerIcon({
  size = 25,
  withColor = true,
}: {
  size?: number;
  withColor?: boolean;
}) {
  const colorJoint = withColor ? "red" : "white";
  const colorBalls = "white";
  const colorBody = "white";
  const smileRadius = 10;
  const strokeWidth = (6 * Math.sqrt(25)) / Math.sqrt(size);
  return (
    <svg width={size + "px"} height={size + "px"} viewBox="-6 -6 121 121">
      <g transform="translate(0 140) rotate(0) scale(1 -1) translate(-6 -6) "></g>
      <g>
        <g transform="  translate(0,0) scale(1)  rotate(0 50 15 )">
          <line
            id="TopHorizontal"
            x1="10"
            x2="90"
            y1="59"
            y2="59"
            strokeLinecap="round"
            stroke="black"
            strokeWidth={strokeWidth}
          ></line>
          <polygon
            points="18,59 42.5,22.5 
            57.5,22.5 82,59 "
            strokeLinecap="round"
            stroke="black"
            strokeWidth={strokeWidth}
            fill={colorBody}
          ></polygon>
          <circle
            id="Joint"
            cx="50"
            cy="15"
            r="15"
            stroke="black"
            strokeWidth={strokeWidth}
            fill={colorJoint}
          ></circle>
          <circle
            className="leftEye"
            cx="45"
            cy="7"
            r="1"
            stroke="black"
            strokeWidth={strokeWidth * 0.6}
            fill="black"
          ></circle>
          <circle
            className="rightEye"
            cx="55"
            cy="7"
            r="1"
            stroke="black"
            strokeWidth={strokeWidth * 0.6}
            fill="black"
          ></circle>
          <path
            className="mouth"
            d={`M ${50 - smileRadius} 17 Q 50 8 ${50 + smileRadius} 17`}
            fill={colorJoint}
            stroke="black"
            strokeWidth={strokeWidth * 0.75}
            strokeLinecap="round"
          />
          <g id="balls">
            <circle
              cx="23.333333333333336"
              cy="69.5"
              r="7.5"
              stroke="black"
              strokeWidth={strokeWidth}
              fill={colorBalls}
            ></circle>
            <circle
              cx="50"
              cy="69.5"
              r="7.5"
              stroke="black"
              strokeWidth={strokeWidth}
              fill={colorBalls}
            ></circle>
            <circle
              cx="76.66666666666667"
              cy="69.5"
              r="7.5"
              stroke="black"
              strokeWidth={strokeWidth}
              fill={colorBalls}
            ></circle>
          </g>
          <g id="bottom">
            <line
              id="BaseLine"
              x1="0"
              y1="80"
              x2="100"
              y2="80"
              stroke="black"
              strokeWidth={strokeWidth}
              strokeLinecap="round"
            ></line>
            <line
              id="Line0"
              x1="15.773502691896258"
              y1="80"
              x2="4.22649730810374"
              y2="100"
              stroke="black"
              strokeWidth={strokeWidth * 0.75}
              strokeLinecap="round"
            ></line>
            <line
              id="Line1"
              x1="35.77350269189626"
              y1="80"
              x2="24.226497308103745"
              y2="100"
              stroke="black"
              strokeWidth={strokeWidth * 0.75}
              strokeLinecap="round"
            ></line>
            <line
              id="Line2"
              x1="55.77350269189626"
              y1="80"
              x2="44.226497308103745"
              y2="100"
              stroke="black"
              strokeWidth={strokeWidth * 0.75}
              strokeLinecap="round"
            ></line>
            <line
              id="Line3"
              x1="75.77350269189625"
              y1="80"
              x2="64.22649730810373"
              y2="100"
              stroke="black"
              strokeWidth={strokeWidth * 0.75}
              strokeLinecap="round"
            ></line>
            <line
              id="Line4"
              x1="95.77350269189625"
              y1="80"
              x2="84.22649730810373"
              y2="100"
              stroke="black"
              strokeWidth={strokeWidth * 0.75}
              strokeLinecap="round"
            ></line>
          </g>
        </g>
      </g>
      <g></g>
    </svg>
  );
}

import { Button, makeStyles } from "@material-ui/core";
import {
  cancelColor,
  cancelColorDark,
} from "features/Components/StyleOnlyComponents/Color";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  buttonRed: {
    background: cancelColor,
    color: "white",
    "&:hover": {
      background: cancelColorDark,
      color: "white",
    },
  },
  buttonBlue: {
    background: "#0d0580",
    color: "white",
  },
  btnWrapper: {
    width: "max-Content",
    display: "inline",
  },
});
export default function SubmitButtons({
  onCancel = () => {},
  onSave = () => {},
  saveDisabled = false,
  submitTitle = "",
  cancelString = "Cancel",
  submitString = "Save Element",
}: {
  onCancel?: any;
  onSave?: any;
  saveDisabled?: boolean;
  submitTitle?: string;
  cancelString?: string | JSX.Element;
  submitString?: string | JSX.Element;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  cancelString = cancelString === "Cancel" ? t("Cancel") : cancelString;
  submitString =
    submitString === "Save Element" ? t("Save Element") : submitString;
  return (
    <>
      <Button
        className={classes.buttonRed}
        variant={"contained"}
        onClick={onCancel}
      >
        {cancelString}
      </Button>
      <div title={submitTitle} className={classes.btnWrapper}>
        <Button
          color="primary"
          disabled={saveDisabled}
          variant={"contained"}
          onClick={onSave}
        >
          {submitString}
        </Button>
      </div>
    </>
  );
}

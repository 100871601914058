import { useContext, useEffect } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../Context/PendingContext";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../../Context/DrawenContext";

import { Slider } from "./InputComponents/Slider";
import { ControlAccordion } from "../../VisualElements/ControlAccordion";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function StickControl() {
  const { t } = useTranslation();
  const { pendingObject, setPendingCoords, setRelativePos } = useContext(
    PendingContext
  ) as PendingContextType;
  useEffect(() => {
    setRelativePos({ distance: 0 });
  }, []);
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  if (!pendingObject) {
    return null;
  }
  const angle = pendingObject.angle;
  const parent = drawenObjects[pendingObject.parentIndex];
  if (!parent || angle === undefined) {
    return null;
  }
  const angleParent = parent.angle;
  const xPart = pendingObject.x;
  const yPart = pendingObject.y;
  const xBearing = parent.x;
  const yBearing = parent.y;
  if (xPart === undefined || yPart === undefined) {
    return null;
  }
  let distance = Math.sqrt(
    (xPart - xBearing) * (xPart - xBearing) +
      (yPart - yBearing) * (yPart - yBearing)
  );
  if (!distance) {
    distance = 0;
  }
  const maxVal =
    pendingObject.mechanicalType === "bearing"
      ? ((parent as any).size as any).width
      : (pendingObject?.size as any)?.width;

  //   const percentage = (distance / pendingObject.width) * 100;
  const handleChange = (newDistance: number) => {
    let newX, newY;
    if (pendingObject.mechanicalType === "part") {
      newX = xBearing - Math.cos((angle / 360) * 2 * Math.PI) * newDistance;
      newY = yBearing - Math.sin((angle / 360) * 2 * Math.PI) * newDistance;
    } else {
      //its a bearing
      newX =
        xBearing + Math.cos((angleParent / 360) * 2 * Math.PI) * newDistance;
      newY =
        yBearing + Math.sin((angleParent / 360) * 2 * Math.PI) * newDistance;
    }

    setPendingCoords({ x: newX, y: newY });
    setRelativePos({ distance: newDistance });
  };
  const marks = [
    { value: maxVal / 4, label: "1/4" },
    { value: maxVal / 3, label: "1/3" },
    { value: maxVal / 2, label: "1/2" },
    { value: (maxVal / 3) * 2, label: "2/3" },
    { value: (maxVal / 4) * 3, label: "3/4" },
  ];

  return (
    <ControlAccordion
      titleMainElement={<Typography>{t("Position of the Support")}</Typography>}
      mainContent={
        <div className={"StickControl"}>
          <Slider
            description={""}
            min={0}
            step={1}
            max={maxVal}
            marks={marks}
            value={distance}
            handleChange={handleChange}
          />
        </div>
      }
      expanded={true}
    ></ControlAccordion>
  );
}

import { makeStyles, Typography } from "@material-ui/core";
import {
  ConsoleContext,
  ConsoleContextType,
} from "features/Context/ConsoleContext";
import { useContext, useEffect, useRef } from "react";
import { ConsoleElement } from "./ConsoleElement";
import {
  panelBackground,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
import { Link } from "react-router-dom";
import { landingPageRoute } from "index";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  consoleTitle: {
    position: "absolute",
    top: "0px",
    right: "20px",
    background: "#707070",
    color: "white",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  dragBox: {
    position: "absolute",
    marginTop: "-5px",
    top: "0px",
    left: "0px",
    background: "rgba(0,0,0,0)",
    width: "100%",
    height: "16px",
    cursor: "row-resize",
  },
  borderDiv: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    background: "rgba(0,0,0,0)",
    width: "100%",
    height: "7px",
    borderTop: "1px solid darkgrey",
  },
  consoleContent: {
    overflowY: "scroll",
    overflowX: "hidden",
    width: "100%",
    height: "100%",
  },
  overflowHidden: {
    height: "100%",
    width: "100%",
    position: "relative",
    background: panelBackground,
  },
  bottomSpace: {
    paddingTop: "20px",
  },
  bottomRight: {
    fontSize: "10px",
    position: "absolute",
    bottom: "0px",
    right: "0px",
  },
}));

export function Console({ dragStart }: { dragStart: Function }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { console } = useContext(ConsoleContext) as ConsoleContextType;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [console]);

  const consoleElements = console.map((consoleObj, index) => {
    return (
      <ConsoleElement
        key={consoleObj.time + " " + index}
        consoleObj={consoleObj}
      ></ConsoleElement>
    );
  });
  return (
    <div className={"Console " + classes.overflowHidden}>
      <div
        id="dragBox"
        className={classes.dragBox}
        onMouseDown={(e) => dragStart(e)}
      >
        <div className={classes.borderDiv}></div>
      </div>
      <div className={classes.consoleTitle}>
        <Typography>{t("Console")}</Typography>
      </div>
      <div className={classes.consoleContent}>
        <>
          {consoleElements}
          <div className="LastMessage" ref={messagesEndRef}></div>
          <div className={classes.bottomSpace}></div>
        </>
      </div>
    </div>
  );
}

import { deg2Rad } from "features/Components/BearingEditor/util/angleConversion";
import { bearingTypes } from "features/GlobalData/bearingTypes";
import { Vector2 } from "features/LinearAlgebra/linearAlgebraModel";
import {
  getSignedAngleOfAnglePoints,
  getSignedAngleToXAxis,
  getSignedAngleToXAxisBetween0And360,
  subV,
} from "features/LinearAlgebra/vectorUtils";
import { useState, createContext, useRef } from "react";
import { MeasureIndex } from "./MeasureDistanceContext";
import {
  AngleMeasurement,
  AngleMeasurements,
  AnglePoints,
} from "./types/AngleMeasurementModel";
import { LoadNames } from "./types/ConstructionElementModel";
import {
  DrawenObject,
  DrawenObjects,
  ObjectIndex,
} from "./types/DrawenObjectModel";
import { LoadObjects } from "./types/LoadModel";
import { useMeasurementUnits } from "features/Components/Functionality_Left/MesurementView/useMeasurementUnits";

export const MeasureAngleContext =
  createContext<MeasureAngleContextType | null>(null);
export type MeasureAngleContextType = {
  createNewAngleMeasurement: (
    initObj: Partial<AngleMeasurement>
  ) => MeasureIndex;
  removeAngleMeasurement: (
    indexArr: MeasureIndex | Array<MeasureIndex>
  ) => void;
  angleMeasurements: AngleMeasurements;
  draggableMeasure: AngleMeasurement | undefined | null;
  setAnglePoints: (anglePoints: AnglePoints, index: MeasureIndex) => void;
  setNewArcRadius: (index: MeasureIndex, arcRadius: number) => void;
  setMeasureAnnotationTextOffset: (
    deltaArr: Vector2,
    index: MeasureIndex
  ) => void;
  setMeasureAnnotationDraggable: (
    newDraggable: boolean,
    index: MeasureIndex
  ) => void;
  removeAllDraggableAnnotations: () => void;
  removeAllDraggableArrows: () => void;
  removeAllFakeAngleMeasurements: () => void;
  setMeasureArrowDraggable: (
    newDraggable: boolean,
    index: MeasureIndex
  ) => void;
  draggableMeasureArrow: AngleMeasurements | undefined | null;
  setIsBeingModified: (
    newIsBeingModified: boolean,
    index: MeasureIndex
  ) => void;
  setIsSymbolic: (index: MeasureIndex, newIsSymbolic: boolean) => void;
  setMagnitude: (index: MeasureIndex, newMagnitude: number) => void;
  setUnit: (index: MeasureIndex, newUnit: "rad" | "°") => void;
  getCurrentlyModified: () => AngleMeasurement | null;
  getHidden: () => Array<AngleMeasurement> | null;
  getSignedAngleOfMeasurement: (index: MeasureIndex) => number | null;
  getAngleStringOnPart: (
    type: "bearing" | LoadNames,
    index: ObjectIndex,
    drawenObjects: DrawenObjects,
    loads: LoadObjects
  ) => string | null;
  getAngleStringsFromInternallyConnectedBearingToPart: (
    index: ObjectIndex, // index of bearing
    drawenObjects: DrawenObjects
  ) => { parentAngle: string; childAngle: string } | null;
  setHiddenByUser: (index: MeasureIndex, isHiddenByUser: boolean) => void;
  setIsHighlighted: (index: ObjectIndex, newIsHighlighted: boolean) => void;
};
export function MeasureAngleProvider(props: any) {
  const [unitOptions, unitFactorFunc] = useMeasurementUnits("angle");
  const angleMeasureIndex = useRef<MeasureIndex>(1);
  const [angleMeasurements, setAngleMeasurements] = useState<AngleMeasurements>(
    {}
  );
  const createNewAngleMeasurement = ({
    index = angleMeasureIndex.current,
    isDraggable = false,
    arrowColor = "black",
    annotationColor = "black",
    anglePoints = {
      startPoint: [0, 0],
      endPoint: [100, 0],
      pivotPoint: [0, 100],
    },
    arcRadius = 40,
    preFactor = "1",
    mainSymbol = "a",
    suffix = "" + angleMeasureIndex.current,
    magnitude = 0,
    isSymbolic = false,
    unitSymbol = "°",
    unitFactor = 1,
    annotationIsDraggable = false,
    arrowIsDraggable = false,
    textOffsetX = 0,
    textOffsetY = 0,

    type = "angle", //relativeDistance,

    fromType = "part", //streckenlast,force,moment,bearing
    fromIndex = -1, //index of according Context

    toType = "part", //streckenlast,force,moment,bearing
    toIndex = -1, //index of according Context

    isBeingModified = false,
    isHidden = false, // this should be set for 0 length measurements or 90° or 0° angles
    isHiddenByUser = false,
    isFake = false,
  }: Partial<AngleMeasurement>) => {
    const numericMagnitude = getSignedAngleOfAnglePoints(anglePoints);
    const angleMagnitude = numericMagnitude ? numericMagnitude : 0;
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          index,
          isDraggable,
          arrowIsDraggable,
          mainSymbol,
          suffix,
          arrowColor,
          annotationColor,
          anglePoints,
          arcRadius,
          magnitude: angleMagnitude,
          isSymbolic,
          unitSymbol,
          unitFactor,
          annotationIsDraggable,
          textOffsetX,
          textOffsetY,
          preFactor,
          type,
          fromType,
          fromIndex,
          toType,
          toIndex,
          isBeingModified,
          isHidden,
          isHiddenByUser,
          isFake,
          isHighlighted: false,
        },
      };
    });
    angleMeasureIndex.current = angleMeasureIndex.current + 1;
    return index;
  };
  /**
   * @param {*} indexArr Array of indices or index to remove from measurements object
   */
  const removeAngleMeasurement = (
    indexArr: MeasureIndex | Array<MeasureIndex>
  ) => {
    const removeMeasure = (index: MeasureIndex) => {
      setAngleMeasurements((prev) => {
        const { [index]: indToRemove, ...rest } = prev;
        return rest;
      });
    };
    if (Array.isArray(indexArr)) {
      //its an array of measurement indices
      indexArr.forEach((ind) => {
        removeMeasure(ind);
      });
    } else {
      //its just one index
      const indexToRemove = indexArr;
      removeMeasure(indexToRemove);
    }
  };
  const removeAllFakeAngleMeasurements = (): void => {
    const allFakeMeasures = Object.values(angleMeasurements).filter(
      (measure) => {
        return measure.isFake;
      }
    );
    if (!allFakeMeasures) {
      return;
    }
    const allFakeIndices = allFakeMeasures.map((measure) => {
      return measure.index;
    });
    removeAngleMeasurement(allFakeIndices);
  };
  const setNewArcRadius = (index: MeasureIndex, arcRadius: number) => {
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          arcRadius: arcRadius,
        },
      };
    });
  };
  const draggableMeasure = angleMeasurements
    ? Object.values(angleMeasurements).find((measure) => {
        return measure.annotationIsDraggable;
      })
    : null;
  const draggableMeasureArrow = angleMeasurements
    ? Object.values(angleMeasurements).find((measure) => {
        return measure.arrowIsDraggable;
      })
    : null;
  const setMeasureAnnotationTextOffset = (
    deltaArr: Vector2,
    index: MeasureIndex
  ) => {
    if (deltaArr.length === 2) {
      setAngleMeasurements((prev) => {
        return {
          ...prev,
          [index]: {
            ...prev[index],
            textOffsetX: prev[index].textOffsetX + deltaArr[0],
            textOffsetY: prev[index].textOffsetY + deltaArr[1],
          },
        };
      });
    }
  };
  const setMeasureAnnotationDraggable = (
    newDraggable: boolean,
    index: MeasureIndex
  ) => {
    setAngleMeasurements((prev) => {
      const newState = {
        ...prev,
        [index]: {
          ...prev[index],
          annotationIsDraggable: newDraggable,
        },
      };
      return newState;
    });
  };
  const setMeasureArrowDraggable = (
    newDraggable: boolean,
    index: MeasureIndex
  ) => {
    setAngleMeasurements((prev) => {
      const newState = {
        ...prev,
        [index]: {
          ...prev[index],
          arrowIsDraggable: newDraggable,
        },
      };

      return newState;
    });
  };
  const removeAllDraggableAnnotations = () => {
    setAngleMeasurements((prev) => {
      let trueIndices: Array<MeasureIndex> = [];
      Object.values(prev).forEach((el) => {
        if (el.annotationIsDraggable) {
          trueIndices.push(el.index);
        }
      });
      let temp = { ...prev };
      if (trueIndices.length > 0) {
        trueIndices?.forEach((index) => {
          temp[index] = {
            ...temp[index],
            annotationIsDraggable: false,
          };
        });
      }
      return temp;
    });
  };
  const removeAllDraggableArrows = () => {
    setAngleMeasurements((prev) => {
      let trueIndices: Array<MeasureIndex> = [];
      Object.values(prev).forEach((el) => {
        if (el.arrowIsDraggable) {
          trueIndices.push(el.index);
        }
      });
      let temp = { ...prev };
      if (trueIndices.length > 0) {
        trueIndices?.forEach((index) => {
          temp[index] = {
            ...temp[index],
            arrowIsDraggable: false,
          };
        });
      }
      return temp;
    });
  };
  const setAnglePoints = (anglePoints: AnglePoints, index: MeasureIndex) => {
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          anglePoints: anglePoints,
        },
      };
    });
  };
  const setIsBeingModified = (
    newIsBeingModified: boolean,
    index: MeasureIndex
  ) => {
    setAngleMeasurements((prev) => {
      let temp = { ...prev };
      for (var key of Object.keys(temp)) {
        // @ts-ignore
        temp[key].isBeingModified = false;
      }
      const newState = {
        ...temp,
        [index]: {
          ...temp[index],
          isBeingModified: newIsBeingModified,
        },
      };
      return newState;
    });
  };
  const setIsSymbolic = (index: MeasureIndex, newIsSymbolic: boolean): void => {
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          isSymbolic: newIsSymbolic,
        },
      };
    });
  };
  const setMagnitude = (index: MeasureIndex, newMagnitude: number): void => {
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          magnitude: newMagnitude,
        },
      };
    });
  };
  const setUnit = (index: MeasureIndex, newUnit: "rad" | "°"): void => {
    const unitFactor: number = unitFactorFunc(newUnit);
    const unitToSet = newUnit === "rad" ? "" : "°";
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          unitSymbol: unitToSet,
          unitFactor: unitFactor,
        },
      };
    });
  };
  const getCurrentlyModified = () => {
    if (!angleMeasurements) {
      return null;
    }
    for (var key of Object.keys(angleMeasurements)) {
      // @ts-ignore
      if (angleMeasurements[key].isBeingModified) {
        // @ts-ignore
        return angleMeasurements[key];
      }
    }
    return null;
  };
  const getHidden = (): Array<AngleMeasurement> | null => {
    if (!angleMeasurements) {
      return null;
    }
    let hiddenList: Array<AngleMeasurement> = [];
    for (var key of Object.keys(angleMeasurements)) {
      // @ts-ignore
      if (angleMeasurements[key].isHidden) {
        // @ts-ignore
        hiddenList.push(angleMeasurements[key]);
      }
    }
    if (hiddenList.length > 0) {
      return hiddenList;
    }
    return null;
  };
  const getSignedAngleOfMeasurement = (index: MeasureIndex): number | null => {
    if (!angleMeasurements || !angleMeasurements[index]) {
      return null;
    }
    const anglePoints = angleMeasurements[index].anglePoints;
    const startVector = subV(anglePoints.startPoint, anglePoints.pivotPoint);
    const endVector = subV(anglePoints.endPoint, anglePoints.pivotPoint);
    const angleStartToX = getSignedAngleToXAxis(startVector);
    const angleEndToX = getSignedAngleToXAxis(endVector);
    const angle = angleEndToX - angleStartToX;
    return angle;
  };

  /**
   * looks for the measurement that connects the load/bearing+index with its part.
   * @param type
   * @param index
   * @returns the angle string e.g. alpha_1 or pi/2. If not symbolic it returns in radians
   */
  const getAngleStringOnPart = (
    type: "bearing" | LoadNames,
    index: ObjectIndex,
    drawenObjects: DrawenObjects,
    loads: LoadObjects
  ): string | null => {
    if (!angleMeasurements || !drawenObjects || !loads) {
      return null;
    }
    if (
      type === "bearing" &&
      drawenObjects[index] &&
      bearingTypes[(drawenObjects[index] as DrawenObject).type]
        .connectorType === "intern"
    ) {
      console.warn("You can't use this function on intern connectors");
      return null;
    }
    const angleMeasure = Object.values(angleMeasurements).find(
      (angleMeasurement) => {
        const bearingIsAtFromPoint =
          angleMeasurement.fromType === type &&
          angleMeasurement.toType === "part" &&
          angleMeasurement.fromIndex === index;
        const bearingIsAtToPoint =
          angleMeasurement.toType === type &&
          angleMeasurement.fromType === "part" &&
          angleMeasurement.toIndex === index;
        return bearingIsAtFromPoint || bearingIsAtToPoint;
      }
    );
    if (!angleMeasure) {
      return null;
    }
    const isSymbolic = angleMeasure.isSymbolic;
    const symbolicAngleString =
      angleMeasure.preFactor +
      "*" +
      angleMeasure.mainSymbol +
      "_" +
      angleMeasure.suffix;
    const numericAngleString =
      angleMeasure.unitSymbol === "°"
        ? deg2Rad(angleMeasure.magnitude)
        : angleMeasure.magnitude;
    const angleString = isSymbolic
      ? symbolicAngleString
      : String(numericAngleString);
    return angleString;
  };
  const getAngleStringsFromInternallyConnectedBearingToPart = (
    index: ObjectIndex, // index of bearing
    drawenObjects: DrawenObjects
  ): { parentAngle: string; childAngle: string } | null => {
    if (!angleMeasurements || !drawenObjects) {
      return null;
    }
    if (
      drawenObjects[index] &&
      bearingTypes[(drawenObjects[index] as DrawenObject).type]
        .connectorType !== "intern"
    ) {
      console.warn("You can't use this function on extern connectors");
      return null;
    }
    const angleMeasures = Object.values(angleMeasurements).filter(
      (angleMeasurement) => {
        const bearingIsAtFromPoint =
          angleMeasurement.fromType === "bearing" &&
          angleMeasurement.toType === "part" &&
          angleMeasurement.fromIndex === index;
        const bearingIsAtToPoint =
          angleMeasurement.toType === "bearing" &&
          angleMeasurement.fromType === "part" &&
          angleMeasurement.toIndex === index;
        return bearingIsAtFromPoint || bearingIsAtToPoint;
      }
    );
    if (!angleMeasures || !(angleMeasures.length === 2)) {
      return null;
    }
    let stringObj: { parentAngle: string; childAngle: string } = {
      parentAngle: "",
      childAngle: "",
    };
    angleMeasures.forEach((angleMeasure) => {
      const isSymbolic = angleMeasure.isSymbolic;
      const symbolicAngleString =
        angleMeasure.preFactor +
        "*" +
        angleMeasure.mainSymbol +
        "_" +
        angleMeasure.suffix;
      const numericAngleString =
        angleMeasure.unitSymbol === "°"
          ? deg2Rad(angleMeasure.magnitude)
          : angleMeasure.magnitude;
      const angleString = isSymbolic
        ? symbolicAngleString
        : String(numericAngleString);
      const partIndex =
        angleMeasure.fromIndex === index
          ? angleMeasure.toIndex
          : angleMeasure.fromIndex;
      const part = drawenObjects[partIndex];
      const partIsParent = (part.children as Array<number>).includes(index);
      if (partIsParent) {
        stringObj.parentAngle = angleString;
      } else {
        stringObj.childAngle = angleString;
      }
    });
    if (!stringObj.parentAngle || !stringObj.childAngle) {
      return null;
    }
    return stringObj;
  };
  const setHiddenByUser = (
    index: MeasureIndex,
    isHiddenByUser = true
  ): void => {
    setAngleMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          isHiddenByUser: isHiddenByUser,
        },
      };
    });
  };
  const setIsHighlighted = (
    index: ObjectIndex,
    newIsHighlighted: boolean
  ): void => {
    setAngleMeasurements((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        isHighlighted: newIsHighlighted,
      },
    }));
  };
  return (
    <MeasureAngleContext.Provider
      value={{
        createNewAngleMeasurement,
        removeAngleMeasurement,
        angleMeasurements,
        draggableMeasure,
        setAnglePoints,
        setNewArcRadius,
        setMeasureAnnotationTextOffset,
        setMeasureAnnotationDraggable,
        removeAllDraggableAnnotations,
        removeAllDraggableArrows,
        removeAllFakeAngleMeasurements,
        setMeasureArrowDraggable,
        draggableMeasureArrow,
        setIsBeingModified,
        setIsSymbolic,
        setMagnitude,
        setUnit,
        getHidden,
        getCurrentlyModified,
        getSignedAngleOfMeasurement,
        getAngleStringOnPart,
        getAngleStringsFromInternallyConnectedBearingToPart,
        setHiddenByUser,
        setIsHighlighted,
      }}
    >
      {props.children}
    </MeasureAngleContext.Provider>
  );
}

import { Vector2 } from "features/LinearAlgebra/linearAlgebraModel";
import { areClose } from "features/LinearAlgebra/vectorUtils";

/**
 * Checks if the bearing is either close to the part end or start or to other bearings already measured
 * @param {*} pos
 * @param {*} positionsAlreadyMeasuredTo
 * @returns
 */
export function checkIfNeedsMeasure(
  pos: Vector2,
  positionsAlreadyMeasuredTo: Array<Vector2>
) {
  let isCloseToAnyMeasured = false;
  positionsAlreadyMeasuredTo?.forEach((posAlready) => {
    if (areClose(pos, posAlready)) {
      isCloseToAnyMeasured = true;
    }
  });
  if (isCloseToAnyMeasured) {
    return false;
  } else {
    return true;
  }
}

import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { checkIfAngleMeasureShouldBeHidden } from "./util/checkIfAngleMeasureShouldBeHidden";
import { useContext } from "react";

export const useRecalcHiddenAngleMeasures = (): Function => {
  const {
    getHidden,
    setIsSymbolic,
    setMagnitude,
    getSignedAngleOfMeasurement,
  } = useContext(MeasureAngleContext) as MeasureAngleContextType;
  const hiddenMeasures = getHidden();
  /**
   * this function reclculates the hidden angle measurements
   * this happens after the meausrements phase where the meausres are fixed.
   * the function for example sets the magnitude to 0°, 90° when this is the reason the measurement is hidden.
   */
  const recalcHiddenAngleMeasures = () => {
    hiddenMeasures?.forEach((hiddenMeasure) => {
      //calculate angle from measurement
      const angle = getSignedAngleOfMeasurement(hiddenMeasure.index);
      if (!angle) {
        return;
      }
      const angleIsDivisbleBy90 = checkIfAngleMeasureShouldBeHidden(angle);
      if (angleIsDivisbleBy90) {
        setIsSymbolic(hiddenMeasure.index, false);
        setMagnitude(hiddenMeasure.index, Math.round(angle));
        return;
      }
      //if there are other reasons for an angle measure to be hidden, they should be handled here
    });
  };
  return recalcHiddenAngleMeasures;
};

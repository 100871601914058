import { ArcPolar } from "./ArcPolar";

export function ArcWithArrowHead({
  angleLocal,
  strokeDashArray = "1,0",
  peakAngle,
  size = 1.5,
  startAngle = 0,
  angle,
  cx,
  cy,
  radius,
  color = "black",
  strokeWidth = 6,
  orientation = 0,
}: {
  angleLocal?: number;
  strokeDashArray?: string;
  peakAngle: number;
  size?: number;
  startAngle?: number;
  angle: number;
  cx: number;
  cy: number;
  radius: number;
  color?: string;
  strokeWidth?: number;
  orientation?: number;
}) {
  //     x2
  //      \
  //------->x1
  //      /
  //      x3
  var angleLoc = angleLocal || angle;
  var arrowSideLength = 3.5 * size;

  const sgn = angleLoc < 0 ? -1 : 1;
  const x1 = Math.cos(toRad(startAngle + angle)) * radius + cx;
  const y1 = Math.sin(toRad(startAngle + angle)) * radius + cy;

  const x2 =
    x1 +
    sgn *
      Math.sin(((angle + startAngle - peakAngle) / 360) * 2 * Math.PI) *
      arrowSideLength;
  const y2 =
    y1 -
    sgn *
      Math.cos(((angle + startAngle - peakAngle) / 360) * 2 * Math.PI) *
      arrowSideLength;

  const x3 =
    x1 +
    sgn *
      Math.cos(((90 - angle - startAngle - peakAngle) / 360) * 2 * Math.PI) *
      arrowSideLength;
  const y3 =
    y1 -
    sgn *
      Math.sin(((90 - angle - startAngle - peakAngle) / 360) * 2 * Math.PI) *
      arrowSideLength;

  return (
    <g id="arc" transform={"scale(1 1)"}>
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1={x1}
        y1={y1}
        x2={x3}
        y2={y3}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <ArcPolar
        startAngle={startAngle}
        cx={cx}
        cy={cy}
        radius={radius}
        color={color}
        strokeWidth={strokeWidth}
        orientation={orientation}
        angle={angle}
        strokeDashArray={strokeDashArray}
      />
    </g>
  );
}

function toRad(angleInDegree: number) {
  return (angleInDegree / 360) * 2 * Math.PI;
}

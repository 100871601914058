import React, {useContext } from 'react';
import {PendingContext} from '../../../Context/PendingContext';
import {DrawenContext} from '../../../Context/DrawenContext';
import {PlaygroundContext} from '../../../Context/PlaygroundContext';


export function useViewBoxManager(){
    const {isZooming,isPaning,translateX,setTranslateX,width,height,translateY,setTranslateY,currentZoom,setCurrentZoom}=useContext(PlaygroundContext);
    const margin=30;

    // viewbox: x y width height
    const viewBox=`${-margin-translateX} ${-margin+translateY} ${width/currentZoom} ${height/currentZoom}`;
    //console.log("view",viewBox)                                       fensterweite         fensterhöhe
    //console.log("tx",translateX)
    return viewBox;
}


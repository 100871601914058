import { Button, IconButton, makeStyles, Paper } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import SymbolRep from "./SymbolRep";
import { UnitOptionEl } from "./unitModel";
import { MagnitudeRep } from "./MagnitudeRep";
import { useTranslation } from "react-i18next";
const useStyles = (props: any) =>
  makeStyles((theme) => ({
    paper: {
      paddingLeft: props.padding + "px",
      paddingRight: props.padding + "px",
      background: props.paperBackground,
    },
    font: {
      fontSize: "10px",
    },
    inline: {
      display: "flex",
      justifyContent: "flex-start",
    },
    paddingTop: {
      paddingTop: "5px",
    },
    stacked: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      paddingTop: "5px",
    },
    alignVertical: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      // alignItems: "center",
      paddingTop: "5px",
    },
    alignHorizontal: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: "5px",
    },
    smallPad: {
      padding: "2px",
      minWidth: "30px",
      // width: "max-content",
    },
    horizontalButton: {
      margin: "10px",
    },
  }));
/**
 * Display of Symbol nad switchable magnitude
 * @param {*} param0
 * @returns
 */
export default function SymbolAndMagnitude({
  handleNameChange, //"string"
  handlePreFactorChange, //"string"
  handleMagnitudeChange, //"number"
  handleUnitChange, //"string"
  setSymbolic,
  symbolName = "F", //"string"
  symbolSuffix = "1",
  preFactor = "1",
  unit,
  unitFactor,
  magnitude,
  isSymbolic = true,
  unitOption,
  height = 40,
  font = 15,
  padding = 0,
  thin = false,
  elevation = 2,
  paperBackground = "white",
  disabled = false,
  alignHorizontal = false,
}: {
  handleNameChange: Function;
  handlePreFactorChange: Function;
  handleMagnitudeChange: Function;
  handleUnitChange: Function;
  setSymbolic: Function;
  symbolName: string;
  symbolSuffix?: string;
  preFactor?: string;
  unit: string;
  unitFactor: number;
  magnitude: number;
  isSymbolic?: boolean;
  unitOption: UnitOptionEl | Array<UnitOptionEl>;
  height?: number;
  font?: number;
  padding?: number;
  thin: boolean;
  elevation?: number;
  paperBackground?: string;
  disabled?: boolean;
  alignHorizontal?: boolean;
}) {
  const { t } = useTranslation();
  const classes = useStyles({ font, padding, paperBackground })();
  const handleToggleManual = () => {
    if (isSymbolic) {
      setSymbolic(false);
    } else {
      setSymbolic(true);
    }
  }; //add horizontal alignment
  return (
    <Paper elevation={elevation} className={classes.paper}>
      <div
        className={
          alignHorizontal ? classes.alignHorizontal : classes.alignVertical
        }
      >
        <SymbolRep
          {...{
            handleNameChange,
            handlePreFactorChange,
            preFactor,
          }}
          disabled={!isSymbolic || disabled}
          name={symbolSuffix}
          height={height}
          symbol={symbolName}
        />
        <Button
          className={alignHorizontal ? classes.horizontalButton : ""}
          disabled={disabled}
          title={t("Switch between symbolic and numeric")}
          classes={{
            root: classes.smallPad,
          }}
          variant={"contained"}
          color="primary"
          size="small"
          onClick={handleToggleManual}
        >
          {!alignHorizontal && <SwapVertIcon fontSize="small" />}
          {alignHorizontal && <SwapHorizIcon fontSize="small" />}
        </Button>
        <MagnitudeRep
          handleMagnitudeChange={handleMagnitudeChange}
          handleUnitChange={handleUnitChange}
          magnitude={magnitude}
          unitFactor={unitFactor}
          unit={unit}
          unitOptionEl={unitOption}
          disabled={isSymbolic || disabled}
          thin={thin}
        ></MagnitudeRep>
      </div>
    </Paper>
  );
}

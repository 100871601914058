import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { DrawenObject } from "features/Context/types/DrawenObjectModel";
import { bearingTypes } from "features/GlobalData/bearingTypes";
import { useContext } from "react";
import {
  getPartOfExternConnectedBearing,
  getPartsOfInternConnectedBearing,
} from "./util/getPartsOnBearing";

export const useCreateFreischnittVisualLoads = () => {
  const { createNewFreischnittLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const createFreischnittVisualLoads = () => {
    //create loads
    if (!drawenObjects) {
      return;
    }
    const bearings = Object.values(drawenObjects)
      .filter((drawenObj): drawenObj is DrawenObject => {
        return drawenObj.index !== "root";
      })
      .filter((drawenObj) => {
        return drawenObj.mechanicalType === "bearing";
      });
    bearings.forEach((bearing) => {
      const isConnector = bearingTypes[bearing.type].connectorType === "intern";
      if (!isConnector) {
        const partIndex = getPartOfExternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndex === null || partIndex === undefined) {
          return;
        }

        if (bearingTypes[bearing.type].reactionForceHorizontal) {
          //create Force horizontal
          createNewFreischnittLoad({
            parentIndex: partIndex,
            pos: [bearing.x, bearing.y],
            loadType: "force",
            name: bearing.bearingPositionName + "H",
            angle: bearing.angle - 180, // to create a horizontal load rotate by 180°
          });
        }
        if (bearingTypes[bearing.type].reactionForceVertical) {
          //create Force vertical
          createNewFreischnittLoad({
            parentIndex: partIndex,
            pos: [bearing.x, bearing.y],
            loadType: "force",
            name: bearing.bearingPositionName + "V",
            angle: bearing.angle - 90, // to create a vertical load rotate by 90°
          });
        }
        if (bearingTypes[bearing.type].reactionMoment) {
          //create Force vertical
          createNewFreischnittLoad({
            parentIndex: partIndex,
            pos: [bearing.x, bearing.y],
            loadType: "moment",
            name: bearing.bearingPositionName + "",
            angle: bearing.angle - 90, // to create a vertical load rotate by 90°
            orientation: +1,
          });
        }
      } else {
        //intern connector bearing
        const partIndexObj = getPartsOfInternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndexObj === null || partIndexObj === undefined) {
          return;
        }
        if (bearingTypes[bearing.type].reactionForceHorizontal) {
          //create Force horizontal
          createNewFreischnittLoad({
            //parentPart should be positive
            parentIndex: partIndexObj.parentPartIndex,
            pos: [bearing.x, bearing.y],
            loadType: "force",
            name: bearing.bearingPositionName + "H",
            angle: bearing.angle - 180, // to create a horizontal load rotate by 180°
          });
          createNewFreischnittLoad({
            //childPart should be negative --> -180°
            parentIndex: partIndexObj.childPartIndex,
            pos: [bearing.x, bearing.y],
            loadType: "force",
            name: bearing.bearingPositionName + "H",
            angle: bearing.angle - 180 - 180, // to create a horizontal load rotate by 180° to create negative schniitufer another -180°
          });
        }
        if (bearingTypes[bearing.type].reactionForceVertical) {
          //create Force vertical
          createNewFreischnittLoad({
            parentIndex: partIndexObj.parentPartIndex,
            pos: [bearing.x, bearing.y],
            loadType: "force",
            name: bearing.bearingPositionName + "V",
            angle: bearing.angle - 90, // to create a vertical load rotate by 90°
          });
          createNewFreischnittLoad({
            parentIndex: partIndexObj.childPartIndex,
            pos: [bearing.x, bearing.y],
            loadType: "force",
            name: bearing.bearingPositionName + "V",
            angle: bearing.angle - 90 - 180, // to create a vertical load rotate by 90°
          });
        }
        if (bearingTypes[bearing.type].reactionMoment) {
          //create Force vertical
          createNewFreischnittLoad({
            parentIndex: partIndexObj.parentPartIndex,
            pos: [bearing.x, bearing.y],
            loadType: "moment",
            name: bearing.bearingPositionName + "",
            angle: bearing.angle - 90, // to create a vertical load rotate by 90°
            orientation: +1,
          });
          createNewFreischnittLoad({
            parentIndex: partIndexObj.childPartIndex,
            pos: [bearing.x, bearing.y],
            loadType: "moment",
            name: bearing.bearingPositionName + "",
            angle: bearing.angle - 90 - 180, // to create a vertical load rotate by 90°
            orientation: -1, // change orientation of moment for it to be negative schnittufer
          });
        }
      }
    });
  };
  return createFreischnittVisualLoads;
};

import { Point } from "features/LinearAlgebra/linearAlgebraModel";

export function SvgLine(props: {
  point1: Point;
  point2: Point;
  [key: string]: any;
  [key: number]: any;
}) {
  const { point1, point2, ...rest } = props;
  return (
    <line
      x1={point1[0]}
      x2={point2[0]}
      y1={point1[1]}
      y2={point2[1]}
      {...rest}
    />
  );
}

import { Grid, Box, Paper, makeStyles } from "@material-ui/core";
import "./reset.css";
import { RightBar } from "./features/Components/Functionality_Right/RightBar";
import { BottomBar } from "./features/Components/Functionality_Bottom/BottomBar";
import { PlaygroundRoot } from "./features/Components/Playground/PlaygroundRoot";
import { LeftPanel } from "features/Components/Functionality_Left/LeftPanel";
import { useDragConsole } from "features/Components/Functionality_Bottom/Console/useDragConsole";
const useStyles = makeStyles({
  noBorderRadius: {
    borderRadius: "0px",
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  posRel: {
    position: "relative",
  },
  outerContainer: {
    width: "100%",
    position: "relative",
    height: "100%",
    overflow: "hidden",
  },
});

export default function AppLayout() {
  const style = useStyles();
  const { consoleMargin, handleDragEnd, handleDragStart, handleMouseMove } =
    useDragConsole();
  return (
    <div
      className={style.outerContainer}
      onMouseUp={(e) => handleDragEnd(e)}
      onMouseMove={(e) => handleMouseMove(e)}
    >
      <Grid
        className={style.fullHeight}
        container
        spacing={0}
        justify={"space-between"}
        alignItems={"stretch"}
        alignContent={"stretch"}
      >
        <Grid item xs={2} style={{ height: "100%" }}>
          <Paper className={style.noBorderRadius + " " + style.fullHeight}>
            <Box borderRight={1} className={style.fullHeight}>
              <LeftPanel></LeftPanel>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={7} className={style.fullHeight}>
          <Grid
            className={style.fullHeight}
            container
            direction="column"
            justify="space-between"
          >
            <Grid
              item
              style={{
                height: "calc(80% - (" + consoleMargin + "px))",
                width: "100%",
              }}
            >
              <PlaygroundRoot></PlaygroundRoot>
            </Grid>
            <Grid
              item
              style={{
                height: "calc(20% + (" + consoleMargin + "px))",
                width: "100%",
              }}
            >
              <Paper
                className={
                  style.noBorderRadius +
                  " " +
                  style.fullWidth +
                  " " +
                  style.fullHeight +
                  " " +
                  style.posRel
                }
              >
                <Box borderRight={1} style={{ height: "100%", width: "100%" }}>
                  <BottomBar dragStartConsole={handleDragStart}></BottomBar>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className={style.fullHeight}>
          <Paper className={style.noBorderRadius + " " + style.fullHeight}>
            <Box borderLeft={1} className={style.fullHeight}>
              <RightBar />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

import { makeStyles } from "@material-ui/core";
import {
  PlaygroundContext,
  PlaygroundContextType,
} from "features/Context/PlaygroundContext";
import { useContext, useEffect, useRef } from "react";
import { useRect } from "../BearingEditor/Hooks/useRect";
import BearingEditor from "../Functionality_Bottom/BearingEditor";
import { BuildingStepper } from "../Functionality_Bottom/BuildingStepper";
import { ZoomAndPan } from "../Functionality_Bottom/ZoomAndPan/ZoomAndPan";
import SvgDownloadButton from "./Functionality/SvgDownloadButton";
import { Playground } from "./Playground";
import { usePanFunctions } from "./playgroundObjectHooks/usePanFunctions";
import { useZoomFunctions } from "./playgroundObjectHooks/useZoomFunctions";
import { LanguageSwitchButton } from "featuresGeneral/LanguageSwitchButton";
import { HomeButton } from "featuresGeneral/HomeButton";
const useStyles = makeStyles((theme) => ({
  fullScreen: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  absoluteTop: {
    position: "absolute",
    width: "calc(100% - 5px)",
    display: "flex",
    top: "0px",
    justifyContent: "space-between",
    padding: "5px",
  },
  absoluteBottom: {
    position: "absolute",
    width: "calc(100% - 5px)",
    display: "flex",
    justifyContent: "flex-end",
    bottom: "0px",
    right: "10px",
    padding: "5px",
  },
  innerShadow: {
    boxShadow: "inset 0px 0px 8px 1px rgba(0,0,0,0.25)",
  },
}));

export function PlaygroundRoot() {
  const classes = useStyles();
  const svgContainer = useRef(null);

  const svgBbox = useRect(svgContainer);

  const { setHeight, setWidth } = useContext(
    PlaygroundContext
  ) as PlaygroundContextType;
  useEffect(() => {
    setHeight(svgBbox.height);
    setWidth(svgBbox.width);
  }, [svgBbox]);
  const { handlePanStart, handlePanMove, handlePanEnd } = usePanFunctions();
  const { handleZoom } = useZoomFunctions();

  return (
    <div
      ref={svgContainer}
      className={classes.fullScreen}
      onMouseUp={(e) => {
        handlePanEnd(e);
      }}
      onMouseDown={(e) => {
        handlePanStart(e);
      }}
      onMouseMove={(e) => {
        handlePanMove(e);
      }}
      onWheel={(e) => handleZoom(e)}
    >
      <div className={classes.absoluteTop}>
        <BuildingStepper />
      </div>
      <div className={classes.absoluteBottom}>
        {/* <BearingEditor></BearingEditor> */}
        <ZoomAndPan></ZoomAndPan>
        <SvgDownloadButton
          downLoadName="System.svg"
          elementID="playground"
        ></SvgDownloadButton>
        <BearingEditor></BearingEditor>
        <LanguageSwitchButton color="#707070"></LanguageSwitchButton>
        <HomeButton></HomeButton>
      </div>
      <div className={classes.innerShadow}>
        <Playground />
      </div>
    </div>
  );
}

import { useContext } from "react";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";

import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";

import { Slider } from "../SystemMode/GeometryControls/InputComponents/Slider";
import { useMoreMarksLoad } from "./util/useMoreMarksLoad";
import { addV, distance, sM } from "features/LinearAlgebra/vectorUtils";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { DrawenObject } from "features/Context/types/DrawenObjectModel";
import { ControlAccordion } from "../VisualElements/ControlAccordion";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function PositionLoadControl() {
  const { t } = useTranslation();
  const { pendingLoad, setPendingLoadPos } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;

  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  //@ts-ignore
  const parent = drawenObjects[pendingLoad.parentIndex] as DrawenObject;

  const maxVal = (parent.size as any).width;
  //@ts-ignore

  const distancer = distance(pendingLoad.pos, [parent.x, parent.y]);
  //   const percentage = (distance / pendingObject.width) * 100;
  const handleChange = (newDistance: number) => {
    //@ts-ignore
    const lineParent = getLineFromPart(parent);
    const [parentStart] = lineParent;
    const newPoint = addV(parentStart, sM(newDistance, getLineDir(lineParent)));
    setPendingLoadPos(newPoint);
  };
  let marks = [
    { value: maxVal / 4, label: "1/4" },
    { value: maxVal / 3, label: "1/3" },
    { value: maxVal / 2, label: "1/2" },
    { value: (maxVal / 3) * 2, label: "2/3" },
    { value: (maxVal / 4) * 3, label: "3/4" },
  ];
  const moreMarks = useMoreMarksLoad(parent, marks);
  const additionalmarks = moreMarks();
  if (additionalmarks) {
    marks = [...marks, ...additionalmarks];
  }

  return (
    <ControlAccordion
      titleMainElement={<Typography>{t("Load Position")}</Typography>}
      mainContent={
        <div className={"PositionloadControl"}>
          <Slider
            description={""}
            min={0}
            step={1}
            max={maxVal}
            marks={marks}
            value={distancer}
            handleChange={handleChange}
          />
        </div>
      }
      expanded={true}
    ></ControlAccordion>
  );
}

import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "features/Context/MeasureDistanceContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { DrawenObject } from "features/Context/types/DrawenObjectModel";
import { Point } from "features/LinearAlgebra/linearAlgebraModel";
import { useContext } from "react";
import { checkIfDistMeasureShouldBeHidden } from "./util/checkIfDistMeasureShouldBeHidden";
import { checkIfNeedsMeasure } from "./util/checkIfNeedsMeasure";
import { checkIfStreckenlastAlreadyMeasuresPart } from "./util/checkIfStreckenlastAlreadyMeasuresPart";
import { getAllBearingsFromPart } from "./util/getAllBearingsOnPart";
import {
  getForcesAndMomentsFromPart,
  getStreckenlastFromPart,
} from "./util/getAllLoadsFromPart";
import { getAllParts } from "./util/getAllParts";
import { getBearingPos } from "./util/getBearingPos";
import {
  LeftPaneContext,
  LeftPaneContextType,
} from "features/Context/LeftPaneContext";
/**
 * hook to create a distance measurement function
 * @param isFake boolean: if true the measurements are tagged fake measurements and are not really considered
 * @returns a function that creates all distance measurements, that are not yet created
 */
export function useChangeToMeasurements(isFake: boolean = false): Function {
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const { loadObjects } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { createNewMeasurement, measurements, setNewLineOffset } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const { setVisibleViewLeftPane } = useContext(
    LeftPaneContext
  ) as LeftPaneContextType;
  let lineOffsetObj: { [key: number]: number } = {};
  const getNewLineOffset = (
    partIndex: MeasureIndex,
    lineOffsetObj: { [key: number]: number }
  ): number => {
    if (!Object.keys(lineOffsetObj).includes(String(partIndex))) {
      //the part is not yet measured to
      const INITIAL_MEASURE_OFFSET = 70;
      lineOffsetObj[partIndex] = INITIAL_MEASURE_OFFSET;
      return INITIAL_MEASURE_OFFSET;
    }
    const INCREMENTAL_MEASURE_OFFSET = 27;
    lineOffsetObj[partIndex] =
      lineOffsetObj[partIndex] + INCREMENTAL_MEASURE_OFFSET;
    return lineOffsetObj[partIndex];
  };
  const handleSwitchToMeasurementStatus = () => {
    const parts = getAllParts(drawenObjects);
    parts.forEach((part) => {
      const mountedBearings = getAllBearingsFromPart(drawenObjects, part);
      const mountedLoads = loadObjects
        ? getForcesAndMomentsFromPart(loadObjects, part)
        : null;

      let positionsAlreadyMeasuredTo: Array<Point> = [];

      const streckenLastLoads = loadObjects
        ? getStreckenlastFromPart(loadObjects, part)
        : null;

      if (streckenLastLoads) {
        // edge case of streckenlast having the same length as the part needs to be covered to not create 2 measurements of the same length
        streckenLastLoads?.forEach((streckenlastLoad) => {
          const measureIndex1 = streckenlastLoad.loadSpec.startMeasurementIndex;
          const measureIndex2 =
            streckenlastLoad.loadSpec.lengthMeasurementIndex;
          if (!measurements) {
            return;
          }
          const startLine = measurements[measureIndex1].initialLine;
          const lengthline = measurements[measureIndex2].initialLine;
          const [p1, p2] = startLine;
          const [p3, p4] = lengthline;
          positionsAlreadyMeasuredTo.push(p1, p2, p3, p4); // add all measurement point of the streckenlast

          //@ts-ignore
          const [start, end] = getLineFromPart(part);
          //total part measure
          const isHidden =
            checkIfDistMeasureShouldBeHidden([start, end]) ||
            checkIfStreckenlastAlreadyMeasuresPart(
              startLine,
              lengthline,
              // @ts-ignore
              getLineFromPart(part)
            );
          //measurement for entire part when there are streckenlasts
          const index = createNewMeasurement({
            initialLine: [start, end],
            isHidden: isHidden,
            fromAttachment: "start",
            toAttachment: "end",
            ownerIndex: part.index,
            isFake: isFake,
          });
          const lineOffset = getNewLineOffset(part.index, lineOffsetObj);
          setNewLineOffset(index, lineOffset);
          positionsAlreadyMeasuredTo.push(start, end);
        });
      } else {
        // part without streckenlast
        //@ts-ignore
        const [start, end] = getLineFromPart(part);
        //total part measure
        const isHidden = checkIfDistMeasureShouldBeHidden([start, end]);
        //measurement for entire part
        const index = createNewMeasurement({
          initialLine: [start, end],
          isHidden: isHidden,
          fromAttachment: "start",
          toAttachment: "end",
          ownerIndex: part.index,
          isFake: isFake,
        });
        const lineOffset = getNewLineOffset(part.index, lineOffsetObj);
        setNewLineOffset(index, lineOffset);
        positionsAlreadyMeasuredTo.push(start, end);
      }
      //@ts-ignore
      const [start, _] = getLineFromPart(part);
      mountedBearings?.forEach((bearing) => {
        const end = getBearingPos(bearing as DrawenObject);
        const needsMeasure = checkIfNeedsMeasure(
          end,
          positionsAlreadyMeasuredTo
        );
        const isHidden =
          checkIfDistMeasureShouldBeHidden([start, end]) || !needsMeasure;
        //measurement for each bearing from part start to bearing
        const index = createNewMeasurement({
          initialLine: [start, end],
          isHidden: isHidden,
          fromType: "part",
          fromAttachment: "start",
          toType: "bearing",
          fromIndex: part.index,
          toIndex: (bearing as DrawenObject).index,
          isFake: isFake,
        });
        const lineOffset = getNewLineOffset(part.index, lineOffsetObj);
        setNewLineOffset(index, lineOffset);
        positionsAlreadyMeasuredTo.push(end);
      });

      mountedLoads?.forEach((load) => {
        const needsMeasure = checkIfNeedsMeasure(
          load.pos,
          positionsAlreadyMeasuredTo
        );
        //loadMeasure
        const isHidden =
          checkIfDistMeasureShouldBeHidden([start, load.pos]) || !needsMeasure;
        const index = createNewMeasurement({
          initialLine: [start, load.pos],
          isHidden: isHidden,
          fromType: "part",
          fromAttachment: "start",
          toType: load.loadType,
          fromIndex: part.index,
          toIndex: load.index,
          isFake: isFake,
        });
        const lineOffset = getNewLineOffset(part.index, lineOffsetObj);
        setNewLineOffset(index, lineOffset);
        positionsAlreadyMeasuredTo.push(load.pos);
      });
    });
    //show the measurement pane
    if (!isFake) {
      setVisibleViewLeftPane((prev) => "measurementsView");
    }
  };
  return handleSwitchToMeasurementStatus;
}

import { useState, createContext } from "react";
export type VisibleViewLeftPane = "measurementsView" | "mountingTreeView";
export const LeftPaneContext = createContext<LeftPaneContextType | null>(null);
export type LeftPaneContextType = {
  visibleViewLeftPane: VisibleViewLeftPane;
  setVisibleViewLeftPane: React.Dispatch<
    React.SetStateAction<VisibleViewLeftPane>
  >;
};
export function LeftPaneProvider(props: any) {
  const [visibleViewLeftPane, setVisibleViewLeftPane] =
    useState<VisibleViewLeftPane>("mountingTreeView");

  return (
    <LeftPaneContext.Provider
      value={{ visibleViewLeftPane, setVisibleViewLeftPane }}
    >
      {props.children}
    </LeftPaneContext.Provider>
  );
}

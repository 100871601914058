export function MomentBalanceIcon({
  size = 24,
  strokeWidth = 2,
  color = "black",
}: {
  size?: number;
  strokeWidth?: number;
  color?: string;
}) {
  const viewBox = `${215} ${320} ${70} ${70}`;
  return (
    <svg width={size + "px"} height={size + "px"} viewBox={viewBox}>
      <g transform="translate(0 506.2949890136719) rotate(0) scale(1 -1)">
        <g transform="translate(250,150) scale(1 -1) translate(0,-2.5) rotate(0,0,2.5)">
          <rect
            x="0"
            y="0"
            width="40"
            height="5"
            fill="white"
            stroke={color}
            strokeWidth={strokeWidth}
          ></rect>
        </g>
        <g>
          <g>
            <g transform="scale(-1 1) translate(-500 0)">
              <polyline
                points="283.98554978509037, 137.630271483055 275.79491207357825, 127.30919765375475 276.1547779452078, 140.48043934410222 283.98554978509037, 137.630271483055 279.8902309293343, 132.46973456840487 "
                fill={color}
                stroke={color}
                strokeWidth={strokeWidth}
              ></polyline>
              <g id="arc" transform="scale(1 1)">
                <path
                  d="M 280.0701638651491 139.0553554135786 A 32 32 0 1 1  219.92964511934738 139.05588023861162"
                  stroke={color}
                  strokeWidth={strokeWidth}
                  strokeDasharray="1,0"
                  fill="none"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

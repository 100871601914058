import { CordsArrow } from "features/Components/Playground/Cords/CordsArrow";
import { SvgLine } from "features/Components/Playground/Cords/SvgLine";

export function StreckenlastIcon({
  size = 24,
  strokeWidth = 4,
  color = "black",
}: {
  size?: number;
  strokeWidth?: number;
  color?: string;
}) {
  const viewBox = `${0} ${-45} ${80} ${50}`;
  return (
    <svg width={size + "px"} height={size + "px"} viewBox={viewBox}>
      <CordsArrow
        // stroke needs to be 0 to make the peaks of the arrows perfect
        polyStrokeWidth={0}
        strokeWidth={strokeWidth}
        color={color}
        peakAngle={30}
        polarDest={{ destX: 10, destY: 0, angle: 90, length: 40 }}
      />
      <CordsArrow
        // stroke needs to be 0 to make the peaks of the arrows perfect
        polyStrokeWidth={0}
        strokeWidth={strokeWidth}
        color={color}
        peakAngle={30}
        polarDest={{ destX: 40, destY: 0, angle: 90, length: 40 }}
      />
      <CordsArrow
        // stroke needs to be 0 to make the peaks of the arrows perfect
        polyStrokeWidth={0}
        strokeWidth={strokeWidth}
        color={color}
        peakAngle={30}
        polarDest={{ destX: 70, destY: 0, angle: 90, length: 40 }}
      />
      <SvgLine
        strokeWidth={strokeWidth}
        stroke={color}
        point1={[10, -40]}
        point2={[70, -40]}
      ></SvgLine>
    </svg>
  );
}

import { makeStyles } from "@material-ui/core";
import { ImgWithHeader } from "./ImgWithHeader";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    width: "100%",
    flexWrap: "wrap",
  },
}));
export type ImgObj = { imgSrc: string; header: string; width: number };
export function SystemExample({ imgObjs }: { imgObjs: Array<ImgObj> }) {
  const classes = useStyles();
  const imgEls = imgObjs.map((imgObj) => {
    return (
      <ImgWithHeader
        imgSource={imgObj.imgSrc}
        header={imgObj.header}
        width={imgObj.width}
      ></ImgWithHeader>
    );
  });
  return <div className={classes.flex}>{imgEls}</div>;
}

import React, { useState } from "react";
import LatexSvg from "./LatexSvg";

export default function LatexForHTML({ height = 40, color = "black", latex }) {
  const [width, setWidth] = useState(height);
  return (
    <svg
      width={width}
      height={height}
      //   viewport={`0 ${-height} ${width} 0`}
      transform={" scale(1 -1)"}
    >
      <LatexSvg
        y={height}
        latex={latex}
        height={height}
        color={color}
        hideHelper
        widthReturner={setWidth}
      />
    </svg>
  );
}

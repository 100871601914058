import { IconButton, makeStyles } from "@material-ui/core";
import { CoordinateSystem } from "features/Components/Playground/Cords/CoordinateSystem";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});
export default function RootElementVisual({
  text,
  visibilityCoordinateSystem,
  setVisibilityCoordinateSystem,
}: {
  text: string;
  visibilityCoordinateSystem: boolean;
  setVisibilityCoordinateSystem: Function;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  let viewBox = `${-20} ${-20} ${100} ${100}`;
  return (
    <div className={classes.flexBetween}>
      <div className={classes.flex}>
        <svg
          width={"30px"}
          height={"30px"}
          transform="scale(1 -1)"
          viewBox={viewBox}
        >
          <CoordinateSystem size={10} hasAnnotation={false}></CoordinateSystem>
        </svg>
        {text}
      </div>
      {visibilityCoordinateSystem && (
        <IconButton
          size="small"
          title={t("Hide Coordinate System")}
          onClick={() => {
            setVisibilityCoordinateSystem(false);
          }}
        >
          <VisibilityIcon fontSize="small"></VisibilityIcon>
        </IconButton>
      )}
      {!visibilityCoordinateSystem && (
        <IconButton
          size="small"
          title={t("Show Coordinate System")}
          onClick={() => {
            setVisibilityCoordinateSystem(true);
          }}
        >
          <VisibilityOffIcon fontSize="small"></VisibilityOffIcon>
        </IconButton>
      )}
    </div>
  );
}

import PolyLine from "features/Components/Playground/Cords/PolyLine";
import { elongateLine } from "features/LinearAlgebra/elongateLine";
import {
  areCloseScalar,
  distance,
  normalize,
  scalarProd,
  subV,
} from "features/LinearAlgebra/vectorUtils";

export default function LShape({
  startOfBiggerInSmaller,
  startOfSmallerInBigger,
  endOfBiggerInSmaller,
  endOfSmallerInBigger,
  intersects,
  outers,
  child,
  parent,
  biggerPart,
  smallerPart,
  strokeWidth,
  strokeColor,
}) {
  const [rr, rl, ll, lr] = intersects;
  let pointAtSmall, pointAtInsideCorner, pointAtBig, pointAtOutsideCorner;
  const relativeAngle = Math.abs(smallerPart.angle - biggerPart.angle);
  let startOfBiggerInSmallerConditional1 =
    relativeAngle > 180 ? !startOfBiggerInSmaller : startOfBiggerInSmaller;
  let startOfSmallerInBiggerConditional1 =
    relativeAngle > 180 ? !startOfSmallerInBigger : startOfSmallerInBigger;
  let endOfSmallerInBiggerConditional1 =
    relativeAngle > 180 ? !endOfSmallerInBigger : endOfSmallerInBigger;
  let endOfBiggerInSmallerConditional1 =
    relativeAngle > 180 ? !endOfBiggerInSmaller : endOfBiggerInSmaller;
  const childIsSmallerOrSame = child.size.height <= parent.size.height;
  let startOfBiggerInSmallerConditional = childIsSmallerOrSame
    ? !startOfBiggerInSmallerConditional1
    : startOfBiggerInSmallerConditional1;
  let startOfSmallerInBiggerConditional = childIsSmallerOrSame
    ? !startOfSmallerInBiggerConditional1
    : startOfSmallerInBiggerConditional1;
  let endOfSmallerInBiggerConditional = childIsSmallerOrSame
    ? !endOfSmallerInBiggerConditional1
    : endOfSmallerInBiggerConditional1;
  let endOfBiggerInSmallerConditional = childIsSmallerOrSame
    ? !endOfBiggerInSmallerConditional1
    : endOfBiggerInSmallerConditional1;
  if (startOfBiggerInSmallerConditional && startOfSmallerInBiggerConditional) {
    pointAtInsideCorner = lr;
    pointAtSmall = ll;
    pointAtBig = rr;
    pointAtOutsideCorner = rl;
  } else if (
    endOfBiggerInSmallerConditional &&
    endOfSmallerInBiggerConditional
  ) {
    pointAtInsideCorner = rl;
    pointAtSmall = rr;
    pointAtBig = ll;
    pointAtOutsideCorner = lr;
  } else if (
    endOfBiggerInSmallerConditional &&
    startOfSmallerInBiggerConditional
  ) {
    pointAtInsideCorner = ll;
    pointAtSmall = lr;
    pointAtBig = rl;
    pointAtOutsideCorner = rr;
  } else if (
    startOfBiggerInSmallerConditional &&
    endOfSmallerInBiggerConditional
  ) {
    pointAtInsideCorner = rr;
    pointAtSmall = rl;
    pointAtBig = lr;
    pointAtOutsideCorner = ll;
  } else {
    pointAtInsideCorner = rr;
    pointAtSmall = rl;
    pointAtBig = lr;
    pointAtOutsideCorner = ll;
  }
  const diffDirectedToOutsidePoint = normalize(
    subV(pointAtBig, pointAtOutsideCorner)
  );
  let fittingOuter = pointAtBig;
  outers.forEach((outerPoint) => {
    const diffOuterToIntersect = normalize(subV(outerPoint, pointAtBig));
    if (
      areCloseScalar(
        scalarProd(diffDirectedToOutsidePoint, diffOuterToIntersect),
        1
      )
    ) {
      //outer point is in correct direction
      fittingOuter = outerPoint;
    }
  });
  //elongate lines
  fittingOuter = elongateLine(
    [pointAtOutsideCorner, fittingOuter],
    2 * strokeWidth
  )[1];
  const distanceOutside = distance(fittingOuter, pointAtBig);
  const pointAtSmallOutside = elongateLine(
    [pointAtOutsideCorner, pointAtSmall],
    2 * strokeWidth
  )[1];
  let pointAtInsideCornerSmall, pointAtInsideCornerBig;
  pointAtInsideCornerSmall = elongateLine(
    [pointAtBig, pointAtInsideCorner],
    2 * strokeWidth
  )[1];
  pointAtInsideCornerBig = elongateLine(
    [pointAtSmall, pointAtInsideCorner],
    distanceOutside
  )[1];
  return (
    <>
      <PolyLine
        points={[pointAtSmallOutside, pointAtOutsideCorner, fittingOuter]}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
      <PolyLine
        points={[
          pointAtInsideCornerSmall,
          pointAtInsideCorner,
          pointAtInsideCornerBig,
        ]}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
    </>
  );
}

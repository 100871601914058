import React, { useState, useContext } from "react";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import {
  FormHelperText,
  FormControl,
  NativeSelect,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { RotationInput } from "../SystemMode/GeometryControls/InputComponents/RotationInput";
import { useReferenceOptions } from "../SystemMode/GeometryControls/referenceHooks/useReferenceOptions";
import { Index } from "features/Context/types/DrawenObjectModel";
import { ControlAccordion } from "../VisualElements/ControlAccordion";
import { inputColor } from "features/Components/StyleOnlyComponents/Color";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  input: {
    background: inputColor,
  },
}));
export function AngleControlLoad() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setPendingLoadAngle, pendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const [angleReferenceIndex, setAngleReferenceIndex] = useState<Index>("root");
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  //@ts-ignore
  const [relativeAngle, setRelativeAngle] = useState(pendingLoad.angle);
  const displayAngle = relativeAngle;

  const refAngleOptions = useReferenceOptions();

  const handleRefAngleChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // new selection in dropdown menu
    const refIndex = parseInt(event.target.value);
    setAngleReferenceIndex(refIndex);
    setPendingLoadAngle(drawenObjects[refIndex].angle);
    setRelativeAngle(0);
  };
  const handleChange = (newDisplayAngle: number) => {
    const refDrawenObject = drawenObjects[angleReferenceIndex];
    if (!refDrawenObject) {
      return;
    }
    setRelativeAngle(newDisplayAngle);
    setPendingLoadAngle(newDisplayAngle + refDrawenObject.angle); // add the input angle to the angle of the reference object to get absolute total angle
  };
  const marks = [
    { value: 45, label: "45°" },
    { value: 90, label: "90°" },
    { value: 135, label: "135°" },
    { value: 180, label: "180°" },
    { value: 225, label: "225°" },
    { value: 270, label: "270°" },
    { value: 315, label: "315°" },
  ];
  return (
    <ControlAccordion
      titleMainElement={<Typography> {t("Load Angle")}</Typography>}
      mainContent={
        <div className={"AngleControl"}>
          <RotationInput
            handleAngleChange={handleChange}
            imgWidth={50}
            imgHeight={50}
            description=""
            angle={displayAngle}
            min={0}
            max={360}
            marks={marks}
            showPizza={false}
          />
          <FormControl className={""}>
            <FormHelperText>{t("Angle is relative to:")}</FormHelperText>

            <NativeSelect
              value={angleReferenceIndex}
              onChange={handleRefAngleChange}
              inputProps={{
                className: classes.input,
                name: t("Angle"),
                id: "Angle-native-label-placeholder",
              }}
            >
              {refAngleOptions}
            </NativeSelect>
          </FormControl>
        </div>
      }
      expanded={true}
    ></ControlAccordion>
  );
}

import { useLoadSpecification } from "features/GlobalData/useLoadSpecification";
import { Point, Vector2 } from "features/LinearAlgebra/linearAlgebraModel";
import { useState, createContext, useContext, useRef } from "react";
import { DrawenContext, DrawenContextType } from "./DrawenContext";
import { LoadStyleContext, LoadStyleContextType } from "./LoadStyleContext";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "./MeasureDistanceContext";
import { LoadNames } from "./types/ConstructionElementModel";
import { DrawenObject, Index, ObjectIndex } from "./types/DrawenObjectModel";
import {
  Load,
  LoadColor,
  LoadIndex,
  LoadObjects,
  MomentOrientation,
} from "./types/LoadModel";
import { getInitialLoadPos } from "./util/getInitialLoadPos";
import { useRemoveMeasurementsFromLoad } from "./util/useRemoveMeasurementsFromLoad";
import {
  primaryColor,
  primaryColorDark,
} from "features/Components/StyleOnlyComponents/Color";
import { areCloseScalar } from "features/LinearAlgebra/vectorUtils";

export const PendingLoadContext = createContext<PendingLoadContextType | null>(
  null
);
export type PendingLoadContextType = {
  initializePendingLoad: (
    parentIndex: ObjectIndex,
    loadType: LoadNames,
    isFake?: boolean
  ) => void;
  isPendingLoad: boolean;
  setPendingLoadType: (loadType: LoadNames) => void;
  loadObjects: LoadObjects | null;
  pendingLoad: Load | null | undefined;
  setPendingLoadPos: (point: Point) => void;
  savePendingLoad: () => void;
  createNewFreischnittLoad: (inputObj: {
    parentIndex: Index;
    pos: Point;
    loadType: LoadNames;
    name: string | number;
    angle: number;
    orientation?: MomentOrientation;
  }) => void;
  deletePendingLoad: () => void;
  setPendingLoadAngle: (angle: number) => void;
  setPendingLoadColor: (colorObj: LoadColor) => void;
  setPendingLoadName: (name: string) => void;
  setPendingLoadMagnitude: (mag: number) => void;
  setPendingUnit: (unitSymbol: string, unitFactor: number) => void;
  setPendingSymbolic: (isSymbolic: boolean) => void;
  setLoadName: (index: LoadIndex, name: string) => void;
  setLoadMagnitude: (index: LoadIndex, mag: number) => void;
  setLoadUnit: (
    index: LoadIndex,
    unitSymbol: string,
    unitFactor: number
  ) => void;
  setLoadIsSymbolic: (index: LoadIndex, isSymbolic: boolean) => void;
  setLoadPreFactor: (index: LoadIndex, preFactor: string) => void;
  deleteLoad: (index: MeasureIndex) => void;
  loadWithDraggableAnnotation: Load | null | undefined;
  setTextOffset: (offset: Vector2, index: ObjectIndex) => void;
  setLoadAnnotationDraggable: (
    isDraggable: boolean,
    index: ObjectIndex,
    annotationIndex?: string | boolean
  ) => void;
  setPendingOrientation: (number: MomentOrientation) => void;
  removeAllDraggableLoads: () => void;
  getLoadsWithParentIndex: (parentIndex: Index) => Array<Load> | null;
  setPendingPrefactor: (preFactor: string) => void;
  getParent: (load: Load) => DrawenObject | null | undefined;
  setPendingStreckenlastPos: (start: Point, end: Point) => void;
  setPendingStreckenlastHeight: (
    startHeight: number,
    endHeight: number
  ) => void;
  loadHasDraggableAnnotation: (load: Load) => boolean;
  setPendingLoadSpec: (loadSpecObj: any) => void;
  resetHighestLoadIndex: () => void;
  setIsHighlighted: (index: MeasureIndex, newIsHighlighted: boolean) => void;
  setIsHiddenByUser: (index: MeasureIndex, newIsHiddenByUser: boolean) => void;
  setPendingLoadTypeWasSelected: (wasSelected: boolean) => void;
};

export function PendingLoadProvider(props: any) {
  const [isPendingLoad, setIsPendingLoad] = useState(false);
  const [pendingIndex, setPendingIndex] = useState(1);
  const [loadObjects, setLoadObjects] = useState<LoadObjects | null>(null);
  // const [highestLoadIndex, setHighestLoadIndex] = useState(1);
  const highestLoadIndex = useRef(0);
  const { setInitialLine } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const removeMeasurementsFromLoad = useRemoveMeasurementsFromLoad();
  const pendingLoad = loadObjects
    ? Object.values(loadObjects).find((el: Load) => el.isPending)
    : null;

  const { color1, color2, textColor, fontSize, size } = useContext(
    LoadStyleContext
  ) as LoadStyleContextType;
  const loadSpecification = useLoadSpecification();
  const resetHighestLoadIndex = () => {
    highestLoadIndex.current = 1;
  };
  const initializePendingLoad = (
    parentIndex: ObjectIndex,
    loadType: LoadNames = "force",
    isFake: boolean = false,
    loadTypeWasSelected: boolean = false
  ) => {
    const newIndex = highestLoadIndex.current;
    const parentPart = drawenObjects[parentIndex];

    const newPos = loadObjects
      ? getInitialLoadPos(parentPart, loadObjects)
      : getInitialLoadPos(parentPart, {});

    highestLoadIndex.current = highestLoadIndex.current + 1;
    setPendingIndex(newIndex);
    const loadSpec = loadSpecification(loadType, newIndex, parentPart);
    setLoadObjects((prev) => {
      const name = !prev ? "1" : getLowestAvailableSuffix(prev);
      return {
        ...prev,
        [newIndex]: {
          index: newIndex,
          isPending: true,
          isFreischnittGhostLoad: false,
          parentIndex: parentIndex,
          pos: newPos,
          x: newPos[0],
          y: newPos[1],
          angle: drawenObjects[parentIndex].angle + 90,
          loadType: loadType, //moment,streckenlast
          loadSpec: { ...loadSpec },
          color1: color1,
          color2: color2,
          textColor: textColor,
          fontSize: fontSize,
          annotationIsDraggable: false,
          textOffsetX: 0,
          textOffsetY: 0,
          size: size,
          preFactor: "1",
          isSymbolic: true,
          measurements: [],
          magnitude: 1, //number  always is in Si units
          isHighlighted: false,
          name: name, // name of Load e.g. F_a
          isFake: isFake,
          isHiddenByUser: false,
          loadTypeWasSelected,
        },
      };
    });
    setIsPendingLoad(true);
  };
  /**
   *
   * @param measurements
   * @returns
   */
  const getLowestAvailableSuffix = (loads: LoadObjects) => {
    const indices = Object.values(loads)
      .filter((load) => {
        const isNumber = !isNaN(load.name as any);
        return isNumber;
      })
      .map((load) => {
        return parseInt(String(load.name));
      })
      .sort();
    if (indices.length === 0) {
      return "1";
    }
    const maxValue = indices.slice(-1)[0];
    for (let i = 0; i < maxValue; i++) {
      if (indices[i] > i + 1) {
        return String(i + 1);
      }
    }
    return String(maxValue + 1);
  };
  const getPendingIndex = (loads: LoadObjects): LoadIndex | null => {
    const loadArr = Object.values(loads);
    for (const load of loadArr) {
      if (load.isPending) {
        return load.index;
      }
    }
    return null;
  };
  const createNewFreischnittLoad = ({
    parentIndex,
    pos,
    loadType,
    name,
    angle,
    orientation = 1,
    isFake = false,
  }: {
    parentIndex: Index;
    pos: Point;
    loadType: LoadNames;
    name: string | number;
    angle: number;
    orientation?: MomentOrientation;
    isFake?: boolean;
  }): void => {
    if (!drawenObjects || !drawenObjects[parentIndex]) {
      return;
    }
    const colorFreischnittLoad = primaryColorDark;
    setLoadObjects((prev) => {
      let newIndex = highestLoadIndex.current;
      highestLoadIndex.current = highestLoadIndex.current + 1;
      return {
        ...prev,
        [newIndex]: {
          index: newIndex,
          isPending: false,
          isFreischnittGhostLoad: true,
          parentIndex: parentIndex,
          pos: pos,
          x: pos[0],
          y: pos[1],
          angle: angle,
          loadType: loadType, //moment,streckenlast
          loadSpec:
            loadType === "force"
              ? { unitSymbol: "N", unitFactor: 1 }
              : {
                  orientation: orientation,
                  angleSpan: 220,
                  unitSymbol: "Nm",
                  unitFactor: 1,
                },
          color1: colorFreischnittLoad,
          color2: colorFreischnittLoad,
          textColor: colorFreischnittLoad,
          fontSize: fontSize,
          annotationIsDraggable: false,
          textOffsetX: 0,
          textOffsetY: 0,
          size: size,
          preFactor: "1",
          isSymbolic: true,
          measurements: [],
          magnitude: 1, //number  always is in Si units
          name: name, // name of Load e.g. F_a
          isHighlighted: false,
          isFake: isFake,
          isHiddenByUser: false,
          loadTypeWasSelected: true,
        },
      };
    });
  };
  const setPendingLoadType = (loadType: LoadNames) => {
    if (isPendingLoad) {
      setLoadObjects((prev) => {
        prev = prev as LoadObjects;
        const parent = drawenObjects[prev[pendingIndex].parentIndex];
        return {
          ...prev,
          [pendingIndex]: {
            ...prev[pendingIndex],
            loadType: loadType,
            loadSpec: { ...loadSpecification(loadType, pendingIndex, parent) },
            textOffsetX: 0,
            textOffsetY: 0,
            annotationIsDraggable: false,
          },
        };
      });
    }
  };
  const setPendingOrientation = (number: MomentOrientation) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            loadSpec: {
              ...loadObjects[pendingIndex].loadSpec,
              orientation: number,
            },
          },
        };
      });
    }
  };
  const setPendingLoadPos = (point: Point) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            pos: [...point],
            x: point[0], //initial pos is parent pos
            y: point[1],
          },
        };
      });
    }
  };
  const setPendingLoadAngle = (angle: number) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            angle: angle,
          },
        };
      });
    }
  };

  const savePendingLoad = () => {
    if (isPendingLoad && loadObjects) {
      // setPendingIndex(false)
      setIsPendingLoad(false);
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            isPending: false,
          },
        };
      });
    }
  };
  const deletePendingLoad = () => {
    if (isPendingLoad && loadObjects) {
      setIsPendingLoad(false);
      removeMeasurementsFromLoad(pendingIndex, loadObjects);
      setLoadObjects((prev) => {
        const { [pendingIndex]: willGetDel, ...restToKeep } =
          prev as LoadObjects;
        return {
          ...restToKeep,
        };
      });
    }
  };
  const deleteLoad = (index: MeasureIndex) => {
    if (!loadObjects) {
      return;
    }
    removeMeasurementsFromLoad(index, loadObjects);
    setLoadObjects((prev) => {
      const { [index]: willGetDel, ...restToKeep } = prev as LoadObjects;
      return {
        ...restToKeep,
      };
    });
  };

  const setPendingLoadColor = (newColorObj: LoadColor) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            color1: newColorObj.color1
              ? newColorObj.color1
              : loadObjects[pendingIndex].color1,
            color2: newColorObj.color2
              ? newColorObj.color2
              : loadObjects[pendingIndex].color2,
            textColor: newColorObj.textColor
              ? newColorObj.textColor
              : loadObjects[pendingIndex].textColor,
          },
        };
      });
    }
  };
  const setPendingLoadName = (newName: string) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            name: newName,
          },
        };
      });
    }
  };
  const setPendingLoadTypeWasSelected = (wasSelected: boolean) => {
    if (loadObjects) {
      setLoadObjects((prev) => {
        if (!prev) {
          return prev;
        }
        const pendingIndexHere = getPendingIndex(prev);
        if (pendingIndexHere === null) {
          return prev;
        }
        return {
          ...prev,
          [pendingIndexHere]: {
            ...prev[pendingIndexHere],
            loadTypeWasSelected: wasSelected,
          },
        };
      });
    }
  };

  const setPendingLoadMagnitude = (newMagnitude: number) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            magnitude: newMagnitude,
          },
        };
      });
    }
  };
  const setPendingUnit = (unitSymbol: string, unitFactor: number) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            loadSpec: {
              ...loadObjects[pendingIndex].loadSpec,
              unitSymbol,
              unitFactor,
            },
          },
        };
      });
    }
  };
  const setPendingSymbolic = (isSymbolic: boolean) => {
    if (isPendingLoad && loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [pendingIndex]: {
            ...loadObjects[pendingIndex],
            isSymbolic,
          },
        };
      });
    }
  };
  const setPendingPrefactor = (preFactor: string) => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        [pendingIndex]: {
          ...prev[pendingIndex],
          preFactor,
        },
      };
    });
  };
  const setLoadMagnitude = (index: LoadIndex, newMagnitude: number) => {
    if (loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [index]: {
            ...loadObjects[index],
            magnitude: newMagnitude,
          },
        };
      });
    }
  };
  const setLoadUnit = (
    index: LoadIndex,
    unitSymbol: string,
    unitFactor: number
  ) => {
    if (loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [index]: {
            ...loadObjects[index],
            loadSpec: {
              ...loadObjects[index].loadSpec,
              unitSymbol,
              unitFactor,
            },
          },
        };
      });
    }
  };
  const setLoadIsSymbolic = (index: LoadIndex, isSymbolic: boolean) => {
    if (loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [index]: {
            ...loadObjects[index],
            isSymbolic,
          },
        };
      });
    }
  };
  const setLoadPreFactor = (index: LoadIndex, preFactor: string) => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        [index]: {
          ...prev[index],
          preFactor,
        },
      };
    });
  };
  const setLoadName = (index: LoadIndex, newName: string) => {
    if (loadObjects) {
      setLoadObjects((prev) => {
        return {
          ...prev,
          [index]: {
            ...loadObjects[index],
            name: newName,
          },
        };
      });
    }
  };
  const setTextOffset = (offset: Vector2, index: ObjectIndex) => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      if (
        prev[index].loadType === "force" ||
        prev[index].loadType === "moment"
      ) {
        return {
          ...prev,
          [index]: {
            ...prev[index],
            textOffsetX: prev[index].textOffsetX + offset[0],
            textOffsetY: prev[index].textOffsetY + offset[1],
          },
        };
      } else if (prev[index].loadType === "streckenlast") {
        const loadSpec = prev[index].loadSpec;
        let newTextOffsets = {};
        if (loadSpec.startAnnotationIsDraggable) {
          newTextOffsets = {
            startTextOffsetX: loadSpec.startTextOffsetX + offset[0],
            startTextOffsetY: loadSpec.startTextOffsetY + offset[1],
          };
        } else if (loadSpec.endAnnotationIsDraggable) {
          newTextOffsets = {
            endTextOffsetX: loadSpec.endTextOffsetX + offset[0],
            endTextOffsetY: loadSpec.endTextOffsetY + offset[1],
          };
        } else if (loadSpec.startMeasureAnnotationIsDraggable) {
          newTextOffsets = {
            startMeasureTextOffsetX:
              loadSpec.startMeasureTextOffsetX + offset[0],
            startMeasureTextOffsetY:
              loadSpec.startMeasureTextOffsetY + offset[1],
          };
        } else if (loadSpec.lengthMeasureAnnotationIsDraggable) {
          newTextOffsets = {
            lengthMeasureTextOffsetX:
              loadSpec.lengthMeasureTextOffsetX + offset[0],
            lengthMeasureTextOffsetY:
              loadSpec.lengthMeasureTextOffsetY + offset[1],
          };
        }
        return {
          ...prev,
          [index]: {
            ...prev[index],
            loadSpec: {
              ...prev[index].loadSpec,
              ...newTextOffsets,
            },
          },
        };
      } else return null;
    });
  };
  const setLoadAnnotationDraggable = (
    isDraggable: boolean,
    index: ObjectIndex,
    annotationIndex: string | boolean = false
  ) => {
    if (!annotationIndex) {
      setLoadObjects((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          [index]: {
            ...prev[index],
            annotationIsDraggable: isDraggable,
          },
        };
      });
    } else {
      //there is a annotation index i.e. streckenlast
      // lengthMeasure
      // lengthMeasureAnnotationIsDraggable

      // startMeasure
      // startMeasureAnnotationIsDraggable

      // endHeight
      // endAnnotationIsDraggable

      // startHeight
      // startAnnotationIsDraggable
      let newAnnotation = {};
      if (annotationIndex === "startHeight") {
        newAnnotation = { startAnnotationIsDraggable: isDraggable };
      } else if (annotationIndex === "endHeight") {
        newAnnotation = { endAnnotationIsDraggable: isDraggable };
      } else if (annotationIndex === "startMeasure") {
        newAnnotation = { startMeasureAnnotationIsDraggable: isDraggable };
      } else if (annotationIndex === "lengthMeasure") {
        newAnnotation = { lengthMeasureAnnotationIsDraggable: isDraggable };
      }
      setLoadObjects((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...prev,
          [index]: {
            ...prev[index],
            loadSpec: { ...prev[index].loadSpec, ...newAnnotation },
          },
        };
      });
    }
  };
  const removeAllDraggableLoads = () => {
    //removes all dragannotations
    // console.log("remove ALL");
    if (!loadObjects) {
      return;
    }
    Object.values(loadObjects).forEach((obj) => {
      setLoadAnnotationDraggable(false, obj.index);
      setLoadAnnotationDraggable(false, obj.index, "startHeight");
      setLoadAnnotationDraggable(false, obj.index, "endHeight");
      setLoadAnnotationDraggable(false, obj.index, "startMeasure");
      setLoadAnnotationDraggable(false, obj.index, "lengthMeasure");
    });
  };
  const getLoadsWithParentIndex = (parentIndex: Index) => {
    if (!loadObjects) {
      return null;
    }
    const loads = Object.values(loadObjects).filter((loadObject) => {
      return loadObject.parentIndex === parentIndex;
    });
    return loads;
  };
  const getParent = (load: Load) => {
    if (!drawenObjects || !load.parentIndex) {
      return null;
    }
    return drawenObjects[load.parentIndex]
      ? (drawenObjects[load.parentIndex] as DrawenObject) // this is a drawenobject, because without a part you cant create loads
      : null;
  };
  const setPendingStreckenlastPos = (start: Point, end: Point) => {
    if (!(isPendingLoad && loadObjects)) {
      return;
    }
    setInitialLine(
      [[...start], [...end]],
      loadObjects[pendingIndex].loadSpec.lengthMeasurementIndex
    );
    const parent = drawenObjects[loadObjects[pendingIndex].parentIndex];
    if (!parent) {
      return;
    }
    const parentStartPos: Point = [parent.x, parent.y];
    //update start Measurement
    setInitialLine(
      [parentStartPos, [...start]],
      loadObjects[pendingIndex].loadSpec.startMeasurementIndex
    );
    setLoadObjects((prev) => {
      //update length Measurements
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        [pendingIndex]: {
          ...prev[pendingIndex],
          loadSpec: {
            ...prev[pendingIndex].loadSpec,
            startPos: [...start],
            endPos: [...end],
          },
        },
      };
    });
  };
  const setPendingStreckenlastHeight = (
    startHeight: number,
    endHeight: number
  ) => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      const sufficesShouldBeDifferent = areCloseScalar(startHeight, endHeight);
      const suffixAreSame =
        prev[pendingIndex].loadSpec.startSuffix ===
        prev[pendingIndex].loadSpec.endSuffix;
      let newSuffixEnd = "1";
      let shouldUpdateSuffixEnd = false;
      if (sufficesShouldBeDifferent && suffixAreSame) {
        shouldUpdateSuffixEnd = true;
        const suffixStart: number = parseInt(
          prev[pendingIndex].loadSpec.startSuffix
        );
        if (isNaN(suffixStart)) {
          newSuffixEnd = "1";
        } else {
          newSuffixEnd = String(suffixStart + 1);
        }
      }
      return {
        ...prev,
        [pendingIndex]: {
          ...prev[pendingIndex],
          loadSpec: {
            ...prev[pendingIndex].loadSpec,
            startHeight: startHeight,
            endHeight: endHeight,
            endSuffix: shouldUpdateSuffixEnd
              ? newSuffixEnd
              : prev[pendingIndex].loadSpec.endSuffix,
          },
        },
      };
    });
  };
  const loadHasDraggableAnnotation = (load: Load) => {
    if (load.loadType === "force" || load.loadType === "moment") {
      return load.annotationIsDraggable;
    } else if (load.loadType === "streckenlast") {
      return (
        load.loadSpec.startMeasureAnnotationIsDraggable ||
        load.loadSpec.startAnnotationIsDraggable ||
        load.loadSpec.endAnnotationIsDraggable ||
        load.loadSpec.lengthMeasureAnnotationIsDraggable
      );
    }
  };
  const loadWithDraggableAnnotation = loadObjects
    ? Object.values(loadObjects).find((el) => loadHasDraggableAnnotation(el))
    : null;
  const setPendingLoadSpec = (object: any) => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        [pendingIndex]: {
          ...prev[pendingIndex],
          loadSpec: {
            ...prev[pendingIndex].loadSpec,
            ...object,
          },
        },
      };
    });
  };
  const setIsHighlighted = (
    index: ObjectIndex,
    newIsHighlighted: boolean
  ): void => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        [index]: {
          ...prev[index],
          isHighlighted: newIsHighlighted,
        },
      };
    });
  };
  const setIsHiddenByUser = (
    index: ObjectIndex,
    newIsHidden: boolean
  ): void => {
    setLoadObjects((prev) => {
      if (!prev) {
        return null;
      }
      return {
        ...prev,
        [index]: {
          ...prev[index],
          isHiddenByUser: newIsHidden,
        },
      };
    });
  };
  return (
    <PendingLoadContext.Provider
      value={{
        initializePendingLoad,
        isPendingLoad,
        setPendingLoadType,
        loadObjects,
        pendingLoad,
        setPendingLoadPos,
        savePendingLoad,
        createNewFreischnittLoad,
        deletePendingLoad,
        setPendingLoadAngle,
        setPendingLoadColor,
        setPendingLoadName,
        setPendingLoadMagnitude,
        setPendingUnit,
        setPendingSymbolic,
        deleteLoad,
        loadWithDraggableAnnotation,
        setTextOffset,
        setLoadAnnotationDraggable,
        setPendingOrientation,
        removeAllDraggableLoads,
        getLoadsWithParentIndex,
        setPendingPrefactor,
        getParent,
        setPendingStreckenlastPos,
        setPendingStreckenlastHeight,
        loadHasDraggableAnnotation,
        setPendingLoadSpec,
        resetHighestLoadIndex,
        setIsHighlighted,
        setIsHiddenByUser,
        setLoadName,
        setLoadMagnitude,
        setLoadUnit,
        setLoadIsSymbolic,
        setLoadPreFactor,
        setPendingLoadTypeWasSelected,
      }}
    >
      {props.children}
    </PendingLoadContext.Provider>
  );
}

import BearingMounter from "features/Components/Playground/BearingMounter";
import {
  BearingStyleContext,
  BearingStyleContextType,
} from "features/Context/BearingStyleContext";
import { DrawenObjectType } from "features/Context/types/ConstructionElementModel";
import { useContext } from "react";

export default function MechanicalIcon({
  type = "losLager",
  size = 24,
  strokeWidth = 6,
  color = "black",
}: {
  type: DrawenObjectType;
  size?: number;
  strokeWidth?: number;
  color?: string;
}) {
  const { dBall, width } = useContext(
    BearingStyleContext
  ) as BearingStyleContextType;
  const isPart = type === "stab";
  let viewBox = `${-strokeWidth} ${-strokeWidth} ${width + 3 * strokeWidth} ${
    width + 3 * strokeWidth
  }`;
  if (type === "schiebehuelse") {
    viewBox = `${-strokeWidth} ${-strokeWidth - dBall * 2.5} ${
      width + strokeWidth
    } ${width + strokeWidth - dBall * 1.6}`;
  } else if (type === "einspannung") {
    viewBox = `${-strokeWidth} ${-strokeWidth - width / 2} ${
      width + strokeWidth
    } ${width + strokeWidth + width / 2}`;
  } else if (type === "losLager") {
    viewBox = `${-strokeWidth} ${-strokeWidth} ${width + strokeWidth + dBall} ${
      width + strokeWidth + dBall
    }`;
  } else if (type === "gelenk") {
    viewBox = `${-strokeWidth} ${-strokeWidth - width * 0.2} ${
      width + strokeWidth
    } ${width * 0.8 + strokeWidth}`;
  }
  return (
    <svg width={size + "px"} height={size + "px"} viewBox={viewBox}>
      {!isPart && (
        <BearingMounter
          measureToCenter={false}
          color={color}
          enforcedStrokeWidth={strokeWidth}
          //@ts-ignore
          bearingObject={{ x: 0, y: 0, angle: 0, type: type, scale: 100 }}
        />
      )}
      {isPart && (
        <rect
          x={0}
          y={width / 2 - width / 12}
          width={width}
          height={width / 6}
          fill={"white"}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      )}
    </svg>
  );
}

import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { MeasureIndex } from "features/Context/MeasureDistanceContext";
import { useContext } from "react";
import useDragAnnotation from "../../playgroundObjectHooks/useDragAnnotation";

export const useMeasureAngleAnnotationDragHelper = (
  measureIndex: MeasureIndex
) => {
  const {
    setMeasureAnnotationDraggable,
    removeAllDraggableAnnotations,
    angleMeasurements,
  } = useContext(MeasureAngleContext) as MeasureAngleContextType;
  const { handleDragAnnotationStart } = useDragAnnotation();

  if (!angleMeasurements || !angleMeasurements[measureIndex]) {
    return {
      handleClick: (e: React.MouseEvent) => null,
      handleMouseDown: (e: React.MouseEvent) => null,
    };
  }
  const measure = angleMeasurements[measureIndex];
  const annotationIsDraggable = measure.annotationIsDraggable;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeAllDraggableAnnotations();
    setMeasureAnnotationDraggable(true, measureIndex);
  };
  const handleMouseDown = (e: React.MouseEvent) => {
    // console.log("onMouseDown", loadObject);

    e.stopPropagation();
    handleDragAnnotationStart(e, annotationIsDraggable);
  };
  return { handleClick, handleMouseDown };
};

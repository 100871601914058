import { ArcPolar } from "./ArcPolar";

export function Pizza({
  startAngle = 0,
  angle,
  cx,
  cy,
  radius,
  color = "black",
  strokeWidth = 6,
  strokeWidth2 = 0,
  orientation = 0,
} = {}) {
  const strokeWidthLines = strokeWidth2 || strokeWidth;

  const x1 = Math.cos(toRad(startAngle)) * radius + cx;
  const y1 = Math.sin(toRad(startAngle)) * radius + cy;

  const x2 = Math.cos(toRad(startAngle + angle - 0.001)) * radius + cx;
  const y2 = Math.sin(toRad(startAngle + angle - 0.001)) * radius + cy;
  return (
    <g id="Pizza" transform={"scale(1 1)"}>
      <ArcPolar
        startAngle={startAngle}
        radius={radius}
        angle={angle}
        cx={cx}
        cy={cy}
        color={color}
        strokeWidth={strokeWidth}
        orientation={orientation}
      />
      <line
        x1={x1}
        y1={y1}
        x2={cx}
        y2={cy}
        strokeWidth={strokeWidthLines}
        stroke={color}
        strokeLinecap="round"
      />
      <line
        x1={x2}
        y1={y2}
        x2={cx}
        y2={cy}
        strokeWidth={strokeWidthLines}
        stroke={color}
        strokeLinecap="round"
      />
    </g>
  );
}

function toRad(angleInDegree) {
  return (angleInDegree / 360) * 2 * Math.PI;
}

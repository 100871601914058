import { Typography, makeStyles } from "@material-ui/core";
import video from "./video/QuickWalkthroughMute.mp4";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    background: "white",
    minHeight: "70vh",
  },
  width: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    border: "2px solid black",
  },
  widthYT: {
    width: "100%",
    aspectRatio: "1.8",
    objectFit: "contain",
    border: "2px solid black",
  },
}));
export function Walkthrough() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const isEnglish = i18n.language === "en";
  const url = window.location.href;

  return (
    <div>
      {isEnglish ? (
        <Typography>
          The video below shows the general process of how to create a
          mechanical system, define a load, adjust measurements and finally
          calculate the support reactions. The example calculates a pinned
          support, beam and roller support configuration with a point load at
          the center. As you can see the solution is only a few clicks away.
        </Typography>
      ) : (
        <Typography>
          {" "}
          Das unten gezeigte Video beschreibt den Prozess wie man von
          mechanischem System über Belastungen und Bemaßungen zur Lösung der
          Reaktionsgrößen gelangt. Das Beispiel berechnet die klassische
          Fest-Loslagerung.
        </Typography>
      )}
      {isEnglish ? (
        <video
          autoPlay
          className={classes.width}
          src={video}
          muted
          controls
        ></video>
      ) : (
        <iframe
          className={classes.widthYT}
          src={"https://www.youtube.com/embed/JJe1K14KniY?origin=" + url}
          title="YouTube Video Player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
}

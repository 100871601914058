import { makeStyles } from "@material-ui/core";
import { primaryColor } from "./Color";

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    typo: {
      width: "max-content",
      lineHeight: props.height - 1 + "px",
      fontSize: props.height - 3 + "px",
      fontWeight: 600,
      color: "white",
      background: props.color,
      height: props.height + "px",
      padding: "2px",
      paddingLeft: props.height / 2 + "px",
      paddingRight: props.height / 2 + "px",
      borderRadius: props.height / 2 + 2 + "px",
    },
  }));
export function Tag({
  color = primaryColor,
  height = 10,
  text,
  title = "",
}: {
  color?: string;
  height?: number;
  text: string;
  title?: string;
}) {
  const classes = useStyles({ color, height })();
  return (
    <span title={title} className={classes.typo}>
      {text}
    </span>
  );
}

import { middlePoint } from "features/LinearAlgebra/vectorUtils";
import React, { useState, useCallback, useMemo } from "react";
import PolyLine from "./PolyLine";

export function CordsArrow(props) {
  //props:{strokeDashArray,peakAngle,size,strokeWidth,color,
  //cartesian:{originX,originY,destX,destY},
  //polarOrigin:{originX,originY,length,angle}
  //,polarDest:{destX,destX,length,angle}}

  // size determines strokewidth and arrowPeaksize

  //                p3
  //      origin   \
  //      p1 --------->p2   destination
  //                 /
  //                p4
  //
  const strokeDashArray = useMemo(
    () => props.strokeDashArray || "1,0",
    [props.strokeDashArray]
  );
  const peakAngle = useMemo(() => props.peakAngle || 45, [props.peakAngle]);
  var p1 = {};
  var p2 = {};
  var p3 = {};
  var p4 = {};
  var { strokeWidth = 4, polyStrokeWidth = 4 } = props;
  var arrowSideLength;
  if (props.size) {
    // wenn size gegeben dann locked strokewidth
    strokeWidth = 2 * props.size;
    arrowSideLength = 2.5 * props.size + 15;
  } else {
    arrowSideLength = 15;
  }

  if (props.cartesian) {
    p1.x = props.cartesian.originX;
    p1.y = props.cartesian.originY;
    p2.x = props.cartesian.destX;
    p2.y = props.cartesian.destY;
    var length = Math.sqrt(
      (p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)
    );
    // arrowSideLength = Math.max((3.5 * length) / 20, 5);

    var arrowAngle = (Math.atan2(p2.y - p1.y, p2.x - p1.x) / 2 / 3.1415) * 360;
    var arrow3Angle = peakAngle - (90 - arrowAngle);

    p3.x =
      p2.x -
      Math.cos(((90 - 2 * peakAngle + arrow3Angle) * 2 * 3.1415) / 360) *
        arrowSideLength;
    p3.y =
      p2.y -
      Math.sin(((90 - 2 * peakAngle + arrow3Angle) * 2 * 3.1415) / 360) *
        arrowSideLength;

    p4.x = p2.x + Math.sin((arrow3Angle * 2 * 3.1415) / 360) * arrowSideLength;
    p4.y = p2.y - Math.cos((arrow3Angle * 2 * 3.1415) / 360) * arrowSideLength;
  } else if (props.polarOrigin) {
    p1.x = props.polarOrigin.originX;
    p1.y = props.polarOrigin.originY;
    var arrowAngle = props.polarOrigin.angle;
    var length = props.polarOrigin.length;
    p2.x = p1.x + Math.cos((arrowAngle * 2 * 3.1415) / 360) * length;
    p2.y = p1.y + Math.sin((arrowAngle * 2 * 3.1415) / 360) * length;
    // arrowSideLength = Math.max((3.5 * length) / 20, 5);

    var arrow3Angle = peakAngle - (90 - arrowAngle);

    p3.x =
      p2.x -
      Math.cos(((90 - 2 * peakAngle + arrow3Angle) * 2 * 3.1415) / 360) *
        arrowSideLength;
    p3.y =
      p2.y -
      Math.sin(((90 - 2 * peakAngle + arrow3Angle) * 2 * 3.1415) / 360) *
        arrowSideLength;

    p4.x = p2.x + Math.sin((arrow3Angle * 2 * 3.1415) / 360) * arrowSideLength;
    p4.y = p2.y - Math.cos((arrow3Angle * 2 * 3.1415) / 360) * arrowSideLength;
  } else if (props.polarDest) {
    p2.x = props.polarDest.destX;
    p2.y = props.polarDest.destY;
    var arrowAngle = props.polarDest.angle;
    var length = props.polarDest.length;
    p1.x = p2.x - Math.cos((arrowAngle * 2 * 3.1415) / 360) * length;
    p1.y = p2.y - Math.sin((arrowAngle * 2 * 3.1415) / 360) * length;
    // arrowSideLength = Math.max((3.5 * length) / 20, 5);

    var arrow3Angle = peakAngle - (90 - arrowAngle);

    p3.x =
      p2.x -
      Math.cos(((90 - 2 * peakAngle + arrow3Angle) * 2 * 3.1415) / 360) *
        arrowSideLength;
    p3.y =
      p2.y -
      Math.sin(((90 - 2 * peakAngle + arrow3Angle) * 2 * 3.1415) / 360) *
        arrowSideLength;

    p4.x = p2.x + Math.sin((arrow3Angle * 2 * 3.1415) / 360) * arrowSideLength;
    p4.y = p2.y - Math.cos((arrow3Angle * 2 * 3.1415) / 360) * arrowSideLength;
  }
  return (
    <g id="cordsArrow" transform={"scale(1 1)"}>
      <PolyLine
        points={[
          Object.values(p4),
          Object.values(p3),
          Object.values(p2),
          Object.values(p4),
          middlePoint(Object.values(p4), Object.values(p3)),
        ]}
        fill={props.color}
        stroke={props.color}
        strokeWidth={polyStrokeWidth}
      />
      <PolyLine
        points={[
          Object.values(p1),

          middlePoint(Object.values(p4), Object.values(p3)),
        ]}
        strokeDasharray={strokeDashArray}
        fill={props.color}
        stroke={props.color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </g>
  );
}

import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";

import { DrawenContext } from "features/Context/DrawenContext";
import { MouseMoveContext } from "features/Context/MouseMoveContext";
import { PendingLoadContext } from "features/Context/PendingLoadContext";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { intersectOfTwoLines } from "features/LinearAlgebra/intersectOfTwoLines";
import {
  addV,
  normalize,
  rotateByDeg,
  scalarProd,
  sM,
} from "features/LinearAlgebra/vectorUtils";
import { useCallback, useContext } from "react";

const useDragLoad = () => {
  const {
    isPendingLoad,
    setPendingLoadType,
    loadObjects,
    pendingLoad,
    setPendingLoadPos,
  } = useContext(PendingLoadContext);
  const { drawenObjects } = useContext(DrawenContext);

  const {
    lastX,
    setLastX,
    lastY,
    setLastY,
    pendingLoadIsDraggable,
    setPendingLoadIsDraggable,
  } = useContext(MouseMoveContext);

  // @ts-ignore
  const handleMouseMoveLoad = useCallback((e) => {
    // in playground

    e.preventDefault();
    if (!pendingLoadIsDraggable) {
      return;
    }
    const pendingLoadPoint = pendingLoad.pos;
    const deltaX = e.pageX - lastX;
    const deltaY = -e.pageY - lastY;
    const parentPart = drawenObjects[pendingLoad.parentIndex];
    const parentLine = getLineFromPart(parentPart);
    const normedLineOfParent = normalize(getLineDir(parentLine));
    // console.log("normedLineOfParent", normedLineOfParent);

    const deltaVec = [deltaX, deltaY];
    const projectedDelta = scalarProd(deltaVec, normedLineOfParent);
    const potentialNewPoint = addV(
      pendingLoadPoint,
      sM(projectedDelta, normedLineOfParent)
    );
    const normalLine = [
      addV(rotateByDeg(normedLineOfParent, 90), potentialNewPoint),
      potentialNewPoint,
    ];
    const [intersect, tOnHelper, tOnPart] = intersectOfTwoLines(
      parentLine,
      normalLine
    );
    let newPoint;
    if (tOnPart >= 0 && tOnPart <= 1) {
      newPoint = potentialNewPoint;
    } else if (tOnPart <= 0) {
      newPoint = parentLine[0];
    } else {
      newPoint = parentLine[1];
    }

    setPendingLoadPos(newPoint);
    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  // @ts-ignore
  const handleDragLoadStart = useCallback((e, name) => {
    // in bearing/part
    e.preventDefault();
    if (name != "pending") {
      // man kann nur das pending element draggen
      return;
    }
    setPendingLoadIsDraggable(true);
    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  // @ts-ignore
  const handleDragLoadEnd = useCallback((e) => {
    // in playground
    e.preventDefault();
    //e.stopPropagation();
    setPendingLoadIsDraggable(false);
  });
  return { handleDragLoadEnd, handleDragLoadStart, handleMouseMoveLoad };
};

export default useDragLoad;

export const bearingTypes: BearingTypes = {
  losLager: {
    src: "/img/bearings/LosLager.svg",
    nameEn: "Roller Support",
    nameDe: "Loslager",
    type: "losLager",
    connectorType: "extern",
    controlType: "Width",
    centerX: 50,
    centerY: 15,
    width: 100,
    height: 100,
    reactionForceHorizontal: false,
    reactionForceVertical: true,
    reactionMoment: false,
    refPoint: {
      // mitte gelenk
      x: 350,
      y: 85,
      w: 701,
      h: 582,
      xRatio: 350 / 701,
      yRatio: 85 / 582,
      wOverH: 701 / 582,
    },
  },
  festLager: {
    // mitte gelenk
    src: "/img/bearings/FestLager.svg",
    nameEn: "Pinned Support",
    nameDe: "Festlager",
    type: "festLager",
    connectorType: "extern",
    controlType: "Width",
    centerX: 50,
    centerY: 15,
    width: 100,
    height: 100,
    reactionForceHorizontal: true,
    reactionForceVertical: true,
    reactionMoment: false,
    refPoint: {
      x: 350,
      y: 85,
      w: 701,
      h: 585,
      xRatio: 350 / 701,
      yRatio: 85 / 585,
      wOverH: 701 / 585,
    },
  },
  gelenk: {
    // mitte gelenk
    src: "/img/bearings/Gelenk.svg",
    nameEn: "Middle Hinge",
    nameDe: "Gelenk",
    type: "gelenk",
    connectorType: "intern",
    controlType: "Width",
    centerX: 50,
    centerY: 15,
    width: 100,
    height: 100,
    reactionForceHorizontal: true,
    reactionForceVertical: true,
    reactionMoment: false,
    refPoint: {
      x: 85, // from top left to refpoint center
      y: 85,
      radius: 85,
      xRatio: 1 / 2,
      yRatio: 1 / 2,
    },
  },
  einspannung: {
    src: "/img/bearings/Einspannung.svg",
    nameEn: "Fixed Support",
    nameDe: "Einspannung",
    type: "einspannung",
    connectorType: "extern",
    controlType: "Width",
    centerX: 50,
    centerY: 2,
    width: 100,
    height: 100,
    reactionForceHorizontal: true,
    reactionForceVertical: true,
    reactionMoment: true,
    refPoint: {
      x: 350,
      y: 0,
      w: 701,
      h: 127,
      xRatio: 350 / 701,
      yRatio: 0,
      wOverH: 701 / 127,
    },
  },
  schiebehuelse: {
    src: "/img/bearings/Schiebehuelse.svg",
    nameEn: "Sliding Sleeve",
    nameDe: "Schiebehülse",
    type: "schiebehuelse",
    connectorType: "extern",
    controlType: "Width",
    centerX: 50,
    centerY: 20,
    width: 100,
    height: 100,
    reactionForceHorizontal: false,
    reactionForceVertical: true,
    reactionMoment: true,
    refPoint: {
      x: 350,
      y: 0,
      w: 700,
      h: 204,
      xRatio: 350 / 700,
      yRatio: 0,
      wOverH: 700 / 204,
    },
  },
  langlochverbindung: {
    src: "/img/bearings/Schiebehuelse.svg",
    nameEn: "Middle Roller",
    nameDe: "Langlochverbindung",
    type: "langlochverbindung",
    connectorType: "intern",
    controlType: "Width",
    centerX: 50,
    centerY: 50,
    width: 100,
    height: 100,
    reactionForceHorizontal: false,
    reactionForceVertical: true,
    reactionMoment: false,
    refPoint: {
      x: 50,
      y: 50,
      w: 100,
      h: 100,
      xRatio: 1 / 2,
      yRatio: 1 / 2,
      wOverH: 1,
    },
  },
  schiebeverbindung: {
    src: "/img/bearings/Schiebehuelse.svg",
    nameEn: "Middle Sliding Sleeve",
    nameDe: "Schiebeverbindung",
    type: "schiebeverbindung",
    connectorType: "intern",
    controlType: "Width",
    centerX: 50,
    centerY: 50,
    width: 100,
    height: 100,
    reactionForceHorizontal: false,
    reactionForceVertical: true,
    reactionMoment: true,
    refPoint: {
      x: 50,
      y: 50,
      w: 100,
      h: 100,
      xRatio: 1 / 2,
      yRatio: 1 / 2,
      wOverH: 1,
    },
  },
  schweissverbindung: {
    src: "/img/bearings/Schiebehuelse.svg",
    nameEn: "Middle Fixed",
    nameDe: "Schweißverbindung",
    type: "schweissverbindung",
    connectorType: "intern",
    controlType: "Width",
    centerX: 50,
    centerY: 50,
    width: 100,
    height: 100,
    reactionForceHorizontal: true,
    reactionForceVertical: true,
    reactionMoment: true,
    refPoint: {
      x: 50,
      y: 50,
      w: 100,
      h: 100,
      xRatio: 1 / 2,
      yRatio: 1 / 2,
      wOverH: 1,
    },
  },
};
export type BearingTypes = {
  [key: string]: BearingSpec;
};
export type BearingSpec = {
  src: string;
  nameEn: string;
  nameDe: string;
  type: string;
  connectorType: string;
  controlType: string;
  centerX: number;
  centerY: number;
  width: number;
  height: number;
  reactionForceHorizontal: boolean;
  reactionForceVertical: boolean;
  reactionMoment: boolean;
  refPoint: {
    x: number;
    y: number;
    w?: number;
    h?: number;
    radius?: number;
    xRatio: number;
    yRatio: number;
    wOverH?: number;
  };
};

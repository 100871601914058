export function HorizontalBalanceIcon({
  size = 24,
  strokeWidth = 2,
  color = "black",
}: {
  size?: number;
  strokeWidth?: number;
  color?: string;
}) {
  const viewBox = `${200} ${325} ${60} ${60}`;
  return (
    <svg width={size + "px"} height={size + "px"} viewBox={viewBox}>
      <g transform="translate(0 506.2949890136719) rotate(0) scale(1 -1)">
        <g transform="translate(250,150) scale(1 -1) translate(0,-2.5) rotate(0,0,2.5)">
          <rect
            x="0"
            y="0"
            width="40"
            height="5"
            fill="white"
            stroke={color}
            strokeWidth={strokeWidth}
          ></rect>
        </g>
        <g>
          <g>
            <g>
              <g id="cordsArrow" transform="scale(1 1)">
                <polyline
                  points="235.7343240545731, 145.3642163747573 235.73410930888429, 154.63512273722475 250, 150 235.7343240545731, 145.3642163747573 235.7342166817287, 149.99966955599103 "
                  fill={color}
                  stroke={color}
                  strokeWidth="0"
                ></polyline>
                <polyline
                  points="170, 150 235.7342166817287, 149.99966955599103 "
                  strokeDasharray="1,0"
                  fill={color}
                  stroke={color}
                  strokeWidth={strokeWidth}
                  strokeLinecap="round"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

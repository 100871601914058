import { makeStyles, Typography } from "@material-ui/core";
import { LatexEquationEl } from "./LatexEquationEl";
import {
  BalanceEquation,
  BalanceType,
} from "features/Context/types/FreischnittModel";
import { HorizontalBalanceIcon } from "./iconsBalanceEquation/HorizontalBalanceIcon";
import { MomentBalanceIcon } from "./iconsBalanceEquation/MomentBalanceIcon";
import { VerticalBalanceIcon } from "./iconsBalanceEquation/VerticalBalanceIcon";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "top",
  },
  padding: {
    padding: "4px",
    paddingBottom: "0px",
    paddingLeft: "0px",
  },
  bold: {
    fontWeight: "bold",
  },
  border: {
    border: "solid 2px black",
    borderRadius: "3px",
    width: "max-content",
    marginRight: "5px",
    background: "lightgrey",
  },
  oneLine: {
    display: "flex",
    alignItems: "center",
  },
  outer: {
    display: "flex",
    alignItems: "end",
    padding: "4px",
    paddingRight: "0px",
  },
}));

export function EquationBundle({
  equations,
  header = "",
  wrap = false,
}: {
  equations:
    | Array<string>
    | Array<{
        eq: BalanceEquation;
        balanceType: BalanceType;
      }>;
  header?: string;
  wrap?: boolean;
}) {
  const classes = useStyles();
  if (!equations[0]) {
    return null;
  }
  const hasIcons = !(typeof equations[0] === "string");
  const getIcon = (type: BalanceType): JSX.Element => {
    if (type === "horizontal") {
      return <HorizontalBalanceIcon size={40}></HorizontalBalanceIcon>;
    } else if (type === "moment") {
      return <MomentBalanceIcon size={40}></MomentBalanceIcon>;
    } else {
      return <VerticalBalanceIcon size={40}></VerticalBalanceIcon>;
    }
  };
  const elements = equations.map((eq, index) => {
    return (
      <div key={eq + " " + index + "1"} className={classes.outer}>
        <div key={eq + " " + index + "1"} className={classes.padding}>
          {wrap && index !== 0 ? ", " : ""}
        </div>
        <div className={classes.oneLine}>
          {hasIcons && (
            <div className={classes.border}>
              {getIcon((eq as any).balanceType)}
            </div>
          )}
          <LatexEquationEl
            key={eq + " " + index}
            equation={hasIcons ? (eq as any).eq : eq}
            height={16}
          />
        </div>
      </div>
    );
  });

  return (
    <div>
      <Typography className={classes.bold} variant="body1">
        {header}
      </Typography>
      <div className={wrap ? classes.flex : ""}>{elements}</div>
    </div>
  );
}

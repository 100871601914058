import ColorPicker from "../../BearingEditor/BearingController/Util/ColorPicker";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { useContext } from "react";
import { ControlAccordion } from "../VisualElements/ControlAccordion";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function ColorControlLoad() {
  const { t } = useTranslation();
  const { pendingLoad, setPendingLoadColor } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  if (!pendingLoad) {
    return null;
  }
  const color1 = pendingLoad.color1;
  const color2 = pendingLoad.color2;
  const textColor = pendingLoad.textColor;
  const showOnlyPrimary =
    pendingLoad.loadType === "force" || pendingLoad.loadType === "moment";
  const handleColor1Change = (color: string) => {
    setPendingLoadColor({ color1: color });
  };
  const handleColor2Change = (color: string) => {
    setPendingLoadColor({ color2: color });
  };
  const handleTextColorChange = (color: string) => {
    setPendingLoadColor({ textColor: color });
  };
  return (
    <ControlAccordion
      titleMainElement={<Typography>{t("Color")}</Typography>}
      mainContent={
        <div>
          <ColorPicker
            key={"Farbe primär"}
            color={color1}
            onChange={handleColor1Change}
            name={showOnlyPrimary ? t("Color") : t("Load Color")}
          />

          {!showOnlyPrimary && (
            <ColorPicker
              key={"Farbe Sekundär"}
              color={color2}
              onChange={handleColor2Change}
              name={t("Side Mesasure Color")}
            />
          )}
          {!showOnlyPrimary && (
            <ColorPicker
              key={"Schriftfarbe"}
              color={textColor}
              onChange={handleTextColorChange}
              name={t("Font Color")}
            />
          )}
        </div>
      }
      expanded={false}
    ></ControlAccordion>
  );
}

import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { useContext } from "react";
import SymbolAndMagnitude from "./util/SymbolAndMagnitude";
import { useUnits } from "./util/useUnits";
import { Load } from "features/Context/types/LoadModel";
import { ControlAccordion } from "../VisualElements/ControlAccordion";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function SymbolForceMoment() {
  const { t } = useTranslation();
  let {
    setPendingLoadName,
    pendingLoad,
    setPendingLoadMagnitude,
    setPendingUnit,
    setPendingSymbolic,
    setPendingPrefactor,
  } = useContext(PendingLoadContext) as PendingLoadContextType;
  pendingLoad = pendingLoad as Load;
  const loadType = pendingLoad.loadType;
  const preFactor = pendingLoad.preFactor;
  const name = pendingLoad.name;
  const unit = pendingLoad.loadSpec.unitSymbol;
  const unitFactor = pendingLoad.loadSpec.unitFactor;
  const magnitude = pendingLoad.magnitude; //is the SI magnitude
  const isSymbolic = pendingLoad.isSymbolic;
  const handleNameChange = (newString: string) => {
    setPendingLoadName(newString);
  };
  const handlePreFactorChange = (newString: string) => {
    setPendingPrefactor(newString);
  };

  const handleMagnitudeChange = (number: number) => {
    setPendingLoadMagnitude(number * unitFactor);
  };
  const handleUnitChange = (unit: string) => {
    setPendingUnit(unit, unitFactorConverter(unit));
    // setPendingLoadMagnitude(magnitude / unitFactorConverter(unit));
  };
  const symbolName = loadType === "force" ? "F" : "M";
  const [unitOption, unitFactorConverter] = useUnits(pendingLoad.loadType);
  return (
    <ControlAccordion
      titleMainElement={<Typography>{t("Magnitude")}</Typography>}
      mainContent={
        <SymbolAndMagnitude
          thin={false}
          {...{
            symbolName,
            preFactor,
            unit,
            magnitude,
            isSymbolic,
            unitOption,
          }}
          handleNameChange={handleNameChange}
          handlePreFactorChange={handlePreFactorChange}
          handleMagnitudeChange={handleMagnitudeChange}
          handleUnitChange={handleUnitChange}
          setSymbolic={setPendingSymbolic}
          symbolSuffix={String(name)}
          unitFactor={unitFactorConverter(unit)}
          height={15}
          elevation={0}
          paperBackground="rgba(0,0,0,0)"
          alignHorizontal={true}
        />
      }
      expanded={true}
    ></ControlAccordion>
  );
}

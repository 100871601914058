import React, { useState, useCallback, useContext, useMemo } from "react";
import { PendingContext } from "../../../../../Context/PendingContext";

import { Slider } from "../InputComponents/Slider";

export function RadiusX2Control(props) {
  const { pendingObject, setPendingSize } = useContext(PendingContext);

  const innerR = useMemo(
    () => pendingObject.size.innerR,
    [pendingObject.size.innerR]
  );
  const outerR = useMemo(
    () => pendingObject.size.outerR,
    [pendingObject.size.outerR]
  );

  const handleInnerRChange = useCallback(
    (innerR) => {
      setPendingSize({ innerR: innerR });
    },
    [setPendingSize]
  );
  const handleOuterRChange = useCallback(
    (outerR) => {
      setPendingSize({ outerR: outerR });
    },
    [setPendingSize]
  );

  return (
    <div className={"rectSizeControl"}>
      <div className="angleAndHorizontal">
        <Slider
          sliderOrientation={"horizontal"}
          description={"Inner Radius"}
          min={2}
          max={outerR - 2}
          name={props.name}
          value={innerR}
          handleChange={handleInnerRChange}
        />
        <Slider
          sliderOrientation={"horizontal"}
          description={"Outer Radius"}
          min={innerR + 2}
          max={560}
          name={props.name}
          value={outerR}
          handleChange={handleOuterRChange}
        />
      </div>
    </div>
  );
}

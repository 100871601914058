export const primaryColor = "#3F51B5";
export const primaryColorDark = "#3848a2";
// export const cancelColor = "#820505";
// export const cancelColorDark = "#750404";
export const cancelColor = "darkgrey";
export const cancelColorDark = "grey";
export const highlightOffset = 4;
export const highlightWidth = 2;
export const accordionRootBackground = "#F7F7F7";
export const panelBackground = "#F7F7F7";
export const inputColor = "#FCFCFC";
export const pendingColor = "red";

import React from "react";
import ReactDOM from "react-dom";

import { App } from "./App";
import { UniversalProvider } from "features/Context/UniversalProvider";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LandingPage } from "LandingPage";
import "./i18n";
import { Hidden } from "@material-ui/core";
import { MobilePage } from "featuresLandingPage/Mobile/MobilePage";
import { ErrorPage } from "featuresGeneral/ErrorPage";
export const calculatorRouter = "/lagerrechner";
export const landingPageRoute = "/";

const router = createBrowserRouter([
  {
    path: calculatorRouter,
    element: <App></App>,
  },
  {
    path: landingPageRoute,
    element: <LandingPage></LandingPage>,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);

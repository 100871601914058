import { Typography, makeStyles } from "@material-ui/core";
import { panelBackground } from "features/Components/StyleOnlyComponents/Color";
import { LinkToCalculator } from "./LinkToCalculator";
import { ParagraphAccordion } from "./Layout/ParagraphAccordion";
import { Capabilities } from "./Capabilities";
import { Abstract } from "./Abstract";
import { Title } from "featuresLandingPage/StyleComps/Title";
import { CalculatorLimits } from "./CalculatorLimits";
import { Walkthrough } from "./Walkthrough";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    background: "white",
    minHeight: "70vh",
    paddingTop: "50px",
  },
  sideColumn: {
    width: "20%",
    background: panelBackground,
  },
  mainColumn: {
    width: "60%",
    padding: "20px",
  },
  calculatorButton: {
    width: "300px",
    height: "190px",
  },
}));
export function MainContent() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.flexOuter}>
      <div className={classes.sideColumn}></div>
      <main className={classes.mainColumn}>
        <ParagraphAccordion
          titleMainElement={<Title>{t("Quick Start")}</Title>}
          mainContent={<Abstract></Abstract>}
          expanded={true}
        ></ParagraphAccordion>
        <ParagraphAccordion
          titleMainElement={<Title>How To</Title>}
          mainContent={<Walkthrough />}
          expanded={true}
        ></ParagraphAccordion>
        <ParagraphAccordion
          titleMainElement={
            <Title>{t("Capabilities of the Calculator")}</Title>
          }
          mainContent={<Capabilities />}
          expanded={true}
        ></ParagraphAccordion>
        <ParagraphAccordion
          titleMainElement={<Title>{t("Limitations of the calculator")}</Title>}
          mainContent={<CalculatorLimits />}
          expanded={true}
        ></ParagraphAccordion>
      </main>
      <div className={classes.sideColumn}></div>
    </div>
  );
}

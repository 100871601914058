import {
  FreischnittContext,
  FreischnittContextType,
} from "features/Context/FreischnittContext";
import { useContext } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useTranslation } from "react-i18next";
export default function ShowBearingsCheckboxMsg() {
  const { bearingsAreHidden, setBearingsAreHidden } = useContext(
    FreischnittContext
  ) as FreischnittContextType;
  const { t } = useTranslation();
  return (
    <div>
      <Checkbox
        color="primary"
        checked={bearingsAreHidden}
        onChange={(e, checked) => setBearingsAreHidden((prev) => checked)}
      ></Checkbox>{" "}
      {t("Bearings are hidden.")}
    </div>
  );
}

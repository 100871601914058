import { useState, createContext } from "react";

export const BearingStyleContext =
  createContext<BearingStyleContextType | null>(null);
export type BearingStyleContextType = {
  hatchAngle: number;
  setHatchAngle: (newAngle: number) => void;
  strokeWidth: number;
  setStrokeWidth: (newWidth: number) => void;
  dJoint: number;
  setDJoint: (newDJoint: number) => void;
  hJointToBase: number;
  setHJointToBase: (newHJoint: number) => void;
  dBall: number;
  setDBall: (newDBall: number) => void;
  drawBalls: boolean;
  setDrawBalls: (newDrawBalls: boolean) => void;
  tFactor: number;
  setTFactor: (newTFactor: number) => void;
  strokeColor: string;
  setStrokeColor: (newColor: string) => void;
  jointFillColor: string;
  setJointFillColor: (newColor: string) => void;
  bodyColor: string;
  setBodyColor: (newColor: string) => void;
  ballsColor: string;
  setBallsColor: (newColor: string) => void;
  height: number;
  setHeight: (newHeight: number) => void;
  width: number;
  setWidth: (newWidth: number) => void;
};
export function BearingStyleProvider(props: any) {
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(20);
  const [hatchAngle, setHatchAngle] = useState(60);
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [dJoint, setDJoint] = useState(30);
  const [hJointToBase, setHJointToBase] = useState(50);

  const [dBall, setDBall] = useState(15);
  const [drawBalls, setDrawBalls] = useState(true);

  const [tFactor, setTFactor] = useState(50);
  // const [strokeColor, setStrokeColor] = useState("rgba(0,0,0,0.1");
  const [strokeColor, setStrokeColor] = useState("black");
  const [jointFillColor, setJointFillColor] = useState("white");
  const [bodyColor, setBodyColor] = useState("white");
  const [ballsColor, setBallsColor] = useState("white");
  return (
    <BearingStyleContext.Provider
      value={{
        hatchAngle,
        setHatchAngle,
        strokeWidth,
        setStrokeWidth,
        dJoint,
        setDJoint,
        hJointToBase,
        setHJointToBase,
        dBall,
        setDBall,
        drawBalls,
        setDrawBalls,
        tFactor,
        setTFactor,
        strokeColor,
        setStrokeColor,
        jointFillColor,
        setJointFillColor,
        bodyColor,
        setBodyColor,
        ballsColor,
        setBallsColor,
        height,
        setHeight,
        width,
        setWidth,
      }}
    >
      {props.children}
    </BearingStyleContext.Provider>
  );
}

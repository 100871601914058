import Tex2SVG from "react-hook-mathjax";
import { ZoomAndPan } from "./ZoomAndPan/ZoomAndPan";
import { BuildingStepper } from "./BuildingStepper";
import BearingEditor from "./BearingEditor";
import { IconButton, makeStyles } from "@material-ui/core";
import MechanicalIcon from "../Functionality_Right/SystemMode/SelectBar/Selectables/MechanicalIcon";
import { Console } from "./Console/Console";
import { DrawenObjectType } from "features/Context/types/ConstructionElementModel";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  flex: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  width: {
    width: "80%",
  },
  borderTop: {
    borderTop: "1px solid black",
  },
  width100: {
    width: "100%",
    height: "100%",
  },
}));
const types: Array<DrawenObjectType> = [
  "losLager",
  "festLager",
  "gelenk",
  "einspannung",
  "schweissverbindung",
  "schiebeverbindung",
  "langlochverbindung",
  "schiebehuelse",
];
const icons = types.map((type) => (
  <IconButton key={type}>
    <MechanicalIcon type={type} />
  </IconButton>
));
export function BottomBar({ dragStartConsole }: { dragStartConsole: any }) {
  const classes = useStyles();

  return (
    <div className={classes.width100}>
      {/* <BuildingStepper /> */}

      <div className={classes.flex + " " + classes.borderTop}>
        {/* <ZoomAndPan /> */}
        {/* <BearingEditor /> */}
        {/* {icons} */}
        <Console dragStart={dragStartConsole}></Console>
      </div>
      {/* <Tex2SVG display="inline" latex="e^{i \pi} + 1 = 0" /> */}
    </div>
  );
}

import { getLineDir } from "./getLineDir";
import { addV, distance, sM } from "./vectorUtils";
import type { Line } from "./linearAlgebraModel";
/**
 * elongates the @line at the endpoint by the amount @deltaLength
 * return new line with new endpoint
 * @param {Line} line
 * @param {number} deltaLength
 */
export function elongateLine(line: Line, deltaLength: number): Line {
  const [start, end] = line;
  const length = distance(start, end);
  const lineDirect = getLineDir(line);
  const newDist = length + deltaLength;
  const newEnd = addV(start, sM(newDist, lineDirect));
  const newLine: Line = [start, newEnd];
  return newLine;
}

export function MomentIcon({
  size = 24,
  color = "black",
  strokeWidth = 6,
}: {
  size?: number;
  color?: string;
  strokeWidth?: number;
}) {
  const viewBox = `${190} ${100} ${120} ${120}`;
  return (
    <svg
      width={size + "px"}
      height={size + "px"}
      viewBox={viewBox}
      transform="rotate(180)"
    >
      <g>
        <polyline
          points="216.01445021490963, 137.630271483055 224.20508792642178, 127.30919765375475 223.8452220547922, 140.48043934410222 216.01445021490963, 137.630271483055 220.10976907066572, 132.46973456840487 "
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth * 2}
        ></polyline>
        <g transform="scale(1 1)">
          <path
            d="M 280.0701638651491 139.0553554135786 A 32 32 0 1 1  219.92964511934738 139.05588023861162"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray="1,0"
            fill="none"
            strokeLinecap="round"
          ></path>
        </g>
      </g>
    </svg>
  );
}

import { makeStyles } from "@material-ui/core";
import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";

import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import {
  DrawenObject,
  PartSize,
} from "features/Context/types/DrawenObjectModel";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { Line, Point } from "features/LinearAlgebra/linearAlgebraModel";
import { addV, distance, sM } from "features/LinearAlgebra/vectorUtils";
import { useContext } from "react";
import { RangedInput } from "../SystemMode/GeometryControls/InputComponents/RangedInput";
import { VerticalSlider } from "../SystemMode/GeometryControls/InputComponents/VerticalSlider";
import Q0ControlLeft from "./streckenlastControl/Q0ControlLeft";
import Q0ControlRight from "./streckenlastControl/Q0ControlRight";
import HtmlDistanceArrow from "./util/HtmlDistanceArrow";

const useStyles = () =>
  makeStyles((theme) => ({
    flex: {
      position: "relative",
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "flex-end",
    },
    flexLeft: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
    },
    flexRight: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    flexItemLeft: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      marginLeft: "-10px",
    },
    flexItemRight: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "-10px",
    },
  }));

export function StreckenlastControl() {
  const classes = useStyles()();

  const {
    pendingLoad,
    getParent,
    setPendingStreckenlastPos,
    setPendingStreckenlastHeight,
  } = useContext(PendingLoadContext) as PendingLoadContextType;
  if (!pendingLoad) {
    return null;
  }
  const parent = getParent(pendingLoad) as DrawenObject;
  // @ts-ignore parent is always a part
  const parentLine: Line = getLineFromPart(parent) as Line;
  const lineDir = getLineDir(parentLine);
  const min = 0;
  const max = (parent?.size as PartSize).width;
  const startPos = pendingLoad.loadSpec.startPos;
  const endPos = pendingLoad.loadSpec.endPos;
  let val1, val2;
  const isMeasuredFromParentStart = true;
  let measurePoint: Point;
  //measurePoint is the point from which the Streckenalst is measured from
  if (isMeasuredFromParentStart) {
    measurePoint = parentLine[0];
  } else {
    measurePoint = parentLine[1];
  }
  val1 = distance(parentLine[0], startPos);
  val2 = distance(parentLine[0], endPos);
  const handleChange = (arr: [number, number]) => {
    const smaller = Math.min(arr[0], arr[1]);
    const bigger = Math.max(arr[0], arr[1]);
    const start = addV(measurePoint, sM(smaller, lineDir));
    const end = addV(measurePoint, sM(bigger, lineDir));
    // console.log("arr", start, end);

    setPendingStreckenlastPos(start, end);
  };

  //handle height changes left and Right
  const startHeight = pendingLoad.loadSpec.startHeight;
  const endHeight = pendingLoad.loadSpec.endHeight;
  const handleChangeLeftHeight = (newVal: number) => {
    // console.log("newVal", newVal);
    setPendingStreckenlastHeight(newVal, endHeight);
  };
  const handleChangeRightHeight = (newVal: number) => {
    setPendingStreckenlastHeight(startHeight, newVal);
  };

  const maxHeight = 100;
  const marks = [
    { value: -maxHeight, label: "" },
    { value: -maxHeight / 2, label: "" },
    { value: -maxHeight / 4, label: "" },
    { value: 0, label: "" },
    { value: maxHeight / 4, label: "" },
    { value: maxHeight / 2, label: "" },
    { value: maxHeight, label: "" },
  ];
  return (
    <>
      <div className={classes.flex}>
        <div className={classes.flexLeft}>
          <VerticalSlider
            step={1}
            handleChange={handleChangeLeftHeight}
            min={-maxHeight}
            max={maxHeight}
            value={startHeight}
            ownMarks={true}
            marks={marks}
            align="left"
            track={false}
          />
          <div className={classes.flexItemLeft}>
            <HtmlDistanceArrow />
            <Q0ControlLeft />
          </div>
        </div>
        <div className={classes.flexRight}>
          <div className={classes.flexItemRight}>
            <Q0ControlRight />

            <HtmlDistanceArrow />
          </div>
          <VerticalSlider
            step={1}
            handleChange={handleChangeRightHeight}
            min={-maxHeight}
            max={maxHeight}
            value={endHeight}
            ownMarks={true}
            marks={marks}
            align="rigth"
            track={false}
          />
        </div>
      </div>
      <RangedInput
        step={1}
        sliderOrientation="horizontal"
        handleChange={handleChange}
        min={min}
        max={max}
        value={[val1, val2]}
        // marks = [],
        // ownMarks = false,
      />
    </>
  );
}

import { makeStyles } from "@material-ui/core";
import { Load } from "features/Context/types/LoadModel";
import { CordsArrow } from "../Cords/CordsArrow";
import LatexAnnotation from "../helper/LatexAnnotation";
import { useLoadAnnotationDragHelper } from "../helper/util/useLoadAnnotationDragHelper";
import { createAnnotation } from "./util/createAnnotation";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  auto: { cursor: "auto" },
  move: {
    cursor: "move",
  },
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
}));
export function Force({
  x,
  y,
  angle,
  color,
  textSuffix = "",
  length = 70,
  fontSize = 15,
  strokeWidth = 1,
  textOffsetX = 0,
  textOffsetY = 0,
  magnitude = 0,
  isSymbolic = true,
  loadObject = null,
  isHighlighted = false,
}: {
  x: number;
  y: number;
  angle: number;
  color: string;
  textSuffix?: string;
  length?: number;
  fontSize?: number;
  strokeWidth?: number;
  textOffsetX?: number;
  textOffsetY?: number;
  magnitude?: number;
  isSymbolic?: boolean;
  loadObject?: Load | null;
  isHighlighted?: boolean;
}) {
  const classes = useStyles();
  //@ts-ignore
  const { handleClick, handleMouseDown } =
    useLoadAnnotationDragHelper(loadObject);
  const cursorIsOnMove = loadObject?.isPending ? true : false;
  const preFactor = loadObject?.preFactor;
  const unitFactor = loadObject?.loadSpec.unitFactor;
  const unitSymbol = loadObject?.loadSpec.unitSymbol;
  const [latex, hasFrac] = createAnnotation(
    loadObject?.loadType ? loadObject?.loadType : "force",
    preFactor,
    unitFactor,
    unitSymbol,
    magnitude,
    textSuffix,
    isSymbolic
  );

  const xM = x + Math.cos((angle / 360) * 2 * 3.1415) * length * 0.7;
  const yM = y + Math.sin((angle / 360) * 2 * 3.1415) * length * 0.7;

  return (
    <g className="force">
      <g
        className={
          (cursorIsOnMove ? classes.move : classes.auto) +
          " " +
          (isHighlighted ? classes.gOutline : "")
        }
      >
        <CordsArrow
          // stroke needs to be 0 to make the peaks of the arrows perfect
          polyStrokeWidth={0}
          strokeWidth={strokeWidth}
          color={color}
          peakAngle={18}
          polarDest={{ destX: x, destY: y, angle: angle - 180, length: length }}
        />
      </g>

      <LatexAnnotation
        latex={latex}
        x={xM + textOffsetX}
        color={color}
        y={yM + textOffsetY}
        {...{ textOffsetY, textOffsetX }}
        hasFrac={hasFrac}
        height={fontSize}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        isClicked={loadObject?.annotationIsDraggable ? true : false}
      />
    </g>
  );
}

import { IconButton, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Einspannung } from "../Bearings/Einspannung";
import { FestLager } from "../Bearings/FestLager";
import { LosLager } from "../Bearings/LosLager";
import { Schiebehuelse } from "../Bearings/Schiebehuelse";
import React, { useContext, useRef, useState } from "react";
import { BearingSelectPaper } from "../BearingController/Util/BearingSelectPaper";
import { EinspannungsController } from "../BearingController/EinspannungsController";
import { FestLagerController } from "../BearingController/FestLagerController";
import { LosLagerController } from "../BearingController/LosLagerController";
import { SchiebehuelseController } from "../BearingController/SchiebehuelseController";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CheckInput } from "../BearingController/Util/CheckInput";
import { SliderInput } from "../BearingController/Util/SliderInput";
import ColorModal from "../BearingController/Util/ColorModal";
import { useBBox } from "../Hooks/useBBox";
import { ControlTabs } from "../BearingController/Util/ControlTabs";
import { useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { BearingStyleContext } from "features/Context/BearingStyleContext";
import { bearingTypes } from "features/GlobalData/bearingTypes";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  wrapp: {
    height: "calc(100% - 35px)",

    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  leftSide: {
    position: "relative",
    width: "70%",
    height: "100%",
    // border: "1px solid black",
    backgroundImage: 'url("transi.png")',
    backgroundRepeat: "repeat",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  svg: {
    outline: " 1px solid blue",
  },
  svgWrapper: {
    border: "1px solid ",
  },
  rightSide: {
    width: "30%",
    borderLeft: "1px solid black",
  },
  download: {
    position: "absolute",
    right: "30x",
    bottom: "20px",
    margin: "8px",
    background: "darkBlue",
    color: "white",
    "&:hover": {
      background: "blue",
    },
  },

  panelBody: {
    width: "100%",
  },

  image: {
    color: "red",
  },
  header: {
    color: "darkgrey",
    textAlign: "center",
  },
  paperWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  topLine: {
    // borderBottom: "1px solid black",
    fontWeight: "bold",
    width: "100%",
    height: "35px",
    display: "flex",
    justifyContent: "center",

    textTransform: "uppercase",
  },
  arrow: {
    display: "flex",
    justifyContent: "left",
    alignContent: "center",
    width: "70%",
    // background: theme.palette.secondary.light,
  },
  controllerName: {
    fontWeight: "bold",
    width: "30%",
    paddingLeft: "10px",
    borderLeft: "1px solid white",
    display: "flex",
    alignItems: "center",
    background: "white",
    color: theme.palette.primary.main,
  },
  overview: {
    fontWeight: "bold",
    width: "30%",
    paddingLeft: "10px",
    borderLeft: "1px solid white",
    display: "flex",
    alignItems: "center",
    color: theme.palette.secondary.main,
    background: "white",
  },
  heightNone: {
    width: "70%",
    height: "100%",
  },
  heightController: {
    width: "100%",
    height: "100%",
  },
  height90: {
    height: "90%",
  },
  heightControlTab: {
    height: "15%",
  },
}));

export function Overview({ handleClose }) {
  const classes = useStyles();
  const theme = useTheme();

  const setCenter = (centerX, centerY, bearingType) => {
    bearingTypes[bearingType].centerX = centerX;
    bearingTypes[bearingType].centerY = centerY;
  };
  const {
    hatchAngle,
    setHatchAngle,
    strokeWidth,
    setStrokeWidth,
    dJoint,
    setDJoint,
    hJointToBase,
    setHJointToBase,
    dBall,
    setDBall,
    drawBalls,
    setDrawBalls,
    tFactor,
    setTFactor,
    strokeColor,
    setStrokeColor,
    jointFillColor,
    setJointFillColor,
    bodyColor,
    setBodyColor,
    ballsColor,
    setBallsColor,
    height,
    setHeight,
    width,
  } = useContext(BearingStyleContext);
  const [isInSliderView, setIsInSliderView] = useState(true);

  const handleStrokeChange = (value, event) => {
    const color = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    setStrokeColor(color);
  };
  const handleJointFillChange = (value, event) => {
    const color = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    setJointFillColor(color);
  };
  const handleBodyChange = (value, event) => {
    const color = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    setBodyColor(color);
  };
  const handleBallsChange = (value, event) => {
    const color = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    setBallsColor(color);
  };
  const svg = useRef();
  const leftSide = useRef();
  const { t } = useTranslation();
  const sliderInputs = [
    {
      name: t("Height Hatching"),
      value: height,
      min: 2,
      max: Math.round(width / 3),
      updateFunc: setHeight,
    },

    {
      name: t("Diameter Joint"),
      value: dJoint,
      min: 0,
      max: width / 3,
      updateFunc: setDJoint,
    },
    {
      name: t("Height Center of Joint"),
      value: hJointToBase,
      min: dJoint / 2,
      max: (width * 3) / 4,
      updateFunc: setHJointToBase,
    },

    {
      name: t("Diameter Balls"),
      value: dBall,
      min: 2,
      max: width / 2.5,
      updateFunc: setDBall,
    },
    {
      name: t("Attachment Points on Joint"),
      value: tFactor,
      min: 0,
      max: 100,
      updateFunc: setTFactor,
    },
    {
      name: t("Angle of Hatching"),
      value: hatchAngle,
      min: 45,
      max: 135,
      updateFunc: setHatchAngle,
    },
    {
      name: t("StrokeWidth"),
      value: strokeWidth,
      min: 1,
      max: 12,
      updateFunc: setStrokeWidth,
    },
  ];
  const checkInputs = [
    {
      name: t("Show Balls"),
      value: drawBalls,
      updateFunc: setDrawBalls,
    },
  ];
  const checkInputsComps = checkInputs.map((obj, index) => {
    return (
      <CheckInput
        color="secondary"
        key={obj.name}
        name={obj.name}
        value={obj.value}
        updateFunc={obj.updateFunc}
      />
    );
  });
  const inputSliders = sliderInputs.map((obj, index) => {
    return (
      <SliderInput
        key={obj.name}
        max={obj.max}
        min={obj.min}
        value={obj.value}
        name={obj.name}
        updateFunc={obj.updateFunc}
        color="secondary"
      />
    );
  });
  const colorView = (
    <>
      <ColorModal
        name={t("Stroke Color")}
        color={strokeColor}
        updateColor={handleStrokeChange}
      />
      <ColorModal
        name={t("Joint Fill Color")}
        color={jointFillColor}
        updateColor={handleJointFillChange}
      />
      <ColorModal
        name={t("Bearing Body Color")}
        color={bodyColor}
        updateColor={handleBodyChange}
      />
      <ColorModal
        name={t("Bearing Balls Color")}
        color={ballsColor}
        updateColor={handleBallsChange}
      />
      {checkInputsComps}
    </>
  );
  const loslagerRef = useRef();
  const festlagerRef = useRef();
  const einspannungRef = useRef();
  const schiebehuelseRef = useRef();

  const loslagerRefBox = useBBox(loslagerRef);
  const festlagerRefBox = useBBox(festlagerRef);
  const einspannungRefBox = useBBox(einspannungRef);
  const schiebehuelseRefBox = useBBox(schiebehuelseRef);
  const bearingsToEdit = [
    {
      name: t("Fixed Support"),
      box: einspannungRefBox,
      component: (
        <Einspannung
          groupRef={einspannungRef}
          x={strokeWidth / 2}
          y={strokeWidth / 2}
          strokeWidth={strokeWidth}
          hatchAngle={hatchAngle}
          width={width}
          angle={0}
          height={height}
          strokeColor={strokeColor}
          setCenter={setCenter}
        />
      ),
    },
    {
      name: t("Roller Support"),
      box: loslagerRefBox,
      component: (
        <LosLager
          groupRef={loslagerRef}
          x={strokeWidth / 2}
          y={strokeWidth / 2}
          strokeWidth={strokeWidth}
          hatchAngle={hatchAngle}
          width={width}
          angle={0}
          height={height}
          dJoint={dJoint}
          hJointToBase={hJointToBase}
          dBall={dBall}
          drawBalls={drawBalls}
          tFactor={tFactor}
          strokeColor={strokeColor}
          jointFillColor={jointFillColor}
          bodyColor={bodyColor}
          ballsColor={ballsColor}
          setCenter={setCenter}
        />
      ),
    },
    {
      name: t("Pinned Support"),
      box: festlagerRefBox,
      component: (
        <FestLager
          groupRef={festlagerRef}
          x={strokeWidth / 2}
          y={strokeWidth / 2}
          strokeWidth={strokeWidth}
          hatchAngle={hatchAngle}
          width={width}
          angle={0}
          height={height}
          dJoint={dJoint}
          hJointToBase={hJointToBase}
          tFactor={tFactor}
          strokeColor={strokeColor}
          jointFillColor={jointFillColor}
          bodyColor={bodyColor}
          setCenter={setCenter}
        />
      ),
    },
    {
      name: t("Sliding Sleeve"),
      box: schiebehuelseRefBox,
      component: (
        <Schiebehuelse
          groupRef={schiebehuelseRef}
          x={strokeWidth / 2}
          y={strokeWidth / 2}
          strokeWidth={strokeWidth}
          hatchAngle={hatchAngle}
          width={width}
          angle={0}
          height={height}
          dBall={dBall}
          drawBalls={drawBalls}
          strokeColor={strokeColor}
          ballsColor={ballsColor}
          setCenter={setCenter}
        />
      ),
    },
  ];

  const [bearingToEdit, setBearingToEdit] = useState("None");

  const buttons = bearingsToEdit.map((obj, index) => {
    return (
      <BearingSelectPaper
        funcOnClick={setBearingToEdit}
        box={obj.box}
        name={obj.name}
        key={obj.name}
        svgGroup={obj.component}
        strokeWidth={strokeWidth}
      />
    );
  });
  let body = <div></div>;
  if (bearingToEdit === t("Fixed Support")) {
    body = (
      <EinspannungsController
        inWidth={width}
        inHeight={height}
        inHatchAngle={hatchAngle}
        inStrokeWidth={strokeWidth}
        inStrokeColor={strokeColor}
      />
    );
  } else if (bearingToEdit === t("Roller Support")) {
    body = (
      <LosLagerController
        inWidth={width}
        inHeight={height}
        inHatchAngle={hatchAngle}
        inStrokeWidth={strokeWidth}
        inDJoint={dJoint}
        inHJointToBase={hJointToBase}
        inTFactor={tFactor}
        inDBalls={dBall}
        inDrawBalls={drawBalls}
        inStrokeColor={strokeColor}
        inJointFillColor={jointFillColor}
        inBodyColor={bodyColor}
        inBallsColor={ballsColor}
      />
    );
  } else if (bearingToEdit === t("Pinned Support")) {
    body = (
      <FestLagerController
        inWidth={width}
        inHeight={height}
        inHatchAngle={hatchAngle}
        inStrokeWidth={strokeWidth}
        inDJoint={dJoint}
        inHJointToBase={hJointToBase}
        inTFactor={tFactor}
        inStrokeColor={strokeColor}
        inJointFillColor={jointFillColor}
        inBodyColor={bodyColor}
      />
    );
  } else if (bearingToEdit === t("Sliding Sleeve")) {
    body = (
      <SchiebehuelseController
        inWidth={width}
        inHeight={height}
        inHatchAngle={hatchAngle}
        inStrokeWidth={strokeWidth}
        inBallsColor={ballsColor}
        inStrokeColor={strokeColor}
        inDBalls={dBall}
        inDrawBalls={drawBalls}
      />
    );
  } else {
    body = (
      <div>
        <div className={classes.paperWrapper}>{buttons}</div>
      </div>
    );
  }
  return (
    <>
      <Paper square elevation={2} className={classes.topLine}>
        <div className={classes.arrow}>
          {bearingToEdit === "None" ? (
            ""
          ) : (
            <IconButton size="small" onClick={() => setBearingToEdit("None")}>
              <ArrowBackIcon />
            </IconButton>
          )}
        </div>
        {bearingToEdit === "None" ? (
          <Typography className={classes.overview}>
            <div>{t("Overview")}</div>
          </Typography>
        ) : (
          <Typography className={classes.controllerName}>
            <div>{bearingToEdit}</div>
          </Typography>
        )}
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Paper>

      <div className={classes.wrapp}>
        <div
          className={
            bearingToEdit === "None"
              ? classes.heightNone
              : classes.heightController
          }
        >
          {body}
        </div>
        {bearingToEdit === "None" ? (
          <div className={classes.rightSide}>
            <div className={classes.heightControlTab}>
              <ControlTabs
                isInSliderView={isInSliderView}
                setIsInSliderView={setIsInSliderView}
                color={theme.palette.secondary.main}
                name1={t("Size")}
                name2={t("Color")}
                strokeColor="white"
                strokeColorUnset="white"
              />
            </div>

            <div className={classes.panelBody}>
              {isInSliderView ? inputSliders : colorView}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

import { AnglePoints } from "features/Context/types/AngleMeasurementModel";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { Line, Point } from "features/LinearAlgebra/linearAlgebraModel";
import {
  addV,
  distance,
  rotateByDeg,
  sM,
} from "features/LinearAlgebra/vectorUtils";

export const getAnglePointsFromLinePivotAndAngle = (
  line: Line,
  pivot: Point,
  angle: number
): AnglePoints => {
  const lineLength = distance(line[0], line[1]);
  const lineDir = getLineDir(line);
  const factorOfLineLength = 0.4;
  const startPoint = addV(pivot, sM(factorOfLineLength * lineLength, lineDir));
  const rotatedLineDir = rotateByDeg(lineDir, angle);
  const endPoint = addV(
    pivot,
    sM(factorOfLineLength * lineLength, rotatedLineDir)
  );
  let anglePoints: AnglePoints = {
    pivotPoint: pivot,
    startPoint: startPoint,
    endPoint: endPoint,
  };
  return anglePoints;
};

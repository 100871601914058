export function isPointInStick(
  pointX: number,
  pointY: number,
  stickX: number,
  stickY: number,
  stickAngle: number,
  stickLength: number
) {
  const dx =
    pointX -
    (stickX + (Math.cos((stickAngle / 360) * 2 * Math.PI) * stickLength) / 2);
  const dy =
    pointY -
    (stickY + (Math.sin((stickAngle / 360) * 2 * Math.PI) * stickLength) / 2);
  const dist = Math.sqrt(dx * dx + dy * dy);
  const dxRot = inRotatedKOS(dx, dy, stickAngle).dxRot;
  const dyRot = inRotatedKOS(dx, dy, stickAngle).dyRot;
  const isOnStick =
    Math.abs(dxRot) <= stickLength / 2 + 0.2 && //taxi Fahrer norm stimmt
    Math.abs(dyRot) <= stickLength / 2 + 0.2 &&
    dist <= stickLength / 2 + 0.2; // l2 norm stimmt
  return isOnStick;
}

function inRotatedKOS(dx: number, dy: number, angle: number) {
  const dxRot =
    dx * Math.cos((angle / 360) * 2 * Math.PI) +
    dy * Math.sin((angle / 360) * 2 * Math.PI);
  const dyRot =
    -dx * Math.sin((angle / 360) * 2 * Math.PI) +
    dy * Math.cos((angle / 360) * 2 * Math.PI);
  return { dxRot, dyRot };
}
// function toPolar(x1, y1, x2, y2) {
//   // phi in degree
//   const phi = (Math.atan2(y2 - y1, x2 - x1) / 2 / Math.PI) * 360;
//   const radius = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));
//   return { phi: phi, radius: radius };
// }
// function toCartesian(phi, radius) {
//   //phi in degree
//   const dx = Math.cos((phi / 360) * 2 * Math.PI) * radius;
//   const dy = Math.sin((phi / 360) * 2 * Math.PI) * radius;
//   return { dx: dx, dy: dy };
// }

import { CordsArrow } from "features/Components/Playground/Cords/CordsArrow";

export function ForceIcon({
  size = 24,
  strokeWidth = 2,
  color = "black",
}: {
  size?: number;
  strokeWidth?: number;
  color?: string;
}) {
  const viewBox = `${0} ${-50} ${50} ${50}`;
  return (
    <svg width={size + "px"} height={size + "px"} viewBox={viewBox}>
      <CordsArrow
        // stroke needs to be 0 to make the peaks of the arrows perfect
        polyStrokeWidth={0}
        strokeWidth={strokeWidth}
        color={color}
        peakAngle={30}
        polarDest={{ destX: 25, destY: 0, angle: 90, length: size * 2 }}
      />
    </svg>
  );
}

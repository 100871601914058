import { MeasureDistanceContext } from "features/Context/MeasureDistanceContext";
import { MouseMoveContext } from "features/Context/MouseMoveContext";
import { useCallback, useContext, useState } from "react";

const useDragMeasurement = () => {
  const { setLineOffset, draggableMeasureArrow } = useContext(
    MeasureDistanceContext
  );
  const { measurementArrowIsDraggable, setMeasurementArrowIsDraggable } =
    useContext(MouseMoveContext);

  const { lastX, setLastX, lastY, setLastY } = useContext(MouseMoveContext);

  const handleMouseMoveMeasurement = useCallback((e) => {
    // in playground

    e.preventDefault();
    if (measurementArrowIsDraggable) {
      const deltaX = e.pageX - lastX;
      const deltaY = -e.pageY - lastY;
      // const newOffsetX = loadWithDraggableAnnotation.textOffsetX + deltaX;
      // const newOffsetY = loadWithDraggableAnnotation.textOffsetY + deltaY;
      if (draggableMeasureArrow) {
        //load has draggable annotation
        setLineOffset([deltaX, deltaY], draggableMeasureArrow?.index);
      }
      setLastX(e.pageX);
      setLastY(-e.pageY);
    }
  });

  const handleDragMeasurementStart = (e, thisMeasurementIsDraggable) => {
    //used onclick in svg comp
    e.preventDefault();

    if (thisMeasurementIsDraggable) {
      setMeasurementArrowIsDraggable(true);
      setLastX(e.pageX);
      setLastY(-e.pageY);
    }
  };

  const handleDragEndMeasurement = useCallback((e) => {
    // in playground
    e.preventDefault();
    setMeasurementArrowIsDraggable(false);
  });
  return {
    handleDragEndMeasurement,
    handleDragMeasurementStart,
    handleMouseMoveMeasurement,
  };
};

export default useDragMeasurement;

import {
  BearingStyleContext,
  BearingStyleContextType,
} from "features/Context/BearingStyleContext";

import {
  DrawenObject,
  PendingObject,
} from "features/Context/types/DrawenObjectModel";
import { useContext } from "react";

export default function BearingPositionName({
  text,
  object,
}: {
  text: string | null;
  object: DrawenObject | PendingObject;
  updater: any;
}) {
  const isPending = object.index === "pending";
  const isPart = object.mechanicalType === "part";
  const xx = object.x ? object.x : 0;
  const yy = object.y ? object.y : 0;
  const angle = object.angle ? object.angle : 0;

  let { width: dist } = useContext(
    BearingStyleContext
  ) as BearingStyleContextType;
  dist = dist * 1.4;
  const textToCenterOffset = 6;
  const x = xx;
  const y = yy + dist;
  return (
    <g
      transform={`translate(${x} ${y}) rotate(${0}) scale(${
        1 + " " + -1
      }) translate(${-textToCenterOffset} ${-textToCenterOffset}) `}
    >
      {!isPending && !isPart && text && (
        <text x={0} y={0}>
          {text}
        </text>
      )}
    </g>
  );
}

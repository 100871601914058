import React, { useState, createContext } from "react";

export const PlaygroundContext = createContext<PlaygroundContextType | null>(
  null
);
export type PlaygroundContextType = {
  isZooming: boolean;
  setIsZooming: React.Dispatch<React.SetStateAction<boolean>>;
  isPaning: boolean;
  setIsPaning: React.Dispatch<React.SetStateAction<boolean>>;
  currentZoom: number;
  setCurrentZoom: React.Dispatch<React.SetStateAction<number>>;
  translateX: number;
  translateY: number;
  setTranslateX: React.Dispatch<React.SetStateAction<number>>;
  setTranslateY: React.Dispatch<React.SetStateAction<number>>;
  width: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  height: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  lastPercentageUsed: number;
  setLastPercentageUsed: React.Dispatch<React.SetStateAction<number>>;
};
export function PlaygroundProvider(props: any) {
  const [isZooming, setIsZooming] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(1);
  const [isPaning, setIsPaning] = useState(false);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const [width, setWidth] = useState(700);
  const [height, setHeight] = useState(500);
  const [lastPercentageUsed, setLastPercentageUsed] = useState(100);
  return (
    <PlaygroundContext.Provider
      value={{
        isZooming: isZooming,
        setIsZooming: setIsZooming,
        isPaning: isPaning,
        setIsPaning: setIsPaning,
        currentZoom: currentZoom,
        setCurrentZoom: setCurrentZoom,
        translateX: translateX,
        translateY: translateY,
        setTranslateX: setTranslateX,
        setTranslateY: setTranslateY,
        width: width,
        setWidth: setWidth,
        height: height,
        setHeight,
        lastPercentageUsed,
        setLastPercentageUsed,
      }}
    >
      {props.children}
    </PlaygroundContext.Provider>
  );
}

import { ArcPolar } from "features/Components/Playground/Cords/ArcPolar";
import { ArcWithArrowHead } from "features/Components/Playground/Cords/ArcWithArrowHead";
import { SvgLine } from "features/Components/Playground/Cords/SvgLine";
import { AnglePoints } from "features/Context/types/AngleMeasurementModel";
import { Point } from "features/LinearAlgebra/linearAlgebraModel";
import {
  getSignedAngleToXAxis,
  subV,
} from "features/LinearAlgebra/vectorUtils";

export default function AngleMeasureIcon({
  size = 24,
  strokeWidth = 1,
  title = "",
  color = "black",
} = {}) {
  const viewBox = `${-strokeWidth} ${-strokeWidth} ${
    size + 3 * strokeWidth
  } ${size}`;
  const arcRadius = size * 0.7;
  const startP: Point = [size * 0.9, 2];
  const endP: Point = [size * 0.7, size * 0.7];
  const pivotP: Point = [2, 2];
  const anglePoints: AnglePoints = {
    startPoint: startP,
    endPoint: endP,
    pivotPoint: pivotP,
  };
  const startAngle = getSignedAngleToXAxis(subV(startP, pivotP));
  const endAngle = getSignedAngleToXAxis(subV(endP, pivotP));
  const lineColor = color;
  const orientation = 1;
  return (
    <svg
      width={size + "px"}
      height={size + "px"}
      viewBox={viewBox}
      transform="scale(1,-1)"
    >
      <ArcPolar
        startAngle={startAngle}
        cx={anglePoints.pivotPoint[0]}
        cy={anglePoints.pivotPoint[1]}
        radius={arcRadius}
        color={lineColor}
        strokeWidth={strokeWidth}
        orientation={orientation}
        angle={endAngle - startAngle}
        strokeDashArray={"1,0"}
      />
      <SvgLine
        point1={pivotP}
        point2={startP}
        fill={lineColor}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      ></SvgLine>
      <SvgLine
        point1={pivotP}
        point2={endP}
        fill={lineColor}
        stroke={lineColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      ></SvgLine>
    </svg>
  );
}

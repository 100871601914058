import { useTranslation } from "react-i18next";
import length from "../imgs/symbolic/length.png";
import moment from "../imgs/symbolic/moment.png";
import { ImgObj, SystemExample } from "./SystemExample";

export function SymbolicCapabilities() {
  const { t } = useTranslation();
  const width = 350;
  const imgObjs: Array<ImgObj> = [
    {
      imgSrc: length,
      header: t("Switch between symbolic and numeric length"),
      width: width,
    },
    {
      imgSrc: moment,
      header: t("Switch between symbolic and numeric moment"),
      width: width,
    },
  ];
  return <SystemExample imgObjs={imgObjs}></SystemExample>;
}

import { useState, createContext } from "react";
import { BalanceEquation, BalanceType } from "./types/FreischnittModel";

export const MechanicalSolutionContext =
  createContext<MechanicalSolutionContextType | null>(null);
export type MechanicalSolutionContextType = {
  balanceEquations: Array<{
    eq: BalanceEquation;
    balanceType: BalanceType;
  }>;
  setBalanceEquations: React.Dispatch<
    React.SetStateAction<
      {
        eq: BalanceEquation;
        balanceType: BalanceType;
      }[]
    >
  >;
  unknowns: Array<string>;
  setUnknwons: React.Dispatch<React.SetStateAction<string[]>>;
  solutionEquations: Array<string>;
  setSolutionEquations: React.Dispatch<React.SetStateAction<string[]>>;
  calculationError: boolean;
  setCalculationError: React.Dispatch<React.SetStateAction<boolean>>;
};
export function MechanicalSolutionProvider(props: any) {
  const [balanceEquations, setBalanceEquations] = useState<
    Array<{
      eq: BalanceEquation;
      balanceType: BalanceType;
    }>
  >([]);
  const [unknowns, setUnknwons] = useState<Array<string>>([]);
  const [solutionEquations, setSolutionEquations] = useState<Array<string>>([]);
  const [calculationError, setCalculationError] = useState(false);

  return (
    <MechanicalSolutionContext.Provider
      value={{
        balanceEquations,
        setBalanceEquations,
        unknowns,
        setUnknwons,
        solutionEquations,
        setSolutionEquations,
        calculationError,
        setCalculationError,
      }}
    >
      {props.children}
    </MechanicalSolutionContext.Provider>
  );
}

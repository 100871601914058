import { IconButton, makeStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  downLoadSvgBlob,
  getSvgBlob,
} from "features/Components/BearingEditor/util/saveSVG";
import {
  ConsoleContext,
  ConsoleContextType,
} from "features/Context/ConsoleContext";
import { useContext } from "react";

const useStyles = makeStyles((theme) => ({
  width: {
    background: "white",
    border: "2px solid black",
    width: "300px",
    objectFit: "contain",
  },
  wrapper: {
    width: "300px",
    position: "relative",
  },
  icon: {
    position: "absolute",

    top: "0px",
    right: "0px",
  },
}));

export const useLogSvg = (): Function => {
  const classes = useStyles();
  const { addEntryToConsole } = useContext(
    ConsoleContext
  ) as ConsoleContextType;
  const logSvg = () => {
    const elementID = "playground";
    const el = document.getElementById(elementID);
    if (!el) {
      return;
    }
    const svgBlob = getSvgBlob(el);
    const handleDownload = () => {
      downLoadSvgBlob(svgBlob);
    };
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(svgBlob);
    const imgEl = (
      <div className={classes.wrapper}>
        <img className={classes.width} src={imageUrl} alt="img" />
        <IconButton className={classes.icon} onClick={handleDownload}>
          <GetAppIcon></GetAppIcon>
        </IconButton>
      </div>
    );
    addEntryToConsole(imgEl);
  };
  return logSvg;
};

import { useState } from "react";
import { makeStyles, Slider as MuiSlider, TextField } from "@material-ui/core";
import useMarkSnapper from "../util/useMarkSnapper";
import { Mark } from "../util/MarkModel";
import { inputColor } from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles({
  slider: {
    width: "100%",
  },
  sliderWrapper: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  input: {
    background: inputColor,
    width: "80px",
  },
});
export function Slider({
  step = 1,
  handleChange,
  description = "",
  min,
  max,
  value,
  marks = [],
  ownMarks = false,
}: {
  step?: number;
  handleChange: Function;
  description?: string | JSX.Element;
  min: number;
  max: number;
  value: number;
  marks?: Array<Mark>;
  ownMarks?: boolean;
}) {
  const classes = useStyles();

  const [input, setInput] = useState<number | string>(1); //handle empty input
  const marksCustom = ownMarks
    ? marks
    : marks.concat([
        { value: min, label: String(Math.round(min)) },
        { value: max, label: String(Math.round(max)) },
      ]);
  const [snapConvert, setLastCommitted] = useMarkSnapper(marksCustom, max / 30);

  const handleChangeSlider = (
    e: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) => {
    if (!Array.isArray(newVal)) {
      handleChange(snapConvert(newVal));
    }
  };
  const handleChangeText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    newVal: string
  ) => {
    if (!isNaN(parseInt(newVal)) && newVal) {
      // check if input is number
      const realNewValue = parseInt(newVal);

      handleChange(realNewValue);

      setInput(1);
    } else {
      setInput("");
    }
  };
  const handleChangeCommitted = (
    e: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) => {
    if (!Array.isArray(newVal)) {
      const snapConvertedNum = snapConvert(newVal);
      if (snapConvertedNum === undefined) {
        return;
      }
      setLastCommitted(snapConvertedNum);
    }
  };

  return (
    <div className={"sliderContainer"}>
      {description && (
        <p className={" sliderDescription sliderDescription"}>{description} </p>
      )}

      <TextField
        type="number"
        onChange={(e) => handleChangeText(e, e.target.value)}
        className={"numberInput numberInput"}
        inputProps={{
          className: classes.input,
          min: min,
          step: step,
          max: max,
        }}
        value={!input || Math.round(value)}
      />
      <div className={classes.sliderWrapper}>
        <MuiSlider
          className={classes.slider}
          onChangeCommitted={(e, newVal) => handleChangeCommitted(e, newVal)}
          onChange={(e, newVal) => handleChangeSlider(e, newVal)}
          min={min}
          step={step}
          max={max}
          marks={marksCustom}
          value={Math.round(value)}
        />
      </div>
    </div>
  );
}

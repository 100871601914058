import { useContext } from "react";
import SubmitButtons from "./SubmitButtons";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { ColorContext, ColorContextType } from "features/Context/ColorContext";
import { SubmitWrapper } from "./SubmitWrapper";
import { useTranslation } from "react-i18next";

export function SubmitFormLoads() {
  const { t } = useTranslation();
  const { savePendingLoad, deletePendingLoad, pendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { handleNewColor } = useContext(ColorContext) as ColorContextType;

  const handleSave = () => {
    if (pendingLoad) {
      handleNewColor(pendingLoad.color1);
      handleNewColor(pendingLoad.color2);
      handleNewColor(pendingLoad.textColor);
      savePendingLoad();
    }
  };

  return (
    <SubmitWrapper isPending={true}>
      <SubmitButtons
        onCancel={deletePendingLoad}
        onSave={handleSave}
        saveDisabled={false}
        submitString={t("Save Pending")}
        cancelString={t("Delete Pending")}
      />
    </SubmitWrapper>
  );
}

import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../Context/PendingContext";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import {
  DrawenObject,
  ObjectIndex,
} from "features/Context/types/DrawenObjectModel";

export function useObj(props: { name: ObjectIndex | "pending" }) {
  const context = useContext(PendingContext) as PendingContextType;
  const pendingObj = context.pendingObject;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  if (props.name === "pending") {
    return pendingObj;
  } else {
    return drawenObjects[props.name] as DrawenObject;
  }
}

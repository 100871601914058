import { useContext, useCallback } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../Context/PendingContext";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../../Context/DrawenContext";

import { useReferenceOptions } from "./referenceHooks/useReferenceOptions";

import { RotationInput } from "./InputComponents/RotationInput";
import {
  InputLabel,
  FormHelperText,
  FormControl,
  NativeSelect,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { deg2Rad } from "features/Components/BearingEditor/util/angleConversion";
import { ControlAccordion } from "../../VisualElements/ControlAccordion";
import {
  inputColor,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
import { Pizza } from "features/Components/Playground/Cords/Pizza";
import { Mark } from "./util/MarkModel";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  pizzaWrap: {
    height: "30px",
    width: "30px",
  },
  input: {
    background: inputColor,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
}));
export function AngleControl() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    pendingObject,
    setPendingAngle,
    setAngleReferenceIndex,
    setPendingRelativeAngle,
  } = useContext(PendingContext) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const { setPendingCoords } = useContext(PendingContext) as PendingContextType;
  const refAngleOptions = useReferenceOptions();

  if (!pendingObject) {
    return null;
  }
  const displayAngle = pendingObject.relativeAngle
    ? pendingObject.relativeAngle
    : 0;
  //   const displayAngle = pendingObject.angle;
  const angleReferenceIndex = pendingObject.angleReferenceIndex;

  const handleRefAngleChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // new selection in dropdown menu
    const refIndex = parseInt(event.target?.value);
    if (pendingObject.mechanicalType === "bearing") {
      setAngleReferenceIndex(refIndex);
      setPendingAngle(drawenObjects[refIndex].angle);
      setPendingRelativeAngle(0);
    } else {
      const newTotalAngle = drawenObjects[refIndex].angle;
      // console.log("ref", refIndex, newTotalAngle);
      const bearing = drawenObjects[pendingObject.parentIndex];
      if (!bearing) {
        return;
      }
      const xBearing = bearing.x;
      const yBearing = bearing.y;
      const distanceToBearing = pendingObject.relativePos?.distance;
      if (distanceToBearing === undefined) {
        return;
      }
      const xDirStickEinheits = Math.cos(deg2Rad(newTotalAngle));
      const yDirStickEinheits = Math.sin(deg2Rad(newTotalAngle));
      const newX = xBearing - xDirStickEinheits * distanceToBearing;
      const newY = yBearing - yDirStickEinheits * distanceToBearing;
      setAngleReferenceIndex(refIndex);
      setPendingCoords({
        x: newX,
        y: newY,
      });
      setPendingRelativeAngle(0);
      setPendingAngle(newTotalAngle);
    }
  };

  const handleChange = (newDisplayAngle: number) => {
    if (angleReferenceIndex === undefined) {
      return;
    }
    if (pendingObject.mechanicalType === "bearing") {
      setPendingRelativeAngle(newDisplayAngle);
      setPendingAngle(
        newDisplayAngle + drawenObjects[angleReferenceIndex].angle
      ); // add the input angle to the angle of the reference object to get absolute total angle
    } else {
      //handle part rotation around parent bearing
      const newTotalAngle =
        newDisplayAngle + drawenObjects[angleReferenceIndex].angle;
      const bearing = drawenObjects[pendingObject.parentIndex];
      if (!bearing) {
        return;
      }
      const xBearing = bearing.x;
      const yBearing = bearing.y;
      const distanceToBearing = pendingObject.relativePos?.distance;
      if (distanceToBearing === undefined) {
        return;
      }
      const xDirStickEinheits = Math.cos(deg2Rad(newTotalAngle));
      const yDirStickEinheits = Math.sin(deg2Rad(newTotalAngle));
      const newX = xBearing - xDirStickEinheits * distanceToBearing;
      const newY = yBearing - yDirStickEinheits * distanceToBearing;
      setPendingCoords({
        x: newX,
        y: newY,
      });
      setPendingRelativeAngle(newDisplayAngle);
      setPendingAngle(newTotalAngle);
    }
  };
  const marks: Array<Mark> = [
    { value: 45, label: "45°" },
    { value: 90, label: "90°" },
    { value: 135, label: "135°" },
    { value: 180, label: "180°" },
    { value: 225, label: "225°" },
    { value: 270, label: "270°" },
    { value: 315, label: "315°" },
  ];
  const imgWidth = 25;
  return (
    <div className={"AngleControl"}>
      <ControlAccordion
        expanded={true}
        titleMainElement={
          <div className={classes.flex}>
            <Typography>{t("Angle")}</Typography>
            <svg width={imgWidth} height={imgWidth}>
              <g id="arc" transform={"scale(1 -1) "}>
                <Pizza
                  angle={Math.round(displayAngle)}
                  color={primaryColor}
                  strokeWidth={3}
                  strokeWidth2={2}
                  radius={(0.8 * imgWidth) / 2}
                  cx={(0.9 * imgWidth) / 2}
                  cy={(-0.9 * imgWidth) / 2}
                  orientation={1}
                />
              </g>
            </svg>
          </div>
        }
        titleEndElement={<></>}
        mainContent={
          <>
            <RotationInput
              handleAngleChange={handleChange}
              imgWidth={50}
              imgHeight={50}
              description=""
              angle={displayAngle}
              min={0}
              max={360}
              marks={marks}
              showPizza={false}
            />
            <FormControl className={""}>
              {/* <InputLabel shrink htmlFor="Pos-native-label-placeholder">
                Angle is relative to:
              </InputLabel> */}
              <FormHelperText>{t("Angle is relative to:")}</FormHelperText>

              <NativeSelect
                value={angleReferenceIndex}
                onChange={handleRefAngleChange}
                inputProps={{
                  className: classes.input,
                  name: "Angle",
                  id: "Angle-native-label-placeholder",
                }}
              >
                {refAngleOptions}
              </NativeSelect>
            </FormControl>
          </>
        }
      ></ControlAccordion>
    </div>
  );
}

import {
  DrawenObject,
  DrawenObjects,
} from "features/Context/types/DrawenObjectModel";

export function getAllBearingsFromPart(
  drawenObjects: DrawenObjects,
  part: DrawenObject
) {
  const parentBearing = drawenObjects[part.parentIndex];
  const children = part.children?.map((childIndex) => {
    return drawenObjects[childIndex];
  });
  const childBearings = children?.filter((child) => {
    return child.mechanicalType === "bearing";
  });
  if (childBearings.length > 0) {
    return [parentBearing, ...childBearings];
  } else {
    return [parentBearing];
  }
}

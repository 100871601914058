import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { useContext } from "react";
import AngleMeasurementEl from "../MeasurementsVisualization/AngleMeasurementEl";

export const useAngleMeasurementObjs = () => {
  // draw all drawen components
  const { angleMeasurements } = useContext(
    MeasureAngleContext
  ) as MeasureAngleContextType;
  if (!angleMeasurements) {
    return null;
  }
  const measureComps = Object.values(angleMeasurements).map((measure) => {
    const index = measure.index;

    return (
      <AngleMeasurementEl
        index={index}
        key={index + "" + measure.magnitude + "" + measure.mainSymbol}
      />
    );
  });
  return measureComps;
};

import React, { useContext } from "react";

import { BearingShowPiece } from "./BearingShowPiece";
import { bearingTypes } from "../../../../../GlobalData/bearingTypes";
import { Typography, makeStyles } from "@material-ui/core";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import { DrawenObjectType } from "features/Context/types/ConstructionElementModel";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  BearingShelf: {
    overflowY: "scroll",
    height: "90%",
    width: "100%",
  },
  flexBearings: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    borderTop: "solid 1px grey",
  },
  width50: {
    width: "50%",
  },
  margin: {
    marginTop: "10px",
  },
});

export function BearingShelf() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;

  const internBearings = Object.values(bearingTypes).filter((bearingType) => {
    return bearingType.connectorType === "intern";
  });
  const externBearings = Object.values(bearingTypes).filter((bearingType) => {
    return bearingType.connectorType === "extern";
  });
  const internImages = internBearings.map((bearingType) => {
    const name =
      i18n.language === "en" ? bearingType.nameEn : bearingType.nameDe;
    return (
      <div className={classes.width50}>
        <BearingShowPiece
          key={name}
          type={bearingType.type as DrawenObjectType}
          name={name}
        />
      </div>
    );
  });
  const externImages = externBearings.map((bearingType) => {
    const name =
      i18n.language === "en" ? bearingType.nameEn : bearingType.nameDe;
    return (
      <div className={classes.width50}>
        <BearingShowPiece
          key={name}
          type={bearingType.type as DrawenObjectType}
          name={name}
        />
      </div>
    );
  });
  const isNotFirstBearing = Boolean(Object.values(drawenObjects).length - 1);

  return (
    <div className={classes.BearingShelf}>
      <Typography color="textSecondary">{t("External Connectors")}</Typography>
      <div className={classes.flexBearings}>{externImages}</div>
      {isNotFirstBearing && (
        <>
          <Typography className={classes.margin} color="textSecondary">
            {t("Internal Connectors")}
          </Typography>
          <div className={classes.flexBearings}>{internImages}</div>
        </>
      )}
    </div>
  );
}

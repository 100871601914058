import { PartType } from "features/Context/types/ConstructionElementModel";
import { ControlType } from "features/Context/types/DrawenObjectModel";

export const partTypes: PartTypes = {
  stab: {
    // mitte gelenk
    src: "/img/parts/Stab.svg",
    name: "Dünner Stab",
    type: "stab",
    controlType: "HeightWidth",
    refPoint: {
      //center
      x: 150,
      y: 5,
      w: 300,
      h: 10,
      xRatio: 0,
      yRatio: 1 / 2,
    },
  },
  root: {
    // helper element
    src: "/img/parts/MassenpunktmitRechteck.svg",
    name: "Inertial-System",
    type: "root",
    controlType: "Width",
    refPoint: {
      x: 0,
      y: 0,
      xRatio: 1 / 2,
      yRatio: 1 / 2,
    },
  },
};
export type PartTypes = {
  [key: number | string]: PartSpec;
};
export type PartSpec = {
  src: string;
  name: string;
  type: PartType;
  controlType: ControlType;
  refPoint: {
    x: number;
    y: number;
    w?: number;
    h?: number;
    xRatio: number;
    yRatio: number;
  };
};

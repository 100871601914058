import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../Context/PendingContext";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import { PositionMeasurements } from "./PositionMeasurements";
import { PendingObject } from "features/Context/types/DrawenObjectModel";

export function Measurements() {
  const { pendingObject, isPending } = useContext(
    PendingContext
  ) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;

  return (
    <>
      {isPending && (
        <PositionMeasurements
          pendingObject={pendingObject as PendingObject}
          drawenObjects={drawenObjects}
        />
      )}
    </>
  );
}

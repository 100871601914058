import { makeStyles } from "@material-ui/core";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { Line } from "features/LinearAlgebra/linearAlgebraModel";
import {
  addV,
  middlePoint,
  rotateByDeg,
  sM,
} from "features/LinearAlgebra/vectorUtils";
import { useContext } from "react";
import LatexAnnotation from "../helper/LatexAnnotation";
import { useMeasureAnnotationDragHelper } from "../helper/util/useMeasureAnnotationDragHelper";
import { useMeasureArrowDragHelper } from "../helper/util/useMeasureArrowDragHelper";
import { createAnnotation } from "../Loads/util/createAnnotation";
import DistanceArrow from "../Cords/DistanceArrow";
import { SvgLine } from "../Cords/SvgLine";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  notClicked: { cursor: "pointer" },
  clicked: {
    cursor: "move",
  },
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
}));
export default function DistanceMeasurement({
  index,
  fontSize = 15,
}: {
  index: number;
  fontSize?: number;
}) {
  const classes = useStyles();
  const { measurements, setIsBeingModified } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const { handleClick, handleMouseDown } =
    useMeasureAnnotationDragHelper(index);
  const {
    handleClick: handleMeasureClick,
    handleMouseDown: handleMeasureMouseDown,
  } = useMeasureArrowDragHelper(index);
  if (!measurements) {
    return null;
  }
  const measure = measurements[index];
  if (
    !measure ||
    measure.isHidden ||
    measure.isHiddenByUser ||
    measure.isFake
  ) {
    return null;
  }
  const isHighlighted = measure.isHighlighted;
  const arrowColor = measure.arrowColor;
  const annotationColor = measure.annotationColor;
  const initialLine = measure.initialLine;
  const {
    textOffsetX,
    textOffsetY,
    lineOffset,
    unitSymbol,
    preFactor,
    mainSymbol,
    suffix,
    magnitude,
    unitFactor,
    isSymbolic,
    annotationIsDraggable,
    arrowIsDraggable,
  } = measure;

  const [latexStart, hasFracStart] = createAnnotation(
    "force",
    preFactor,
    unitFactor,
    unitSymbol,
    magnitude,
    suffix,
    isSymbolic,
    mainSymbol
  );
  //calculate offset
  const normalDir = rotateByDeg(getLineDir(initialLine), 90);
  const [p1, p2] = initialLine;
  const offsetVec = sM(lineOffset, normalDir);
  const p1Offset = addV(p1, offsetVec);
  const p2Offset = addV(p2, offsetVec);
  const offsetVecHigher = sM(lineOffset + 8, normalDir);
  const p1OffsetHigher = addV(p1, offsetVecHigher);
  const p2OffsetHigher = addV(p2, offsetVecHigher);
  const offsetVecLow = sM(20, normalDir);
  const p1OffsetLow = addV(p1, offsetVecLow);
  const p2OffsetLow = addV(p2, offsetVecLow);
  const offsettedLine: Line = [p1Offset, p2Offset];
  const annotationPoint = middlePoint(...offsettedLine);

  const handleClickOnMeasure = (e: any) => {
    handleMeasureClick(e);
    setIsBeingModified(true, index);
  };
  const p1DragHelper = addV(offsettedLine[0], sM(5, normalDir));
  const p2DragHelper = addV(offsettedLine[1], sM(5, normalDir));
  const p3DragHelper = addV(offsettedLine[1], sM(-5, normalDir));
  const p4DragHelper = addV(offsettedLine[0], sM(-5, normalDir));
  return (
    <g id={"Measure" + index}>
      <g
        className={isHighlighted ? classes.gOutline : ""}
        onClick={handleClickOnMeasure}
        onMouseDown={handleMeasureMouseDown}
      >
        <DistanceArrow
          points={offsettedLine}
          color={arrowIsDraggable ? primaryColor : arrowColor}
          arrowWidth={3}
        />
        <polygon
          className={arrowIsDraggable ? classes.clicked : classes.notClicked}
          fill="rgba(0,0,0,0)"
          points={`${p1DragHelper[0]},${p1DragHelper[1]} ${p2DragHelper[0]},${p2DragHelper[1]} ${p3DragHelper[0]},${p3DragHelper[1]} ${p4DragHelper[0]},${p4DragHelper[1]}`}
        />
      </g>
      <SvgLine
        strokeWidth={1}
        stroke={arrowColor}
        point1={p1OffsetLow}
        point2={p1OffsetHigher}
      ></SvgLine>
      <SvgLine
        strokeWidth={1}
        stroke={arrowColor}
        point1={p2OffsetLow}
        point2={p2OffsetHigher}
      ></SvgLine>
      <LatexAnnotation
        id={"measureAnnotation" + index}
        latex={latexStart}
        textOffsetX={textOffsetX}
        textOffsetY={textOffsetY}
        x={annotationPoint[0] + textOffsetX}
        color={annotationColor}
        y={annotationPoint[1] + textOffsetY}
        hasFrac={hasFracStart}
        height={fontSize}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        isClicked={annotationIsDraggable}
      />
    </g>
  );
}

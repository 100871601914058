import PolyLine from "features/Components/Playground/Cords/PolyLine";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { intersectOfTwoLines } from "features/LinearAlgebra/intersectOfTwoLines";
import {
  addV,
  distance,
  rotateByDeg,
  sM,
} from "features/LinearAlgebra/vectorUtils";
import { getAllLinesFromPart } from "../util/getAllLinesFromPart";

export default function ElongationConnector({
  schweissverbindungPoint,
  biggerPart,
  smallerPart,
  strokeWidth,
  strokeColor,
}) {
  const [lineBigger] = getAllLinesFromPart(biggerPart);
  const [lineSmaller, outerSmall1, outerSmall2] =
    getAllLinesFromPart(smallerPart);
  const biggerHeight = biggerPart.size.height;

  const [biggerStart, biggerEnd] = lineBigger;
  const [smallerStart, smallerEnd] = lineSmaller;
  // @ts-ignore
  const normalDirBigger = rotateByDeg(getLineDir(lineBigger), 90);
  const threshold = 3;
  const points1 = []; //4 top  elements
  const points2 = []; //4 bottom elements
  const elongLeft = 5;
  if (
    // @ts-ignore
    distance(biggerStart, schweissverbindungPoint) < threshold &&
    // @ts-ignore
    distance(smallerStart, schweissverbindungPoint) < threshold
  ) {
    //biggerStart and smallerStart are together

    const lineBiggerNormal = [
      // @ts-ignore
      addV(biggerStart, sM(biggerHeight / 2, normalDirBigger)),
      // @ts-ignore
      addV(biggerStart, sM(-biggerHeight / 2, normalDirBigger)),
    ];
    const [intersect1] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall1
    );
    const [intersect2] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall2
    );
    const p1 =
      distance(lineBiggerNormal[1], intersect1) <
      distance(lineBiggerNormal[0], intersect1)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    const p2 =
      distance(lineBiggerNormal[1], intersect2) <
      distance(lineBiggerNormal[0], intersect2)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    // @ts-ignore
    points1[0] = addV(intersect1, sM(elongLeft, getLineDir(lineSmaller)));
    points1[1] = intersect1;
    points1[2] = p1;
    // @ts-ignore
    points1[3] = addV(p1, sM(elongLeft, getLineDir(lineBigger)));

    // @ts-ignore
    points2[0] = addV(intersect2, sM(elongLeft, getLineDir(lineSmaller)));
    points2[1] = intersect2;
    points2[2] = p2;
    // @ts-ignore
    points2[3] = addV(p2, sM(elongLeft, getLineDir(lineBigger)));
  } else if (
    // @ts-ignore
    distance(biggerEnd, schweissverbindungPoint) < threshold &&
    // @ts-ignore
    distance(smallerStart, schweissverbindungPoint) < threshold
  ) {
    const lineBiggerNormal = [
      // @ts-ignore
      addV(biggerEnd, sM(biggerHeight / 2, normalDirBigger)),
      // @ts-ignore
      addV(biggerEnd, sM(-biggerHeight / 2, normalDirBigger)),
    ];
    // @ts-ignore
    const [intersect1, s, t] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall1
    );
    // @ts-ignore
    const [intersect2, f, d] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall2
    );
    const p1 =
      distance(lineBiggerNormal[1], intersect1) <
      distance(lineBiggerNormal[0], intersect1)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    const p2 =
      distance(lineBiggerNormal[1], intersect2) <
      distance(lineBiggerNormal[0], intersect2)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    // @ts-ignore
    points1[0] = addV(intersect1, sM(elongLeft, getLineDir(lineSmaller)));
    points1[1] = intersect1;
    points1[2] = p1;
    // @ts-ignore
    points1[3] = addV(p1, sM(-elongLeft, getLineDir(lineBigger)));

    // @ts-ignore
    points2[0] = addV(intersect2, sM(elongLeft, getLineDir(lineSmaller)));
    points2[1] = intersect2;
    points2[2] = p2;
    // @ts-ignore
    points2[3] = addV(p2, sM(-elongLeft, getLineDir(lineBigger)));
  } else if (
    // @ts-ignore
    distance(biggerStart, schweissverbindungPoint) < threshold &&
    // @ts-ignore
    distance(smallerEnd, schweissverbindungPoint) < threshold
  ) {
    const lineBiggerNormal = [
      // @ts-ignore
      addV(biggerStart, sM(biggerHeight / 2, normalDirBigger)),
      // @ts-ignore
      addV(biggerStart, sM(-biggerHeight / 2, normalDirBigger)),
    ];
    // @ts-ignore
    const [intersect1] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall1
    );
    // @ts-ignore
    const [intersect2] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall2
    );
    const p1 =
      distance(lineBiggerNormal[1], intersect1) <
      distance(lineBiggerNormal[0], intersect1)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    const p2 =
      distance(lineBiggerNormal[1], intersect2) <
      distance(lineBiggerNormal[0], intersect2)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    // @ts-ignore
    points1[0] = addV(intersect1, sM(-elongLeft, getLineDir(lineSmaller)));
    points1[1] = intersect1;
    points1[2] = p1;
    // @ts-ignore
    points1[3] = addV(p1, sM(elongLeft, getLineDir(lineBigger)));

    // @ts-ignore
    points2[0] = addV(intersect2, sM(-elongLeft, getLineDir(lineSmaller)));
    points2[1] = intersect2;
    points2[2] = p2;
    // @ts-ignore
    points2[3] = addV(p2, sM(elongLeft, getLineDir(lineBigger)));
  } else if (
    // @ts-ignore
    distance(biggerEnd, schweissverbindungPoint) < threshold &&
    // @ts-ignore
    distance(smallerEnd, schweissverbindungPoint) < threshold
  ) {
    const lineBiggerNormal = [
      // @ts-ignore
      addV(biggerEnd, sM(biggerHeight / 2, normalDirBigger)),
      // @ts-ignore
      addV(biggerEnd, sM(-biggerHeight / 2, normalDirBigger)),
    ];
    // @ts-ignore
    const [intersect1] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall1
    );
    // @ts-ignore
    const [intersect2, f, d] = intersectOfTwoLines(
      // @ts-ignore
      lineBiggerNormal,
      outerSmall2
    );
    const p1 =
      distance(lineBiggerNormal[1], intersect1) <
      distance(lineBiggerNormal[0], intersect1)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    const p2 =
      distance(lineBiggerNormal[1], intersect2) <
      distance(lineBiggerNormal[0], intersect2)
        ? lineBiggerNormal[1]
        : lineBiggerNormal[0];
    // @ts-ignore
    points1[0] = addV(intersect1, sM(-elongLeft, getLineDir(lineSmaller)));
    points1[1] = intersect1;
    points1[2] = p1;
    // @ts-ignore
    points1[3] = addV(p1, sM(-elongLeft, getLineDir(lineBigger)));

    // @ts-ignore
    points2[0] = addV(intersect2, sM(-elongLeft, getLineDir(lineSmaller)));
    points2[1] = intersect2;
    points2[2] = p2;
    // @ts-ignore
    points2[3] = addV(p2, sM(-elongLeft, getLineDir(lineBigger)));
  } else {
  }
  return (
    <>
      <PolyLine
        points={[...points1, ...points2.reverse()]}
        fill="white"
        strokeWidth={strokeWidth - 1}
        strokeLinecap="butt"
      />
      <PolyLine
        points={points1}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
      <PolyLine
        points={points2}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
    </>
  );
}

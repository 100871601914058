import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";
import { checkIfDistMeasureShouldBeHidden } from "features/Components/Functionality_Bottom/onStatusUpdate/util/checkIfDistMeasureShouldBeHidden";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import { LoadNames } from "features/Context/types/ConstructionElementModel";
import {
  DrawenObject,
  Index,
  RootObject,
} from "features/Context/types/DrawenObjectModel";
import { useContext } from "react";
export function useLoadSpecification() {
  const { createNewMeasurement } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const loadSpecification = (
    loadType: LoadNames,
    loadIndex: Index,
    parent?: DrawenObject | RootObject
  ) => {
    if (loadType === "force") {
      return { unitSymbol: "N", unitFactor: 1 };
    } else if (loadType === "moment") {
      return {
        orientation: 1,
        angleSpan: 220,
        unitSymbol: "Nm",
        unitFactor: 1,
      }; //unitFactor= new unit as fraction of si --> Nmm=1/1000Nm
    } else if (loadType === "streckenlast") {
      //@ts-ignore

      const [p1, p2] = getLineFromPart(parent);
      const leftPoint = p1[0] < p2[0] ? p1 : p2;
      const rightPoint = p1[0] < p2[0] ? p2 : p1;
      const lineOffsetForNewStreckenlast = 65;
      const loadSpec = {
        startUnitSymbol: "N/m",
        endUnitSymbol: "N/m",

        startUnitFactor: 1,
        endUnitFactor: 1,

        startSuffix: "0",
        endSuffix: "0",

        startPreFactor: "1",
        endPreFactor: "1",

        startIsSymbolic: true,
        endIsSymbolic: true,

        startAnnotationIsDraggable: false,
        endAnnotationIsDraggable: false,

        startTextOffsetX: 0,
        startTextOffsetY: 0,
        endTextOffsetX: 0,
        endTextOffsetY: 0,
        //magnitude is actual load
        startMagnitude: 50,
        endMagnitude: 100,
        //height is visual
        startHeight: 50,
        endHeight: 50,
        startPos: leftPoint,
        endPos: rightPoint,
        startMeasurementIndex: createNewMeasurement({
          initialLine: [leftPoint, leftPoint],
          mainSymbol: "l",
          isHidden: checkIfDistMeasureShouldBeHidden([leftPoint, leftPoint]),
          fromType: "part",
          fromAttachment: "start",
          toType: "streckenlast",
          toAttachment: "start",
          fromIndex: parent?.index,
          toIndex: loadIndex,
          lineOffset: lineOffsetForNewStreckenlast,
        }),
        lengthMeasurementIndex: createNewMeasurement({
          initialLine: [leftPoint, rightPoint],
          mainSymbol: "l",
          isHidden: checkIfDistMeasureShouldBeHidden([leftPoint, rightPoint]),
          fromType: "streckenlast",
          fromAttachment: "start",
          toType: "streckenlast",
          toAttachment: "end",
          fromIndex: loadIndex,
          toIndex: loadIndex,
          lineOffset: lineOffsetForNewStreckenlast,
        }),
        // startMeasureSymbol: "a",
        // lengthMeasureSymbol: "a",

        // startMeasureSuffix: "",
        // lengthMeasureSuffix: "",
        // startMeasureMagnitude: 0,
        // lengthMeasureMagnitude: 0,

        // startMeasureIsSymbolic: true,
        // lengthMeasureIsSymbolic: true,

        // startMeasureUnitSymbol: "m",
        // lengthMeasureUnitSymbol: "m",

        // startMeasureUnitFactor: 1,
        // lengthMeasureUnitFactor: 1,

        // startMeasureAnnotationIsDraggable: false,
        // lengthMeasureAnnotationIsDraggable: false,

        // startMeasureTextOffsetX: 0,
        // startMeasureTextOffsetY: 0,
        // lengthMeasureTextOffsetX: 0,
        // lengthMeasureTextOffsetY: 0,

        roughDistBetweenArrows: 40,
      };
      // console.log("startPos", loadSpec.startPos);
      // console.log("startPos", loadSpec.endPos);
      return loadSpec;
    }
  };
  return loadSpecification;
}

import {
  DrawenObject,
  DrawenObjects,
  ObjectIndex,
} from "features/Context/types/DrawenObjectModel";
/**
 *
 * @param bearingIndex
 * @param drawenobjects
 * @returns the partIndex of the part the extern bearing is connected
 */
export function getPartOfExternConnectedBearing(
  bearingIndex: ObjectIndex,
  drawenObjects: DrawenObjects
): number | null {
  if (!drawenObjects || !drawenObjects[bearingIndex]) {
    return null;
  }
  const bearing = drawenObjects[bearingIndex];
  let parent = drawenObjects[bearing.parentIndex];
  parent = parent?.index === "root" ? undefined : (parent as DrawenObject);
  if (parent && parent.mechanicalType === "part") {
    return parent.index;
  }

  if (bearing.children.length === 0) {
    return null;
  }

  const child = drawenObjects[bearing.children[0]];
  if (child && child.mechanicalType === "part") {
    return child.index;
  }

  return null;
}

/**
 *
 * @param bearingIndex
 * @param drawenobjects
 * @returns the partIndex of the part the extern bearing is connected
 */
export function getPartsOfInternConnectedBearing(
  bearingIndex: ObjectIndex,
  drawenObjects: DrawenObjects
): { parentPartIndex: ObjectIndex; childPartIndex: ObjectIndex } | null {
  if (!drawenObjects || !drawenObjects[bearingIndex]) {
    return null;
  }

  const bearing = drawenObjects[bearingIndex];
  let parent = drawenObjects[bearing.parentIndex];
  parent = parent?.index === "root" ? undefined : (parent as DrawenObject);
  let indexObj: { parentPartIndex: ObjectIndex; childPartIndex: ObjectIndex } =
    {
      parentPartIndex: 0,
      childPartIndex: 0,
    };
  if (parent && parent.mechanicalType === "part") {
    indexObj.parentPartIndex = parent.index;
  }

  if (bearing.children.length === 0) {
    return null;
  }

  const child = drawenObjects[bearing.children[0]];
  if (child && child.mechanicalType === "part") {
    indexObj.childPartIndex = child.index;
    return indexObj;
  }

  return null;
}

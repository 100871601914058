import { CordsArrow } from "../../Cords/CordsArrow";

//pure Function
export function CartesianGlobalMeasure({
  x1,
  x2,
  y1,
  y2,
  color = "grey",
  strokeWidth = 1,
  xDescription = "",
  yDescription = "",
  strokeDashArray = "10,10",
  size = 1,
} = {}) {
  const fontSize = Math.max(strokeWidth * 2, 10);
  const xText = {
    x: (x2 - x1) / 2 + x1,
    y: y1 + fontSize,
  };
  const yText = {
    x: x2 + fontSize,
    y: (y2 - y1) / 2 + y1,
  };
  return (
    <g id="cartsian-global">
      <g
        transform={
          "translate(" +
          xText.x +
          " " +
          xText.y +
          ") rotate(" +
          -180 +
          ") scale(" +
          -1 +
          " " +
          1 +
          ")"
        }
      >
        <text x={0} y={0} fill={color} fontSize={fontSize + "px"}>
          {xDescription}
        </text>
      </g>

      <CordsArrow
        peakAngle={20}
        strokeWidth={0}
        color={color}
        cartesian={{ originX: x1, originY: y1, destX: x2, destY: y1 }}
        size={size}
        strokeDashArray={strokeDashArray}
      />
      <g
        transform={
          "translate(" +
          yText.x +
          " " +
          yText.y +
          ") rotate(" +
          -180 +
          ") scale(" +
          -1 +
          " " +
          1 +
          ")"
        }
      >
        <text x={0} y={0} fill={color} fontSize={fontSize + "px"}>
          {yDescription}
        </text>
      </g>
      <CordsArrow
        peakAngle={20}
        strokeWidth={0}
        color={color}
        cartesian={{ originX: x2, originY: y1, destX: x2, destY: y2 }}
        size={size}
        strokeDashArray={strokeDashArray}
      />
    </g>
  );
}

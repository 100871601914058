import { recursivelyGetMeasureStringToPos } from "features/Components/Functionality_Bottom/onStatusUpdate/useRecalcHiddenDistMeasures";
import {
  getPartOfExternConnectedBearing,
  getPartsOfInternConnectedBearing,
} from "features/Components/Functionality_Bottom/onStatusUpdate/util/getPartsOnBearing";
import { bearingTypes } from "features/GlobalData/bearingTypes";
import { Point } from "features/LinearAlgebra/linearAlgebraModel";
import { useState, createContext, useContext, useRef } from "react";
import { DrawenContext, DrawenContextType } from "./DrawenContext";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "./MeasureAngleContext";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "./MeasureDistanceContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "./PendingLoadContext";
import { BearingType } from "./types/ConstructionElementModel";
import { DrawenObject, ObjectIndex } from "./types/DrawenObjectModel";
import {
  BalanceEquation,
  FreischnittForce,
  FreischnittLoads,
  FreischnittMoment,
  PartBalance,
  PartBalances,
} from "./types/FreischnittModel";
import { LoadIndex } from "./types/LoadModel";
import { number, evaluate } from "mathjs";
import {
  lSubEnd,
  lSubStart,
} from "features/Components/LatexUtils/latexParsingHelp";

export const FreischnittContext = createContext<FreischnittContextType | {}>(
  {}
);
export type FreischnittContextType = {
  freischnittLoads: FreischnittLoads;
  createFreischnittLoadsFromBearing: (
    bearingIndex: ObjectIndex,
    isFake?: boolean
  ) => void;
  createFreischnittLoadFromExternalMoment: (index: LoadIndex) => void;
  createFreischnittLoadFromExternalForce: (index: LoadIndex) => void;
  createFreischnittLoadFromExternalStreckenlast: (index: LoadIndex) => void;
  createFreischnittLoadFromExternalLoad: (index: LoadIndex) => void;
  createFreischnittLoadsForAllLoadObjects: () => void;
  balances: PartBalances | null;
  createBalanceForPart: () => PartBalance | undefined;
  createBalancesForAllParts: () => Array<PartBalance> | null;
  bearingsAreHidden: boolean;
  setBearingsAreHidden: React.Dispatch<React.SetStateAction<boolean>>;
  removeAllFreischnittLoads: () => void;
};
export function FreischnittProvider(props: any) {
  const { drawenObjects, getAllParts } = useContext(
    DrawenContext
  ) as DrawenContextType;
  const { measurements } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const {
    getAngleStringOnPart,
    getAngleStringsFromInternallyConnectedBearingToPart,
  } = useContext(MeasureAngleContext) as MeasureAngleContextType;
  const { loadObjects } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;

  const [freischnittLoads, setFreischnittLoads] = useState<FreischnittLoads>(
    {}
  );
  const [balances, setBalances] = useState<PartBalances | null>(null);
  const [bearingsAreHidden, setBearingsAreHidden] = useState(false);
  const freischnittLoadIndex = useRef<MeasureIndex>(0);
  const [decimalPlaces, setDecimalPlaces] = useState(3);
  const cosinus = (rad: string, decimalPlaces: number = 3): number => {
    const radianNumber = number(evaluate(rad));
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(Math.cos(radianNumber) * factor) / factor;
  };
  const sinus = (rad: string, decimalPlaces: number = 3): number => {
    const radianNumber = number(evaluate(rad));
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(Math.sin(radianNumber) * factor) / factor;
  };
  const removeAllFreischnittLoads = (): void => {
    setFreischnittLoads((prev) => {
      return {};
    });
  };
  const createFreischnittLoadsForAllLoadObjects = (): void => {
    if (!loadObjects) {
      return;
    }
    Object.values(loadObjects)?.forEach((loadObj) => {
      createFreischnittLoadFromExternalLoad(loadObj.index);
    });
  };
  /**
   * this functions returns and stores in the context the balances for all parts
   * @returns the created balances
   */
  const createBalancesForAllParts = (): Array<PartBalance> | null => {
    const parts = getAllParts();
    if (!parts) {
      return null;
    }
    let arr: Array<PartBalance> = [];
    parts.forEach((part) => {
      const balance = createBalanceForPart(part.index);
      // console.log("balacne", balance);
      if (balance) {
        arr = [...arr, { ...balance }];
      }
    });
    return arr;
  };
  const createBalanceForPart = (
    index: ObjectIndex
  ): PartBalance | undefined => {
    if (!drawenObjects || !drawenObjects[index] || !freischnittLoads) {
      return;
    }
    const loadsOnPart = Object.values(freischnittLoads).filter(
      (freischnittLoad) => {
        return freischnittLoad.partIndex === index;
      }
    );
    if (!loadsOnPart || loadsOnPart.length === 0) {
      console.warn("A part without any loads is not expected!");
      return;
    }
    let momentEquation: BalanceEquation = "0=0";
    let forceHorizontalEquation: BalanceEquation = "0=0";
    let forceVerticalEquation: BalanceEquation = "0=0";
    let unknownReactionLoads: Set<string> = new Set();
    loadsOnPart.forEach((load) => {
      //if is reactionForce add to unknowns:
      if (load.isReactionLoad) {
        unknownReactionLoads.add(load.magnitude);
      }
      if (load.type === "moment") {
        //respect orientation
        momentEquation =
          momentEquation +
          "+(" +
          load.orientation +
          ")*(" +
          load.magnitude +
          ")";
      } else if (load.type === "force") {
        //projezierte kraft * hebelarm
        momentEquation =
          momentEquation +
          "+" +
          sinus(load.angle, decimalPlaces) +
          "*(" +
          load.magnitude +
          ")*(" +
          load.lengthToPartStart +
          ")";
        forceVerticalEquation =
          forceVerticalEquation +
          "+" +
          sinus(load.angle, decimalPlaces) +
          "*(" +
          load.magnitude +
          ")";
        forceHorizontalEquation =
          forceHorizontalEquation +
          "+" +
          cosinus(load.angle, decimalPlaces) +
          "*(" +
          load.magnitude +
          ")";
      }
    });
    // //this removes empty subscripts from the balance equation
    // momentEquation.replace(lSubStart + lSubEnd, "");
    // forceVerticalEquation.replace(lSubStart + lSubEnd, "");
    // forceHorizontalEquation.replace(lSubStart + lSubEnd, "");
    const newBalance: PartBalance = {
      unknownReactionLoads: new Set(unknownReactionLoads),
      partIndex: index,
      moment: momentEquation,
      forceHorizontal: forceHorizontalEquation,
      forceVertical: forceVerticalEquation,
    };
    setBalances((prev) => {
      return {
        ...prev,
        [index]: {
          ...newBalance,
        },
      };
    });
    return newBalance;
  };
  const createFreischnittLoadFromExternalLoad = (index: LoadIndex): void => {
    if (!loadObjects || !loadObjects[index] || !drawenObjects) {
      return;
    }
    const load = loadObjects[index];
    if (load.loadType === "moment") {
      createFreischnittLoadFromExternalMoment(index);
    } else if (load.loadType === "streckenlast") {
      createFreischnittLoadFromExternalStreckenlast(index);
    } else if (load.loadType === "force") {
      createFreischnittLoadFromExternalForce(index);
    }
  };
  const createFreischnittLoadFromExternalMoment = (index: LoadIndex): void => {
    if (!loadObjects || !loadObjects[index] || !drawenObjects) {
      return;
    }
    const load = loadObjects[index];
    if (load.loadType !== "moment") {
      return;
    }
    const magnitude = load.isSymbolic
      ? load.preFactor + "*M" + lSubStart + load.name + lSubEnd //symbolic load
      : load.loadSpec.unitFactor + //numeric load
        "*" +
        load.magnitude +
        " " +
        "*N*m";
    if (!drawenObjects[load.parentIndex]) {
      return;
    }
    const parentPart = drawenObjects[load.parentIndex];
    if (!parentPart || !measurements) {
      return;
    }
    const parentPartPos: Point = [parentPart.x, parentPart.y];
    const loadPos: Point = [load.x, load.y];
    let lengthMeasureContainerParentPart: Array<string> = [];
    recursivelyGetMeasureStringToPos(
      // this function fills the lengthMeasureContainer with a value
      parentPartPos,
      loadPos,
      measurements,
      -1,
      "",
      lengthMeasureContainerParentPart,
      false
    );
    const newMoment: FreischnittMoment = {
      type: "moment",
      isReactionLoad: false,
      bearingIndex: -1,
      magnitude: magnitude,
      orientation: load.loadSpec.orientation,
      partIndex: load.parentIndex,
      lengthToPartStart: lengthMeasureContainerParentPart[0],
      isFake: false,
    };
    setFreischnittLoads((prev) => {
      freischnittLoadIndex.current = freischnittLoadIndex.current + 1;
      return {
        ...prev,
        [freischnittLoadIndex.current]: {
          ...newMoment,
        },
      };
    });
  };
  const createFreischnittLoadFromExternalForce = (index: LoadIndex): void => {
    if (!loadObjects || !loadObjects[index] || !drawenObjects) {
      return;
    }
    const load = loadObjects[index];
    if (load.loadType !== "force") {
      return;
    }
    const magnitude = load.isSymbolic
      ? load.preFactor + "*F" + lSubStart + load.name + lSubEnd //symbolic load
      : load.loadSpec.unitFactor + //numeric load
        "*" +
        load.magnitude +
        " " +
        load.loadSpec.unitSymbol;
    if (!drawenObjects[load.parentIndex]) {
      return;
    }
    const parentPart = drawenObjects[load.parentIndex];
    if (!parentPart || !measurements) {
      return;
    }
    const parentPartPos: Point = [parentPart.x, parentPart.y];
    const loadPos: Point = [load.x, load.y];
    let lengthMeasureContainerParentPart: Array<string> = [];
    recursivelyGetMeasureStringToPos(
      // this function fills the lengthMeasureContainer with a value
      parentPartPos,
      loadPos,
      measurements,
      -1,
      "",
      lengthMeasureContainerParentPart,
      false
    );
    const angle = getAngleStringOnPart(
      "force",
      index,
      drawenObjects,
      loadObjects
    );
    if (!angle) {
      return;
    }
    const newForce: FreischnittForce = {
      type: "force",
      isReactionLoad: false,
      bearingIndex: -1,
      magnitude: magnitude,
      partIndex: load.parentIndex,
      lengthToPartStart: lengthMeasureContainerParentPart[0],
      angle: angle + "-pi", //subtract pi to make it into the right coordinate system
      isFake: false,
    };
    setFreischnittLoads((prev) => {
      freischnittLoadIndex.current = freischnittLoadIndex.current + 1;
      return {
        ...prev,
        [freischnittLoadIndex.current]: {
          ...newForce,
        },
      };
    });
  };
  /**
   * This function creates a resulting force for the Streckenlast and adds it to the freischnitts context
   * @param index
   */
  const createFreischnittLoadFromExternalStreckenlast = (
    index: LoadIndex
  ): void => {
    if (!loadObjects || !loadObjects[index] || !drawenObjects) {
      return;
    }
    const load = loadObjects[index];
    if (load.loadType !== "streckenlast") {
      return;
    }
    //we assume the streckenlast the following:
    //                   __----q1
    //      qo__-----++/        |
    //       |                  |
    // +--a--+----------l-------+
    const loadSpec = load.loadSpec;
    const q0Symbolic =
      loadSpec.startPreFactor +
      "*q" +
      lSubStart +
      loadSpec.startSuffix +
      lSubEnd;
    const q0Numeric =
      Math.sign(loadSpec.startHeight) * loadSpec.startUnitFactor +
      "*" +
      loadSpec.startMagnitude +
      "*" +
      loadSpec.startUnitSymbol;

    const q0Sign = String(Math.sign(load.loadSpec.startHeight));

    const q0 =
      "(" +
      q0Sign +
      ")*(" +
      (loadSpec.startIsSymbolic ? q0Symbolic : q0Numeric) +
      ")";

    const q1Symbolic =
      loadSpec.endPreFactor + "*q" + lSubStart + loadSpec.endSuffix + lSubEnd;
    const q1Numeric =
      Math.sign(loadSpec.endHeight) * loadSpec.endUnitFactor +
      "*" +
      loadSpec.endMagnitude +
      "*" +
      loadSpec.endUnitSymbol;
    const q1Sign = String(Math.sign(load.loadSpec.endHeight));

    const q1 =
      "(" +
      q1Sign +
      ")*(" +
      (loadSpec.startIsSymbolic ? q1Symbolic : q1Numeric) +
      ")";

    if (!drawenObjects[load.parentIndex]) {
      return;
    }
    const parentPart = drawenObjects[load.parentIndex];
    if (!parentPart || !measurements) {
      return;
    }
    const parentPartPos: Point = [parentPart.x, parentPart.y];
    const loadStartPos: Point = loadSpec.startPos;
    const loadEndPos: Point = loadSpec.endPos;
    let lengthMeasureContainerParentPartToStartPos: Array<string> = [];
    recursivelyGetMeasureStringToPos(
      // this function fills the lengthMeasureContainer with a value
      parentPartPos,
      loadStartPos,
      measurements,
      -1,
      "",
      lengthMeasureContainerParentPartToStartPos,
      false
    );
    let lengthMeasureContainerStartPosToEndPos: Array<string> = [];
    recursivelyGetMeasureStringToPos(
      // this function fills the lengthMeasureContainer with a value
      loadStartPos,
      loadEndPos,
      measurements,
      -1,
      "",
      lengthMeasureContainerStartPosToEndPos,
      false
    );
    const l = lengthMeasureContainerStartPosToEndPos[0];
    const a = lengthMeasureContainerParentPartToStartPos[0];
    const resultingMagnitude = "(" + q0 + "+" + q1 + ")/2*(" + l + ")";
    //angriffspnkt trapez: https://www.ingenieurkurse.de/technische-mechanik-statik/schwerpunkte/uebersicht-flaechen-mit-schwerpunktlage-und-flaecheninhalt.html
    const resultingAngriffspunkt =
      "(" + l + ")/3*(" + q0 + "+2*" + q1 + ")/(" + q0 + "+" + q1 + ")";

    const newForce: FreischnittForce = {
      type: "force",
      isReactionLoad: false,
      bearingIndex: -1,
      magnitude: resultingMagnitude,
      partIndex: load.parentIndex,
      lengthToPartStart: "" + a + "+" + resultingAngriffspunkt,
      angle: "-pi/2",
      isFake: false,
    };
    setFreischnittLoads((prev) => {
      freischnittLoadIndex.current = freischnittLoadIndex.current + 1;
      return {
        ...prev,
        [freischnittLoadIndex.current]: {
          ...newForce,
        },
      };
    });
  };
  const createFreischnittLoadsFromBearing = (
    bearingIndex: ObjectIndex,
    isFake: boolean = false
  ): void => {
    if (!drawenObjects || !drawenObjects[bearingIndex]) {
      return;
    }

    const drawenObj = drawenObjects[bearingIndex] as DrawenObject;
    if (drawenObj.mechanicalType !== "bearing") {
      return;
    }

    if (!loadObjects) {
      console.warn("There are no loads mounted");
      return;
    }
    if (!measurements) {
      return;
    }

    const bearing = drawenObj;
    const bearingPos: Point = [bearing.x, bearing.y];
    const bearingType: BearingType = bearing.type as BearingType;
    const needsHorizontalForce =
      bearingTypes[bearingType].reactionForceHorizontal;
    const needsVerticalForce = bearingTypes[bearingType].reactionForceVertical;
    const needsMoment = bearingTypes[bearingType].reactionMoment;
    const isActioReactio = bearingTypes[bearingType].connectorType === "intern";
    //------------------------------------------------------------------------------------------
    //-----------------------HORIZONTAL FORCE---------------------------------------------------
    //------------------------------------------------------------------------------------------
    if (needsHorizontalForce) {
      //add a force type
      const magnitude =
        "F" + lSubStart + bearing.bearingPositionName + "H" + lSubEnd;
      //angle must be the relative angle to the parent part
      //get measurement that describes the bearing
      if (isActioReactio) {
        const partIndexObj = getPartsOfInternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndexObj === null) {
          return;
        }

        const parentPart = drawenObjects[partIndexObj.parentPartIndex];
        const parentPartPos: Point = [parentPart.x, parentPart.y];
        let lengthMeasureContainerParentPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          parentPartPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerParentPart,
          false
        );

        if (!lengthMeasureContainerParentPart[0]) {
          return;
        }

        const angleObj = getAngleStringsFromInternallyConnectedBearingToPart(
          bearing.index,
          drawenObjects
        ); // this must be read from the measurements
        if (!angleObj) {
          return;
        }

        const newForceActio: FreischnittForce = {
          type: "force",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          angle: angleObj.parentAngle + "",
          partIndex: partIndexObj?.parentPartIndex,
          lengthToPartStart: lengthMeasureContainerParentPart[0],
          isFake: false,
        };

        const childPart = drawenObjects[partIndexObj.childPartIndex];
        const childPartPos: Point = [childPart.x, childPart.y];
        let lengthMeasureContainerChildPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          childPartPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerChildPart,
          false
        );
        if (!lengthMeasureContainerChildPart[0]) {
          return;
        }

        const newForceReactio: FreischnittForce = {
          type: "force",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          angle: angleObj.childAngle + "+pi", //add pi to make it a neagtive schnittufer force
          partIndex: partIndexObj?.childPartIndex,
          lengthToPartStart: lengthMeasureContainerChildPart[0],
          isFake: isFake,
        };
        setFreischnittLoads((prev) => {
          freischnittLoadIndex.current = freischnittLoadIndex.current + 2; //increment index by one force
          return {
            ...prev,
            [freischnittLoadIndex.current - 1]: {
              ...newForceActio,
            },
            [freischnittLoadIndex.current]: {
              ...newForceReactio,
            },
          };
        });
      } else {
        //only one force counts --> actio
        const partIndex = getPartOfExternConnectedBearing(
          bearing.index,
          drawenObjects
        );

        if (partIndex === null) {
          return;
        }

        const part = drawenObjects[partIndex];
        const partPos: Point = [part.x, part.y];
        let lengthMeasureContainerPart: Array<string> = [];

        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          partPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerPart,
          false
        );

        if (!lengthMeasureContainerPart[0]) {
          return;
        }

        const angleString = getAngleStringOnPart(
          "bearing",
          bearing.index,
          drawenObjects,
          loadObjects
        ); // this must be read from the measurements
        if (!angleString) {
          return;
        }
        const newForceActio: FreischnittForce = {
          type: "force",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          angle: angleString + "",
          partIndex: partIndex,
          lengthToPartStart: lengthMeasureContainerPart[0],
          isFake: isFake,
        };
        setFreischnittLoads((prev) => {
          freischnittLoadIndex.current = freischnittLoadIndex.current + 1; //increment index by one force
          return {
            ...prev,
            [freischnittLoadIndex.current]: {
              ...newForceActio,
            },
          };
        });
      }
    }
    //------------------------------------------------------------------------------------------
    //-----------------------VERTICAL FORCE-----------------------------------------------------
    //------------------------------------------------------------------------------------------
    if (needsVerticalForce) {
      //add a force type
      const magnitude =
        "F" + lSubStart + bearing.bearingPositionName + "V" + lSubEnd;
      //angle must be the relative angle to the parent part
      //get measurement that describes the bearing
      if (isActioReactio) {
        const partIndexObj = getPartsOfInternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndexObj === null) {
          return;
        }

        const parentPart = drawenObjects[partIndexObj.parentPartIndex];
        const parentPartPos: Point = [parentPart.x, parentPart.y];
        let lengthMeasureContainerParentPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          parentPartPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerParentPart,
          false
        );
        if (!lengthMeasureContainerParentPart[0]) {
          return;
        }

        const angleObj = getAngleStringsFromInternallyConnectedBearingToPart(
          bearing.index,
          drawenObjects
        ); // this must be read from the measurements
        if (!angleObj) {
          return;
        }
        const newForceActio: FreischnittForce = {
          type: "force",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          angle: angleObj.parentAngle + "+pi/2", // add pi/2 to make it vertical force
          partIndex: partIndexObj?.parentPartIndex,
          lengthToPartStart: lengthMeasureContainerParentPart[0],
          isFake: isFake,
        };

        const childPart = drawenObjects[partIndexObj.childPartIndex];
        const childPartPos: Point = [childPart.x, childPart.y];
        let lengthMeasureContainerChildPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          childPartPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerChildPart,
          false
        );
        if (!lengthMeasureContainerChildPart[0]) {
          return;
        }
        const newForceReactio: FreischnittForce = {
          type: "force",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          angle: angleObj.childAngle + "+pi/2-pi", //addpi/2 to make it vertical,load and pi to make it a neagtive schnittufer force
          partIndex: partIndexObj?.childPartIndex,
          lengthToPartStart: lengthMeasureContainerChildPart[0],
          isFake: isFake,
        };
        setFreischnittLoads((prev) => {
          freischnittLoadIndex.current = freischnittLoadIndex.current + 2;
          return {
            ...prev,
            [freischnittLoadIndex.current - 1]: {
              ...newForceActio,
            },
            [freischnittLoadIndex.current]: {
              ...newForceReactio,
            },
          };
        });
      } else {
        //only one force counts --> actio
        const partIndex = getPartOfExternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndex === null) {
          return;
        }

        const part = drawenObjects[partIndex];
        const partPos: Point = [part.x, part.y];
        let lengthMeasureContainerPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          partPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerPart,
          false
        );

        if (!lengthMeasureContainerPart[0]) {
          return;
        }
        //this is the angle from bearing to part
        const angleString = getAngleStringOnPart(
          "bearing",
          bearing.index,
          drawenObjects,
          loadObjects
        ); // this must be read from the measurements

        if (!angleString) {
          return;
        }

        const newForceActio: FreischnittForce = {
          type: "force",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          angle: angleString + "+pi/2", //add pi/2 to make it vertical load
          partIndex: partIndex,
          lengthToPartStart: lengthMeasureContainerPart[0],
          isFake: isFake,
        };

        setFreischnittLoads((prev) => {
          freischnittLoadIndex.current = freischnittLoadIndex.current + 1;
          return {
            ...prev,
            [freischnittLoadIndex.current]: {
              ...newForceActio,
            },
          };
        });
      }
    }
    // ------------------------------------------------------------------------------------------
    // -----------------------MOMENT-------------------------------------------------------------
    // ------------------------------------------------------------------------------------------
    if (needsMoment) {
      //add a force type
      const magnitude = "M" + lSubStart + bearing.bearingPositionName + lSubEnd;
      //angle must be the relative angle to the parent part
      //get measurement that describes the bearing
      if (isActioReactio) {
        const partIndexObj = getPartsOfInternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndexObj === null) {
          return;
        }

        const parentPart = drawenObjects[partIndexObj.parentPartIndex];
        const parentPartPos: Point = [parentPart.x, parentPart.y];
        let lengthMeasureContainerParentPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          parentPartPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerParentPart,
          false
        );
        if (!lengthMeasureContainerParentPart[0]) {
          return;
        }

        const newForceActio: FreischnittMoment = {
          type: "moment",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          partIndex: partIndexObj?.parentPartIndex,
          lengthToPartStart: lengthMeasureContainerParentPart[0],
          orientation: 1,
          isFake: isFake,
        };

        const childPart = drawenObjects[partIndexObj.childPartIndex];
        const childPartPos: Point = [childPart.x, childPart.y];
        let lengthMeasureContainerChildPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          childPartPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerChildPart,
          false
        );
        if (!lengthMeasureContainerChildPart[0]) {
          return;
        }
        const newForceReactio: FreischnittMoment = {
          type: "moment",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          partIndex: partIndexObj?.childPartIndex,
          lengthToPartStart: lengthMeasureContainerChildPart[0],
          orientation: -1,
          isFake: isFake,
        };
        setFreischnittLoads((prev) => {
          freischnittLoadIndex.current = freischnittLoadIndex.current + 2;
          return {
            ...prev,
            [freischnittLoadIndex.current - 1]: {
              ...newForceActio,
            },
            [freischnittLoadIndex.current]: {
              ...newForceReactio,
            },
          };
        });
      } else {
        //only one force counts --> actio
        const partIndex = getPartOfExternConnectedBearing(
          bearing.index,
          drawenObjects
        );
        if (partIndex === null) {
          return;
        }

        const part = drawenObjects[partIndex];
        const partPos: Point = [part.x, part.y];
        let lengthMeasureContainerPart: Array<string> = [];
        recursivelyGetMeasureStringToPos(
          // this function fills the lengthMeasureContainer with a value
          partPos,
          bearingPos,
          measurements,
          -1,
          "",
          lengthMeasureContainerPart,
          false
        );
        if (!lengthMeasureContainerPart[0]) {
          return;
        }

        const newForceActio: FreischnittMoment = {
          type: "moment",
          isReactionLoad: true,
          bearingIndex: bearing.index,
          magnitude: magnitude,
          partIndex: partIndex,
          lengthToPartStart: lengthMeasureContainerPart[0],
          orientation: 1,
          isFake: isFake,
        };

        setFreischnittLoads((prev) => {
          freischnittLoadIndex.current = freischnittLoadIndex.current + 1;
          return {
            ...prev,
            [freischnittLoadIndex.current]: {
              ...newForceActio,
            },
          };
        });
      }
    }
  };
  return (
    <FreischnittContext.Provider
      value={{
        freischnittLoads,
        createFreischnittLoadsFromBearing,
        createFreischnittLoadFromExternalMoment,
        createFreischnittLoadFromExternalForce,
        createFreischnittLoadFromExternalStreckenlast,
        createFreischnittLoadFromExternalLoad,
        createFreischnittLoadsForAllLoadObjects,
        createBalanceForPart,
        balances,
        createBalancesForAllParts,
        bearingsAreHidden,
        setBearingsAreHidden,
        removeAllFreischnittLoads,
      }}
    >
      {props.children}
    </FreischnittContext.Provider>
  );
}

import {
  MouseMoveContext,
  MouseMoveContextType,
} from "features/Context/MouseMoveContext";
import { Point, Vector2 } from "features/LinearAlgebra/linearAlgebraModel";
import { scalarProd, subV } from "features/LinearAlgebra/vectorUtils";
import { useContext, useState } from "react";

export const useDragConsole = () => {
  const [consoleMargin, setConsoleMargin] = useState(0);

  const {
    lastX,
    setLastX,
    lastY,
    setLastY,
    consoleIsDraggable,
    setConsoleIsDraggable,
  } = useContext(MouseMoveContext) as MouseMoveContextType;

  const handleMouseMove = (e: React.MouseEvent) => {
    // in playground

    e.preventDefault();
    if (!consoleIsDraggable || !lastX || !lastY) {
      return;
    }
    const newX = e.pageX;
    const newY = -e.pageY;
    const newPoint: Point = [newX, newY];
    const oldPoint: Point = [lastX, lastY];

    const relativeMove = subV(newPoint, oldPoint);
    const consoleDragDir: Vector2 = [0, 1];
    const dragLengthInVerticalDir = scalarProd(relativeMove, consoleDragDir);
    setConsoleMargin((prev) => prev + dragLengthInVerticalDir);
    setLastX(e.pageX);
    setLastY(-e.pageY);
  };
  const handleDragStart = (e: React.MouseEvent) => {
    e.preventDefault();
    setConsoleIsDraggable(true);
    setLastX(e.pageX);
    setLastY(-e.pageY);
  };
  const handleDragEnd = (e: React.MouseEvent) => {
    e.preventDefault();
    const SNAP_THRESHOLD = 14;
    if (Math.abs(consoleMargin) < SNAP_THRESHOLD) {
      //this snaps the console to the original position
      setConsoleMargin(0);
    }
    setConsoleIsDraggable(false);
  };
  return { consoleMargin, handleDragEnd, handleDragStart, handleMouseMove };
};

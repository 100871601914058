import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  bold: {
    // fontWeight: "bold",
    marginTop: "10px",
    marginBottom: "5px",
    marginLeft: "-10px",
    fontSize: "30px",
  },
}));

export function SubHeader(props: any) {
  const classes = useStyles();
  return (
    <Typography variant="h3" className={classes.bold}>
      {props.children}
    </Typography>
  );
}

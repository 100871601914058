import system from "../imgs/festLos/system.png";
import frei from "../imgs/festLos/frei.png";
import balanceEq from "../imgs/festLos/balanceEq.png";
import solution from "../imgs/festLos/solution.png";
import { ImgObj, SystemExample } from "./SystemExample";
import { useTranslation } from "react-i18next";
export default function FestLosExample() {
  const { t } = useTranslation();
  const width = 300;
  const imgObjs: Array<ImgObj> = [
    { imgSrc: system, header: t("1. Mechanical system"), width: width },
    { imgSrc: frei, header: t("2. Free body diagram"), width: width },
    {
      imgSrc: balanceEq,
      header: t("3. Equations of Equilibrium"),
      width: width,
    },
    {
      imgSrc: solution,
      header: t("4. Suppport reaction solution"),
      width: width,
    },
  ];
  return <SystemExample imgObjs={imgObjs}></SystemExample>;
}

import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "features/Context/MeasureDistanceContext";
import { useContext } from "react";
import useDragMeasurement from "../../playgroundObjectHooks/useDragMeasurement";

const useMeasureArrowDragHelper = (measureIndex: MeasureIndex) => {
  const { removeAllDraggableArrows, setMeasureArrowDraggable, measurements } =
    useContext(MeasureDistanceContext) as MeasureDistanceContextType;
  const { handleDragMeasurementStart } = useDragMeasurement();

  if (!measurements || !measurements[measureIndex]) {
    return {
      handleClick: (e: React.MouseEvent) => null,
      handleMouseDown: (e: React.MouseEvent) => null,
    };
  }
  const measure = measurements[measureIndex];
  const arrowIsDraggable = measure.arrowIsDraggable;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeAllDraggableArrows();
    setMeasureArrowDraggable(true, measureIndex);
  };
  const handleMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleDragMeasurementStart(e, arrowIsDraggable);
  };
  return { handleClick, handleMouseDown };
};

export { useMeasureArrowDragHelper };

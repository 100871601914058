import {
  deg2Rad,
  rad2Deg,
} from "features/Components/BearingEditor/util/angleConversion";
import { getLineDir } from "./getLineDir";
import type { Point, Line, Vector2 } from "./linearAlgebraModel";
import { AnglePoints } from "features/Context/types/AngleMeasurementModel";

export function addV(vector1: Vector2, vector2: Vector2): Vector2 {
  return [vector1[0] + vector2[0], vector1[1] + vector2[1]];
}
/**
 * subtracts res=v1-v2
 * @param {*} vector1
 * @param {*} vector2
 * @returns
 */
export function subV(vector1: Vector2, vector2: Vector2) {
  //subtracts v2 rom v1 --> v1-v2
  return addV(vector1, sM(-1, vector2));
}

export function scalarProd(v1: Vector2, v2: Vector2): number {
  return v1[0] * v2[0] + v1[1] * v2[1];
}

export function scalarMultiply(scalar: number, v: Vector2): Vector2 {
  return [v[0] * scalar, v[1] * scalar];
}
export function sM(scalar: number, v: Vector2): Vector2 {
  return scalarMultiply(scalar, v);
}

export function norm(v: Vector2): number {
  return Math.sqrt(v[0] * v[0] + v[1] * v[1]);
}
export function normalize(v: Vector2): Vector2 {
  const normer = norm(v);
  return [v[0] / normer, v[1] / normer];
}
export function distance(v1: Vector2, v2: Vector2): number {
  return Math.sqrt(
    (v1[0] - v2[0]) * (v1[0] - v2[0]) + (v1[1] - v2[1]) * (v1[1] - v2[1])
  );
}
export function areClose(v1: Vector2, v2: Vector2, threshold = 0.01): boolean {
  return distance(v1, v2) < threshold ? true : false;
}
export function rotateByDeg(v: Vector2, angle: number): Vector2 {
  const angleRad = deg2Rad(angle);
  const newX = Math.cos(angleRad) * v[0] - Math.sin(angleRad) * v[1];
  const newY = Math.sin(angleRad) * v[0] + Math.cos(angleRad) * v[1];
  return [newX, newY];
}
export function getAngleInDeg(v: Vector2): number {
  const angleRad = Math.atan2(v[1], v[0]);
  return rad2Deg(angleRad);
}

export function middlePoint(p1: Point, p2: Point): Vector2 {
  const diff = subV(p2, p1);
  const middle = addV(p1, sM(0.5, diff));
  return middle;
}
export function centerOfPoints(pointArray: Array<Point>): Point {
  let centerX = 0;
  let centerY = 0;
  let N = pointArray.length;
  pointArray.forEach((point) => {
    centerX = centerX + point[0];
    centerY = centerY + point[1];
  });
  return [centerX / N, centerY / N];
}
export function areCloseScalar(
  a: number,
  b: number,
  threshold = 0.001
): boolean {
  if (Math.abs(a - b) < threshold) {
    return true;
  } else {
    return false;
  }
}
export function reverseLine(line: Line): Line {
  return [line[1], line[0]];
}
export function areParallelLines(line1: Line, line2: Line): boolean {
  return areParallelVectors(getLineDir(line1), getLineDir(line2));
}
export function areParallelVectors(v1: Vector2, v2: Vector2) {
  if (areCloseScalar(Math.abs(scalarProd(normalize(v1), normalize(v2))), 1)) {
    return true;
  } else {
    return false;
  }
}
/**
 *
 * @param v1
 * @param v2
 * @returns the unsigned angle between the vectors
 */
export function getAngleDegBetweenVectors(v1: Vector2, v2: Vector2): number {
  return (
    (Math.acos(scalarProd(normalize(v1), normalize(v2))) * 360) / 2 / Math.PI
  );
}
/**
 * This returns the mathematical angle. (right hand rule with x to the right and y to the top) Then the angle returned is the counterclockwise angle from the x axis to the vector
 * @param v1
 * @returns angle in degree
 */
export function getSignedAngleToXAxis(v1: Vector2): number {
  const xAxis: Vector2 = [1, 0];

  const quadrant = getQuadrantOfVector(v1);
  const spitzerWinkel = Math.acos(scalarProd(normalize(v1), normalize(xAxis)));
  let angleInRad;
  if (quadrant === 1) {
    angleInRad = spitzerWinkel;
  } else if (quadrant === 2) {
    angleInRad = spitzerWinkel;
  } else if (quadrant === 3) {
    angleInRad = 2 * Math.PI - spitzerWinkel;
  } else {
    angleInRad = 2 * Math.PI - spitzerWinkel;
  }
  return rad2Deg(angleInRad);
}
export const getQuadrantOfVector = (v1: Vector2) => {
  const x = v1[0];
  const y = v1[1];
  if (x >= 0 && y >= 0) {
    return 1;
  }
  if (x < 0 && y >= 0) {
    return 2;
  }
  if (x <= 0 && y < 0) {
    return 3;
  }
  if (x > 0 && y < 0) {
    return 4;
  }
};
/**
 *
 * @param v1
 * @returns angle in degree between 0 and 360
 */
export function getSignedAngleToXAxisBetween0And360(v1: Vector2): number {
  let angle = getSignedAngleToXAxis(v1);
  while (angle < 0) {
    angle = angle + 360;
  }
  return angle;
}
export const getSignedAngleOfAnglePoints = (
  anglePoints: AnglePoints
): number | null => {
  if (!anglePoints) {
    return null;
  }
  const startVector = subV(anglePoints.startPoint, anglePoints.pivotPoint);
  const endVector = subV(anglePoints.endPoint, anglePoints.pivotPoint);
  const angleStartToX = getSignedAngleToXAxisBetween0And360(startVector);
  const angleEndToX = getSignedAngleToXAxisBetween0And360(endVector);
  const angle = angleEndToX - angleStartToX;
  return angle;
};
export function getDirFromAngleDeg(angle: number): Vector2 {
  return normalize([
    Math.cos((angle / 360) * 2 * Math.PI),
    Math.sin((angle / 360) * 2 * Math.PI),
  ]);
}

//vector = [a,b]
//line= [[x1,y1] , [x2,y2]]

import MechanicalIcon from "features/Components/Functionality_Right/SystemMode/SelectBar/Selectables/MechanicalIcon";
import { MoreInfoBtn } from "./MoreInfoBtn";
import { ObjectIndex } from "features/Context/types/DrawenObjectModel";
import { DrawenObjectType } from "features/Context/types/ConstructionElementModel";
import { makeStyles } from "@material-ui/core";
import { primaryColor } from "features/Components/StyleOnlyComponents/Color";
import { PendingTag } from "features/Components/StyleOnlyComponents/PendingTag";
const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alignItems: {
    display: "flex",
    alignItems: "center",
  },
  borderBold: {
    // borderBottom: "solid 4px black",
  },
  paddingX: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  rotate: {
    transform: "rotate(90deg)",
  },
});
export default function TreeElementVisual({
  index,
  type,
  text,
  isHighlighted = false,
  isPending = false,
}: {
  index: ObjectIndex;
  type: DrawenObjectType;
  text: string;
  isHighlighted?: boolean;
  isPending?: boolean;
}) {
  const classes = useStyles();
  const color = isHighlighted ? primaryColor : "black";
  return (
    <div className={classes.flex + " " + classes.borderBold}>
      {/* <ElementIndexAvatar index={index} /> */}
      <div className={classes.paddingX + " " + classes.alignItems}>
        <MechanicalIcon color={color} type={type} />
        {text}
      </div>
      {isPending && <PendingTag></PendingTag>}
      <MoreInfoBtn elementIndex={index} />
    </div>
  );
}

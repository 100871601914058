/**
 * This function returns a factor for the latex height depending of the contents ogf the latex expression.
 * If there is a fraction for example the height needs to be increased.
 * @param latex
 */
export const getLatexHeightFactor = (latex: string) => {
  const hasBracket = latex.includes("right") || latex.includes(")");
  const hasFrac = latex.includes("frac");
  let heightFractor = 1;
  if (hasBracket && hasFrac) {
    heightFractor = 3;
  } else if (hasBracket) {
    heightFractor = 1.21;
  } else if (hasFrac) {
    heightFractor = 2.85;
  }

  return heightFractor;
};

import { Line } from "features/LinearAlgebra/linearAlgebraModel";
import { deg2Rad } from "../../util/angleConversion";

export function getLineFromPart(part1: {
  x: number;
  y: number;
  angle: number;
  size: { width: number };
}) {
  const {
    x: xStart1,
    y: yStart1,
    angle: angle1,
    size: { width: length1 },
  } = part1;

  const xEnd1 = xStart1 + Math.cos(deg2Rad(angle1)) * length1;
  const yEnd1 = yStart1 + Math.sin(deg2Rad(angle1)) * length1;

  const line1: Line = [
    [xStart1, yStart1],
    [xEnd1, yEnd1],
  ];

  return line1;
}

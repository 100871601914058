import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";
import { GeometricalPart } from "features/Components/BearingEditor/Bearings/util/PartType";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { Line, Point } from "features/LinearAlgebra/linearAlgebraModel";
import { useContext } from "react";
import { checkIfAngleMeasureShouldBeHidden } from "./util/checkIfAngleMeasureShouldBeHidden";
import { getForcesFromPart } from "./util/getAllLoadsFromPart";
import { getAllParts } from "./util/getAllParts";
import { getAnglePointsFromLinePivotAndAngle } from "./util/getAnglePoints";
/**
 *
 * @returns function that creates all angle measurements for the current drawenObjects and Loads(Forces)
 */
export function useChangeToAngleMeasurements(
  isFake: boolean = false
): Function {
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const { loadObjects } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { createNewAngleMeasurement } = useContext(
    MeasureAngleContext
  ) as MeasureAngleContextType;

  const handleSwitchToAngleMeasurementStatus = () => {
    //handle root to first bearing:
    const root = drawenObjects["root"];
    if (root && root.children.length >= 1) {
      const childIndex = root.children[0] as number;
      const firstBearing = drawenObjects[childIndex];
      const angleRootBearing = firstBearing.angle;
      const rootLine: Line = [
        [0, 0],
        [200, 0],
      ];
      const anglePointsRoot = getAnglePointsFromLinePivotAndAngle(
        rootLine,
        [firstBearing.x, firstBearing.y],
        angleRootBearing
      );
      createNewAngleMeasurement({
        anglePoints: anglePointsRoot,
        fromType: "root",
        toType: "bearing",
        fromIndex: -1,
        toIndex: firstBearing.index,
        isHidden: true,
        isFake: isFake,
      });
    }

    //measurements for all parts
    const parts = getAllParts(drawenObjects);
    parts.forEach((part) => {
      const parent = drawenObjects[part.parentIndex];
      if (parent?.index === "root" || !parent) {
        //should not happen because parts dont have root as parent, and should always have parents
        return;
      }
      // ----create measure to Parent-----
      const line = getLineFromPart(part as GeometricalPart);
      const bearingPoint: Point = [parent.x, parent.y];
      // get numeric angle by goig recursively through the angleReference until one finds root.
      // always reference against root
      const relAngle = parent.angle - part.angle;
      const anglePoints = getAnglePointsFromLinePivotAndAngle(
        line,
        bearingPoint,
        relAngle
      );
      const isHidden = checkIfAngleMeasureShouldBeHidden(relAngle);
      createNewAngleMeasurement({
        anglePoints: anglePoints,
        fromType: "part",
        toType: "bearing",
        fromIndex: part.index,
        toIndex: parent.index,
        isHidden: isHidden,
        isFake: isFake,
      });
      // ----create measure to Children bearings-----
      const childIndizes = part.children;
      childIndizes?.forEach((childIndex) => {
        const childBearing = drawenObjects[childIndex];
        const bearingPoint: Point = [childBearing.x, childBearing.y];
        const relAngle = childBearing.angle - part.angle;
        const anglePointsChild = getAnglePointsFromLinePivotAndAngle(
          line,
          bearingPoint,
          relAngle
        );
        const isHidden = checkIfAngleMeasureShouldBeHidden(relAngle);
        createNewAngleMeasurement({
          anglePoints: anglePointsChild,
          fromType: "part",
          toType: "bearing",
          fromIndex: part.index,
          toIndex: childIndex,
          isHidden: isHidden,
          isFake: isFake,
        });
      });
      // --- handle forces on part ---
      if (!loadObjects) {
        return;
      }
      const forceObjs = getForcesFromPart(loadObjects, part);
      forceObjs.forEach((forceObj) => {
        const relAngle = forceObj.angle - part.angle;
        const line = getLineFromPart(part as GeometricalPart);
        const pivotPoint = forceObj.pos;
        const anglePoints = getAnglePointsFromLinePivotAndAngle(
          line,
          pivotPoint,
          relAngle
        );
        const isHidden = checkIfAngleMeasureShouldBeHidden(relAngle);
        createNewAngleMeasurement({
          anglePoints: anglePoints,
          fromType: "part",
          toType: "force",
          fromIndex: part.index,
          toIndex: forceObj.index,
          isHidden: isHidden,
          isFake: isFake,
        });
      });
    });
  };
  return handleSwitchToAngleMeasurementStatus;
}

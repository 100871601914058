import { useContext } from "react";

import {
  PendingContext,
  PendingContextType,
} from "../../../../Context/PendingContext";

import { HeightWidthControl } from "./sizeControlTypes/HeightWidthControl";
import { WidthControl } from "./sizeControlTypes/WidthControl";
import { RadiusControl } from "./sizeControlTypes/RadiusControl";
import { RadiusX2Control } from "./sizeControlTypes/RadiusX2Control";

export function GeometryControl() {
  const { pendingObject } = useContext(PendingContext) as PendingContextType;
  if (!pendingObject) {
    return null;
  }
  const fittingControlType = () => {
    if (pendingObject.controlType === "HeightWidth") {
      return <HeightWidthControl />;
    } else if (pendingObject.controlType === "Width") {
      return <WidthControl />;
    } else if (pendingObject.controlType === "Radius") {
      return <RadiusControl />;
    } else if (pendingObject.controlType === "RadiusX2") {
      return <RadiusX2Control />;
    }
  };

  return <>{fittingControlType()}</>;
}

import systemFestLos from "../imgs/festLos/system.png";
import systemEinspannung from "../imgs/einspannung/system.png";
import systemGelenk from "../imgs/gelenk/system.png";
import { ImgObj, SystemExample } from "./SystemExample";
import { useTranslation } from "react-i18next";

export default function DifferentSystems() {
  const { t } = useTranslation();
  const width = 250;
  const imgObjs: Array<ImgObj> = [
    {
      imgSrc: systemFestLos,
      header: t("Roller and Pinned Support"),
      width: width,
    },
    {
      imgSrc: systemEinspannung,
      header: t("System with fixed support"),
      width: width,
    },
    {
      imgSrc: systemGelenk,
      header: t("Complex system with hinge"),
      width: width,
    },
  ];
  return <SystemExample imgObjs={imgObjs}></SystemExample>;
}

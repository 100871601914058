/**
 *
 * @param {string} attributeString attribute that should be changed
 * @param {*} val value that should be set for all child atrributes
 * @param {*} node the element note of the parent
 */
export function recursiveAttribute(attributeString, val, node) {
  node?.childNodes.forEach((child) => {
    recursiveAttribute(child);
    if (child.hasAttribute(attributeString)) {
      child.setAttribute(attributeString, val);
    }
  });
}

import { PendingLoadContext } from "features/Context/PendingLoadContext";
import React, { useContext, useState } from "react";
import SymbolAndMagnitude from "../util/SymbolAndMagnitude";
import { useUnits } from "../util/useUnits";

export default function Q0ControlLeft() {
  const { pendingLoad, setPendingLoadSpec } = useContext(PendingLoadContext);
  const preFactor = pendingLoad.loadSpec.startPreFactor;
  const name = pendingLoad.loadSpec.startSuffix;
  const unit = pendingLoad.loadSpec.unitSymbol;
  const unitFactor = pendingLoad.loadSpec.startUnitFactor;
  const magnitude = pendingLoad.loadSpec.startMagnitude;
  const isSymbolic = pendingLoad.loadSpec.startIsSymbolic;

  const setPendingSymbolic = (isSymbolic) => {
    const newLoadSpecObject = {
      startIsSymbolic: isSymbolic,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const handleNameChange = (newString) => {
    const newLoadSpecObject = {
      startSuffix: newString,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const handlePreFactorChange = (newString) => {
    const newLoadSpecObject = {
      startPreFactor: newString,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };

  const handleMagnitudeChange = (number) => {
    // console.log("newMag", number);
    const newLoadSpecObject = {
      startMagnitude: number,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const handleUnitChange = (unit) => {
    const newLoadSpecObject = {
      startUnitSymbol: unit,
      startUnitFactor: unitFactorConverter(unit),
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const symbolName = "q";
  const [unitOption, unitFactorConverter] = useUnits(pendingLoad.loadType);
  return (
    <SymbolAndMagnitude
      {...{
        symbolName,
        preFactor,
        unit,
        magnitude,
        isSymbolic,
        unitOption,
      }}
      padding={5}
      handleNameChange={handleNameChange}
      handlePreFactorChange={handlePreFactorChange}
      handleMagnitudeChange={handleMagnitudeChange}
      handleUnitChange={handleUnitChange}
      setSymbolic={setPendingSymbolic}
      symbolSuffix={name}
      unitFactor={unitFactor}
      height={20}
      thin={true}
    />
  );
}

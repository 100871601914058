import {
  UnitOptionEl,
  UnitOptionObj,
} from "features/Components/Functionality_Right/LoadMode/util/unitModel";

export const useMeasurementUnits = (
  measurement: "length" | "angle"
): [UnitOptionEl | Array<UnitOptionEl>, Function] => {
  let units: Array<UnitOptionObj> = [];
  if (measurement === "length") {
    units = [
      { unitSymbol: "m", unitFactor: 1 },
      { unitSymbol: "mm", unitFactor: 1 / 1000 },
      { unitSymbol: "km", unitFactor: 1000 },
      { unitSymbol: "cm", unitFactor: 1 / 100 },
      { unitSymbol: "dm", unitFactor: 1 / 10 },
    ];
  } else if (measurement === "angle") {
    units = [
      { unitSymbol: "°", unitFactor: 1 },
      { unitSymbol: "rad", unitFactor: 57.2958 },
    ];
  }
  const unitOptions = units.map((unitObj): UnitOptionEl => {
    return (
      <option key={unitObj.unitSymbol} value={unitObj.unitSymbol}>
        {unitObj.unitSymbol}
      </option>
    );
  });
  function unitFactor(unitSymbol: string) {
    const obj = units.find((unitObj) => {
      return unitObj.unitSymbol == unitSymbol;
    });
    return obj?.unitFactor;
  }
  return [unitOptions, unitFactor];
};

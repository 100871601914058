export function inRotatedKOS(dx,dy,angle){
    const dxRot=dx*Math.cos(angle/360*2*Math.PI)+dy*Math.sin(angle/360*2*Math.PI);
    const dyRot=-dx*Math.sin(angle/360*2*Math.PI)+dy*Math.cos(angle/360*2*Math.PI);
    return{dxRot,dyRot};
}
export function toPolar(x1,y1,x2,y2){   // phi in degree
    const phi=Math.atan2(y2-y1,x2-x1)/2/Math.PI*360;
    const radius = Math.sqrt((y2-y1)*(y2-y1)+(x2-x1)*(x2-x1));
    return {phi:phi,radius:radius};
}
export function toCartesian(phi,radius){ //phi in degree
    const dx=Math.cos(phi/360*2*Math.PI)*radius; 
    const dy=Math.sin(phi/360*2*Math.PI)*radius; 
    return {dx:dx,dy:dy};
}
import { useContext } from "react";

import { Button, makeStyles } from "@material-ui/core";
import {
  cancelColor,
  cancelColorDark,
} from "features/Components/StyleOnlyComponents/Color";
import { LoadTypeSelector } from "./LoadTypeSelector";
import { SubmitWrapper } from "../SubmitPending/SubmitWrapper";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  buttonColor: {
    background: cancelColor,
    color: "white",
    "&:hover": {
      background: cancelColorDark + "!important",
    },
  },
  height: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  height90: {
    padding: "10px",
    boxSizing: "border-box",
    flexGrow: 1,
  },
  height10: {
    flexShrink: 0,
  },
});
/**
 * This component allows to select between the several bearings
 * @returns
 */
export function LoadSelector() {
  const { t } = useTranslation();
  const { deletePendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const classes = useStyles();

  return (
    <div className={classes.height}>
      <div className={classes.height90}>
        <LoadTypeSelector></LoadTypeSelector>
      </div>
      <div className={classes.height10}>
        <SubmitWrapper isPending={false}>
          <Button
            className={classes.buttonColor}
            variant={"contained"}
            onClick={deletePendingLoad}
          >
            {t("Cancel")}
          </Button>
        </SubmitWrapper>
      </div>
    </div>
  );
}

import DistanceArrow from "features/Components/Playground/Cords/DistanceArrow";

export default function HtmlDistanceArrow({
  width = 0,
  height = 100,
  strokeWidth = 2,
  color = "black",
}) {
  const margin = 8;

  return (
    <svg
      width={width + 2 * margin}
      height={height + 2 * margin}
      viewBox={`${-margin} ${-margin} ${width + 2 * margin} ${
        height + 2 * margin
      }`}
    >
      <DistanceArrow
        points={[
          [0, 0],
          [width, height],
        ]}
        arrowWidth={3}
        strokeWidth={strokeWidth}
        color={color}
      />
    </svg>
  );
}

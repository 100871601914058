import { Typography, makeStyles } from "@material-ui/core";
import { UnhappyLosLagerIcon } from "features/Components/Functionality_Right/SystemMode/SelectBar/Selectables/UnhappyLosLagerIcon";
import { Bold } from "featuresLandingPage/mainContent/Layout/Bold";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "15px",
    boxSizing: "border-box",
  },
  iconWrap: {
    width: "max-content",
    flexShrink: 0,
  },
}));
export function MobileNotSupported() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.flexOuter}>
      <div className={classes.iconWrap}>
        <UnhappyLosLagerIcon size={160}></UnhappyLosLagerIcon>
      </div>
      <Typography>
        {" "}
        {t("The calculator is not designed for small devices.")}
        <Bold> {t("Please come back with your laptop!")}</Bold>
      </Typography>
    </div>
  );
}

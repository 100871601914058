import { makeStyles } from "@material-ui/core";
import LatexForHTML from "features/Components/Playground/helper/LatexForHTML";
import { getLatexHeightFactor } from "./getLatexHeightFactor";
import { simplifyEquation } from "./simplifyEquation";
import { convertToLatex } from "features/Components/LatexUtils/convertToLatex";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "top",
  },
}));

export function LatexEquationEl({
  equation,
  height = 17,
}: {
  equation: string;
  height?: number;
}) {
  const classes = useStyles();
  //nerdamer simplify has to many bugs
  // console.log("Before mathjs simplify", equation);
  const simplifiedEq = simplifyEquation(equation);
  // console.log("latex to convert", simplifiedEq);
  const latexEq = convertToLatex(simplifiedEq);
  const heightFactor = getLatexHeightFactor(latexEq);
  return (
    <div className={classes.flex}>
      <LatexForHTML
        height={heightFactor * height}
        latex={latexEq}
      ></LatexForHTML>
    </div>
  );
}

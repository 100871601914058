import { Button, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    button: {
      width: "75px",
      height: "75px",
    },
    flex: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    size: {
      width: "35px",
      height: "35px",
    },
    container: {
      width: "max-content",
      display: "inline",
    },
  }));
export default function AddButton({
  handleClick,
  name,
  disabled = false,
  title = "",
}: {
  handleClick: Function;
  name: string;
  disabled: boolean;
  title?: string;
}) {
  const classes = useStyles({})();

  return (
    <div title={title} className={classes.container}>
      <Button
        disabled={disabled}
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={() => handleClick()}
      >
        <div className={classes.flex}>
          <AddIcon className={classes.size}></AddIcon> <div>{name}</div>
        </div>
      </Button>
    </div>
  );
}

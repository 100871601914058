import { fractionToLatex } from "./fractiontoLatex";

export function preFactorToLatex(str: string) {
  const stringWithoutLiterals = str.replace(/[^0-9.//]/g, "");
  //   console.log("stringAfter Reg", stringWithoutLiterals);
  let latex;
  let hasFrac = false;
  if (stringWithoutLiterals.indexOf("/") > 0) {
    latex = fractionToLatex(stringWithoutLiterals);
    hasFrac = true;
  } else {
    latex = stringWithoutLiterals;
    if (stringWithoutLiterals == "1") {
      //1 can be omitted
      latex = "";
    }
  }
  return [latex, hasFrac];
}

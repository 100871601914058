import { Hidden } from "@material-ui/core";
import AppLayout from "AppLayout";
import { UniversalProvider } from "features/Context/UniversalProvider";
import { MobilePage } from "featuresLandingPage/Mobile/MobilePage";
export function App() {
  return (
    <UniversalProvider>
      {/*@ts-ignore*/}
      <Hidden only={["xs", "sm"]}>
        <AppLayout></AppLayout>
      </Hidden>

      <MobilePage></MobilePage>
    </UniversalProvider>
  );
}

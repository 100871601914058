import { makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import Tex2SVG, { MathJaxProvider } from "react-hook-mathjax";
import { recursiveAttribute } from "./util/recursiveAttribute";

const useStyles = makeStyles({
  hider: {
    position: "absolute",
    visibility: "hidden",
  },
  shower: {},
});
export default function LatexSvg({
  latex = "F_{1}",
  x = 0,
  y = 0,
  height = 40,
  color = "red",
  widthReturner = (e) => {},
  hideHelper = true,
} = {}) {
  const mathJaxOptions = {
    loader: { load: ["[tex]/boldsymbol"] },
    tex: { packages: { "[+]": ["boldsymbol"] } },
    // loader: { load: ["[tex]/autoload"] },
    // tex: { packages: { "[+]": ["autoload"] } },
    svg: {
      scale: 1, // global scaling factor for all expressions
      minScale: 0.5, // smallest scaling factor to use
      mtextInheritFont: false, // true to make mtext elements use surrounding font
      merrorInheritFont: true, // true to make merror text use surrounding font
      mathmlSpacing: false, // true for MathML spacing rules, false for TeX rules
      skipAttributes: {}, // RFDa and other attributes NOT to copy to the output
      exFactor: 0.5, // default size of ex in em units
      displayAlign: "center", // default for indentalign when set to 'auto'
      displayIndent: "0", // default for indentshift when set to 'auto'
      fontCache: "local", // or 'global' or 'none'
      localID: null, // ID to use for local font cache (for single equation processing)
      internalSpeechTitles: true, // insert <title> tags with speech content
      titleID: 0, // initial id number to use for aria-labeledby titles
    },
  };
  const classes = useStyles();

  const testRef = useRef();
  const groupRef = useRef();

  const [showHelpertex, setShowHelperTex] = useState(true);
  //   const width = height;
  useEffect(() => {
    const svg = testRef.current?.querySelector("svg")?.cloneNode(true);
    const svgNew = groupRef.current?.querySelector("svg");

    const oldHeigt = parseFloat(svg?.getAttribute("height").replace("ex", ""));
    const oldWidth = parseFloat(svg?.getAttribute("width").replace("ex", ""));
    const ohOverOw = oldHeigt / oldWidth;
    svg?.setAttribute("height", height);
    svg?.setAttribute("width", height / ohOverOw);
    const children = svg?.childNodes;

    if (svg) {
      if (svgNew) {
        groupRef.current.removeChild(svgNew);
      }
      recursiveAttribute("stroke", color, svg);
      recursiveAttribute("fill", color, svg);
      groupRef.current.appendChild(svg);
      widthReturner(height / ohOverOw);
    }
  });

  return (
    <>
      <g className={hideHelper ? classes.hider : classes.shower} ref={testRef}>
        <MathJaxProvider options={mathJaxOptions}>
          <Tex2SVG
            onSuccess={() => setShowHelperTex((prev) => !prev)}
            latex={latex}
          />
        </MathJaxProvider>
      </g>

      <g
        ref={groupRef}
        transform={"translate(" + x + " " + y + ") scale(1 -1)"}
      ></g>
    </>
  );
}

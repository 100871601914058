import React, { useState, createContext } from "react";

export const HighlightContext = createContext<HighlightContextType | null>(
  null
);
export type HighlightContextType = {
  currentlyHighlighted: string | number;
  setCurrentlyHighlighted: (newIndexOfCurrHighlighted: number | string) => void;
  highlightColor: string;
  setHighlightColor: React.Dispatch<React.SetStateAction<string>>;
  lastClicked: string | number | null;
  setLastClicked: React.Dispatch<React.SetStateAction<string | number | null>>;
};
export function HighlightProvider(props: any) {
  const [currentlyHighlighted, setCurrentlyHighlighted] = useState<
    string | number
  >("pending");
  const [highlightColor, setHighlightColor] = useState("red");
  const [lastClicked, setLastClicked] = useState<string | number | null>(null);

  return (
    <HighlightContext.Provider
      value={{
        currentlyHighlighted,
        setCurrentlyHighlighted,
        highlightColor,
        setHighlightColor,
        lastClicked,
        setLastClicked,
      }}
    >
      {props.children}
    </HighlightContext.Provider>
  );
}

import { useContext } from "react";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import { DistanceMeasurementControl } from "./DistanceMeasurementControl";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { AngleMeasurementControl } from "./AngleMeasurementControl";
import { Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  fullWidth: {
    width: "100%",
  },
  nomeasure: {
    margin: "20px",
    fontSize: "12px",
  },
}));
export function MeasurementView() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { measurements } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const { angleMeasurements } = useContext(
    MeasureAngleContext
  ) as MeasureAngleContextType;
  if (
    (!measurements || Object.values(measurements).length === 0) &&
    Object.values(angleMeasurements).length === 0
  ) {
    return (
      <Typography className={classes.nomeasure} color="textSecondary">
        {t(
          "There are no measurements here yet. Measurements will be created in the Measurements Stage."
        )}
      </Typography>
    );
  }
  const distanceMeasurementEls = measurements ? (
    Object.values(measurements).map((measure) => {
      return (
        <DistanceMeasurementControl
          key={measure.index + " "}
          measurement={measure}
        ></DistanceMeasurementControl>
      );
    })
  ) : (
    <div></div>
  );
  const angleMeasurementEls = angleMeasurements ? (
    Object.values(angleMeasurements).map((angleMeasure) => {
      return (
        <AngleMeasurementControl
          key={angleMeasure.index}
          angleMeasurement={angleMeasure}
        ></AngleMeasurementControl>
      );
    })
  ) : (
    <div></div>
  );
  return (
    <div className={classes.fullWidth}>
      {distanceMeasurementEls}
      {angleMeasurementEls}
    </div>
  );
}

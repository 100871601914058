import { PendingLoadContext } from "features/Context/PendingLoadContext";
import React, { useContext, useState } from "react";
import SymbolAndMagnitude from "../util/SymbolAndMagnitude";
import { useUnits } from "../util/useUnits";

export default function Q0ControlRight() {
  const { pendingLoad, setPendingLoadSpec } = useContext(PendingLoadContext);
  const preFactor = pendingLoad.loadSpec.endPreFactor;
  const name = pendingLoad.loadSpec.endSuffix;
  const unit = pendingLoad.loadSpec.unitSymbol;
  const unitFactor = pendingLoad.loadSpec.endUnitFactor;
  const magnitude = pendingLoad.loadSpec.endMagnitude;
  const isSymbolic = pendingLoad.loadSpec.endIsSymbolic;

  const setPendingSymbolic = (isSymbolic) => {
    const newLoadSpecObject = {
      endIsSymbolic: isSymbolic,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const handleNameChange = (newString) => {
    const newLoadSpecObject = {
      endSuffix: newString,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const handlePreFactorChange = (newString) => {
    const newLoadSpecObject = {
      endPreFactor: newString,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };

  const handleMagnitudeChange = (number) => {
    // console.log("newMag", number);
    const newLoadSpecObject = {
      endMagnitude: number,
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const handleUnitChange = (unit) => {
    const newLoadSpecObject = {
      endUnitSymbol: unit,
      endUnitFactor: unitFactorConverter(unit),
    };
    setPendingLoadSpec(newLoadSpecObject);
  };
  const symbolName = "q";
  const [unitOption, unitFactorConverter] = useUnits(pendingLoad.loadType);
  return (
    <SymbolAndMagnitude
      {...{
        symbolName,
        preFactor,
        unit,
        magnitude,
        isSymbolic,
        unitOption,
      }}
      padding={5}
      handleNameChange={handleNameChange}
      handlePreFactorChange={handlePreFactorChange}
      handleMagnitudeChange={handleMagnitudeChange}
      handleUnitChange={handleUnitChange}
      setSymbolic={setPendingSymbolic}
      symbolSuffix={name}
      unitFactor={unitFactor}
      height={20}
      thin={true}
    />
  );
}

import { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { mountingTreeStyles } from "./mountingTreeStyles";

import TreeElementVisual from "./TreeElementVisual";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import {
  PendingContext,
  PendingContextType,
} from "features/Context/PendingContext";
import { useTranslation } from "react-i18next";
// @ts-ignore
const useStyles = makeStyles(mountingTreeStyles);

export function VolumePending() {
  const { t } = useTranslation();
  const { pendingObject } = useContext(PendingContext) as PendingContextType;

  const { handleMouseEnter, handleMouseLeave } = useHighlight(
    "part",
    "pending"
  );

  const classes = useStyles();
  const part = pendingObject;
  if (!part) {
    return <div></div>;
  }

  const isHighlighted = part.isHighlighted;
  const partType = "stab";
  let partName = t("Beam");
  return (
    <div className={classes.tabbedRightPart}>
      <div className={classes.tabbedBorderPart}>
        <div
          key="2"
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <TreeElementVisual
            index={0}
            type={partType}
            text={partName}
            isHighlighted={isHighlighted}
            isPending={true}
          ></TreeElementVisual>
        </div>
      </div>
    </div>
  );
}

import { useContext } from "react";
import { Connector } from "./Connector";
import { AddToTree } from "./AddToTree";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import {
  StatusContext,
  StatusContextType,
} from "../../../Context/StatusContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "../../../Context/PendingLoadContext";
import { makeStyles } from "@material-ui/core";
import { mountingTreeStyles } from "./mountingTreeStyles";

import {
  DrawenObject,
  Index,
  ObjectIndex,
  RootObject,
} from "features/Context/types/DrawenObjectModel";
import TreeElementVisual from "./TreeElementVisual";
import RootElementVisual from "./RootElementVisual";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import AddButton from "./AddButton";
import { LoadExplorerControl } from "./LoadExplorerControl";
import {
  PendingContext,
  PendingContextType,
} from "features/Context/PendingContext";
import { ConnectorPending } from "./ConnectorPending";
import { useTranslation } from "react-i18next";
// @ts-ignore
const useStyles = makeStyles(mountingTreeStyles);

export function Volume({ index }: { index: Index }) {
  const { t } = useTranslation();
  const { drawenObjects, setRootCoordinateSystemVisibility } = useContext(
    DrawenContext
  ) as DrawenContextType;
  const { pendingObject } = useContext(PendingContext) as PendingContextType;

  const { status } = useContext(StatusContext) as StatusContextType;
  const { initializePendingLoad, getLoadsWithParentIndex, isPendingLoad } =
    useContext(PendingLoadContext) as PendingLoadContextType;
  const { handleMouseEnter, handleMouseLeave } = useHighlight("part", index);

  const isInLoadMode = status.status === "load" ? true : false;

  const classes = useStyles();
  const isRootEl = index === "root";
  const part = drawenObjects[index];
  if (!part) {
    return <div></div>;
  }

  const partIsParentOfPendingObject =
    pendingObject && pendingObject.parentIndex === index;

  let content = partIsParentOfPendingObject
    ? [<ConnectorPending key={"pendingEl"}></ConnectorPending>]
    : [];
  if (part.children[0] === 0 || part.children[0]) {
    const newContent = part.children.map((childIndex, index) => {
      return <Connector index={childIndex as number} />;
    });
    content = [...content, ...newContent];
    content = [
      !isRootEl ? (
        <AddToTree
          type={"connectorAdder"}
          key={"addToTree"}
          parentIndex={index}
        />
      ) : (
        <></>
      ),
      ...content,
    ];
  } else {
    content = [
      ...content,
      <>
        <AddToTree type={"connectorAdder"} parentIndex={index} />
      </>,
    ];
  }
  const attachedLoads = getLoadsWithParentIndex(index);
  const attachedLoadsEls = attachedLoads?.map((load) => {
    return <LoadExplorerControl key={load.index} load={load} />;
  });
  const isRoot = index === "root" ? true : false;
  var addLoadButton =
    isInLoadMode && !isRoot ? (
      <div className={classes.padding}>
        <AddButton
          disabled={isPendingLoad}
          handleClick={() =>
            initializePendingLoad(index as ObjectIndex, "force")
          }
          name={t("Load")}
        ></AddButton>
      </div>
    ) : (
      ""
    );

  const isHighlighted = !isRoot && (part as DrawenObject).isHighlighted;
  const partType = "stab";
  let partName = isRootEl ? t("System Root") : t("Beam");
  return (
    <div className={classes.tabbedRightPart}>
      <div className={classes.tabbedBorderPart}>
        {!isRoot && <div key="1" className={classes.partLine} />}
        {!isRoot && (
          <div
            key="2"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <TreeElementVisual
              index={index as number}
              type={partType}
              text={partName}
              isHighlighted={isHighlighted}
            ></TreeElementVisual>
          </div>
        )}
        {isRoot && (
          <RootElementVisual
            visibilityCoordinateSystem={
              !(part as RootObject).coordinateSystemIsHidden
            }
            key="3"
            text={partName}
            setVisibilityCoordinateSystem={setRootCoordinateSystemVisibility}
          ></RootElementVisual>
        )}
        {attachedLoadsEls}
        {addLoadButton}
      </div>
      {content}
    </div>
  );
}

import { DrawenContext } from "features/Context/DrawenContext";
import { PendingContext } from "features/Context/PendingContext";
import { useContext } from "react";
import BearingMounter from "../BearingMounter";
import { Part } from "../Part";
import { FreischnittContext } from "features/Context/FreischnittContext";

export const useSystemObjs = () => {
  // draw all drawen components
  const { drawenObjects, getZIndexSortedList } = useContext(DrawenContext);
  const { pendingObject, isPending } = useContext(PendingContext);
  const { bearingsAreHidden } = useContext(FreischnittContext);

  const zIndexList = getZIndexSortedList();
  const drawenPlaygroundComponents = zIndexList.map((currentIndex) => {
    if (drawenObjects[currentIndex].mechanicalType === "part") {
      return (
        <Part
          mechanicalType={drawenObjects[currentIndex].mechanicalType}
          type={drawenObjects[currentIndex].type}
          name={drawenObjects[currentIndex].index}
          key={drawenObjects[currentIndex].index}
        />
      );
    } else if (
      drawenObjects[currentIndex].mechanicalType === "bearing" &&
      !bearingsAreHidden
    ) {
      return (
        <BearingMounter
          key={currentIndex}
          rotate180={true}
          bearingObject={drawenObjects[currentIndex]}
        />
      );
    }
  });

  //draw all pending components

  let pendingPlaygroundComponent;
  if (isPending && pendingObject.mechanicalType == "part") {
    pendingPlaygroundComponent = (
      <Part
        mechanicalType={pendingObject.mechanicalType}
        type={pendingObject.type}
        name={"pending"}
        key={"pending"}
      />
    );
  } else if (isPending && pendingObject.mechanicalType == "bearing") {
    pendingPlaygroundComponent = (
      <BearingMounter
        key="pending"
        rotate180={true}
        bearingObject={pendingObject}
      />
    );
  } else {
    pendingPlaygroundComponent = [];
  }

  const playgroundComponents = [
    ...drawenPlaygroundComponents,
    pendingPlaygroundComponent,
  ];
  return playgroundComponents;
};

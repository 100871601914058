import React from "react";
import Popover from "@material-ui/core/Popover";
import { Overview } from "../BearingEditor/Overview/Overview";
import { IconButton } from "@material-ui/core";
import { ColorfulLosLagerIcon } from "../Functionality_Right/SystemMode/SelectBar/Selectables/ColorfulLosLagerIcon";
import { useTranslation } from "react-i18next";

export default function BearingEditor() {
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & Element) | null
  >(null);
  const { t } = useTranslation();
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton title={t("Customize element styles")} onClick={handleClick}>
        <ColorfulLosLagerIcon></ColorfulLosLagerIcon>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{
          top: 0,
          left: 0,
        }}
        PaperProps={{
          style: { width: "100%", height: "100%" },
        }}
      >
        <Overview handleClose={handleClose} />
      </Popover>
    </div>
  );
}

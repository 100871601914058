import { makeStyles, Slider, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Pizza } from "../../../../Playground/Cords/Pizza";
import useMarkSnapper from "../util/useMarkSnapper";
import {
  inputColor,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
import { Mark } from "../util/MarkModel";
const useStyles = makeStyles({
  slider: {
    width: "100%",
  },
  sliderWrapper: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  input: {
    background: inputColor,
    width: "80px",
  },
});
export function RotationInput({
  imgWidth = 20,
  imgHeight = 20,
  angle = 0,
  handleAngleChange = (angle: number) => {},
  marks = [],
  description = "",
  min = 0,
  max = 360,
  showPizza = true,
}: {
  imgWidth?: number;
  imgHeight?: number;
  angle?: number;
  handleAngleChange?: Function;
  marks?: Array<Mark>;
  description?: string;
  min?: number;
  max?: number;
  showPizza?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const [input, setInput] = useState<number | string>(1); //handle empty input

  const [mouseAngle, setMouseAngle] = useState(0);
  const [isRotatable, setRotatable] = useState(false);

  const rotCenterX = imgWidth / 2;
  const rotCenterY = imgHeight / 2;

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isRotatable) {
      var svg = document.getElementById("rotateSvg");
      if (!svg) {
        return;
      }
      var dx = e.pageX - svg.getBoundingClientRect().left - rotCenterX;

      var dy = e.pageY - svg.getBoundingClientRect().top - rotCenterY;

      var angle = (Math.atan2(dy, dx) * 180) / Math.PI;
      if (angle < 0) {
        angle = angle + 360;
      } else if (angle >= 360) {
        angle = angle - 360;
      }
      var deltaAngle = angle - mouseAngle;

      var realAngle = angle + deltaAngle;
      if (realAngle < 0) {
        realAngle = realAngle + 360;
      } else if (realAngle >= 360) {
        realAngle = realAngle - 360;
      }
      //dispatch
      handleAngleChange(realAngle);

      setMouseAngle(angle);
    }
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!isRotatable) {
      setRotatable(true);
      var svg = document.getElementById("rotateSvg");
      if (!svg) {
        return;
      }
      var dx = e.pageX - svg.getBoundingClientRect().left - rotCenterX;

      var dy = e.pageY - svg.getBoundingClientRect().top - rotCenterY;

      var angle = (Math.atan2(dy, dx) * 180) / Math.PI;
      if (angle < 0) {
        angle = angle + 360;
      } else if (angle >= 360) {
        angle = angle - 360;
      }

      setMouseAngle(angle);
    }
  };
  const handleMouseUp = () => {
    setRotatable(false);
  };

  const marksCustom = marks.concat([
    { value: 0, label: "0°" },
    { value: 360, label: "360°" },
  ]);
  const [snapConvert, setLastCommitted] = useMarkSnapper(marksCustom, 13);

  const handleChange = (
    e: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) => {
    if (!Array.isArray(newVal)) {
      handleAngleChange(snapConvert(newVal));
    }
  };
  const handleChangeText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    newVal: string
  ) => {
    if (!isNaN(parseInt(newVal)) && newVal) {
      // check if input is number
      const realAngle = parseInt(e.target.value);

      handleAngleChange(realAngle);

      setInput(1);
    } else {
      setInput("");
    }
  };
  const handleChangeCommitted = (
    e: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) => {
    if (!Array.isArray(newVal)) {
      const snapConvertedNum = snapConvert(newVal);
      if (snapConvertedNum === undefined) {
        return;
      }
      setLastCommitted(snapConvertedNum);
    }
  };
  return (
    <div className={"rotationInput"}>
      {Boolean(description) && (
        <Typography className={" rotationDescription"}>
          {description}{" "}
        </Typography>
      )}
      <TextField
        type="number"
        name="angle"
        onChange={(e) => handleChangeText(e, e.target.value)}
        className={"numberInput numberInput"}
        inputProps={{
          className: classes.input,
          min: min,
          step: 1,
          max: max,
        }}
        value={!input || Math.round(angle)}
      />

      <div className={classes.sliderWrapper}>
        <Slider
          className={classes.slider}
          marks={marksCustom}
          step={1}
          onChange={(e, newVal) => handleChange(e, newVal)}
          onChangeCommitted={(e, newVal) => handleChangeCommitted(e, newVal)}
          min={min}
          max={max}
          value={Math.round(angle)}
        />
      </div>

      {showPizza && (
        <svg
          id="rotateSvg"
          width={imgWidth}
          height={imgHeight}
          onMouseMove={(e) => handleMouseMove(e)}
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={() => {
            handleMouseUp();
          }}
        >
          <g id="arc" transform={"scale(1 -1) "}>
            <Pizza
              angle={angle}
              color={primaryColor}
              strokeWidth={3}
              strokeWidth2={2}
              radius={(0.8 * imgWidth) / 2}
              cx={(0.9 * imgWidth) / 2}
              cy={(-0.9 * imgWidth) / 2}
              orientation={1}
            />
          </g>
        </svg>
      )}
    </div>
  );
}

import { Line } from "features/LinearAlgebra/linearAlgebraModel";
import { areClose } from "features/LinearAlgebra/vectorUtils";

export const checkIfStreckenlastAlreadyMeasuresPart = (
  streckMeasureLineStart: Line,
  streckMeasureLineLength: Line,
  partLine: Line
) => {
  const [p1, p2] = streckMeasureLineStart;
  const [p3, p4] = streckMeasureLineLength;
  const streckPoints = [p1, p2, p3, p4];
  const [start, end] = partLine;
  let startContained = false;
  let endContained = false;
  streckPoints.forEach((point) => {
    if (areClose(point, start)) {
      startContained = true;
    }
    if (areClose(point, end)) {
      endContained = true;
    }
  });
  return startContained && endContained;
};

import { makeStyles } from "@material-ui/core";
import { Tag } from "./Tag";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
  },
}));
export function StageStartMsg({
  msg,
  stageName,
}: {
  msg: string;
  stageName: string;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.flex}>
      <Tag height={16} text={stageName + t(" Stage:")}></Tag>
      {msg}
    </div>
  );
}

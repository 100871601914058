import React, { useState, useContext, createContext } from "react";
import { getBearingSize, getPartSize } from "../GlobalData/initialGeometry";
import { initialPos } from "../GlobalData/initialPosition";
import { bearingTypes } from "../GlobalData/bearingTypes";
import { partTypes } from "../GlobalData/partTypes";
import { DrawenContext, DrawenContextType } from "./DrawenContext";
import {
  BearingSize,
  DrawenObject,
  FinishedPendingObject,
  Index,
  ObjectIndex,
  PartSize,
  PendingObject,
} from "./types/DrawenObjectModel";
import {
  DrawenObjectMechanicalType,
  DrawenObjectType,
} from "./types/ConstructionElementModel";
import { CoordinateSystemName } from "./types/CoordinateSystems";
import { areCloseScalar } from "features/LinearAlgebra/vectorUtils";
export type RelativePos = {
  relativeX?: number;
  relativeY?: number;
  distance: number;
};
export const PendingContext = createContext<PendingContextType | null>(null);
export type PendingContextType = {
  initializePendingObject: (initializer: {
    parentIndex: ObjectIndex;
    mechanicalType: DrawenObjectMechanicalType;
    color: string;
  }) => void;
  fillPendingObject: (filler: {
    type: DrawenObjectType;
    mechanicalType: DrawenObjectMechanicalType;
    color?: string;
    x?: number;
    y?: number;
    relativeAngle?: number;
    posReferenceIndex?: Index;
    posKOS?: CoordinateSystemName;
    scale?: number;
    relativePos?: RelativePos;
  }) => void;
  removePendingObject: () => void;
  savePendingObject: () => void;
  setPendingCoords: (cords: { x: number; y: number }) => void;
  setPendingSize: (size: BearingSize | PartSize) => void;
  pendingObject: PendingObject | null;
  isPending: boolean;
  isInitialized: boolean;
  setIsInitialized: (newIsInitialized: boolean) => void;
  setPendingAngle: (angle: number) => void;
  setPendingDeltaAngle: (angle: number) => void;
  setAngleReferenceIndex: (index: ObjectIndex) => void;
  setPendingRelativeAngle: (angle: number) => void;
  setPosReferenceIndex: (index: ObjectIndex) => void;
  setPosKOS: (coordinateSystem: CoordinateSystemName) => void;
  setRelativePos: (relPos: RelativePos) => void;
  setPendingScale: (scale: number) => void;
};
export function PendingProvider(props: any) {
  const { createNewDrawenObject, drawenObjects } = useContext(
    DrawenContext
  ) as DrawenContextType; // access drawenContext methods to move from pending to drawen in savePendingObkect

  const [isPending, setIsPending] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [pendingObject, setPendingObject] = useState<PendingObject | null>(
    null
  );

  const initializePendingObject = ({
    parentIndex,
    mechanicalType,
    color = "black",
  }: {
    parentIndex: ObjectIndex;
    mechanicalType: DrawenObjectMechanicalType;
    color: string;
  }) => {
    setPendingObject({
      parentIndex: parentIndex,
      children: [],
      mechanicalType: mechanicalType,
      color: color,
      posReferenceIndex: "root",
      isHighlighted: false,
    });
    setIsInitialized((prev) => {
      return true;
    });
  };
  const fillPendingObject = ({
    type,
    mechanicalType,
    color = "black",
    x = initialPos.x,
    y = initialPos.y,
    relativeAngle = 0,
    posReferenceIndex = "root",
    posKOS = "cartesian-global",
    scale = 100,
    relativePos = { relativeX: 0, relativeY: 0, distance: 0 },
  }: {
    type: DrawenObjectType;
    mechanicalType: DrawenObjectMechanicalType;
    color?: string;
    x?: number;
    y?: number;
    relativeAngle?: number;
    posReferenceIndex?: Index;
    posKOS?: CoordinateSystemName;
    scale?: number;
    relativePos?: RelativePos;
  }) => {
    setPendingObject((prevPending: any) => {
      const size =
        //@ts-ignore
        mechanicalType === "bearing" ? getBearingSize(type) : getPartSize(type);
      const refPoint =
        mechanicalType === "bearing"
          ? bearingTypes[type].refPoint
          : partTypes[type].refPoint;
      const controlType =
        mechanicalType === "bearing"
          ? bearingTypes[type].controlType
          : partTypes[type].controlType;
      //place part, so the left side matches bearing
      let xNew = x;
      let yNew = y;
      let relativePosNew = relativePos;
      let helperAngle = relativeAngle;

      if (mechanicalType !== "bearing") {
        const xParentBearing = drawenObjects[prevPending.parentIndex]?.x;
        const yParentBearing = drawenObjects[prevPending.parentIndex]?.y;
        xNew = xParentBearing ? xParentBearing : 0;
        yNew = yParentBearing ? yParentBearing : 0;
      } else if (
        mechanicalType === "bearing" &&
        prevPending.parentIndex !== "root"
      ) {
        //construct point, which is far away from grandparent bearing
        const xParentPart = drawenObjects[prevPending.parentIndex].x;
        const yParentPart = drawenObjects[prevPending.parentIndex].y;
        const widthParentPart = (
          drawenObjects[prevPending.parentIndex].size as PartSize
        ).width;
        const angleParentPart = drawenObjects[prevPending.parentIndex].angle;
        const grandParentIndex =
          drawenObjects[prevPending.parentIndex].parentIndex;
        const xGrandParentBearing = (
          drawenObjects[grandParentIndex] as DrawenObject
        ).x;
        const yGrandParentBearing = (
          drawenObjects[grandParentIndex] as DrawenObject
        ).y;

        const distanceToLeftPoint = Math.sqrt(
          (xGrandParentBearing - xParentPart) *
            (xGrandParentBearing - xParentPart) +
            (yGrandParentBearing - yParentPart) *
              (yGrandParentBearing - yParentPart)
        );
        const distanceToRightPoint = widthParentPart - distanceToLeftPoint;
        if (distanceToLeftPoint > distanceToRightPoint) {
          xNew = xParentPart;
          yNew = yParentPart;
          relativePosNew = { distance: 0 };
        } else {
          const relativeX =
            Math.cos((angleParentPart / 360) * 2 * Math.PI) * widthParentPart;
          const relativeY =
            Math.sin((angleParentPart / 360) * 2 * Math.PI) * widthParentPart;
          xNew = xParentPart + relativeX;
          yNew = yParentPart + relativeY;
          //also update relative Pos
          const dist = Math.sqrt(relativeX * relativeX + relativeY * relativeY);
          relativePosNew = {
            distance: dist,
          };
          //rotate einspannung when it is placed on the right of a part
          const rotateEinspannung =
            relativeAngle === 0 &&
            type === "einspannung" &&
            areCloseScalar(
              dist,
              (drawenObjects[prevPending.parentIndex].size as PartSize).width
            );

          helperAngle = rotateEinspannung ? 180 : helperAngle;
        }
      }
      const parent = drawenObjects[prevPending.parentIndex];
      const newAngleRefIndex = parent?.index ? parent.index : "root";

      const newAngle = parent ? parent.angle + helperAngle : 0;
      return {
        ...prevPending,
        index: "pending",
        children: [],
        type: type,
        mechanicalType: mechanicalType,
        x: xNew,
        y: yNew,

        posReferenceIndex: posReferenceIndex,
        posKOS: posKOS,
        scale: scale,
        relativePos: relativePosNew, //object
        xRefRatio: refPoint.xRatio,
        yRefRatio: refPoint.yRatio,
        angle: newAngle,
        angleReferenceIndex: newAngleRefIndex,
        relativeAngle: relativeAngle,
        size: size, //{width,height}
        color: color,
        controlType: controlType,
      };
    });
    setIsPending((prev) => true);
    setIsInitialized((prev) => {
      return false;
    });
  };
  const setPendingCoords = ({
    x = pendingObject?.x,
    y = pendingObject?.y,
  } = {}) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      x: x,
      y: y,
    }));
  };
  const setPendingAngle = (angle: number) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      angle: angle,
    }));
  };
  const setPendingDeltaAngle = (deltaAngle: number) => {
    //set delta and according relative angle
    setPendingObject((prevPending: any) => {
      if (prevPending.angleReferenceIndex === "root") {
        return {
          ...prevPending,
          angle: prevPending.angle + deltaAngle,
          relativeAngle: prevPending.angle + deltaAngle,
        };
      } else {
        //it has relative angle measurements --> use drawenObjetcs
        let newRelAngle =
          prevPending.angle +
          deltaAngle -
          drawenObjects[prevPending.angleReferenceIndex].angle;
        if (newRelAngle < 0) {
          newRelAngle = newRelAngle + 360;
        } else if (newRelAngle > 360) {
          newRelAngle = newRelAngle - 360;
        }
        return {
          ...prevPending,
          angle: prevPending.angle + deltaAngle,
          relativeAngle: newRelAngle,
        };
      }
    });
  };
  const setPendingRelativeAngle = (relativeAngle: number) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      relativeAngle: relativeAngle,
    }));
  };
  const setAngleReferenceIndex = (index: ObjectIndex) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      angleReferenceIndex: index,
      // relativeAngle: 0,
      // angle: drawenObjects[index].angle,
    }));
  };
  const setPosReferenceIndex = (index: ObjectIndex) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      posReferenceIndex: index,
    }));
  };
  const setPosKOS = (KOS: CoordinateSystemName) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      posKOS: KOS,
    }));
  };
  const setPendingScale = (scale: number) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      scale: scale,
    }));
  };

  const setRelativePos = (relativePos: RelativePos) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      relativePos: { ...relativePos },
    }));
  };
  const setPendingSize = (sizeObject: PartSize | BearingSize) => {
    setPendingObject((prevPending: any) => ({
      ...prevPending,
      size: {
        ...prevPending.size,
        ...sizeObject,
      },
    }));
  };
  const removePendingObject = () => {
    setPendingObject(null);
    setIsPending(false);
    setIsInitialized(false);
  };
  const savePendingObject = () => {
    if (!pendingObject) {
      return;
    }
    const pendingObj = pendingObject as FinishedPendingObject;
    createNewDrawenObject({
      parentIndex: pendingObj.parentIndex,
      type: pendingObj.type,
      mechanicalType: pendingObj.mechanicalType,
      x: pendingObj.x,
      y: pendingObj.y,
      posReferenceIndex: pendingObj.posReferenceIndex,
      posKOS: pendingObj.posKOS,
      relativePos: { ...pendingObj.relativePos },
      angle: pendingObj.angle,
      color: pendingObj.color,
      scale: pendingObj.scale,
      size: { ...pendingObj.size },
      controlType: pendingObj.controlType,
      angleReferenceIndex: pendingObj.angleReferenceIndex,
      relativeAngle: pendingObj.relativeAngle,
      isHighlighted: pendingObj.isHighlighted,
    });
    removePendingObject();
  };

  return (
    <PendingContext.Provider
      value={{
        initializePendingObject: initializePendingObject,
        fillPendingObject: fillPendingObject,
        removePendingObject: removePendingObject,
        savePendingObject: savePendingObject,
        setPendingCoords: setPendingCoords,
        setPendingSize: setPendingSize,
        pendingObject: pendingObject,
        isPending: isPending,
        isInitialized: isInitialized,
        setIsInitialized: setIsInitialized,
        setPendingAngle: setPendingAngle,
        setPendingDeltaAngle: setPendingDeltaAngle,
        setAngleReferenceIndex: setAngleReferenceIndex,
        setPendingRelativeAngle: setPendingRelativeAngle,
        setPosReferenceIndex: setPosReferenceIndex,
        setPosKOS: setPosKOS,
        setRelativePos: setRelativePos,
        setPendingScale: setPendingScale,
      }}
    >
      {props.children}
    </PendingContext.Provider>
  );
}

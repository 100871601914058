import {
  FormControl,
  FormHelperText,
  Input,
  NativeSelect,
  makeStyles,
} from "@material-ui/core";
import { UnitOptionEl } from "./unitModel";
import { inputColor } from "features/Components/StyleOnlyComponents/Color";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  font: {
    fontSize: "10px",
  },
  inline: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "flex-start",
  },
  paddingTop: {
    paddingTop: "5px",
  },
  stacked: {
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: "5px",
  },
  input: {
    background: inputColor,
    paddingBottom: "0px",
    paddingTop: "0px",
    marginBottom: "5px",
  },
}));
export function MagnitudeRep({
  handleMagnitudeChange,
  handleUnitChange,
  magnitude,
  unitFactor,
  unit,
  unitOptionEl,
  disabled = false,
  thin = false,
}: {
  handleMagnitudeChange: Function;
  handleUnitChange: Function;
  magnitude: number;
  unitFactor: number;
  unit: string;
  unitOptionEl: UnitOptionEl | Array<UnitOptionEl>;
  disabled: boolean;
  thin: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const classes = useStyles();
  const valueHelper = magnitude;
  const magUnit =
    magnitude === 0 || magnitude ? Math.round(valueHelper * 10000) / 10000 : "";
  return (
    <div className={thin ? classes.stacked : classes.inline}>
      <Input
        disabled={disabled}
        type="number"
        value={magUnit}
        style={{
          height: "22px",
          fontSize: "18px",
          textAlign: "right",
          width: "70px",
        }}
        inputProps={{
          className: classes.input,
        }}
        onChange={(e) => {
          handleMagnitudeChange(e.target.value);
        }}
      />

      <FormControl className={""}>
        {/* <InputLabel shrink htmlFor="Pos-native-label-placeholder">
              Einheit
            </InputLabel> */}
        <NativeSelect
          disabled={disabled}
          value={unit}
          onChange={(e) => {
            handleUnitChange(e.target.value);
          }}
          inputProps={{
            className: classes.input,
            name: t("Angle"),
            id: "Angle-native-label-placeholder",
          }}
          style={{
            height: "22px",
            fontSize: "18px",
            textAlign: "left",
            width: "70px",
          }}
        >
          {unitOptionEl}
        </NativeSelect>
      </FormControl>
    </div>
  );
}

import React, { useState, useContext } from "react";
import { CordsArrow } from "../../Cords/CordsArrow";
import { ArcWithArrowHead } from "../../Cords/ArcWithArrowHead";
import { inRotatedKOS, toCartesian } from "./kosHelper";
//pure Function
export function PolarGlobalMeasure({
  x1,
  x2,
  y1,
  y2,
  color,
  strokeWidth,
  rDescription = "",
  phiDescription = "",
  strokeDashArray = "10,10",
  size,
} = {}) {
  const angle = (Math.atan2(y2 - y1, x2 - x1) * 360) / 2 / Math.PI;
  const orientation = angle < 0 ? 0 : 1;
  const angleRadius = 60;
  const xEnd = x1 + Math.cos(toRad(angle)) * 1.1 * angleRadius;
  const yEnd = y1 + Math.sin(toRad(angle)) * 1.1 * angleRadius;

  const xBeg = x1 + 1.1 * angleRadius;
  const yBeg = y1;

  const fontSize = Math.max(strokeWidth * 4, 10);
  const distToArrow = 1.6;
  const rText = {
    x:
      (x1 + x2) / 2 -
      Math.sin((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
      Math.cos((angle * 2 * 3.1415) / 360) * fontSize * distToArrow -
      fontSize / 2,
    y:
      (y1 + y2) / 2 +
      Math.cos((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
      Math.sin((angle * 2 * 3.1415) / 360) * fontSize * distToArrow,
  };
  const phiText = {
    x: x1 + Math.cos(toRad(angle / 2)) * 1.1 * angleRadius,
    y: y1 + Math.sin(toRad(angle / 2)) * 1.1 * angleRadius,
  };
  return (
    <g>
      <g
        transform={
          "translate(" +
          rText.x +
          " " +
          rText.y +
          ") rotate(" +
          -180 +
          ") scale(" +
          -1 +
          " " +
          1 +
          ")"
        }
      >
        <text x={0} y={0} fill={color} fontSize={fontSize + "px"}>
          {rDescription}
        </text>
      </g>
      <g
        transform={
          "translate(" +
          phiText.x +
          " " +
          phiText.y +
          ") rotate(" +
          -180 +
          ") scale(" +
          -1 +
          " " +
          1 +
          ")"
        }
      >
        <text x={0} y={0} fill={color} fontSize={fontSize + "px"}>
          {phiDescription}
        </text>
      </g>
      <g id="angleDisplay">
        <line
          x1={x1}
          y1={y1}
          x2={xEnd}
          y2={yEnd}
          stroke={color}
          strokeWidth={strokeWidth * 0.7}
          strokeLinecap="round"
        />
        <line
          x1={x1}
          y1={y1}
          x2={xBeg}
          y2={yBeg}
          stroke={color}
          strokeWidth={strokeWidth * 0.7}
          strokeLinecap="round"
        />
        <ArcWithArrowHead
          size={size}
          peakAngle={45}
          startAngle={0}
          cx={x1}
          cy={y1}
          radius={angleRadius}
          color={color}
          strokeWidth={strokeWidth}
          orientation={orientation}
          angle={angle}
          strokeDashArray={strokeDashArray}
        />
      </g>

      <g id="RadiusLine">
        <CordsArrow
          color={color}
          cartesian={{ originX: x1, originY: y1, destX: x2, destY: y2 }}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDashArray}
          size={size}
        />
      </g>
    </g>
  );
}
function toRad(angleInDegree) {
  return (angleInDegree / 360) * 2 * Math.PI;
}

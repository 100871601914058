import React, { useState,useContext } from 'react';
import {CordsArrow} from '../../Cords/CordsArrow';
import {inRotatedKOS,toCartesian} from'./kosHelper';
//pure Function
export function CartesianLocalMeasure({x1,x2,y1,y2,angle1,color,strokeWidth,xDescription="",yDescription="",strokeDashArray="10,10",size}={}) {
                            //p2
     //p1                    |
    //-----------------------| p3
    const dxGlobal=x2-x1;
    const dyGlobal=y2-y1;
    const dxRot=inRotatedKOS(dxGlobal,dyGlobal,angle1).dxRot;//transform
    const dyRot=inRotatedKOS(dxGlobal,dyGlobal,angle1).dyRot;
    const dxRotinGlobal=inRotatedKOS(dxRot,0,-angle1).dxRot;//transform back y trafo not needed becuase endpount are available
    const dyRotinGlobal=inRotatedKOS(dxRot,0,-angle1).dyRot;//transform back

    const x3=x1+dxRotinGlobal;
    const y3=y1+dyRotinGlobal;
    
    const fontSize=Math.max(strokeWidth*4,10);
    const xText={
        x:(x3-x1)/2+x1,
        y:(y3-y1)/2+y1+fontSize,
    }
    const yText={
        x:(x2-x3)/2+x3+fontSize,
        y:(y2-y3)/2+y3,
    }
    
    return (
        <g>
            <g  transform={"translate("+ xText.x +" "+ xText.y +") rotate("+(-180)+") scale("+(-1)+" "+1+")"}>
                <text x={0} y={0} fill={color} fontSize={fontSize+"px"}>{xDescription}</text>
            </g>
           
            <CordsArrow strokeWidth={strokeWidth} color={color} cartesian={{originX:x1,originY:y1,destX:x3,destY:y3}} 
            size={size} strokeDashArray={strokeDashArray} />
             <g  transform={"translate("+ yText.x +" "+ yText.y +") rotate("+(-180)+") scale("+(-1)+" "+1+")"}>
                <text x={0} y={0} fill={color} fontSize={fontSize+"px"}>{yDescription}</text>
            </g>
            <CordsArrow strokeWidth={strokeWidth} color={color} cartesian={{originX:x3,originY:y3,destX:x2,destY:y2}} 
            size={size} strokeDashArray={strokeDashArray}/>
        </g>
    )
  }
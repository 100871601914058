import { useMemo } from "react";

import { CoordinateSystem } from "../Cords/CoordinateSystem";
import { CartesianGlobalMeasure } from "./PositionMeasures/CartesianGlobalMeasure";
import { CartesianLocalMeasure } from "./PositionMeasures/CartesianLocalMeasure";
import { PolarLocalMeasure } from "./PositionMeasures/PolarLocalMeasure";
import { PolarGlobalMeasure } from "./PositionMeasures/PolarGlobalMeasure";
import {
  DrawenObjects,
  PendingObject,
} from "features/Context/types/DrawenObjectModel";
/**
 * Measaurements for placing a bearing or part on the screen initially
 * @returns
 */
export function PositionMeasurements({
  pendingObject,
  drawenObjects,
}: {
  pendingObject: PendingObject;
  drawenObjects: DrawenObjects;
}) {
  const posReferenceIndex = pendingObject.posReferenceIndex;
  const refObject = useMemo(() => {
    return drawenObjects[posReferenceIndex];
  }, [posReferenceIndex, drawenObjects]);

  const strokeDashArray = "10,10";
  const size = 1;

  const strokeWidth = 1;
  const [color, angle, measure] = useMemo(() => {
    if (!pendingObject.posKOS) {
      return ["black", 0, <div></div>];
    }
    switch (pendingObject.posKOS) {
      case "cartesian-global":
        const measure0 = (
          <CartesianGlobalMeasure
            x1={refObject?.x}
            y1={refObject?.y}
            x2={pendingObject.x}
            y2={pendingObject.y}
            xDescription={"X"}
            yDescription={"Y"}
            size={size}
            color="grey"
            strokeWidth={strokeWidth}
            strokeDashArray={strokeDashArray}
          />
        );
        return ["blue", 0, measure0];
      case "cartesian-local":
        const measure1 = (
          <CartesianLocalMeasure
            x1={refObject?.x}
            y1={refObject?.y}
            angle1={refObject?.angle}
            x2={pendingObject.x}
            y2={pendingObject.y}
            color="green"
            xDescription={"X"}
            yDescription={"Y"}
            size={size}
            strokeWidth={strokeWidth}
            strokeDashArray={strokeDashArray}
          />
        );
        return ["#03b1fc", refObject?.angle, measure1];
        break;
      case "polar-global":
        const measure2 = (
          <PolarGlobalMeasure
            x1={refObject?.x}
            y1={refObject?.y}
            x2={pendingObject.x}
            y2={pendingObject.y}
            color="#118f06"
            strokeWidth={strokeWidth}
            rDescription={"R"}
            phiDescription={"Phi"}
            size={size}
            strokeDashArray={strokeDashArray}
          />
        );
        return ["blue", 0, measure2];
        break;
      case "polar-local":
        const measure3 = (
          <PolarLocalMeasure
            x1={refObject?.x}
            y1={refObject?.y}
            angle1={refObject?.angle}
            x2={pendingObject.x}
            y2={pendingObject.y}
            color="#118f06"
            strokeWidth={strokeWidth}
            rDescription={"R"}
            phiDescription={"Phi"}
            size={size}
            strokeDashArray={strokeDashArray}
          />
        );
        return ["#03b1fc", refObject?.angle, measure3];
    }
  }, [refObject, pendingObject.x, pendingObject.y, pendingObject.posKOS]);

  return (
    <>
      {/* <CoordinateSystem
        color={color}
        x={refObject?.x}
        y={refObject?.y}
        angle={angle}
        size={15}
        strokeWidth={2}
      /> */}
      {measure}
    </>
  );
}

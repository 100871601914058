import { Typography } from "@material-ui/core";
import FestLosExample from "./systemExample/FestLosExample";
import DifferentSystems from "./systemExample/DifferentSystems";
import { Bold } from "./Layout/Bold";
import { SubHeader } from "./Layout/SubHeader";
import { SymbolicCapabilities } from "./systemExample/SymbolicCapabilities";
import BearingEditor from "features/Components/Functionality_Bottom/BearingEditor";
import { LinkToCalculator } from "./LinkToCalculator";
import { useTranslation } from "react-i18next";

export function Capabilities({ showLink = true }: { showLink?: boolean }) {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <div>
      <SubHeader> {t("Mechanical System")}</SubHeader>
      {isEnglish ? (
        <Typography>
          The calculator can solve systems with custom designs. Of course the
          well known statically determinate systems are possible. For example
          the popular structure of fixed support, beam and roller support. But
          also more complex designs are possible. In general all statically
          determinate (non truss) systems are possible.
        </Typography>
      ) : (
        <Typography>
          Der Auflagerkräfte Rechner ermöglicht es Systeme frei zu erstellen.
          Zum Beipiel ist natürlich die Fest-Loslagerung einfach zu realisieren.
          Aber auch komplexere Systeme mit Winkeln und mehreren Balken sind
          möglich. Die Systeme müssen statisch bestimmt sein und dürfen keine
          Fachwerke sein.
        </Typography>
      )}
      <DifferentSystems></DifferentSystems>
      <SubHeader>{t("Stages to Solution")}</SubHeader>

      {isEnglish ? (
        <Typography>
          The solution of these systems is reached in several steps. First one
          prepares the mechanical system consisting of the supports and the
          beams. In the next stage loads are applied. Here Point Loads,
          Distributed Loads and Bending Moments are available. In the next stage
          length and angle measurements are created. These can then be
          customized (i.e naming and switching between symbolic and numerical
          measurements). Then the free body diagram is drawn and in the last
          stage the solution of the balance equations is provided.
        </Typography>
      ) : (
        <Typography>
          Die Lösung der Lagerreaktionen wird schrittweise erreicht. Zuerst wird
          ein mechanisches System aus Lagern und Balken erstellt. Ist das System
          statisch bestimmt kann es mit Lasten beaufschlagt werden. Die
          typischen Lasten wie Einzelkräfte, Drehmomente und Streckenlasten
          stehen zur Verfügung. Anschließend werden automatisch die Winkel und
          Längenmaße kreiert. Diese können dann so modifiziert werden, dass sie
          dem gewünschten System entsprechen. Nachdem dann der Freischritt
          erzeugt wird, werden im letzten Schritt die unbekannten
          Lagerreaktionen berechnet.
        </Typography>
      )}
      <FestLosExample></FestLosExample>
      <SubHeader>{t("Symbolical Solving")}</SubHeader>
      {isEnglish ? (
        <Typography>
          The novelty of this calculator is the capability to solve and
          configure symbolical (and even mixed) systems. Not only the loads can
          be symbolic, but also the lengths measurements can be symbolic.
          Symbols can have the same name and will be treated as the same symbol.
          This way use cases like placing loads at half the beam length are
          elegantly achieved.
        </Typography>
      ) : (
        <Typography>
          {" "}
          Die Neuerung die mit diesem Rechner einhergeht ist die Möglichkeit mit
          symbolischen Lasten und Längenmaßen zu rechnen. Da auch numerische
          Größen möglich sind ist sogar eine gemischte Auswertung denkbar.
          Sobald Symbolen der gleiche Name zugewiesen wird, werden diese als das
          gleiche Symbol behandelt. Auf diese Weise werden Probleme wie Lasten
          in der Balkenmitte elegant symbolisch gelöst.
        </Typography>
      )}
      <SymbolicCapabilities></SymbolicCapabilities>
      <SubHeader>{t("Checking for Statical Determinacy")}</SubHeader>
      {isEnglish ? (
        <Typography>
          The solver has a check for statical determinacy included. One can only
          got to the load stage when the mechanical system is statical
          determinate. This built in functionality in the system stage can be
          used to simply just check if a system is statically determinate.
        </Typography>
      ) : (
        <Typography>
          {" "}
          Der Löser hat in dem "System Schritt" eine Abfrage für die statische
          Bestimmtheit des Systems integriert. Dies kann auch direkt dazu
          verwendet werden, um zu prüfen ob ein vorliegendes System statisch
          bestimmt ist.
        </Typography>
      )}
      <SubHeader>{t("Creating Visuals")}</SubHeader>
      {isEnglish ? (
        <Typography>
          This calculator can also be just used to create good looking
          representations of 2D mechanical systems. In the Editor one can adjust
          the visuals for all mechanical symbols as well as their color. Some
          symbols are even provides for direct download. Try it yourself below:
          <BearingEditor></BearingEditor>
          Furthermore at every point in time it is possible to export the
          created system to a svg file. Before exporting you might want to
          rearrange measurements ore their annotations. Not wanted elements can
          also be completely hidden.{" "}
          {showLink && <Bold>Convinced? Try it out now:</Bold>}
        </Typography>
      ) : (
        <Typography>
          Der Rechner kann auch etwas zweckentfremdet verwendet werden um gut
          aussehende 2D Darstellungen von mechanischen Systemen zu erstellen. Im
          Editor kann man die Darstellung von allen Lagern und vielen Farben
          anpassen. Manche Lagersymbole kann man sogar direkt herunterladen.
          <BearingEditor></BearingEditor> Außerdem ist es möglich zu jeder Zeit
          das momentane System als SVG zu exportieren. Vor dem Export können
          gewisse Annotationen oder komplette Lasten versteckt werden.
          Zusätzlich wird nach jedem Schritt automatisch ein SVG des momentanen
          Systems exportiert. Diese SVG lassen sich in der Konsole
          herunterladen.
          {showLink && (
            <Bold> Starte jetzt mit deinem mechanischen System:</Bold>
          )}
        </Typography>
      )}
      {showLink && <LinkToCalculator></LinkToCalculator>}
    </div>
  );
}

import { Paper, Typography, makeStyles } from "@material-ui/core";
import { panelBackground } from "features/Components/StyleOnlyComponents/Color";
import { PendingTag } from "features/Components/StyleOnlyComponents/PendingTag";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  padding: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
    // boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.31)",
    background: panelBackground,
    position: "relative",
  },
  flex: {
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  pending: {
    position: "sticky",
    paddingTop: "5px",
    paddingBottom: "5px",
    display: "flex",
    justifyContent: "start",
  },
  pendingFont: {
    fontSize: "12px",
    color: "grey",
  },
});
export function SubmitWrapper(props: any) {
  const { t } = useTranslation();
  const classes = useStyles();
  const isPending = props.isPending ? props.isPending : false;
  return (
    <Paper elevation={2} square className={classes.padding}>
      <div
        className={classes.pending}
        style={{ visibility: isPending ? "visible" : "hidden" }}
      >
        <Typography className={classes.pendingFont}>
          {t("Save of")} <PendingTag></PendingTag> {t("to continue!")}
        </Typography>
      </div>{" "}
      <div className={classes.flex}>{props.children}</div>
    </Paper>
  );
}

import { useContext } from "react";
import { LoadTypeSelector } from "./LoadTypeSelector";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { AngleControlLoad } from "./AngleControlLoad";
import { PositionLoadControl } from "./PositionLoadControl";
import ToggleOrientation from "./ToggleOrientation";
import { SubmitFormLoads } from "../SubmitPending/SubmitFormLoads";
import { ColorControlLoad } from "./ColorControlLoad";
import { StreckenlastControl } from "./StreckenlastControl";
import SymbolForceMoment from "./SymbolForceMoment";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  fullWidth: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  height90: {
    boxSizing: "border-box",
    overflow: "scroll",
    padding: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "max-content",
    flexGrow: 1,
  },
  height10: { flexShrink: 0 },
});
export function LoadControl() {
  const classes = useStyles();
  const { pendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const pendingLoadType = pendingLoad?.loadType;
  const momentControl = [
    <SymbolForceMoment key={3} />,
    <ToggleOrientation key={1} />,
    <PositionLoadControl key={2} />,
  ];
  const forceControl = [
    <SymbolForceMoment key={6} />,
    <PositionLoadControl key={5} />,
    <AngleControlLoad key={4} />,
  ];
  const streckenlastControl = [<StreckenlastControl key={7} />];
  return (
    <div className={classes.fullWidth}>
      <div className={classes.height90}>
        {pendingLoadType === "force" && forceControl}
        {pendingLoadType === "moment" && momentControl}
        {pendingLoadType === "streckenlast" && streckenlastControl}
        <ColorControlLoad />
      </div>
      <div className={classes.height10}>
        <SubmitFormLoads />
      </div>
    </div>
  );
}

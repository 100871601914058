import {
  ConsoleContext,
  ConsoleContextType,
} from "features/Context/ConsoleContext";
import {
  FreischnittContext,
  FreischnittContextType,
} from "features/Context/FreischnittContext";
import {
  BalanceEquation,
  BalanceType,
} from "features/Context/types/FreischnittModel";
import { useContext } from "react";
import { EquationBundle } from "../LatexEquationVisuals/EquationBundle";
import {
  FakeRunContext,
  FakeRunContextType,
} from "features/Context/FakeRunContext";
import {
  MechanicalSolutionContext,
  MechanicalSolutionContextType,
} from "features/Context/MechanicalSolutionContext";
import { ErrorMsgSolution } from "features/Components/Functionality_Right/MechanicalSolutionMode/ErrorMsgSolution";
import { useTranslation } from "react-i18next";

var nerdamer = require("nerdamer");
// Load additional modules. These are not required.
require("nerdamer/Algebra");
require("nerdamer/Calculus");
require("nerdamer/Solve");
require("nerdamer/Extra");

export const useChangeToSolvingMechanicalProblem = (
  isFake: boolean = false
) => {
  const { t } = useTranslation();
  const { createBalancesForAllParts, freischnittLoads } = useContext(
    FreischnittContext
  ) as FreischnittContextType;
  const { addEntryToConsole } = useContext(
    ConsoleContext
  ) as ConsoleContextType;
  const { lastFakeRunCompleted, setLastFakeRunCompleted } = useContext(
    FakeRunContext
  ) as FakeRunContextType;
  const {
    setBalanceEquations,
    setSolutionEquations,
    setUnknwons,
    setCalculationError,
  } = useContext(MechanicalSolutionContext) as MechanicalSolutionContextType;
  const calculateProlem = () => {
    if (!freischnittLoads || Object.keys(freischnittLoads).length === 0) {
      console.log(
        "There are no Freischnitt loads yet. So we dont need to calculate the mechanical problem"
      );
      return;
    }

    const solveMechanicalProblem = () => {
      const balances = createBalancesForAllParts();
      if (!balances) {
        return;
      }
      let allBalances: Array<{
        eq: BalanceEquation;
        balanceType: BalanceType;
      }> = [];
      let allUnknowns: Set<string> = new Set();
      balances.forEach((balanceObj) => {
        allBalances.push({ eq: balanceObj.moment, balanceType: "moment" });
        allBalances.push({
          eq: balanceObj.forceHorizontal,
          balanceType: "horizontal",
        });
        allBalances.push({
          eq: balanceObj.forceVertical,
          balanceType: "vertical",
        });
        allUnknowns = new Set([
          ...allUnknowns,
          ...balanceObj.unknownReactionLoads,
        ]);
      });
      const balancesMapped = allBalances.map((balance) => {
        return { ...balance, eq: nerdamer(balance.eq).evaluate().toString() };
      });
      const numberOfUnknownsBeforeManipulation = [...allBalances].length;
      //manipulate equations to ensure solving success
      //by adding zero to each equation a the form of adding a variable, but also adding an equation that sets this variable to zero
      let allUnknownsManipulated = [...allUnknowns];
      let allBalancesManipulated = balancesMapped.map(
        (balanceObj) => balanceObj.eq
      );
      // [...allUnknowns].forEach((unkown, index) => {
      //   const newSymbol = "helperXXX" + index;
      //   allUnknownsManipulated.push(newSymbol);
      //   allBalancesManipulated[index] =
      //     allBalancesManipulated[index] + " + " + newSymbol;
      //   allBalancesManipulated.push(newSymbol + " = 0");
      // });
      if (!isFake) {
        const el = (
          <EquationBundle
            equations={[...balancesMapped]}
            header={t("Equations of Equilibrium")}
          />
        );
        // addEntryToConsole(el);
        setBalanceEquations([...balancesMapped]);
        const elUn = (
          <EquationBundle
            equations={[...allUnknowns]}
            header={t("Unknown Loads")}
            wrap={true}
          />
        );
        // addEntryToConsole(elUn);
        setUnknwons([...allUnknowns]);
      }
      try {
        nerdamer.flush();
        // console.log(allBalancesManipulated);
        var sol = nerdamer.solveEquations(allBalancesManipulated, [
          ...allUnknownsManipulated,
        ]);
        const solArr: Array<string> = sol.toString().split(","); //TODO latex conversion sucks
        let solEquations: Array<string> = [];
        for (let i = 0; i < solArr.length; i++) {
          if (i % 2 === 1) {
            solEquations.push(solArr[i - 1] + "= (" + solArr[i] + ")");
          }
        }
        if (!isFake) {
          //when not fake run print solution to console
          const solEl = (
            <EquationBundle
              equations={[...solEquations]}
              header={t("Reaction Loads")}
            />
          );
          setSolutionEquations([...solEquations]);
          // addEntryToConsole(solEl);
        } else {
          //The fake run SUCCEEDED (indicates a statically contrained system)
          setLastFakeRunCompleted((prev) => true);
          addEntryToConsole(
            <div>{t("The system is statically determinate. 🎉🥳")}</div>
          );
        }
      } catch (error) {
        if (!isFake) {
          setCalculationError(true);
          addEntryToConsole(<ErrorMsgSolution></ErrorMsgSolution>);
        }

        if (isFake) {
          //The fake run FAILED (indicates a NOT statically contrained system)
          setLastFakeRunCompleted((prev) => false);
          addEntryToConsole(
            <div>{t("The system is not (yet) statically determinate.")}</div>
          );
        }
      }
    };
    solveMechanicalProblem();
    return () => {
      //on delete
    };
  };
  return calculateProlem;
};

import { Link, Typography } from "@material-ui/core";
import { SubHeader } from "./Layout/SubHeader";
import { useTranslation } from "react-i18next";
export const issueLink =
  "https://github.com/DominikFle/ReactionLoadCalculator/tree/main";
export function CalculatorLimits() {
  const { t } = useTranslation();
  return (
    <div>
      <SubHeader> {t("Limitations and Issues")}</SubHeader>
      <Typography>
        {t(
          "Known limitations are the lack of computing power for systems with more then 4 beams."
        )}
      </Typography>
      <Typography>
        {t("Also currently symbolic angles are not supported.")}
      </Typography>
      <Typography>
        {t("Distributed loads only support 90° relative to beam.")}
      </Typography>
      <Typography>
        {t("Circular connection of beams (i.e. a truss) are not supported.")}
      </Typography>
      <Typography>
        {t("If you find any issue feel free to report them to the ")}
        <Link title="Github Issue Tracker" href={issueLink}>
          Issue Tracking
        </Link>
      </Typography>
    </div>
  );
}

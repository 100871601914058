import React, { useContext } from "react";
import { Volume } from "./Volume";

import { AddToTree } from "./AddToTree";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import { bearingTypes } from "../../../GlobalData/bearingTypes";
import { makeStyles } from "@material-ui/core";
import { mountingTreeStyles } from "./mountingTreeStyles";
import { ObjectIndex } from "features/Context/types/DrawenObjectModel";
import TreeElementVisual from "./TreeElementVisual";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import {
  PendingContext,
  PendingContextType,
} from "features/Context/PendingContext";
import { VolumePending } from "./VolumePending";
import { panelBackground } from "features/Components/StyleOnlyComponents/Color";
import { useTranslation } from "react-i18next";

//@ts-ignore
const useStyles1 = makeStyles(mountingTreeStyles);
const useStyles2 = makeStyles({
  overlay: {
    background: panelBackground,
    width: "5px",
    height: "4000px",
    position: "absolute",
    marginLeft: "-12px",
    marginTop: "-20px",
    borderTop: "solid 1px black",
  },
});
export function Connector({ index }: { index: ObjectIndex }) {
  const { i18n } = useTranslation();
  const { pendingObject } = useContext(PendingContext) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const { handleMouseEnter, handleMouseLeave } = useHighlight("bearing", index);
  const classes = useStyles1();
  const classesOverlay = useStyles2();
  const bearing = drawenObjects[index];
  if (!bearing) {
    return null;
  }
  const parent = drawenObjects[bearing.parentIndex];

  const connectorIsParentOfPendingObject =
    pendingObject && pendingObject.parentIndex === index;

  const tree = () => {
    if (connectorIsParentOfPendingObject) {
      return <VolumePending></VolumePending>;
    } else if (bearing.children[0] === 0 || bearing.children[0]) {
      const childIndex: ObjectIndex = bearing.children[0];
      // children kann nur 1 volumen sein
      return <Volume index={childIndex} />;

      //hier kann nichtmehr passieren da nur ein volume and bearing kann
    } else {
      const isInternalConnector =
        bearingTypes[bearing.type].connectorType === "intern";
      const isFirstBearing = parent?.index === "root";
      const hasPartAdder = isInternalConnector || isFirstBearing;
      return (
        <>
          {hasPartAdder && (
            <AddToTree type={"volumeAdder"} parentIndex={index} />
          )}
        </>
      );
    }
  };
  const isHighlighted = bearing.isHighlighted;
  const isLastBearingOnPart = parent?.children.slice(-1)[0] === bearing.index;
  var connectorType = drawenObjects[index].type;
  const bearinTypeName =
    i18n.language === "en"
      ? bearingTypes[connectorType].nameEn
      : bearingTypes[connectorType].nameDe;

  var ConnectorName = bearinTypeName + "  " + bearing.bearingPositionName;

  return (
    <>
      <div className={classes.tabbedRightBearing}>
        <div
          className={classes.tabbedBorderBearing}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <div className={classes.bearingLine} />
          <TreeElementVisual
            index={index}
            type={connectorType}
            text={ConnectorName}
            isHighlighted={isHighlighted}
          />
          {isLastBearingOnPart && (
            <div className={classesOverlay.overlay}></div>
          )}
        </div>
        {tree()}
      </div>
    </>
  );
}

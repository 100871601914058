import { useState, createContext } from "react";

export const ColorContext = createContext<ColorContextType | null>(null);
export type ColorContextType = {
  lastUsedColors: Array<string>;
  handleNewColor: (newColor: string) => void;
};
export function ColorProvider(props: any) {
  const [lastUsedColors, setLastUsedColors] = useState([
    "rgba(0,0,0,1)",
    "rgba(10,10,10,1)",
    "rgba(20,20,20,1)",
    "rgba(40,40,40,1)",
    "rgba(60,60,60,1)",
    "rgba(80,80,80,1)",
    "rgba(100,100,100,1)",
    "rgba(120,120,120,1)",
  ]);
  const handleNewColor = (color: string) => {
    setLastUsedColors((prev) => {
      const ind = prev.indexOf(color);
      let newColorArr = [...prev];
      if (ind >= 0) {
        newColorArr.splice(ind, 1);
      } else {
        newColorArr.pop();
      }
      newColorArr = [color, ...newColorArr];
      newColorArr = [...new Set(newColorArr)];
      let newLength = newColorArr.length;
      const maxNumberOfColors = 8;
      while (newLength < maxNumberOfColors) {
        newColorArr.push("black");
        newLength = newColorArr.length;
      }
      return newColorArr;
    });
  };

  return (
    <ColorContext.Provider
      value={{
        lastUsedColors,
        handleNewColor,
      }}
    >
      {props.children}
    </ColorContext.Provider>
  );
}

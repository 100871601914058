import { BearingType } from "features/Context/types/ConstructionElementModel";
import { DrawenObject } from "features/Context/types/DrawenObjectModel";
import ElongationConnector from "./schweissverbindungUtil/ElongationConnector";
import ShapeDecider from "./schweissverbindungUtil/ShapeDecider";

export function Schweissverbindung({
  x = 0,
  y = 0,
  xCenter = 0,
  yCenter = 0,
  strokeWidth = 3,
  strokeColor = "black",
  width = 100,
  angle = 0,
  child = false,
  parent,
  groupRef,
  bodyColor = "white",
  measureToCenter = false,
  scale = 100,
  setCenter = false,
}: {
  x: number;
  y: number;
  xCenter?: number;
  yCenter?: number;
  strokeWidth?: number;
  strokeColor?: string;
  width: number;
  angle: number;
  child?: false | DrawenObject;
  parent?: false | DrawenObject;
  groupRef: React.MutableRefObject<any>;
  bodyColor?: string;
  measureToCenter?: boolean;
  scale?: number;
  setCenter?: false | ((x: number, y: number, name: BearingType) => void);
}) {
  let shapeDecider;
  let showPlaceHolder = true;
  if (parent) {
    //@ts-ignore
    const thicknessParent = parent.size.height;
    if (child && child.size) {
      showPlaceHolder = false;
      //@ts-ignore
      const thicknessChild = child.size.height;

      let biggerPart = thicknessChild > thicknessParent ? child : parent;
      let smallerPart = thicknessChild < thicknessParent ? child : parent;
      if (thicknessChild === thicknessParent) {
        biggerPart = parent;
        smallerPart = child;
      }
      const angleOfSmaller = smallerPart.angle;
      const angleOfBigger = biggerPart.angle;
      const angleDelta = angleOfBigger - angleOfSmaller;
      const thresholdAngle = 20;
      if (
        Math.abs(angleDelta % 180) < thresholdAngle ||
        Math.abs(angleDelta % 180) > 180 - thresholdAngle
      ) {
        //case that angle are very similar
        shapeDecider = (
          <ElongationConnector
            schweissverbindungPoint={[x, y]}
            {...{
              biggerPart,
              smallerPart,
              strokeWidth,
              strokeColor,
            }}
          />
        );
      } else {
        shapeDecider = (
          <ShapeDecider
            {...{
              child,
              parent,
              biggerPart,
              smallerPart,
              strokeWidth,
              strokeColor,
            }}
          />
        );
      }
    }
  }

  const xCenterCond = xCenter + width / 2;
  const yCenterCond = yCenter + width / 2;
  const dezScale = scale / 100;

  let xTransLate;
  let yTransLate;
  if (measureToCenter) {
    xTransLate = x - dezScale * xCenterCond;
    yTransLate = y - dezScale * yCenterCond;
  } else {
    xTransLate = x;
    yTransLate = y;
  }
  return (
    <>
      <g
        ref={groupRef}
        id="LosLager"
        transform={`  translate(${xTransLate},${yTransLate}) scale(${dezScale})  rotate(${angle} ${xCenterCond} ${yCenterCond} )`}
      >
        {showPlaceHolder && (
          <polygon
            id="hoverHelper"
            points={
              "0,0 0," + width + " " + width + "," + width + " " + width + ",0"
            }
            fill={bodyColor}
            stroke={strokeColor}
            strokeWidth={strokeWidth}
          />
        )}
      </g>
      <g id="schnittpunkte">
        {/* {intersectMarks}{" "} */}

        {/* <polygon
          points={makePolygonString(outers)}
          fill={"white"}
          stroke={"white"}
          strokeWidth={strokeWidth}
        /> */}
        {shapeDecider}
      </g>
    </>
  );
}

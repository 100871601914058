import React, { useState } from "react";
import { makeStyles, Slider as MuiSlider, TextField } from "@material-ui/core";
import useMarkSnapper from "../util/useMarkSnapper";
import { Mark } from "../util/MarkModel";
const useStyles = (props: any) =>
  makeStyles((theme) => ({
    input: { width: "50px" },
    sliderWrapper: {
      paddingTop: "15px",
      paddingBottom: "15px",
      height: props.height + "px",
    },
    numberSliderWrapper: {
      display: "flex",
      flexDirection: "column",
    },
    alignLeft: {
      alignItems: "flex-start",
    },
    alignRight: {
      alignItems: "flex-end",
    },
    inputEl: {
      background: "white",
      width: "50px",
    },
  }));
export function VerticalSlider({
  step = 1,
  handleChange,
  description = "",
  min,
  max,
  value,
  marks = [],
  ownMarks = false,
  height = 100,
  align = "left",
  track = false,
}: {
  step?: number;
  handleChange: Function;
  description?: string | JSX.Element;
  min: number;
  max: number;
  value: number;
  marks?: Array<Mark>;
  ownMarks?: boolean;
  height?: number;
  align?: string;
  track?: boolean;
}) {
  const classes = useStyles({ height, align })();

  const [input, setInput] = useState<number | string>(1); //handle empty input
  const marksCustom = ownMarks
    ? marks
    : marks.concat([
        { value: min, label: String(Math.round(min)) },
        { value: max, label: String(Math.round(max)) },
      ]);
  const [snapConvert, setLastCommitted] = useMarkSnapper(marksCustom, 10);

  const handleChangeSlider = (
    e: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) => {
    if (!Array.isArray(newVal)) {
      handleChange(snapConvert(newVal));
    }
  };
  const handleChangeText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    newVal: string
  ) => {
    if (!isNaN(parseInt(newVal)) && newVal) {
      // check if input is number
      const realNewValue = parseInt(newVal);

      handleChange(realNewValue);

      setInput(1);
    } else {
      setInput("");
    }
  };
  const handleChangeCommitted = (
    e: React.ChangeEvent<{}>,
    newVal: number | number[]
  ) => {
    if (!Array.isArray(newVal)) {
      const snapConvertedNum = snapConvert(newVal);
      if (snapConvertedNum === undefined) {
        return;
      }
      setLastCommitted(snapConvertedNum);
    }
  };

  return (
    <div className={"sliderContainer"}>
      {description && (
        <p className={" sliderDescription sliderDescription"}>{description} </p>
      )}
      <div
        className={`${classes.numberSliderWrapper} ${
          align === "left" ? classes.alignLeft : classes.alignRight
        }`}
      >
        <div className={classes.input}>
          <TextField
            type="number"
            onChange={(e) => handleChangeText(e, e.target.value)}
            className={"numberInput numberInput"}
            inputProps={{
              className: classes.inputEl,
              min: min,
              step: step,
              max: max,
            }}
            value={!input || Math.round(value)}
          />
        </div>

        <div className={classes.sliderWrapper}>
          <MuiSlider
            orientation="vertical"
            onChangeCommitted={(e, newVal) => handleChangeCommitted(e, newVal)}
            onChange={(e, newVal) => handleChangeSlider(e, newVal)}
            min={min}
            step={step}
            max={max}
            marks={marksCustom}
            value={Math.round(value)}
            track={track as any}
          />
        </div>
      </div>
    </div>
  );
}

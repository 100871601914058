import { Paper, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import calculatorImg from "./imgs/calculator.png";
import {
  primaryColor,
  primaryColorDark,
} from "features/Components/StyleOnlyComponents/Color";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { calculatorRouter } from "index";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
    margin: "5px",
    overflow: "hidden",
    border: "3px solid " + primaryColor,
    "&:hover": {
      border: "3px solid " + primaryColorDark,
    },
  },
  imgWidth: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  overlay: {
    position: "absolute",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    background: "rgba(255,255,255,0.6)",
    color: primaryColor,

    "&:hover": {
      background: "rgba(0,0,0,0.6)",
      color: "white",
    },
  },
  linkFont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  linkTitle: {
    fontSize: "20px",
    fontWeight: "bold",
  },
}));

export function LinkToCalculator() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Paper elevation={2} className={classes.container}>
      <Link to={calculatorRouter} title={t("Reaction Calculator")}>
        <div className={classes.overlay}>
          <Typography align="center" className={classes.linkFont}>
            <ArrowForwardIcon></ArrowForwardIcon>
            <span className={classes.linkTitle}>
              {t("Reaction Calculator")}
            </span>
          </Typography>
        </div>
        <img
          className={classes.imgWidth}
          src={calculatorImg}
          alt={t("Reaction Calculator")}
          title={t("Reaction Calculator")}
        ></img>
      </Link>
    </Paper>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { convertToLatex } from "features/Components/LatexUtils/convertToLatex";
import {
  lSubEnd,
  lSubStart,
} from "features/Components/LatexUtils/latexParsingHelp";
import LatexForHTML from "features/Components/Playground/helper/LatexForHTML";
import { roundIfSmall } from "features/LinearAlgebra/roundIfSmall";
import DeleteIcon from "@material-ui/icons/Delete";
import { useContext } from "react";
import { Tag } from "features/Components/StyleOnlyComponents/Tag";
import SymbolAndMagnitude from "features/Components/Functionality_Right/LoadMode/util/SymbolAndMagnitude";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import { useUnits } from "features/Components/Functionality_Right/LoadMode/util/useUnits";
import { Load, getLoadMainSymbol } from "features/Context/types/LoadModel";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { ForceIcon } from "../icons/ForceIcon";
import { MomentIcon } from "../icons/MomentIcon";
import { StreckenlastIcon } from "../icons/StreckenlastIcon";
import { getLatexHeightFactor } from "features/Components/Functionality_Bottom/LatexEquationVisuals/getLatexHeightFactor";
import { preFactorToLatex } from "features/Components/Playground/helper/util/preFactorToLatex";
import {
  accordionRootBackground,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { PendingTag } from "features/Components/StyleOnlyComponents/PendingTag";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px",
    paddingBottom: "5px",
    paddingTop: "10px",
    margin: "0px",
    background: accordionRootBackground,
  },
  fullWidth: {
    width: "100%",
  },
  flex: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  flexBetween: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  verticalFlex: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  smallPad: {
    padding: "0px",
    minHeight: "20px",
    overflow: "hidden",
  },
  latex: {
    padding: "10px",
  },
  leftPad: {
    paddingLeft: "10px",
  },
  smallMargin: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  expanded: {
    marginTop: "0px",
    marginBottom: "0px",
    padding: "0px",
    minHeight: "20px",
    overflow: "hidden",
  },
  negMargin: {
    marginTop: "-15px",
    marginBottom: "-15px",
    marginLeft: "12px",
    marginRight: "0px",
    height: "24px",
    width: "24px",
    overflow: "hidden",
    position: "relative",
  },
  tagPlaceholder: {
    marginRight: "5px",
  },
  widthLatex: {},
  widthIcon: {
    paddingLeft: "15px",
  },
  rotate: {
    transform: "rotate(90deg)",
  },
}));
export function LoadExplorerControl({ load }: { load: Load }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    setIsHiddenByUser,
    setLoadMagnitude,
    setLoadUnit,
    setLoadIsSymbolic,
    setLoadName,
    setLoadPreFactor,
    deleteLoad,
    pendingLoad,
  } = useContext(PendingLoadContext) as PendingLoadContextType;
  const { status } = useContext(StatusContext) as StatusContextType;
  const { handleMouseEnter, handleMouseLeave } = useHighlight(
    "load",
    load.index
  );

  const [unitOptions, unitFactor] = useUnits(load.loadType);
  if (load.isFake || !load.loadTypeWasSelected) {
    return null;
  }
  const mainSymbol = getLoadMainSymbol(load);
  const isHighlighted = load.isHighlighted;
  const isStreckenlast = load.loadType === "streckenlast";
  const symbolicString = mainSymbol + lSubStart + load.name + lSubEnd;
  const numericLength = load.magnitude;
  const sign = Math.sign(
    (numericLength || numericLength === 0) && !isNaN(numericLength)
      ? numericLength
      : 1
  );
  const unsignedLength = roundIfSmall(numericLength / sign, 0.000001);
  const numericLengthWithUnit = unsignedLength + "" + load.loadSpec.unitSymbol;
  const measureString = load.isSymbolic
    ? symbolicString
    : numericLengthWithUnit;
  const latexConverted = convertToLatex(measureString);
  let latex = load.isSymbolic
    ? preFactorToLatex(load.preFactor)[0] + latexConverted
    : latexConverted;
  if (isStreckenlast) {
    //create latex out of both substrings
    latex = "\\text{" + t("Dist") + "}";
  }
  const handleMagnitudeChange = (number: string) => {
    setLoadMagnitude(load.index, sign * parseFloat(number));
  };
  const handleUnitChange = (unit: any) => {
    setLoadUnit(load.index, unit, unitFactor(unit));
  };
  const handleShowMeasureChange = (newShowMeasure: boolean) => {
    setIsHiddenByUser(load.index, !newShowMeasure);
  };
  const handleSuffixChange = (newSuffix: string) => {
    setLoadName(load.index, newSuffix);
  };
  const handlePrefactorChange = (newPrefactor: string) => {
    setLoadPreFactor(load.index, newPrefactor);
  };
  const handleSymbolicChange = (newIsSymbolic: boolean) => {
    setLoadIsSymbolic(load.index, newIsSymbolic);
  };
  const handleOnDeleteClick = () => {
    deleteLoad(load.index);
  };
  const loadInterActionIsDisabled = status.status !== "load";
  let iconEl;
  const iconColor = isHighlighted ? primaryColor : "black";
  if (load.loadType === "force") {
    iconEl = <ForceIcon color={iconColor}></ForceIcon>;
  } else if (load.loadType === "moment") {
    iconEl = <MomentIcon color={iconColor}></MomentIcon>;
  } else {
    iconEl = <StreckenlastIcon color={iconColor}></StreckenlastIcon>;
  }
  const itIsThePendingLoad = pendingLoad?.index === load.index;
  const latexHeight = getLatexHeightFactor(latex) * 15;
  return (
    <div
      className={classes.fullWidth}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <Accordion
        square
        elevation={0}
        className={classes.fullWidth}
        classes={{
          root: classes.smallPad,
          expanded: classes.expanded,
        }}
      >
        <AccordionSummary
          classes={{
            root: classes.smallPad, // class name, e.g. `classes-nesting-root-x`
            expanded: classes.expanded,

            content: classes.smallMargin,
          }}
          expandIcon={
            <div className={classes.negMargin}>
              <ExpandMoreIcon />
            </div>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.fullWidth + " " + classes.flex}>
            <div className={classes.widthIcon}>{iconEl}</div>

            <div className={classes.latex + " " + classes.widthLatex}>
              {!isStreckenlast && (
                <LatexForHTML height={latexHeight} latex={latex}></LatexForHTML>
              )}
              {isStreckenlast && <Typography>Dist.</Typography>}
            </div>
            <div className={classes.tagPlaceholder}>
              {load.isHiddenByUser && <Tag text={t("HIDDEN")}></Tag>}
              {load.isPending && <PendingTag></PendingTag>}
            </div>
            {/* {isHighlighted && (
              <HighlightIcon
                className={classes.rotate}
                color="primary"
              ></HighlightIcon>
            )} */}
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
          }}
        >
          <div className={classes.verticalFlex}>
            <div className={classes.leftPad}>
              {!isStreckenlast && (
                <SymbolAndMagnitude
                  disabled={loadInterActionIsDisabled}
                  handleNameChange={handleSuffixChange}
                  handlePreFactorChange={handlePrefactorChange}
                  handleMagnitudeChange={handleMagnitudeChange}
                  handleUnitChange={handleUnitChange}
                  setSymbolic={handleSymbolicChange}
                  symbolSuffix={String(load.name)}
                  isSymbolic={load.isSymbolic}
                  symbolName={mainSymbol}
                  unit={load.loadSpec.unitSymbol}
                  unitFactor={load.loadSpec.unitFactor}
                  magnitude={load.magnitude}
                  unitOption={unitOptions}
                  height={20}
                  preFactor={load.preFactor}
                  elevation={0}
                  paperBackground={accordionRootBackground}
                  // font: number
                  // padding: number
                  thin={false}
                ></SymbolAndMagnitude>
              )}
              {isStreckenlast && (
                <Typography>
                  {t(
                    "Please remove and reapply the Distributed Load to reconfigure it."
                  )}
                </Typography>
              )}
            </div>

            <div className={classes.flexBetween}>
              <div className={classes.flex}>
                <Checkbox
                  checked={!load.isHiddenByUser}
                  onChange={(e, checked) => handleShowMeasureChange(checked)}
                  color="primary"
                ></Checkbox>
                <Typography>{t("Show Load")}</Typography>
              </div>
              <IconButton
                disabled={loadInterActionIsDisabled || itIsThePendingLoad}
                color="primary"
                onClick={() => handleOnDeleteClick()}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

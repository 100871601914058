import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "Download SVG": "Download SVG",
      "Height Hatching": "Height Hatching",
      Angle: "Angle",
      "Angle of Hatching": "Angle of Hatching",
      StrokeWidth: "StrokeWidth",
      "Stroke Color": "Stroke Color",
      "Diameter Joint": "Diameter Joint",
      "Height Center of Joint": "Height Center of Joint",
      "Attachment Points on Joint": "Attachment Points on Joint",
      "Show Hatching": "Show Hatching",
      "Show Only Joint": "Show Only Joint",
      "Width Balls": "Width Balls",
      "Diameter Balls": "Diameter Balls",
      "Show Balls": "Show Balls",
      "Joint Fill Color": "Joint Fill Color",
      "Bearing Body Color": "Support Body Color",
      "Bearing Balls Color": "Support Balls Color",
      "Show Hatching Bottom": "Show Hatching Bottom",
      "Show Hatching Top": "Show Hatching Top",
      "Quick Download": "Quick Download",
      "Fixed Support": "Fixed Support",
      "Roller Support": "Roller Support",
      "Pinned Support": "Pinned Support",
      "Sliding Sleeve": "Sliding Sleeve",
      Size: "Size",
      Color: "Color",
      Overview: "Overview",
      "CUSTOMIZE BEARING": "CUSTOMIZE SUPPORT",
      "Bearings are hidden.": "Supports are hidden.",
      Console: "Console",
      "Equations of Equilibrium": "Equations of Equilibrium",
      "Unknown Loads": "Unknown Reactions",
      "Reaction Loads": "Reactions",
      "The system is not (yet) statically determinate.":
        "The system is not (yet) statically determinate.",
      "The system is statically determinate. 🎉🥳":
        "The system is statically determinate. 🎉🥳",
      Zooming: "Zooming",
      Paning: "Paning",
      "Customize element styles": "Customize element styles",
      "Proceed to:": "Proceed to:",
      "Finish the configuration of the current object to go to the next stage!":
        "Finish the configuration of the current object to go to the next stage!",
      "The system must be statically determinate to go to the next stage!":
        "The system must be statically determinate to go to the next stage!",
      "Finish the pending Load before continuing":
        "Finish the pending Load before continuing",
      "Add at least one load to the system! \n You can add loads on the left pane.":
        "Add at least one load to the system! \n You can add loads on the left pane.",
      "Angle Measurement": "Angle Measurement",
      HIDDEN: "HIDDEN",
      "Show Measure": "Show Measure",
      "There are no measurements here yet. Measurements will be created in the Measurements Stage.":
        "There are no measurements here yet. Measurements will be created in the Measurements Stage.",
      "Finish the current element before adding another one!":
        "Finish the current element before adding another one!",
      "add element": "Add element",
      Dist: "Dist",
      "Please remove and reapply the Distributed Load to reconfigure it.":
        "Please remove and reapply the Distributed Load to reconfigure it.",
      "Show Load": "Show Load",
      "Remove Element": "Remove Element",
      "Show more Information": "Show more Information",
      "Eine Ebene nach oben": "Move one layer up",
      "Eine Ebene nach unten": "Move one layer down",
      "Nach ganz oben": "Move to the top",
      "Nach ganz unten": "Move to the bottom",
      "Show Coordinate System": "Show Coordinate System",
      "Hide Coordinate System": "Hide Coordinate System",
      Load: "Load",
      "System Root": "System Root",
      Beam: "Beam",
      "Mechanical System": "Mechanical System",
      Measurements: "Measurements",
      "Switch between symbolic and numeric":
        "Switch between symbolic and numeric",
      "Angle is relative to:": "Angle is relative to:",
      "Load Angle": "Load Angle",
      "Load Color": "Load Color",
      "Side Mesasure Color": "Side Mesasure Color",
      "Font Color": "Font Color",
      Cancel: "Cancel",
      Force: "Point Load",
      Moment: "Moment",
      "Distributed Load": "Distributed Load",
      Support: "Support",
      Magnitude: "Magnitude",
      "Something went wrong solving the balance Equations. Please report the issue on the":
        "Something went wrong solving the balance Equations. Please report the issue on the",
      "Equation Of Equilibrium": "Equation Of Equilibrium",
      "Unknown Reaction Loads": "Unknown Reactions",
      "Save Element": "Save Element",
      "Save Pending": "Save Pending",
      "Delete Pending": "Delete Pending",
      "Elements are properly mounted.": "Elements are properly mounted.",
      "Elements are not properly mounted.":
        "Elements are not properly mounted.",
      "Save of": "Save",
      "to continue!": "to continue!",
      "Position Support": "Position Support",
      "Size Beam": "Visual Size Beam",
      "Position of the Support": "Position of the Support",
      "External Connectors": "Support connecting with system border",
      "Internal Connectors": "Support connecting two beams",
      "Save Me": "Save Me",
      "Save Pending to continue!": "Save Pending to continue!",
      Pending: "Pending",
      " Stage:": " Stage:",
      System: "System",
      "Please compose a system out of supports and beams. On the left you can add the elements.":
        "Please compose a system out of supports and beams. On the left you can add the elements.",
      Loads: "Loads",
      "Please add loads to your system. You can add them to each beam in the tree on the left.":
        "Please add loads to your system. You can add them to each beam in the tree on the left.",
      MeasurementsStage: "Measurements",
      "In this stage you can rename, hide or rescale measurements. Also consider to switch between symbolic and numeric measurements.":
        "In this stage you can rename, hide or rescale measurements. Also consider to switch between symbolic and numeric measurements.",
      "Free Body Diagram": "Free Body Diagram",
      "The free body diagram of the system was created. Switch on the bearing visualization with the Checkbox below. Finally you can go to the next stage to calculate the reaction forces.":
        "The free body diagram of the system was created. Switch on the bearing visualization with the Checkbox below. Finally you can go to the next stage to calculate the reaction forces.",
      "Finally there is the solution!": "Finally there is the solution🥳🥳🥳!",
      "Symbol Scale": "Visual Scale of Support",
      "Set to last used value: ": "Set to last used value: ",
      Width: "Length",
      Height: "Breadth",
      "Load Position": "Load Position",
      "Export the drawen elements as svg image.":
        "Export the drawen elements as svg image.",
      Orientation: "Orientation",
      "This page is not available. Go back to":
        "This page is not available. Go back to",
      Calculator: "Calculator",
      "Roller and Pinned Support": "Roller and Pinned Support",
      "System with fixed support": "System with fixed support",
      "Complex system with hinge": "Complex system with hinge",
      "1. Mechanical system": "1. Mechanical system",
      "2. Free body diagram": "2. Free body diagram",
      "3. Equations of Equilibrium": "3. Equations of Equilibrium",
      "4. Suppport reaction solution": "4. Suppport reaction solution",
      "Switch between symbolic and numeric length":
        "Switch between symbolic and numeric length",
      "Switch between symbolic and numeric moment":
        "Switch between symbolic and numeric moment",
      "This calculator is designed to find the support reactions of mechanical systems. The novelty of this calculator is that it can work with":
        "This calculator is designed to find the support reactions of mechanical systems. The novelty of this calculator is that it can work with",
      " symbolical loads": " symbolical loads",
      and: "and",
      lengths: "lengths",
      ". In addition numerical calculations are supported. Just try it out yourself and create and calculate your first structure now!":
        ". In addition numerical calculations are supported. Just try it out yourself and create and calculate your first structure now!",
      "Limitations and Issues": "Limitations and Issues",
      "Known limitations are the lack of computing power for systems with more then 4 beams.":
        "Known limitations are the lack of computing power for systems with more then 4 beams.",
      "Also currently symbolic angles are not supported.":
        "Also currently symbolic angles are not supported.",
      "Distributed loads only support 90° relative to beam.":
        "Distributed loads only support 90° relative to beam.",
      "Circular connection of beams (i.e. a truss) are not supported.":
        "Circular connection of beams (i.e. a truss) are not supported.",
      "If you find any issue feel free to report them to the ":
        "If you find any issue feel free to report them to the ",
      "Reaction Calculator": "Reaction Calculator",
      "Quick Start": "Quick Start",
      "Capabilities of the Calculator": "Capabilities of the Calculator",
      "Limitations of the calculator": "Limitations of the calculator",
      "Stages to Solution": "Stages to Solution",
      "Symbolical Solving": "Symbolical Solving",
      "Checking for Statical Determinacy": "Checking for Statical Determinacy",
      "Creating Visuals": "Creating Visuals",
      "The calculator is not designed for small devices.":
        "The calculator is not designed for small devices.",
      "Please come back with your laptop!":
        "Please come back with your laptop!",
        "title":"Support Reaction Calculator",
        "Symbolical Reaction Calculator for Mechanical Systems.":"Symbolical Reaction Calculator for Mechanical Systems.",

    },
  },
  de: {
    translation: {
      "Symbolical Reaction Calculator for Mechanical Systems.":"Symbolischer Auflagerreaktionen Rechner Online. Bestimme die Auflagerreaktionen von mechanischen Systemen.",
      "title":"Auflagerreaktionen Rechner",
      "Please come back with your laptop!": "Bitte wechsle auf dein Laptop!",
      "The calculator is not designed for small devices.":
        "Der Rechner is auf größere bildschrime ausgelegt.",
      "Creating Visuals":
        "2D Darstellungen von mechanischen Systemen erstellen",
      "Checking for Statical Determinacy":
        "Überprüfung der statischen Bestimmtheit",
      "Symbolical Solving": "Symbolisches Lösen",
      "Stages to Solution": "Schritte bis zur Lösung",
      "Limitations of the calculator": "Grenzen des Rechners",
      "Capabilities of the Calculator": "Umfang des Auflagerreaktionen Rechners",
      "Quick Start": "Direkteinstieg",
      "Reaction Calculator": "Lagerreaktionsrechner",
      "If you find any issue feel free to report them to the ":
        "Gerne sind Verbesserungsvorschläge willkommen. Nutze hierzu das ",
      "Circular connection of beams (i.e. a truss) are not supported.":
        "Fachwerke und ähnliche Konstruktionen bei denen Balken zirkulär miteinander verbunden sind werden nicht unterstützt.",
      "Distributed loads only support 90° relative to beam.":
        "Streckenlasten müssen 90° relativ zum Balken stehen.",
      "Also currently symbolic angles are not supported.":
        "Symbolische Winkel sind momentan nicht verfügbar.",
      "Known limitations are the lack of computing power for systems with more then 4 beams.":
        "Ab einer Anzahl von vier Balken benötigt die symbolische Kalkulation der Lagerreaktionen viel Ressourcen.",
      "Limitations and Issues": "Grenzen des Rechners",
      ". In addition numerical calculations are supported. Just try it out yourself and create and calculate your first structure now!":
        " Zusätzlich werden auch Berechnungen mit numerischen Größen unterstützt. Erstelle jetzt dein erstes mechanisches System!",
      lengths: "Längenmaßen",
      and: "und",
      " symbolical loads": " symbolischer Lasten",
      "This calculator is designed to find the support reactions of mechanical systems. The novelty of this calculator is that it can work with":
        "Der Rechner ist darauf ausgelegt Auflagerreaktionen eines mechanischen Systems in der Statik zu bestimmen. Die Neuerung dabei ist die Verwendung von",
      "Switch between symbolic and numeric moment":
        "Wechsel zwischen symbolischen und numerischen Lasten",
      "Switch between symbolic and numeric length":
        "Wechsel zwischen symbolischen und numerischen Bemaßungen",
      "4. Suppport reaction solution": "4. Lösung der Auflagerreaktionen",
      "3. Equations of Equilibrium": "3. Gleichgewichtsbedingungen",
      "2. Free body diagram": "2. Freischnitt",
      "1. Mechanical system": "1. Mechanisches System",
      "Complex system with hinge": "System mit Gelenk und zwei Balken",
      "System with fixed support": "System mit Einspannung",
      "Roller and Pinned Support": "Fest-Loslagerung",
      Calculator: "Auflagerreaktion Rechner",
      "This page is not available. Go back to":
        "Diese Seite ist nicht verfügbar. Zurück zu",
      Orientation: "Orientierung",
      "Export the drawen elements as svg image.":
        "Exportiere das System als SVG!",
      "Load Position": "Position Last",
      Width: "Länge",
      Height: "Breite",
      "Set to last used value: ": "Zurücksetzen zum letzen Wert: ",
      "Symbol Scale": "Skalierung des Lagersymbols",
      "Finally there is the solution!": "Hier ist endlich die Lösung🥳🥳🥳.",
      "The free body diagram of the system was created. Switch on the bearing visualization with the Checkbox below. Finally you can go to the next stage to calculate the reaction forces.":
        " Der Freischnitt des Systems wurde erstellt. Nun können die Lagerreaktionen berechnet werden!",
      "Free Body Diagram": "Freischnitt",
      "In this stage you can rename, hide or rescale measurements. Also consider to switch between symbolic and numeric measurements.":
        "Im Bemaßungsschritt können Maße angepasst und umbenannt werden. Außerdem kann zwischen numerischer und symbolischer Bemaßung entschieden werden.",
      MeasurementsStage: "Bemaßung",
      "Please add loads to your system. You can add them to each beam in the tree on the left.":
        "Füge Lasten hinzu. Die Lasten können im Explorer links dem Balken beaufschlagt werden.",
      Loads: "Belastung",
      "Please compose a system out of supports and beams. On the left you can add the elements.":
        "Stelle ein System aus Lagern und Balken zusammen. Links können die Elemente hinzugefügt werden.",
      System: "System",
      " Stage:": " Schritt:",
      Pending: "Ausstehend",
      "Save Pending to continue!":
        "Speichern Sie das ausstehendes Element um forzufahren!",
      "Save Me": "Speichern",
      "Internal Connectors": "Lager um Balken zu verbinden",
      "External Connectors": "Lager an Systemgrenze",
      "Position of the Support": "Position des Lagers",
      "Size Beam": "Visuelle Länge des Balken",
      "Position Support": "Position Lager",
      "to continue!": "Element um fortzufahren",
      "Save of": "Speichere das",
      "Elements are not properly mounted.":
        "Elemente sind nicht korrekt verbunden.",
      "Elements are properly mounted.": "Elemente sind korrekt verbunden",
      "Delete Pending": "Verwerfen",
      "Save Pending": "Speichern",
      "Save Element": "Element speichern",
      "Unknown Reaction Loads": "Unbekannte Auflagerreaktionen",
      "Equation Of Equilibrium": "Gleichgewichtsbedingungen",
      "Something went wrong solving the balance Equations. Please report the issue on the":
        "Etwas ist schief gelaufen. Für Verbesserungsvorschläge oder Fehlerreports nutze den ",
      Magnitude: "Größe",
      Support: "Lager",
      "Distributed Load": "Streckenlast",
      Moment: "Drehmoment",
      Force: "Kraft",
      Cancel: "Abbrechen",
      "Font Color": "Schriftfarbe",
      "Side Mesasure Color": "Farbe Seitenmaß",
      "Load Color": "Farbe Last",
      "Load Angle": "Winkel der Last",
      "Angle is relative to:": "Winkel ist relativ zu:",
      "Switch between symbolic and numeric":
        "Wechsel zwischen symbolischer und numerischer Darstellung",
      Measurements: "Bemaßungen",
      "Mechanical System": "Mechanisches System",
      Beam: "Balken",
      "System Root": "System Grenze",
      Load: "Last",
      "Hide Coordinate System": "Koordinatensystem verstecken",
      "Show Coordinate System": "Koordinatensystem anzeigen",
      "Nach ganz unten": "Nach ganz unten",
      "Nach ganz oben": "Nach ganz oben",
      "Eine Ebene nach unten": "Eine Ebene nach unten",
      "Eine Ebene nach oben": "Eine Ebene nach oben",
      "Show more Information": "Mehr anzeigen",
      "Remove Element": "Element entfernen",
      "Show Load": "Last anzeigen",
      "Please remove and reapply the Distributed Load to reconfigure it.":
        "Bitte entferne die Streckenlast und füge eine neue Streckenlast hinzu, um Änderungen vorzunehmen.",
      Dist: "Streckenl.",
      "add element": "Element hinzufügen",
      "Finish the current element before adding another one!":
        "Schließe das ausstehende Element ab um ein weiteres hinzuzufügen.",
      "There are no measurements here yet. Measurements will be created in the Measurements Stage.":
        "Momentan gibt es hier noch keine Maße. Die Maße werden im Bemaßungsschritt erstellt.",
      "Show Measure": "Maß anzeigen",
      HIDDEN: "VERSTECKT",
      "Angle Measurement": "Winkel Maß",
      "Add at least one load to the system! \n You can add loads on the left pane.":
        "Füge mindestens eine Belastung hinzu. \nLasten können links im Explorer hinzugefügt werden.",
      "Finish the pending Load before continuing":
        "Schließe die konfiguration der ausstehenden Belastung ab, um weiter zu gehen!",
      "The system must be statically determinate to go to the next stage!":
        "Das System muss statisch bestimmt sein um zum nächsten Schritt zu gehen.",
      "Finish the configuration of the current object to go to the next stage!":
        "Beende zuerst die Konfiguration des ausstehenden Elements!",
      "Proceed to:": "Weiter zu:",
      "Customize element styles": "Element Darstellung anpassen",
      Paning: "Verschieben",
      Zooming: "Zoomen",
      "The system is statically determinate. 🎉🥳":
        "Das System ist statisch bestimmt. 🎉🥳",
      "The system is not (yet) statically determinate.":
        "Das System ist (noch) nicht statisch bestimmt.",
      "Reaction Loads": "Lagerreaktionen",
      "Unknown Loads": "Unbekannte Lagerreaktionen",
      "Equations of Equilibrium": "Gleichgewichtsbedingungen",
      Console: "Konsole",
      "Bearings are hidden.": "Lager sind versteckt.",
      "CUSTOMIZE BEARING": "LAGER ANPASSEN",
      Overview: "Übersicht",
      Color: "Farbe",
      Size: "Größe",
      "Sliding Sleeve": "Schiebehülse",
      "Pinned Support": "Festlager",
      "Roller Support": "Loslager",
      "Fixed Support": "Feste Einspannung",
      "Quick Download": "Herunterladen",
      "Welcome to React": "Hello React",
      "Download SVG": "SVG Herunterladen",
      "Height Hatching": "Höhe der Schraffur",
      Angle: "Winkel",
      "Angle of Hatching": "Winkel der Schraffur",
      StrokeWidth: "Strichstärke",
      "Stroke Color": "Linienfarbe",
      "Diameter Joint": "Durchmesser Gelenk",
      "Height Center of Joint": "Höhe Gelenkmittelpunkt",
      "Attachment Points on Joint": "Anbaupunkte Gelenk",
      "Show Hatching": "Schraffur anzeigen",
      "Show Only Joint": "Nur Gelenk anzeigen",
      "Width Balls": "Breite der Kugelfläche",
      "Diameter Balls": "Durchmesser Kugeln",
      "Show Balls": "Kugeln anzeigen",
      "Joint Fill Color": "Gelenk Füllfarbe",
      "Bearing Body Color": "Farbe Lagerrumpf",
      "Bearing Balls Color": "Farbe Kugeln",
      "Show Hatching Bottom": "Untere Schraffur anzeigen",
      "Show Hatching Top": "Obere Schraffur anzeigen",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    fallbackLng: "de",
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnNull: false,
  });

export default i18n;

import { deg2Rad } from "features/Components/BearingEditor/util/angleConversion";
import { DrawenContext } from "features/Context/DrawenContext";
import { MouseMoveContext } from "features/Context/MouseMoveContext";
import { PendingContext } from "features/Context/PendingContext";
import { useCallback, useContext, useState } from "react";
import { getRelativePos } from "./getRelativePos";

const useDrag = () => {
  const { drawenObjects } = useContext(DrawenContext);
  const { pendingObject, setPendingCoords, isPending, setRelativePos } =
    useContext(PendingContext);

  const {
    lastX,
    setLastX,
    lastY,
    setLastY,
    pendingIsDraggable,
    setPendingIsDraggable,
  } = useContext(MouseMoveContext);

  const handleMouseMove = useCallback((e) => {
    // in playground

    e.preventDefault();
    if (!pendingIsDraggable) {
      return;
    }
    // console.log("hi from useDrag", e);
    if (pendingObject.mechanicalType == "bearing") {
      let x, y;
      if (pendingObject.parentIndex == "root") {
        x = e.pageX - lastX + pendingObject.x; //delta from mousemove plus xposition before
        y = -e.pageY - lastY + pendingObject.y; // minus in front of y to invert y direction to fit KOS
        setPendingCoords({
          x: x,
          y: y,
        });

        setRelativePos(
          getRelativePos(x, y, pendingObject, drawenObjects) // creates object of relative size depending on the current KOS settings
        );
      } else {
        //move earing along the parent part
        const angle = drawenObjects[pendingObject.parentIndex].angle;
        const deltaX = e.pageX - lastX;
        const deltaY = -e.pageY - lastY;
        //can be interpreted as scalar product
        const xDirStickEinheits = Math.cos(deg2Rad(angle));
        const yDirStickEinheits = Math.sin(deg2Rad(angle));
        const scalerProd =
          xDirStickEinheits * deltaX + yDirStickEinheits * deltaY;
        const newX = xDirStickEinheits * scalerProd + pendingObject.x;
        const newY = yDirStickEinheits * scalerProd + pendingObject.y;

        const xPart = drawenObjects[pendingObject.parentIndex].x;
        const yPart = drawenObjects[pendingObject.parentIndex].y;

        //distance part start to new bearing
        const distance = Math.sqrt(
          (newX - xPart) * (newX - xPart) + (newY - yPart) * (newY - yPart)
        );
        const parent = drawenObjects[pendingObject.parentIndex];
        const xPartEnd = xPart + xDirStickEinheits * parent.size.width;
        const yPartEnd = yPart + yDirStickEinheits * parent.size.width;
        //distance part end to new bearing
        const dist2 = Math.sqrt(
          (xPartEnd - newX) * (xPartEnd - newX) +
            (yPartEnd - newY) * (yPartEnd - newY)
        );

        let newDistance;
        // console.log("new", newX, newY);
        // console.log("part", xPartEnd, yPartEnd);
        // console.log("dist2", dist2 <= parent.size.width, dist2);
        // console.log("distance", distance <= parent.size.width, distance);
        if (dist2 <= parent.size.width && distance <= parent.size.width) {
          //drag in limits of part
          x = newX;
          y = newY;
          newDistance = distance;
        } else if (
          dist2 <= parent.size.width &&
          distance >= parent.size.width
        ) {
          //drag further than part end
          x = xPartEnd;
          y = yPartEnd;
          newDistance = parent.size.width;
        } else {
          //drag further than part start
          x = xPart;
          y = yPart;
          newDistance = 0;
        }
        setPendingCoords({
          x: x,
          y: y,
        });

        setRelativePos({ distance: newDistance });
      }
    } else {
      //a part is dragged
      const angle = pendingObject.angle;
      const deltaX = e.pageX - lastX;
      const deltaY = -e.pageY - lastY;
      //can be interpreted as scalar product
      const xDirStickEinheits = Math.cos(deg2Rad(angle));
      const yDirStickEinheits = Math.sin(deg2Rad(angle));
      const scalerProd =
        xDirStickEinheits * deltaX + yDirStickEinheits * deltaY;
      const newX = xDirStickEinheits * scalerProd + pendingObject.x;
      const newY = yDirStickEinheits * scalerProd + pendingObject.y;

      const xBearing = drawenObjects[pendingObject.parentIndex].x;
      const yBearing = drawenObjects[pendingObject.parentIndex].y;

      const distance = Math.sqrt(
        (newX - xBearing) * (newX - xBearing) +
          (newY - yBearing) * (newY - yBearing)
      );
      const xPartEnd = newX + xDirStickEinheits * pendingObject.size.width;
      const yPartEnd = newY + yDirStickEinheits * pendingObject.size.width;
      const dist2 = Math.sqrt(
        (xPartEnd - xBearing) * (xPartEnd - xBearing) +
          (yPartEnd - yBearing) * (yPartEnd - yBearing)
      );
      const parent = drawenObjects[pendingObject.parentIndex];
      // console.log("new", newX, newY);
      // console.log("part", xPartEnd, yPartEnd);
      // console.log("dist2", dist2 <= pendingObject.size.width, dist2);
      // console.log("distance", distance <= pendingObject.size.width, distance);
      if (
        distance <= pendingObject.size.width &&
        dist2 <= pendingObject.size.width
      ) {
        setPendingCoords({
          x: newX,
          y: newY,
        });
        setRelativePos({ distance: distance });
      } else if (
        dist2 <= pendingObject.size.width &&
        distance >= pendingObject.size.width
      ) {
        setPendingCoords({
          x: -xDirStickEinheits * pendingObject.size.width + xBearing,
          y: -yDirStickEinheits * pendingObject.size.width + yBearing,
        });
        setRelativePos({ distance: pendingObject.size.width });
      } else {
        setPendingCoords({
          x: xBearing,
          y: yBearing,
        });
        setRelativePos({ distance: 0 });
      }
    }

    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  const handleDragStart = useCallback((e, name) => {
    // in bearing/part
    e.preventDefault();
    if (name != "pending") {
      // man kann nur das pending element draggen
      return;
    }
    setPendingIsDraggable(true);
    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  const handleDragEnd = useCallback((e) => {
    // in playground
    e.preventDefault();
    //e.stopPropagation();
    setPendingIsDraggable(false);
  });
  return { handleDragEnd, handleDragStart, handleMouseMove };
};

export default useDrag;

import { makeStyles } from "@material-ui/core";
import { AngleControl } from "./GeometryControls/AngleControl";
import { GeometryControl } from "./GeometryControls/GeometryControl";
import { SubmitFormSystem } from "../SubmitPending/SubmitFormSystem";
import { PositionControl } from "./GeometryControls/PositionControl";
const useStyles = makeStyles({
  fullWidth: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  heightControl: {
    boxSizing: "border-box",
    overflowY: "scroll",
    overflowX: "hidden",
    flexGrow: 1,
    padding: "20px",
  },
  heightSubmit: { flexShrink: 0 },
});
export function RootBearingControl() {
  const classes = useStyles();
  return (
    <div className={classes.fullWidth}>
      <div className={classes.heightControl}>
        <GeometryControl></GeometryControl>
        <PositionControl></PositionControl>
        <AngleControl></AngleControl>
      </div>
      <div className={classes.heightSubmit}>
        <SubmitFormSystem />
      </div>
    </div>
  );
}

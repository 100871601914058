import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import MechanicalIcon from "features/Components/Functionality_Right/SystemMode/SelectBar/Selectables/MechanicalIcon";
import { primaryColor } from "features/Components/StyleOnlyComponents/Color";
import { LanguageSwitchButton } from "featuresGeneral/LanguageSwitchButton";
import { calculatorRouter } from "index";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
    padding: "10px",
    // position: "relative",
    background: primaryColor,
    position: "fixed",
    zIndex: 10,
  },
  flex: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "row",
  },
  headerText: {
    fontSize: "20px",
    textDecoration: "none !important",
    color: "white",
  },
}));

export function Header() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <Paper square elevation={2} className={classes.flexOuter}>
      <Link
        to={calculatorRouter}
        className={classes.headerText}
        title={t("Calculator")}
      >
        <div className={classes.flex}>
          <MechanicalIcon type={"losLager"} size={40}></MechanicalIcon>

          <Typography variant="h1" className={classes.headerText}>
            {t("Calculator")}
          </Typography>
        </div>
      </Link>
      <div>
        <LanguageSwitchButton></LanguageSwitchButton>
      </div>
    </Paper>
  );
}

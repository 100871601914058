import { BearingType } from "features/Context/types/ConstructionElementModel";
import { deg2Rad } from "../util/angleConversion";

export function Einspannung({
  x = 0,
  y = 0,
  hatchAngle = 60,
  strokeWidth = 3,
  strokeColor = "black",
  width = 100,
  height = 20,
  angle = 0,
  groupRef,
  xCenter = 0,
  yCenter = 0,
  measureToCenter = false,
  scale = 100,
  setCenter = false,
}: {
  x: number;
  y: number;
  xCenter?: number;
  yCenter?: number;
  hatchAngle: number;
  strokeWidth: number;
  strokeColor: string;
  width: number;
  height: number;
  angle: number;
  groupRef: React.MutableRefObject<any>;
  measureToCenter?: boolean;
  scale?: number;
  setCenter?: false | ((x: number, y: number, name: BearingType) => void);
}) {
  const numberOfHatches =
    Math.floor(width / height) >= 1 ? Math.floor(width / height) : 1;

  const hatchWidth = height / Math.tan(deg2Rad(hatchAngle));
  const borderLength = width - numberOfHatches * height - (height - hatchWidth);
  const xHatchPositions = [];
  for (var i = 1; i <= numberOfHatches; i++) {
    const xPos = borderLength / 2 + height * i;
    xHatchPositions.push(xPos);
  }
  const hatchLines = xHatchPositions.map((xHatchPos, index) => {
    return (
      <line
        id={"Line" + index}
        x1={xHatchPos}
        y1={strokeWidth / 2}
        x2={xHatchPos - hatchWidth}
        y2={height}
        stroke={strokeColor}
        strokeWidth={(strokeWidth * 3) / 4}
        key={xHatchPos}
        strokeLinecap="round"
      />
    );
  });
  const dezScale = scale / 100;
  let xCenterCond;
  let yCenterCond;
  if (xCenter === 0 && yCenter === 0) {
    xCenterCond = width / 2;
    yCenterCond = strokeWidth / 2;
  } else {
    xCenterCond = xCenter;
    yCenterCond = yCenter;
  }
  if (setCenter) {
    setCenter(xCenterCond, yCenterCond, "einspannung");
  }
  let xTransLate;
  let yTransLate;
  if (measureToCenter) {
    xTransLate = x - dezScale * xCenterCond;
    yTransLate = y - dezScale * yCenterCond;
  } else {
    xTransLate = x;
    yTransLate = y;
  }

  return (
    <g
      ref={groupRef}
      id="Einspannung"
      transform={`  translate(${xTransLate},${yTransLate}) scale(${dezScale})  rotate(${
        angle - 90
      } ${xCenterCond} ${yCenterCond} )`}
    >
      <polygon
        id="hoverHelper"
        points={
          "0,0 0," + height + " " + width + "," + height + " " + width + ",0"
        }
        fill="rgba(0,0,0,0)"
      />

      <g id="bottom">
        <line
          id="BaseLine"
          x1="0"
          y1={strokeWidth / 2}
          x2={width}
          y2={strokeWidth / 2}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />
        {hatchLines}
      </g>
      {/* <circle id="refPoint" cx="350" cy="0" r="10" fill="red" /> */}
    </g>
  );
}

import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../../Context/PendingContext";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../../../Context/DrawenContext";

import { Slider } from "../InputComponents/Slider";
import { Index } from "features/Context/types/DrawenObjectModel";
import { ControlAccordion } from "features/Components/Functionality_Right/VisualElements/ControlAccordion";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function CartesianGlobal({
  posReferenceIndex = "root",
}: {
  posReferenceIndex: Index;
}) {
  const { t } = useTranslation();
  // default reference point is root point
  const { pendingObject, setPendingCoords, setRelativePos } = useContext(
    PendingContext
  ) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  if (!pendingObject) {
    return null;
  }
  const xPending = pendingObject.x;
  const yPending = pendingObject.y;

  const xReference = drawenObjects[posReferenceIndex]?.x;

  const yReference = drawenObjects[posReferenceIndex]?.y;
  if (
    xPending === undefined ||
    xReference === undefined ||
    yPending === undefined ||
    yReference === undefined
  ) {
    return null;
  }
  const dx = xPending - xReference;
  const dy = yPending - yReference;

  const handleXChange = (dx: number) => {
    //@ts-ignore
    setPendingCoords({
      x: xReference + dx,
    });
    setRelativePos({
      relativeX: dx, // old dx
      relativeY: dy, // new dy
      distance: Math.sqrt(dx * dx + dy * dy),
    });
  };
  const handleYChange = (dy: number) => {
    //@ts-ignore
    setPendingCoords({
      y: yReference + dy,
    });
    setRelativePos({
      relativeX: dx, // old dx
      relativeY: dy, // new dy
      distance: Math.sqrt(dx * dx + dy * dy),
    });
  };

  return (
    <div className={"rectSizeControl"}>
      <ControlAccordion
        titleMainElement={<Typography>{t("Position Support")}</Typography>}
        mainContent={
          <>
            <Slider
              description={"X"}
              min={-500}
              max={500}
              value={dx}
              handleChange={handleXChange}
            />

            <Slider
              description={"Y"}
              min={-500}
              max={500}
              value={dy}
              handleChange={handleYChange}
            />
          </>
        }
        expanded={false}
      ></ControlAccordion>
    </div>
  );
}

import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../Context/PendingContext";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import { isPointInStick } from "./CollisionTests/isPointInStick";
/**
 *
 * @returns if the pending Object is mounted properly on the parent (i.e. it overlaps with it)
 */
export function useMounted() {
  const { pendingObject } = useContext(PendingContext) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  if (!pendingObject) {
    return false;
  }
  const pendX = pendingObject.x;
  const pendY = pendingObject.y;
  const parent = drawenObjects[pendingObject.parentIndex];
  if (!parent) {
    return false;
  }
  if (pendingObject.parentIndex === "root") {
    return true;
  }
  if (pendingObject.mechanicalType === "bearing") {
    if (parent.type === "stab") {
      const stickX = parent.x;
      const stickY = parent.y;
      const stickAngle = parent.angle;
      //@ts-ignore
      const stickWidth = parent.size.width;
      if (pendX === undefined || pendY === undefined) {
        return false;
      }
      return isPointInStick(
        pendX,
        pendY,
        stickX,
        stickY,
        stickAngle,
        stickWidth
      );
    }
  }
  if (pendingObject.mechanicalType === "part") {
    if (pendingObject.type === "stab") {
      const stickX = pendingObject.x;
      const stickY = pendingObject.y;
      const stickAngle = pendingObject.angle;
      //@ts-ignore
      const stickWidth = pendingObject.size.width;
      const pointX = parent.x;
      const pointY = parent.y;
      if (
        stickX === undefined ||
        stickY === undefined ||
        stickAngle === undefined
      ) {
        return false;
      }
      return isPointInStick(
        pointX,
        pointY,
        stickX,
        stickY,
        stickAngle,
        stickWidth
      );
    }
  }
}

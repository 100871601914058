import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { useContext } from "react";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "features/Context/MeasureDistanceContext";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";

import { Index } from "features/Context/types/DrawenObjectModel";
export type HighlightableObject =
  | "bearing"
  | "part"
  | "load"
  | "distMeasurement"
  | "angleMeasurement";
/**
 * hook that provides function for hightlighting on mouse enter
 * and function for stopping the highlighting on mouse leave
 * @param objectToHighlight select for which type the highlighting functions hsould be created
 * @param index index of the object to highlight
 * @returns
 */
export const useHighlight = (
  objectToHighlight: HighlightableObject = "bearing",
  index: Index | "pending"
): { handleMouseEnter: Function; handleMouseLeave: Function } => {
  // draw all drawen components
  const { setIsHighlighted: highlightAngle } = useContext(
    MeasureAngleContext
  ) as MeasureAngleContextType;
  const { setIsHighlighted: highlightDist } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const { setIsHighlighted: highlightBearingOrPart } = useContext(
    DrawenContext
  ) as DrawenContextType;
  const { setIsHighlighted: highlightLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;

  let highlightFunc: Function;
  if (objectToHighlight === "bearing") {
    highlightFunc = highlightBearingOrPart;
  } else if (objectToHighlight === "part") {
    highlightFunc = highlightBearingOrPart;
  } else if (objectToHighlight === "angleMeasurement") {
    highlightFunc = highlightAngle;
  } else if (objectToHighlight === "distMeasurement") {
    highlightFunc = highlightDist;
  } else if (objectToHighlight === "load") {
    highlightFunc = highlightLoad;
  }
  //@ts-ignore
  if (!highlightFunc || index === "root" || index === "pending") {
    return { handleMouseEnter: () => {}, handleMouseLeave: () => {} };
  } else {
    const handleMouseEnter = () => {
      highlightFunc(index, true);
    };
    const handleMouseLeave = () => {
      highlightFunc(index, false);
    };
    return { handleMouseEnter, handleMouseLeave };
  }
};

import { deg2Rad } from "features/Components/BearingEditor/util/angleConversion";
import { DrawenContext } from "features/Context/DrawenContext";
import { MouseMoveContext } from "features/Context/MouseMoveContext";
import { PendingContext } from "features/Context/PendingContext";
import { useCallback, useContext, useState } from "react";

const useDragResize = () => {
  const { drawenObjects } = useContext(DrawenContext);
  const { pendingObject, setPendingSize, isPending, setRelativePos } =
    useContext(PendingContext);

  const {
    lastX,
    setLastX,
    lastY,
    setLastY,
    pendingIsResizable,
    setPendingIsResizable,
    lastResizeDir,
    setLastResizeDir,
  } = useContext(MouseMoveContext);

  const handleResizeMove = useCallback((e) => {
    // in playground
    e.preventDefault();
    if (!pendingIsResizable) {
      return;
    } else if (!pendingObject.mechanicaltype == "part") {
      return;
    }
    const deltaX = e.pageX - lastX;
    const deltaY = -e.pageY - lastY;
    const angle = pendingObject.angle;
    const xDirStickEinheits = Math.cos(deg2Rad(angle));
    const yDirStickEinheits = Math.sin(deg2Rad(angle));
    const oldWidth = pendingObject.size.width;
    const oldHeight = pendingObject.size.height;

    if (lastResizeDir == "right") {
      //widht changes
      const scalerProd =
        xDirStickEinheits * deltaX + yDirStickEinheits * deltaY;
      let newWidth = oldWidth + scalerProd;
      if (pendingObject.relativePos.distance > newWidth) {
        newWidth = oldWidth;
      }
      setPendingSize({ width: newWidth, height: oldHeight });
    } else if (lastResizeDir == "top" || lastResizeDir == "bottom") {
      //height changes
      const scaler = xDirStickEinheits * deltaY - yDirStickEinheits * deltaX;
      const scalerProd = lastResizeDir == "top" ? scaler : -scaler;

      let newHeight = oldHeight + scalerProd;
      if (newHeight > oldWidth / 2) {
        newHeight = oldWidth / 2;
      } else if (newHeight < 2) {
        newHeight = 2;
      }
      setPendingSize({ width: oldWidth, height: newHeight });
    }

    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  const handleResizeStart = useCallback((e, name, resizeDirection) => {
    // in bearing/part
    e.preventDefault();
    //e.stopPropagation();
    if (name != "pending") {
      // man kann nur das pending element draggen
      return;
    }
    setLastResizeDir(resizeDirection);
    setPendingIsResizable(true);
    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  const handleResizeEnd = useCallback((e) => {
    // in playground
    e.preventDefault();
    //e.stopPropagation();
    setPendingIsResizable(false);
  });
  return { handleResizeEnd, handleResizeStart, handleResizeMove };
};

export default useDragResize;

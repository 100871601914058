import { elongateLine } from "features/LinearAlgebra/elongateLine";
import { Point } from "features/LinearAlgebra/linearAlgebraModel";
import LineArrow from "./LineArrow";

export default function DistanceArrow({
  points,
  color = "black",
  ...rest
}: {
  points: Array<Point>;
  color?: string;
  [key: string | number]: any;
}) {
  const p1 = points[0];
  const p2 = points[1];
  const helper1 = elongateLine([p1, p2], -10)[1];
  const helper2 = elongateLine([p1, p2], 0.01)[1];
  return (
    <g id="distanceArrow">
      <LineArrow line={[helper1, p1]} color={color} {...rest} />
      <LineArrow
        line={[p2, helper2]}
        color={color}
        drawLine={false}
        {...rest}
      />
    </g>
  );
}

import { Checkbox, Typography, makeStyles } from "@material-ui/core";
import { convertToLatex } from "features/Components/LatexUtils/convertToLatex";
import {
  lSubEnd,
  lSubStart,
} from "features/Components/LatexUtils/latexParsingHelp";
import LatexForHTML from "features/Components/Playground/helper/LatexForHTML";
import { roundIfSmall } from "features/LinearAlgebra/roundIfSmall";
import { useMeasurementUnits } from "./useMeasurementUnits";
import { useContext, useState } from "react";
import { Tag } from "features/Components/StyleOnlyComponents/Tag";
import {
  DistanceMeasurement,
  DistanceUnitType,
} from "features/Context/types/DistanceMeasurementModel";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import SymbolAndMagnitude from "features/Components/Functionality_Right/LoadMode/util/SymbolAndMagnitude";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import { DistMeasureIcon } from "../icons/DistMeasureIcon";
import {
  accordionRootBackground,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { ControlAccordion } from "features/Components/Functionality_Right/VisualElements/ControlAccordion";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px",
    paddingBottom: "5px",
    paddingTop: "10px",
    margin: "0px",
    background: accordionRootBackground,
  },
  fullWidth: {
    width: "100%",
  },
  flex: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  verticalFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  smallPad: {
    padding: "0px",
    minHeight: "20px",
    overflow: "hidden",
  },
  latex: {
    padding: "10px",
  },
  leftPad: {
    paddingLeft: "10px",
  },
  smallMargin: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  expanded: {
    marginTop: "0px",
    marginBottom: "0px",
    padding: "0px",
    minHeight: "20px",
    overflow: "hidden",
  },
  negMargin: {
    marginTop: "-15px",
    marginBottom: "-15px",
    marginLeft: "12px",
    marginRight: "0px",
    height: "24px",
    width: "24px",
    overflow: "hidden",
    position: "relative",
  },
  widthIcon: {
    width: "50px",
    marginRight: "5px",
  },
  tagPlaceholder: {
    width: "40px",
    marginRight: "5px",
  },
  widthLatex: {},
  rotate: {
    transform: "rotate(90deg)",
  },
}));
export function DistanceMeasurementControl({
  measurement,
}: {
  measurement: DistanceMeasurement;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    setHiddenByUser,
    setMagnitude,
    setUnit,
    setIsSymbolic,
    setSuffix,
    setPreFactor,
  } = useContext(MeasureDistanceContext) as MeasureDistanceContextType;
  const { handleMouseEnter, handleMouseLeave } = useHighlight(
    "distMeasurement",
    measurement.index
  );
  const { status } = useContext(StatusContext) as StatusContextType;

  const [unitOptions, unitFactor] = useMeasurementUnits("length");
  if (measurement.isHidden) {
    return null;
  }
  const isHighlighted = measurement.isHighlighted;
  const symbolicString =
    measurement.mainSymbol + lSubStart + measurement.suffix + lSubEnd;
  const numericLength = measurement.magnitude;
  const sign = Math.sign(
    (numericLength || numericLength === 0) && !isNaN(numericLength)
      ? numericLength
      : 1
  );
  const unsignedLength = roundIfSmall(numericLength / sign, 0.000001);
  const numericLengthWithUnit = unsignedLength + "" + measurement.unitSymbol;
  const measureString = measurement.isSymbolic
    ? symbolicString
    : numericLengthWithUnit;
  const latex = convertToLatex(measureString);

  const handleMagnitudeChange = (number: string) => {
    setMagnitude(measurement.index, sign * parseFloat(number));
  };
  const handleUnitChange = (unit: DistanceUnitType) => {
    setUnit(measurement.index, unit);
  };
  const handleShowMeasureChange = (newShowMeasure: boolean) => {
    setHiddenByUser(measurement.index, !newShowMeasure);
  };
  const handleSuffixChange = (newSuffix: string) => {
    setSuffix(measurement.index, newSuffix);
  };
  const handlePrefactorChange = (newPrefactor: string) => {
    setPreFactor(measurement.index, newPrefactor);
  };
  const handleSymbolicChange = (newIsSymbolic: boolean) => {
    setIsSymbolic(measurement.index, newIsSymbolic);
  };
  const iconColor = isHighlighted ? primaryColor : "black";
  const measureInterActionIsDisabled = status.status !== "measurements";
  return (
    <div
      className={classes.fullWidth}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <ControlAccordion
        titleMainElement={
          <div className={classes.fullWidth + " " + classes.flex}>
            <div className={classes.widthIcon}>
              <DistMeasureIcon color={iconColor} />
            </div>

            <div className={classes.latex + " " + classes.widthLatex}>
              <LatexForHTML height={15} latex={latex}></LatexForHTML>
            </div>
            <div className={classes.tagPlaceholder}>
              {measurement.isHiddenByUser && <Tag text={t("HIDDEN")}></Tag>}
            </div>
          </div>
        }
        mainContent={
          <div className={classes.verticalFlex}>
            <div className={classes.leftPad}>
              <SymbolAndMagnitude
                disabled={measureInterActionIsDisabled}
                handleNameChange={handleSuffixChange}
                handlePreFactorChange={handlePrefactorChange}
                handleMagnitudeChange={handleMagnitudeChange}
                handleUnitChange={handleUnitChange}
                setSymbolic={handleSymbolicChange}
                symbolSuffix={measurement.suffix}
                isSymbolic={measurement.isSymbolic}
                symbolName={measurement.mainSymbol}
                unit={measurement.unitSymbol}
                unitFactor={measurement.unitFactor}
                magnitude={measurement.magnitude}
                unitOption={unitOptions}
                height={20}
                preFactor={measurement.preFactor}
                elevation={0}
                paperBackground={accordionRootBackground}
                // font: number
                // padding: number
                thin={false}
              ></SymbolAndMagnitude>
            </div>

            <div className={classes.flex}>
              <Checkbox
                checked={!measurement.isHiddenByUser}
                onChange={(e, checked) => handleShowMeasureChange(checked)}
                color="primary"
              ></Checkbox>
              <Typography>{t("Show Measure")}</Typography>
            </div>
          </div>
        }
        expanded={false}
      ></ControlAccordion>
    </div>
  );
}

import {
  ConsoleContext,
  ConsoleContextType,
} from "features/Context/ConsoleContext";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  FreischnittContext,
  FreischnittContextType,
} from "features/Context/FreischnittContext";
import { useContext } from "react";
import ShowBearingsCheckboxMsg from "../Console/ConsoleMsgElements/ShowBearingsCheckboxMsg";
import {
  LeftPaneContext,
  LeftPaneContextType,
} from "features/Context/LeftPaneContext";

export const useCreateFreischnittCalcLoads = (
  isFake: boolean = false
): Function => {
  const {
    createFreischnittLoadsFromBearing,
    createFreischnittLoadsForAllLoadObjects,
    setBearingsAreHidden,
  } = useContext(FreischnittContext) as FreischnittContextType;
  const { setVisibleViewLeftPane } = useContext(
    LeftPaneContext
  ) as LeftPaneContextType;
  const { getAllBearings } = useContext(DrawenContext) as DrawenContextType;
  const { addEntryToConsole } = useContext(
    ConsoleContext
  ) as ConsoleContextType;
  const hideBearings = () => {
    setBearingsAreHidden((prev) => true);
    const toggleBearingEl = <ShowBearingsCheckboxMsg></ShowBearingsCheckboxMsg>;
    addEntryToConsole(toggleBearingEl);
  };
  const createFreischnittLoads = () => {
    const bearings = getAllBearings();

    bearings.forEach((bearing) => {
      createFreischnittLoadsFromBearing(bearing.index, isFake);
    });
    if (!isFake) {
      //every externally applied load should get its freischnitt representation
      createFreischnittLoadsForAllLoadObjects();
      //hide the bearings for the freischnittView
      hideBearings();
      setVisibleViewLeftPane((prev) => "mountingTreeView");
    }
  };
  return createFreischnittLoads;
};

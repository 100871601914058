import { useState, createContext } from "react";

export const LoadStyleContext = createContext<LoadStyleContextType | null>(
  null
);
export type LoadStyleContextType = {
  forceLength: number;
  setForceLength: (force: number) => void;
  color1: string;
  setColor1: (color: string) => void;
  color2: string;
  setColor2: (color: string) => void;
  textColor: string;
  setTextColor: (color: string) => void;
  fontSize: number;
  setFontSize: (fontSize: number) => void;
  strokeWidth: number;
  setStrokeWidth: (strokeWidth: number) => void;
  size: number;
  setSize: (strokeWidth: number) => void;
};
export function LoadStyleProvider(props: any) {
  const grey = "#636E72";
  const [forceLength, setForceLength] = useState(80);
  const [color1, setColor1] = useState(grey);
  const [color2, setColor2] = useState("black");
  const [textColor, setTextColor] = useState(grey);
  const [fontSize, setFontSize] = useState(15);
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [size, setSize] = useState(1);

  return (
    <LoadStyleContext.Provider
      value={{
        forceLength,
        setForceLength,
        color1,
        setColor1,
        color2,
        setColor2,
        textColor,
        setTextColor,
        fontSize,
        setFontSize,
        strokeWidth,
        setStrokeWidth,
        size,
        setSize,
      }}
    >
      {props.children}
    </LoadStyleContext.Provider>
  );
}

import { IconButton, Link, Typography, makeStyles } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";

const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    background: "black",
    height: "250px",
    color: "white",
  },
  flexInner: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    background: "black",
    height: "250px",
    color: "white",
  },
  flex: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
  },
  bottom: {},
  icon: {
    color: "white",
  },
}));
export function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.flexOuter}>
      <div className={classes.flexInner}></div>
      <div className={classes.bottom}>
        <Typography align="center">
          {new Date().getFullYear()} Dominik Fletschinger{" "}
          <Link href={"https://github.com/DominikFle"}>
            <IconButton>
              <GitHubIcon className={classes.icon}></GitHubIcon>
            </IconButton>
          </Link>
        </Typography>
      </div>
    </div>
  );
}

import { Typography, makeStyles } from "@material-ui/core";
import { UnhappyLosLagerIcon } from "features/Components/Functionality_Right/SystemMode/SelectBar/Selectables/UnhappyLosLagerIcon";
import { Bold } from "featuresLandingPage/mainContent/Layout/Bold";
import { landingPageRoute } from "index";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  flexOuter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    boxSizing: "border-box",
  },
  iconWrap: {
    width: "max-content",
    flexShrink: 0,
  },
}));
export function ErrorPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.flexOuter}>
      <div className={classes.iconWrap}>
        <UnhappyLosLagerIcon size={300}></UnhappyLosLagerIcon>
      </div>
      <Typography>
        {t("This page is not available. Go back to")}
        <Link to={landingPageRoute}> Home</Link> !
      </Typography>
    </div>
  );
}

import { useContext } from "react";
import {
  PlaygroundContext,
  PlaygroundContextType,
} from "../../Context/PlaygroundContext";
import { CoordinateSystem } from "./Cords/CoordinateSystem";
import { useViewBoxManager } from "./playgroundObjectHooks/useViewBoxManager";
import { Measurements } from "./Measurements/Measurements";
import useDrag from "./playgroundObjectHooks/useDrag";
import {
  MouseMoveContext,
  MouseMoveContextType,
} from "features/Context/MouseMoveContext";
import useDragRotate from "./playgroundObjectHooks/useDragRotate";
import useDragResize from "./playgroundObjectHooks/useDragResize";
import { useSystemObjs } from "./playgroundObjectHooks/useSystemObjs";
import { useLoadObjs } from "./playgroundObjectHooks/useLoadObjs";
import useDragLoad from "./playgroundObjectHooks/useDragLoad";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import useDragAnnotation from "./playgroundObjectHooks/useDragAnnotation";
import { makeStyles } from "@material-ui/core";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import useDragMeasurement from "./playgroundObjectHooks/useDragMeasurement";
import { useMeasurementObjs } from "./playgroundObjectHooks/useMeasurementObjs";
import { useAngleMeasurementObjs } from "./playgroundObjectHooks/useAngleMeasurementObjs";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import { primaryColor } from "../StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  pointer: { cursor: "pointer" },
  move: {
    cursor: "move",
  },
  auto: {
    cursor: "auto",
  },
}));
export function Playground() {
  const classes = useStyles();

  const { handleDragEnd, handleMouseMove } = useDrag();
  const { handleRotateEnd, handleRotateMove } = useDragRotate();
  const { handleResizeEnd, handleResizeMove } = useDragResize();
  const { handleDragLoadEnd, handleMouseMoveLoad } = useDragLoad();
  const { handleDragEndAnnotation, handleMouseMoveAnnotation } =
    useDragAnnotation();
  const { handleDragEndMeasurement, handleMouseMoveMeasurement } =
    useDragMeasurement();
  const { removeAllDraggableLoads } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { removeAllDraggableAnnotations, removeAllDraggableArrows } =
    useContext(MeasureDistanceContext) as MeasureDistanceContextType;
  const { removeAllDraggableAnnotations: removeAllDraggableAngleAnnotations } =
    useContext(MeasureAngleContext) as MeasureAngleContextType;
  const { anyLinearDragIsHappening } = useContext(
    MouseMoveContext
  ) as MouseMoveContextType;
  const playgroundComponents = useSystemObjs();
  const loadComponents = useLoadObjs();
  const measureComponents = useMeasurementObjs();
  const angleMeasureComponents = useAngleMeasurementObjs();
  const { width: playgroundWidth, height: playgroundHeight } = useContext(
    PlaygroundContext
  ) as PlaygroundContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const rootObj = drawenObjects["root"];
  const viewBox = useViewBoxManager();
  const playgroundConstantZoom = 0.9;
  return (
    <svg
      id="playground"
      width={playgroundWidth}
      height={playgroundHeight}
      viewBox={viewBox}
      className={anyLinearDragIsHappening ? classes.move : classes.auto}
      onMouseUp={(e) => {
        handleDragEnd(e);
        handleRotateEnd(e);
        handleResizeEnd(e);
        handleDragLoadEnd(e);
        handleDragEndAnnotation(e);
        handleDragEndMeasurement(e);
      }}
      onMouseDown={(e) => {}}
      onMouseMove={(e) => {
        handleMouseMove(e);
        handleRotateMove(e);
        handleResizeMove(e);
        handleMouseMoveLoad(e);
        handleMouseMoveAnnotation(e);
        handleMouseMoveMeasurement(e);
      }}
      onClick={(e) => {
        // setLoadAnnotationDraggable(false, loadWithDraggableAnnotation?.index);
        removeAllDraggableLoads();
        removeAllDraggableAnnotations();
        removeAllDraggableAngleAnnotations();
        removeAllDraggableArrows();
      }}
    >
      <g
        transform={`translate(${0} ${
          playgroundHeight * playgroundConstantZoom
        }) rotate(${0}) scale(${1 + " " + -1})`}
      >
        {
          "" /*<CordsArrow peakAngle={80} color="green"   polarOrigin={polarOrigin} />
            <CordsArrow color="blue" size={6} strokeWidth={3} polarDest={polarDest} />
            <MeasurementArrow color="black"   cartesian={cartesianM} />*/
        }

        {!rootObj?.coordinateSystemIsHidden && (
          <CoordinateSystem
            color={primaryColor}
            x={0}
            y={0}
            angle={0}
            size={27}
            strokeWidth={2}
          />
        )}
        {/* <DistanceArrow
          points={[
            [20, 10],
            [100, 100],
          ]}
          strokeWidth={2}
          color="red"
          strokeDasharray="5 5 "
        /> */}
        {/* <Moment
          x={100}
          y={100}
          angle={90}
          color="green"
          size={2}
          orientation={-1}
        />
        <Force x={100} y={100} angle={45} color="red" size={2} /> */}
        {/* <Force x={100} y={100} angle={0} color="red" size={2} /> */}
        {/* <CordsArrow
          // size={size}
          strokeWidth={3}
          color={"red"}
          peakAngle={15}
          polarDest={{ destX: 100, destY: 100, angle: 0 - 180, length: 80 }}
        /> */}
        {/* <BearingMounter
          handleDragStart={handleDragStart}
          bearingObject={{
            x: 110,
            y: 110,
            angle: 20,
            type: "langlochverbindung",
          }}
        /> */}
        {/* <Einspannung
          groupRef={lagerGroup}
          x={0}
          y={0}
          strokeWidth={strokeWidth}
          hatchAngle={hatchAngle}
          xCenter={0}
          yCenter={0}
          width={50}
          angle={20}
          height={10}
          strokeColor={strokeColor}
          measureToCenter={true}
        />
        <BearingMounter
          handleDragStart={handleDragStart}
          bearingObject={{ x: 0, y: 0, angle: 20, type: "losLager" }}
        /> */}
        {/* <CoordinateSystem
          color={"red"}
          x={100}
          y={100}
          angle={45}
          size={27}
          strokeWidth={3}
        />
        <line
          x1="100"
          y1="100"
          x2="300"
          y2="100"
          stroke="black"
          strokeWidth="2"
        /> */}
        {/* <Force x={100} y={100} angle={45} color="red" />
        <Moment x={100} y={100} angle={45} color="red" orientation={-1} />
        <Moment
          x={100}
          y={100}
          angle={45}
          color="green"
          size={2}
          orientation={1}
        />
        <Moment
          x={100}
          y={100}
          angle={45}
          color="blue"
          size={3}
          orientation={1}
        />
        <line
          x1="100"
          y1="100"
          x2="300"
          y2="100"
          stroke="black"
          strokeWidth="2"
        />
        <line
          x1="100"
          y1="100"
          x2="300"
          y2="100"
          stroke="rgba(0,0,0,0.2)"
          strokeWidth="1.5"
          strokeDasharray="5 5 "
        />
        <line
          x1="100"
          y1="100"
          x2="300"
          y2="200"
          stroke="rgba(0,0,0,0.2)"
          strokeWidth="1.5"
          strokeDasharray="5 5 "
        /> */}

        {playgroundComponents}
        {measureComponents}

        {loadComponents}
        {angleMeasureComponents}
        <Measurements />
      </g>
    </svg>
  );
}

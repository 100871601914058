import { intersectOfTwoLines } from "features/LinearAlgebra/intersectOfTwoLines";
import { getAllLinesFromPart } from "./getAllLinesFromPart";
import { GeometricalPart } from "./PartType";

export function intersectOfTwoParts(
  part1: GeometricalPart,
  part2: GeometricalPart
) {
  const [line1, outerLeft1, outerRight1] = getAllLinesFromPart(part1);
  const [line2, outerLeft2, outerRight2] = getAllLinesFromPart(part2);

  const [middlePoint, fraction1, fraction2] = intersectOfTwoLines(line1, line2);

  const [ll] = intersectOfTwoLines(outerLeft1, outerLeft2);
  const [lr] = intersectOfTwoLines(outerLeft1, outerRight2);
  const [rl] = intersectOfTwoLines(outerRight1, outerLeft2);
  const [rr] = intersectOfTwoLines(outerRight1, outerRight2);
  return { middlePoint, ll, lr, rl, rr, fraction1, fraction2 };
}

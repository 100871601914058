import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { getLoadsUnderPart } from "features/Context/util/getLoadsUnderPart";
import { distance } from "features/LinearAlgebra/vectorUtils";
import { useContext } from "react";
import { Mark } from "../../SystemMode/GeometryControls/util/MarkModel";
/**
 * This function creates marks based on a part and the loads that are already on this part
 * @param {*} parentPart
 * @param {*} marks
 * @returns
 */
export const useMoreMarksLoad = (parentPart: any, marks: Array<Mark>) => {
  const { loadObjects } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;

  const addMoreMarks = () => {
    let moreMarks: Array<number> = [];
    if (!loadObjects) {
      return;
    }
    const loadsUnderparent = getLoadsUnderPart(parentPart, loadObjects);
    const siblingLoads = loadsUnderparent.filter((load) => !load.isPending);
    const parentLine = getLineFromPart(parentPart);

    moreMarks = siblingLoads.map((load) => {
      const dist = distance(parentLine[0], load.pos);
      return dist;
    });

    //remove doubles
    moreMarks = [...new Set(moreMarks)];
    const markArray = marks.map((markObj) => markObj.value);
    markArray.forEach((mark) => {
      const indexInMoreMarks = moreMarks.indexOf(mark);
      if (indexInMoreMarks >= 0) {
        moreMarks.splice(indexInMoreMarks, 1);
      }
    });
    const newMoreMarks: Array<Mark> = moreMarks.map((mark) => {
      return { value: mark, label: "" };
    });

    return newMoreMarks;
  };
  return addMoreMarks;
};

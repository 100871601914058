import { DrawenObject } from "../types/DrawenObjectModel";
import { LoadObjects } from "../types/LoadModel";

export function getLoadsUnderPart(
  part: DrawenObject,
  loadObjects: LoadObjects
) {
  const loadsUnderParent = Object.values(loadObjects).filter((loadObject) => {
    return loadObject.parentIndex === part.index;
  });
  return loadsUnderParent;
}

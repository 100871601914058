const mountingTreeStyles = {
  tabbedButton: {
    marginLeft: "0px",
    marginTop: "5px",
  },
  horizontalLine: {
    display: "inline-block",
    height: "50%",
    width: "30px",
    borderColor: "black",
    borderBottom: "solid 1px",
  },
  bearingLine: {
    position: "absolute",
    display: "inline-block",
    height: "10px",
    width: "9px",
    borderColor: "black",
    borderBottom: "solid 1px",
    marginLeft: "-11px",
    //marginTop:"10px",
  },
  partLine: {
    position: "absolute",
    display: "inline-block",
    height: "9px",
    width: "19px",
    borderColor: "black",
    borderLeft: "solid 1px",
    marginLeft: "19px",
    marginTop: "-11px",
    //marginTop:"10px",
  },
  horizontalVerticalLine: {
    marginLeft: "29px",
    marginTop: "-20px",
    display: "inline-block",
    height: "46px",
    // backgroundColor: "red",
    width: "18px",
    borderColor: "black",
    borderBottom: "solid 1px",
    borderLeft: "solid 1px",
  },
  tabbedRightPart: {
    marginTop: "8px",
    marginLeft: "8px",
    borderColor: "black",
    borderLeft: "solid 1px",
    borderTop: "solid 1px",
    position: "relative",
  },
  tabbedRightBearing: {
    marginTop: "8px",
    marginLeft: "8px",
    borderColor: "black",
    /*borderLeft:"solid 1px",  */
    borderTop: "solid 1px",
    position: "relative",
  },
  tabbedBorderBearing: {
    paddingLeft: "2px",
    marginLeft: "0px",
    padding: "2px",
    borderColor: "black",
    borderLeft: "solid 1px",
    borderBottom: "solid 1px",
    backgroundColor: "white",
    // borderTopLeftRadius: "5px",
    // borderBottomLeftRadius: "17px",
  },
  tabbedBorderPart: {
    paddingLeft: "2px",
    marginLeft: "0px",
    padding: "2px",
    borderColor: "black",
    /*borderLeft:"solid 1px",*/
    borderBottom: "solid 1px",
    backgroundColor: "white",
  },
  padding: {
    padding: "10px",
  },
};
export { mountingTreeStyles };

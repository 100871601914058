import React, { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../../Context/PendingContext";

import { Slider } from "../InputComponents/Slider";
import { ControlAccordion } from "features/Components/Functionality_Right/VisualElements/ControlAccordion";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function HeightWidthControl() {
  const { t } = useTranslation();
  const { pendingObject, setPendingSize } = useContext(
    PendingContext
  ) as PendingContextType;
  if (!pendingObject || !pendingObject.size) {
    return null;
  }
  const width = (pendingObject.size as any).width;

  const height = (pendingObject.size as any).height;

  const handleWidthChange = (width: number) => {
    //@ts-ignore
    setPendingSize({ width: width });
  };
  const handleHeightChange = (height: number) => {
    //@ts-ignore
    setPendingSize({ height: height });
  };
  const marksHeight = [{ value: 10, label: "10" }];
  const marksWidth = [{ value: 300, label: "300" }];
  return (
    <ControlAccordion
      titleMainElement={<Typography>{t("Size Beam")}</Typography>}
      mainContent={
        <div className={"rectSizeControl"}>
          <div className="angleAndHorizontal">
            <Slider
              marks={marksWidth}
              description={t("Width")}
              min={2}
              max={560}
              value={width}
              handleChange={handleWidthChange}
            />
          </div>

          <Slider
            marks={marksHeight}
            description={t("Height")}
            min={2}
            max={width / 2}
            value={height}
            handleChange={handleHeightChange}
          />
        </div>
      }
      expanded={false}
    ></ControlAccordion>
  );
}

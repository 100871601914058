import { useContext, useState } from "react";

import {
  PlaygroundContext,
  PlaygroundContextType,
} from "../../../Context/PlaygroundContext";

export function usePanFunctions() {
  const [lastX, setLastX] = useState<number>();
  const [lastY, setLastY] = useState<number>();
  const [isMouseDown, setIsMouseDown] = useState(false);
  const { isPaning, setTranslateX, setTranslateY, currentZoom } = useContext(
    PlaygroundContext
  ) as PlaygroundContextType;
  const handlePanStart = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPaning && !isMouseDown) {
      setIsMouseDown(true);

      setLastX(-e.pageX);
      setLastY(-e.pageY); //inverting KOS
    }
  };
  const handlePanMove = (e: React.MouseEvent) => {
    e.preventDefault();
    if (isPaning && isMouseDown && lastX && lastY) {
      const deltaX = -e.pageX - lastX;
      const deltaY = -e.pageY - lastY;
      setTranslateX((prevX: number) => prevX - deltaX / currentZoom);
      setTranslateY((prevY: number) => prevY + deltaY / currentZoom);
      setLastX(-e.pageX);
      setLastY(-e.pageY); //inverting KOS
    }
  };
  const handlePanEnd = (e: React.MouseEvent) => {
    setIsMouseDown(false);
  };
  return {
    handlePanEnd: handlePanEnd,
    handlePanMove: handlePanMove,
    handlePanStart: handlePanStart,
  };
}

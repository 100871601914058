import { parse } from "mathjs";
import { degreeSub, lSubEnd, lSubStart } from "./latexParsingHelp";
export const convertToLatex = (expression: string): string => {
  const degreeReplacedExpr = replaceDegreeSymbol(expression);
  const equationParts = degreeReplacedExpr.split("=");
  //simplify eqch part
  let newExpression = "";
  equationParts.forEach((expressionPart, index) => {
    const isFirst = index === 0;
    const connector = isFirst ? "" : " = ";
    newExpression = newExpression + connector + parse(expressionPart).toTex();
  });
  const withCorrectedUnderscores =
    parseUnderscoreAsLatexSubscirpt(newExpression);
  const withCorrectDegree = replaceDegreeSubWithDegreeSymbol(
    withCorrectedUnderscores
  );

  return withCorrectDegree;
};

const parseUnderscoreAsLatexSubscirpt = (expression: string): string => {
  const replacedSubscriopts = expression
    .replaceAll(lSubStart, "_{")
    .replaceAll(lSubEnd, "}");

  return replacedSubscriopts;
};
const replaceDegreeSymbol = (expr: string): string => {
  const replacedDegree = expr.replaceAll("°", degreeSub);
  return replacedDegree;
};
const replaceDegreeSubWithDegreeSymbol = (expr: string): string => {
  const replacedDegree = expr.replaceAll(degreeSub, "°");
  return replacedDegree;
};

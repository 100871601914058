import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";

import { SketchPicker } from "react-color";
import { ColorContext } from "features/Context/ColorContext";

const useStyles = (props) =>
  makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    name: {
      margin: "8px",
    },
    colorBox: {
      marginLeft: "5%",
      cursor: "pointer",
      width: "1rem",
      height: "1rem",
      border: "1px solid grey",
      background: props.color,
    },
    anchorWrapper: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
    },
  }));

export default function ColorModal({ name = "", color, updateColor }) {
  const classes = useStyles({ color })();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleColorUpdate = (value) => {
    updateColor(value);
    const color = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    handleNewColor(color);
  };
  const { lastUsedColors, handleNewColor } = useContext(ColorContext);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <div className={classes.anchorWrapper}>
        <div className={classes.colorBox} onClick={handleClick}></div>
        <Typography className={classes.name}>{name}</Typography>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SketchPicker
          color={color}
          disableAlpha
          onChange={handleColorUpdate}
          presetColors={[
            ...lastUsedColors,
            "#D0021B",
            "#F5A623",
            "#F8E71C",
            "#8B572A",
            "#7ED321",
            "#417505",
            "#BD10E0",
            "#9013FE",
            "#4A90E2",
            "#50E3C2",
            "#B8E986",
            "#000000",
            "#4A4A4A",
            "#9B9B9B",
            "#FFFFFF",
          ]}
        />
      </Popover>
    </div>
  );
}

//default presets
// "#D0021B",
// "#F5A623",
// "#F8E71C",
// "#8B572A",
// "#7ED321",
// "#417505",
// "#BD10E0",
// "#9013FE",
// "#4A90E2",
// "#50E3C2",
// "#B8E986",
// "#000000",
// "#4A4A4A",
// "#9B9B9B",
// "#FFFFFF",

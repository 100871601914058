import { simplify } from "mathjs";

export const simplifyEquation = (equation: string) => {
  const equationParts = equation.split("=");
  //simplify eqch part
  let newEquation = "";
  equationParts.forEach((equationPart, index) => {
    const isFirst = index === 0;
    const connector = isFirst ? "" : " = ";
    newEquation =
      newEquation +
      connector +
      simplify(equationPart, {}, { exactFractions: false });
  });
  return newEquation;
};

import { checkIfDistMeasureShouldBeHidden } from "features/Components/Functionality_Bottom/onStatusUpdate/util/checkIfDistMeasureShouldBeHidden";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { Line, Vector2 } from "features/LinearAlgebra/linearAlgebraModel";
import {
  areCloseScalar,
  distance,
  rotateByDeg,
  scalarProd,
} from "features/LinearAlgebra/vectorUtils";
import { useState, createContext, useRef } from "react";
import {
  DistanceMeasurement,
  DistanceMeasurementObjects,
  DistanceUnitType,
} from "./types/DistanceMeasurementModel";
import { useMeasurementUnits } from "features/Components/Functionality_Left/MesurementView/useMeasurementUnits";
export type MeasureIndex = number;
export const MeasureDistanceContext =
  createContext<MeasureDistanceContextType | null>(null);
export type MeasureDistanceContextType = {
  createNewMeasurement: (
    measurement: Partial<DistanceMeasurement>
  ) => MeasureIndex;
  removeMeasurement: (indexArr: MeasureIndex | Array<MeasureIndex>) => void;
  measurements: DistanceMeasurementObjects | null;
  setNewLineOffset: (index: MeasureIndex, lineOffset: number) => void;
  draggableMeasure: DistanceMeasurement | null | undefined;
  setMeasureAnnotationTextOffset: (
    deltaArr: Vector2,
    index: MeasureIndex
  ) => void;
  setMeasureAnnotationDraggable: (
    newDraggable: boolean,
    index: MeasureIndex
  ) => void;
  removeAllDraggableAnnotations: () => void;
  removeAllDraggableArrows: () => void;
  removeAllFakeMeasurements: () => void;
  setMeasureArrowDraggable: (
    newDraggable: boolean,
    index: MeasureIndex
  ) => void;
  draggableMeasureArrow: DistanceMeasurementObjects | null | undefined;
  setLineOffset: (deltaArr: Vector2, index: MeasureIndex) => void;
  setInitialLine: (line: Line, index: MeasureIndex) => void;
  setIsBeingModified: (
    newIsBeingModified: boolean,
    index: MeasureIndex
  ) => void;
  setUnit: (index: MeasureIndex, newUnit: DistanceUnitType) => void;
  setIsSymbolic: (index: MeasureIndex, newIsSymbolic: boolean) => void;
  setMagnitude: (index: MeasureIndex, newMagnitude: number) => void;
  setMainSymbol: (index: MeasureIndex, newMainSymbol: string) => void;
  setSuffix: (index: MeasureIndex, newSuffix: string) => void;
  getCurrentlyModified: () => DistanceMeasurement | null;
  getHidden: () => Array<DistanceMeasurement> | null;
  setHiddenByUser: (index: MeasureIndex, isHiddenByUser: boolean) => void;
  setPreFactor: (index: MeasureIndex, newPreFactor: string) => void;
  setIsHighlighted: (index: MeasureIndex, newIsHighlighted: boolean) => void;
};
export function MeasureDistanceProvider(props: any) {
  const [unitOptions, unitFactorFunc] = useMeasurementUnits("length");
  const measureIndex = useRef<number>(1);
  const [measurements, setMeasurements] = useState<DistanceMeasurementObjects>(
    {}
  );
  const DEFAULT_SUFFIX = "1";
  const createNewMeasurement = ({
    index = measureIndex.current,
    isDraggable = false,
    initialLine = [
      [0, 0],
      [120, 120],
    ],
    arrowColor = "black",
    annotationColor = "black",
    lineOffset = 0,
    preFactor = "1",
    mainSymbol = "l",
    suffix = DEFAULT_SUFFIX,
    magnitude = 1,
    unitType = "length", //length,Force
    isSymbolic = true,
    unitSymbol = "m",
    unitFactor = 1,
    arrowIsDraggable = false,
    annotationIsDraggable = false,
    textOffsetX = -6,
    textOffsetY = 19,

    type = "length", //relativeDistance,
    ownerIndex = -1,

    fromType = "part", //streckenlast,force,moment,bearing
    fromIndex = -1, //index of according Context
    fromAttachment = "start", //end,middle

    toType = "part", //streckenlast,force,moment,bearing
    toIndex = -1, //index of according Context
    toAttachment = "end", //start

    isBeingModified = false,
    isHidden = false, // this should be set for 0 length measurements or 90° or 0° angles
    isHiddenByUser = false,
    isFake = false,
  }: Partial<DistanceMeasurement>) => {
    const isZero = areCloseScalar(distance(initialLine[0], initialLine[1]), 0);
    const conditionalSymbolic = isZero ? false : isSymbolic;
    const conditionalMagnitude = isZero ? 0 : magnitude;
    setMeasurements((prev) => {
      const suffixCalulated =
        suffix !== DEFAULT_SUFFIX ? suffix : getLowestAvailableSuffix(prev);

      return {
        ...prev,
        [index]: {
          index,
          isDraggable,
          initialLine,
          lineOffset,
          mainSymbol,
          suffix: suffixCalulated,
          arrowColor,
          annotationColor,
          magnitude: conditionalMagnitude,
          unitType,
          isSymbolic: conditionalSymbolic,
          unitSymbol,
          unitFactor,
          arrowIsDraggable,
          annotationIsDraggable,
          textOffsetX,
          textOffsetY,
          preFactor,
          type,
          ownerIndex,
          fromType,
          fromIndex,
          fromAttachment,
          toType,
          toIndex,
          toAttachment,
          isBeingModified,
          isHidden,
          isHiddenByUser,
          isFake,
          isHighlighted: false,
        },
      };
    });
    measureIndex.current = measureIndex.current + 1;
    return index;
  };
  const getLowestAvailableSuffix = (
    measurements: DistanceMeasurementObjects
  ) => {
    const indices = Object.values(measurements)
      .filter((measure) => {
        const isNumber = !isNaN(measure.suffix as any);
        const isNotHidden = !measure.isHidden;
        return isNotHidden && isNumber;
      })
      .map((measure) => {
        return parseInt(measure.suffix);
      })
      .sort();
    if (indices.length === 0 || !indices) {
      return "1";
    }
    const maxValue = indices.slice(-1)[0];
    for (let i = 0; i < maxValue; i++) {
      if (indices[i] > i + 1) {
        return String(i + 1);
      }
    }
    return String(maxValue + 1);
  };
  /**
   * @param {*} indexArr Array of indices or index to remove from measurements object
   */
  const removeMeasurement = (indexArr: MeasureIndex | Array<MeasureIndex>) => {
    const removeMeasure = (index: MeasureIndex) => {
      setMeasurements((prev) => {
        const { [index]: indToRemove, ...rest } = prev;
        return rest;
      });
    };
    if (Array.isArray(indexArr)) {
      //its an array of measurement indices
      indexArr.forEach((ind) => {
        removeMeasure(ind);
      });
    } else {
      //its just one index
      const indexToRemove = indexArr;
      removeMeasure(indexToRemove);
    }
  };

  const removeAllFakeMeasurements = (): void => {
    const allFakeMeasures = Object.values(measurements).filter((measure) => {
      return measure.isFake;
    });
    if (!allFakeMeasures) {
      return;
    }
    const allFakeIndices = allFakeMeasures.map((measure) => {
      return measure.index;
    });
    removeMeasurement(allFakeIndices);
  };

  const setNewLineOffset = (index: MeasureIndex, lineOffset: number) => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          lineOffset: lineOffset,
        },
      };
    });
  };
  const draggableMeasure = measurements
    ? Object.values(measurements).find((measure) => {
        return measure.annotationIsDraggable;
      })
    : null;
  const draggableMeasureArrow = measurements
    ? Object.values(measurements).find((measure) => {
        return measure.arrowIsDraggable;
      })
    : null;

  const setLineOffset = (deltaArr: Vector2, index: MeasureIndex) => {
    if (deltaArr.length === 2) {
      setMeasurements((prev) => {
        const prevLineOffset = prev[index].lineOffset;
        const initialLine = [...prev[index].initialLine];
        // @ts-ignore
        const normalDir = rotateByDeg(getLineDir(initialLine), 90);
        const projectiononNormal = scalarProd(deltaArr, normalDir);
        const newLineOffset = prevLineOffset + projectiononNormal;
        return {
          ...prev,
          [index]: {
            ...prev[index],
            lineOffset: newLineOffset,
          },
        };
      });
    }
  };

  const setMeasureAnnotationTextOffset = (
    deltaArr: Vector2,
    index: MeasureIndex
  ) => {
    if (deltaArr.length === 2) {
      setMeasurements((prev) => {
        return {
          ...prev,
          [index]: {
            ...prev[index],
            textOffsetX: prev[index].textOffsetX + deltaArr[0],
            textOffsetY: prev[index].textOffsetY + deltaArr[1],
          },
        };
      });
    }
  };
  const setMeasureAnnotationDraggable = (
    newDraggable: boolean,
    index: MeasureIndex
  ) => {
    setMeasurements((prev) => {
      const newState = {
        ...prev,
        [index]: {
          ...prev[index],
          annotationIsDraggable: newDraggable,
        },
      };
      return newState;
    });
  };
  const setMeasureArrowDraggable = (
    newDraggable: boolean,
    index: MeasureIndex
  ) => {
    setMeasurements((prev) => {
      const newState = {
        ...prev,
        [index]: {
          ...prev[index],
          arrowIsDraggable: newDraggable,
        },
      };

      return newState;
    });
  };
  const removeAllDraggableAnnotations = () => {
    setMeasurements((prev) => {
      let trueIndices: Array<MeasureIndex> = [];
      Object.values(prev).forEach((el) => {
        if (el.annotationIsDraggable) {
          trueIndices.push(el.index);
        }
      });
      let temp = { ...prev };
      if (trueIndices.length > 0) {
        trueIndices?.forEach((index) => {
          temp[index] = {
            ...temp[index],
            annotationIsDraggable: false,
          };
        });
      }
      return temp;
    });
  };
  const removeAllDraggableArrows = () => {
    setMeasurements((prev) => {
      let trueIndices: Array<MeasureIndex> = [];
      Object.values(prev).forEach((el) => {
        if (el.arrowIsDraggable) {
          trueIndices.push(el.index);
        }
      });
      let temp = { ...prev };
      if (trueIndices.length > 0) {
        trueIndices?.forEach((index) => {
          temp[index] = {
            ...temp[index],
            arrowIsDraggable: false,
          };
        });
      }
      return temp;
    });
  };
  const setInitialLine = (line: Line, index: MeasureIndex) => {
    const dist = distance(line[0], line[1]);
    setMeasurements((prev) => {
      const measure = prev[index];
      //not zero anymore so it could be symbolic now
      const shouldNowBeSymbolic = !areCloseScalar(dist, 0);
      const firstTimeSymbolic = !measure.isSymbolic && shouldNowBeSymbolic;
      return {
        ...prev,
        [index]: {
          ...prev[index],
          initialLine: line,
          isHidden: checkIfDistMeasureShouldBeHidden(line),
          isSymbolic: shouldNowBeSymbolic || measure.isSymbolic,
          suffix: firstTimeSymbolic
            ? getLowestAvailableSuffix(prev)
            : measure.suffix,
          magnitude: areCloseScalar(measure.magnitude, 0)
            ? 1
            : measure.magnitude,
        },
      };
    });
  };
  const setIsSymbolic = (index: MeasureIndex, newIsSymbolic: boolean): void => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          isSymbolic: newIsSymbolic,
        },
      };
    });
  };
  const setMagnitude = (index: MeasureIndex, newMagnitude: number): void => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          magnitude: newMagnitude,
        },
      };
    });
  };
  const setMainSymbol = (index: MeasureIndex, newMainSymbol: string): void => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          mainSymbol: newMainSymbol,
        },
      };
    });
  };
  const setSuffix = (index: MeasureIndex, newSuffix: string): void => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          suffix: newSuffix,
        },
      };
    });
  };
  const setIsBeingModified = (
    newIsBeingModified: boolean,
    index: MeasureIndex
  ) => {
    setMeasurements((prev) => {
      let temp = { ...prev };
      for (var key of Object.keys(temp)) {
        // @ts-ignore
        temp[key].isBeingModified = false;
      }
      const newState = {
        ...temp,
        [index]: {
          ...temp[index],
          isBeingModified: newIsBeingModified,
        },
      };
      return newState;
    });
  };
  const getCurrentlyModified = (): DistanceMeasurement | null => {
    if (!measurements) {
      return null;
    }
    for (var key of Object.keys(measurements)) {
      // @ts-ignore
      if (measurements[key].isBeingModified) {
        // @ts-ignore
        return measurements[key];
      }
    }
    return null;
  };
  const getHidden = (): Array<DistanceMeasurement> | null => {
    if (!measurements) {
      return null;
    }
    let hiddenList: Array<DistanceMeasurement> = [];
    for (var key of Object.keys(measurements)) {
      // @ts-ignore
      if (measurements[key].isHidden) {
        // @ts-ignore
        hiddenList.push(measurements[key]);
      }
    }
    if (hiddenList.length > 0) {
      return hiddenList;
    }
    return null;
  };
  const setHiddenByUser = (
    index: MeasureIndex,
    isHiddenByUser = true
  ): void => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          isHiddenByUser: isHiddenByUser,
        },
      };
    });
  };
  const setPreFactor = (index: MeasureIndex, newPreFactor: string): void => {
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          preFactor: newPreFactor,
        },
      };
    });
  };
  const setUnit = (index: MeasureIndex, newUnit: DistanceUnitType): void => {
    const unitFactor: number = unitFactorFunc(newUnit);
    setMeasurements((prev) => {
      return {
        ...prev,
        [index]: {
          ...prev[index],
          unitSymbol: newUnit,
          unitFactor: unitFactor,
        },
      };
    });
  };
  const setIsHighlighted = (
    index: MeasureIndex,
    newIsHighlighted: boolean
  ): void => {
    setMeasurements((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        isHighlighted: newIsHighlighted,
      },
    }));
  };
  return (
    <MeasureDistanceContext.Provider
      value={{
        createNewMeasurement,
        removeMeasurement,
        measurements,
        setNewLineOffset,
        draggableMeasure,
        setMeasureAnnotationTextOffset,
        setMeasureAnnotationDraggable,
        removeAllDraggableAnnotations,
        removeAllDraggableArrows,
        setMeasureArrowDraggable,
        draggableMeasureArrow,
        setLineOffset,
        setInitialLine,
        setIsBeingModified,
        setIsSymbolic,
        setMagnitude,
        setMainSymbol,
        setSuffix,
        getCurrentlyModified,
        getHidden,
        setHiddenByUser,
        setUnit,
        setPreFactor,
        removeAllFakeMeasurements,
        setIsHighlighted,
      }}
    >
      {props.children}
    </MeasureDistanceContext.Provider>
  );
}

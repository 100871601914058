import { useContext } from "react";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import { bearingTypes } from "../../../GlobalData/bearingTypes";
import { makeStyles } from "@material-ui/core";
import { mountingTreeStyles } from "./mountingTreeStyles";
import TreeElementVisual from "./TreeElementVisual";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import {
  PendingContext,
  PendingContextType,
} from "features/Context/PendingContext";
import { panelBackground } from "features/Components/StyleOnlyComponents/Color";
import { useTranslation } from "react-i18next";

//@ts-ignore
const useStyles1 = makeStyles(mountingTreeStyles);
const useStyles2 = makeStyles({
  overlay: {
    background: panelBackground,
    width: "5px",
    height: "4000px",
    position: "absolute",
    marginLeft: "-12px",
    marginTop: "-20px",
    borderTop: "solid 1px black",
  },
});
export function ConnectorPending() {
  const { i18n } = useTranslation();
  const { pendingObject } = useContext(PendingContext) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const { handleMouseEnter, handleMouseLeave } = useHighlight(
    "bearing",
    "pending"
  );
  const classes = useStyles1();
  const classesOverlay = useStyles2();
  const bearing = pendingObject;
  if (!bearing) {
    return null;
  }
  const parent = drawenObjects[bearing.parentIndex];

  const isHighlighted = bearing.isHighlighted;
  const isLastBearingOnPart = parent?.children.slice(-1)[0] === bearing.index;
  var connectorType = bearing.type;
  if (!connectorType) {
    return null;
  }
  const ConnectorName =
    i18n.language === "en"
      ? bearingTypes[connectorType].nameEn
      : bearingTypes[connectorType].nameDe;

  return (
    <>
      <div className={classes.tabbedRightBearing}>
        <div
          className={classes.tabbedBorderBearing}
          onMouseEnter={() => handleMouseEnter()}
          onMouseLeave={() => handleMouseLeave()}
        >
          <div className={classes.bearingLine} />
          <TreeElementVisual
            index={0}
            type={connectorType}
            text={ConnectorName}
            isHighlighted={isHighlighted}
            isPending={true}
          />
          {isLastBearingOnPart && (
            <div className={classesOverlay.overlay}></div>
          )}
        </div>
      </div>
    </>
  );
}

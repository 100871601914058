import React from 'react';



export function getRelativePos(newX,newY,pendingObject,drawenObjects){
 
    const posKOS = pendingObject.posKOS;
    const posReferenceIndex = pendingObject.posReferenceIndex;
    const xRef=drawenObjects[posReferenceIndex].x;
    const yRef=drawenObjects[posReferenceIndex].y;
    const angleRef=drawenObjects[posReferenceIndex].angle;
    
   
    switch (posKOS) {
        case "cartesian-global":
            return(
                {
                    relativeX:newX-xRef,
                    relativeY:newY-yRef,
                }
            );
        case "cartesian-local":
            const dxGlobal=newX-xRef;
            const dyGlobal=newY-yRef;
            const dxRot=inRotatedKOS(dxGlobal,dyGlobal,angleRef).dxRot;
            const dyRot=inRotatedKOS(dxGlobal,dyGlobal,angleRef).dyRot;
            return(
                {
                    relativeX:dxRot,
                    relativeY:dyRot,
                }
            );
        case "polar-global":
            const phiG=toPolar(xRef,yRef,newX,newY).phi;
            const radiusG=toPolar(xRef,yRef,newX,newY).radius;
            return(
                {
                    relativePhi:phiG,
                    relativeRadius:radiusG,
                }
            );
        case "polar-local":
            const phi=toPolar(xRef,yRef,newX,newY).phi;
            const radius=toPolar(xRef,yRef,newX,newY).radius;
            return(
                {
                    relativePhi:phi-angleRef, // just subtract the already turned angle
                    relativeRadius:radius,
                }
            );
        default:
          return
      }

}

function inRotatedKOS(dx,dy,angle){
    const dxRot=dx*Math.cos(angle/360*2*Math.PI)+dy*Math.sin(angle/360*2*Math.PI);
    const dyRot=-dx*Math.sin(angle/360*2*Math.PI)+dy*Math.cos(angle/360*2*Math.PI);
    return{dxRot,dyRot};
}
function toPolar(x1,y1,x2,y2){   // phi in degree
    const phi=Math.atan2(y2-y1,x2-x1)/2/Math.PI*360;
    const radius = Math.sqrt((y2-y1)*(y2-y1)+(x2-x1)*(x2-x1));
    return {phi:phi,radius:radius};
}
function toCartesian(phi,radius){ //phi in degree
    const dx=Math.cos(phi/360*2*Math.PI)*radius; 
    const dy=Math.sin(phi/360*2*Math.PI)*radius; 
    return {dx:dx,dy:dy};
}
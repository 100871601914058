import React, { useContext, useState } from "react";
import {
  DrawenContext,
  DrawenContextType,
} from "../../../Context/DrawenContext";
import { makeStyles } from "@material-ui/core";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ObjectIndex } from "features/Context/types/DrawenObjectModel";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  menuItem: {
    padding: "4px",
    fontSize: "13px",
  },
});

const ITEM_HEIGHT = 48;

export function MoreInfoBtn({ elementIndex }: { elementIndex: ObjectIndex }) {
  //prpps is list that is in Menu
  const { t } = useTranslation();
  const { removeObject, moveOneUp, moveOneDown, moveToTop, moveToBottom } =
    useContext(DrawenContext) as DrawenContextType;
  const { status } = useContext(StatusContext) as StatusContextType;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: any) => {
    setAnchorEl(null);
  };
  const options = [
    {
      text: t("Remove Element"),
      onClick: () => {
        removeObject(elementIndex);
      },
    },
    // {
    //   text: t("Show more Information"),
    //   onClick: () => {},
    // },
    {
      text: t("Eine Ebene nach oben"),
      onClick: () => {
        moveOneUp(elementIndex);
      },
    },
    {
      text: t("Eine Ebene nach unten"),
      onClick: () => {
        moveOneDown(elementIndex);
      },
    },
    {
      text: t("Nach ganz oben"),
      onClick: () => {
        moveToTop(elementIndex);
      },
    },
    {
      text: t("Nach ganz unten"),
      onClick: () => {
        moveToBottom(elementIndex);
      },
    },
  ];
  //only when in system status the remove button should be there
  const optionsWithStatusCondintion =
    status.status === "system" ? options : options.slice(1);
  return (
    <>
      <IconButton size={"small"} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "180px",
          },
        }}
      >
        {optionsWithStatusCondintion.map((option) => (
          <MenuItem
            className={classes.menuItem}
            key={option.text}
            onClick={(e) => {
              handleClose(e);
            }}
          >
            <div onClick={option.onClick}>{option.text}</div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

import { useState, createContext } from "react";

export const FakeRunContext = createContext<FakeRunContextType | null>(null);
export type FakeRunContextType = {
  lastFakeRunCompleted: boolean;
  setLastFakeRunCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};
export function FakeRunProvider(props: any) {
  const [lastFakeRunCompleted, setLastFakeRunCompleted] = useState(false);

  return (
    <FakeRunContext.Provider
      value={{
        setLastFakeRunCompleted,
        lastFakeRunCompleted,
      }}
    >
      {props.children}
    </FakeRunContext.Provider>
  );
}

import React, { useContext, useState } from "react";

import { PlaygroundContext } from "../../../Context/PlaygroundContext";

export function useZoomFunctions() {
  const {
    isZooming,
    isPaning,
    translateX,
    width,
    height,
    setTranslateX,
    translateY,
    setTranslateY,
    currentZoom,
    setCurrentZoom,
  } = useContext(PlaygroundContext);
  const [lastX, setLastX] = useState(width / 2);
  const [lastY, setLastY] = useState(height / 2);

  const zoomSensitivity = 0.05;
  const handleZoom = (e) => {
    if (isZooming) {
      const scrollPosX = e.pageX - e.target.getBoundingClientRect().left;

      const scrollPosY = e.pageY - e.target.getBoundingClientRect().bottom;
      //console.log("btop",e.target.getBoundingClientRect().top)
      const scrollFactor = 1 + (-e.deltaY / 100) * zoomSensitivity;
      //console.log("scrolF",scrollFactor)
      const newTranslateX =
        scrollPosX - (scrollPosX - translateX) * scrollFactor;

      setTranslateX(
        (prevX) =>
          prevX + (width / currentZoom / scrollFactor - width / currentZoom) / 2
      );
      setTranslateY(
        (prevY) =>
          prevY -
          (height / currentZoom / scrollFactor - height / currentZoom) / 2
      );
      setCurrentZoom((prevZoom) => {
        return Math.round(scrollFactor * prevZoom * 100) / 100;
      });
    }
  };
  const resetZoom = () => {
    setTranslateX(0);
    setTranslateY(0);
    setCurrentZoom(1);
  };
  return { handleZoom, resetZoom };
}

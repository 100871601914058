import { Button, makeStyles, Paper } from "@material-ui/core";
import { primaryColor } from "features/Components/StyleOnlyComponents/Color";

const useStyles = (props: any) =>
  makeStyles({
    bottomBorder: {
      borderBottom: "5px solid " + props.underLineColor,
      color: props.strokeColor,
    },
    notSelected: {
      color: props.strokeColorUnset,
      borderBottom: "5px solid rgba(0,0,0,0)",
    },
    panelSelect: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
      alignItems: "center",
      background: props.color,
      position: "relative",
      paddingBottom: "5px",
    },
    tabButtons: {
      width: "40%",
      height: "80%",
      borderRadius: "0px",
      fontSize: "12px",
    },
  });
/**
 * If is in slideview the first name is displayed. Else the second one
 * @param param0
 * @returns
 */
export function ControlTabs({
  isInSliderView,
  setIsInSliderView,
  name1,
  name2,
  color = primaryColor,
  strokeColor = "black",
  strokeColorUnset = "#9E9E9E",
  underLineColor = "white",
}: {
  isInSliderView: boolean;
  setIsInSliderView: (isInview: boolean) => void;
  name1: string;
  name2: string;
  color?: string;
  strokeColor?: string;
  strokeColorUnset?: string;
  underLineColor?: string;
}) {
  const classes = useStyles({
    color,
    strokeColor,
    strokeColorUnset,
    underLineColor,
  })();
  return (
    <Paper square elevation={2} className={classes.panelSelect}>
      <Button
        className={`${classes.tabButtons} ${
          isInSliderView ? classes.bottomBorder : classes.notSelected
        }`}
        onClick={() => setIsInSliderView(true)}
      >
        {name1}
      </Button>
      <Button
        className={`${classes.tabButtons} ${
          isInSliderView ? classes.notSelected : classes.bottomBorder
        }`}
        onClick={() => setIsInSliderView(false)}
      >
        {name2}
      </Button>
    </Paper>
  );
}

/**
 * String to identify the starting position of a latex subscript
 */
export const lSubStart = "xxxxxxstartSub";
/**
 * String to identify the ending position of a latex subscript
 */
export const lSubEnd = "xxxxxxendSub";
/**
 * Degree (°) replacement
 */
export const degreeSub = "xxxxxxDegree";

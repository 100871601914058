import ColorModal from "./ColorModal";

export default function ColorPicker({
  color = "rgba(0,0,0,1)",
  onChange = (color: any) => {},
  name = "",
} = {}) {
  const handleColorChange = (value: any) => {
    const color = `rgba(${value.rgb.r},${value.rgb.g},${value.rgb.b},${value.rgb.a})`;
    onChange(color);
  };
  return <ColorModal {...{ name, color }} updateColor={handleColorChange} />;
}

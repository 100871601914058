import { useContext, useRef } from "react";
import { Einspannung } from "../BearingEditor/Bearings/Einspannung";
import { FestLager } from "../BearingEditor/Bearings/FestLager";
import { Schiebehuelse } from "../BearingEditor/Bearings/Schiebehuelse";
import { LosLager } from "../BearingEditor/Bearings/LosLager";
import {
  BearingStyleContext,
  BearingStyleContextType,
} from "features/Context/BearingStyleContext";
import Gelenk from "../BearingEditor/Bearings/Gelenk";
import { makeStyles } from "@material-ui/core";
import Rescaler from "./Functionality/Rescaler";
import { Langlochverbindung } from "../BearingEditor/Bearings/Langlochverbindung";
import { Schiebeverbindung } from "../BearingEditor/Bearings/Schiebeverbindung";
import { Schweissverbindung } from "../BearingEditor/Bearings/Schweissverbindung";
import useDrag from "./playgroundObjectHooks/useDrag";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  PendingContext,
  PendingContextType,
} from "features/Context/PendingContext";
import {
  DrawenObject,
  PendingObject,
} from "features/Context/types/DrawenObjectModel";
import { BearingType } from "features/Context/types/ConstructionElementModel";
import BearingPositionName from "./helper/BearingPositionName";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "../StyleOnlyComponents/Color";
const useStyles = makeStyles({
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
  move: {
    cursor: "move",
  },
  auto: {
    cursor: "auto",
  },
});
export default function BearingMounter({
  bearingObject,
  measureToCenter = true,
  rotate180 = false,
  enforcedStrokeWidth = false,
  color = "black",
}: {
  bearingObject: DrawenObject | PendingObject;
  measureToCenter?: boolean;
  rotate180?: boolean;
  enforcedStrokeWidth?: false | number;
  color?: string;
}) {
  const classes = useStyles();
  const {
    hatchAngle,
    strokeWidth: styledStrokeWidth,
    dJoint,
    hJointToBase,
    dBall,
    drawBalls,
    tFactor,
    strokeColor,
    jointFillColor,
    bodyColor,
    ballsColor,
    height,
    width,
  } = useContext(BearingStyleContext) as BearingStyleContextType;
  const { pendingObject } = useContext(PendingContext) as PendingContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const strokeWidth = enforcedStrokeWidth
    ? enforcedStrokeWidth
    : styledStrokeWidth;
  const lagerGroup = useRef(null);
  const frameRef = useRef(null);
  const masterColorStroke = color !== "black" ? color : strokeColor;
  let { x, y, angle, type, scale = 100 } = bearingObject;
  x = x ? x : 0;
  y = y ? y : 0;
  angle = angle ? angle : 0;
  if (rotate180) {
    angle = angle + 180;
  }
  const center = useRef({});
  const setCenter = (x: number, y: number, name: BearingType) => {
    center.current = { x: x, y: y, name: name };
  };
  // const { currentlyHighlighted, setLastClicked } = useContext(
  //   HighlightContext
  // ) as HighlightContextType;
  // const bearingIsHighlighted = bearingObject.index === currentlyHighlighted;
  //------------------------------------
  // bearings that connect to surrounding
  //-------------------------------------
  const einspannung = (
    <Einspannung
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      strokeColor={masterColorStroke}
      measureToCenter={measureToCenter}
      scale={scale}
    />
  );
  const festlager = (
    <FestLager
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      dJoint={dJoint}
      hJointToBase={hJointToBase}
      drawHatching={true}
      tFactor={tFactor}
      strokeColor={masterColorStroke}
      jointFillColor={jointFillColor}
      bodyColor={bodyColor}
      showOnlyJoint={false}
      measureToCenter={measureToCenter}
      scale={scale}
    />
  );

  const loslager = (
    <LosLager
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      dJoint={dJoint}
      hJointToBase={hJointToBase}
      dBall={dBall}
      drawBalls={drawBalls}
      drawHatching={true}
      tFactor={tFactor}
      strokeColor={masterColorStroke}
      jointFillColor={jointFillColor}
      bodyColor={bodyColor}
      ballsColor={ballsColor}
      measureToCenter={measureToCenter}
      scale={scale}
      setCenter={setCenter}
    />
  );
  const schiebehuelse = (
    <Schiebehuelse
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      dBall={dBall}
      drawBalls={drawBalls}
      drawBottomHatch={true}
      drawTopHatch={false}
      strokeColor={masterColorStroke}
      ballsColor={ballsColor}
      measureToCenter={measureToCenter}
      scale={scale}
      setCenter={setCenter}
    />
  );
  //------------------------------------
  //bearings that connect parts

  //get parent and child part
  const parent = drawenObjects[bearingObject.parentIndex];
  let child: false | PendingObject | DrawenObject = false;
  const isPending = pendingObject?.parentIndex === bearingObject.index;
  if (pendingObject && isPending) {
    child = pendingObject;
  } else if (
    bearingObject.index === "pending" ||
    !bearingObject.hasOwnProperty("children")
  ) {
    child = false;
  } else {
    //@ts-ignore
    child = drawenObjects[bearingObject.children[0]];
  }

  const gelenk = (
    <Gelenk
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      dJoint={dJoint}
      hJointToBase={hJointToBase}
      drawHatching={true}
      tFactor={tFactor}
      strokeColor={masterColorStroke}
      jointFillColor={jointFillColor}
      bodyColor={bodyColor}
      showOnlyJoint={false}
      measureToCenter={measureToCenter}
      scale={scale}
    />
  );
  const langlochverbindung = (
    <Langlochverbindung
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      dJoint={dJoint}
      hJointToBase={hJointToBase}
      dBall={dBall}
      drawBalls={drawBalls}
      drawHatching={true}
      tFactor={tFactor}
      strokeColor={masterColorStroke}
      jointFillColor={jointFillColor}
      bodyColor={bodyColor}
      ballsColor={ballsColor}
      measureToCenter={measureToCenter}
      scale={scale}
      setCenter={setCenter}
    />
  );
  const schiebeverbindung = (
    <Schiebeverbindung
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      width={width}
      angle={angle}
      dJoint={dJoint}
      strokeColor={masterColorStroke}
      jointFillColor={jointFillColor}
      bodyColor={bodyColor}
      measureToCenter={measureToCenter}
      scale={scale}
      setCenter={setCenter}
    />
  );
  const schweissverbindung = (
    <Schweissverbindung
      groupRef={lagerGroup}
      x={x}
      y={y}
      strokeWidth={strokeWidth}
      hatchAngle={hatchAngle}
      width={width}
      angle={angle}
      height={height}
      dJoint={dJoint}
      hJointToBase={hJointToBase}
      dBall={dBall}
      drawBalls={drawBalls}
      drawHatching={true}
      tFactor={tFactor}
      strokeColor={masterColorStroke}
      jointFillColor={jointFillColor}
      bodyColor={bodyColor}
      ballsColor={ballsColor}
      measureToCenter={measureToCenter}
      scale={scale}
      setCenter={setCenter}
      //@ts-ignore
      parent={parent}
      //@ts-ignore
      child={child}
    />
  );
  //------------------------------------
  let bearing;
  if (type === "festLager") {
    bearing = festlager;
  } else if (type === "losLager") {
    bearing = loslager;
  } else if (type === "einspannung") {
    bearing = einspannung;
  } else if (type === "schiebehuelse") {
    bearing = schiebehuelse;
  } else if (type === "gelenk") {
    bearing = gelenk;
  } else if (type === "langlochverbindung") {
    bearing = langlochverbindung;
  } else if (type === "schweissverbindung") {
    bearing = schweissverbindung;
  } else if (type === "schiebeverbindung") {
    bearing = schiebeverbindung;
  }

  const { handleDragStart } = useDrag();
  let bearingName = null;
  if (
    bearingObject.index !== "pending" &&
    (bearingObject as DrawenObject).bearingPositionName
  ) {
    bearingName = (bearingObject as DrawenObject).bearingPositionName;
  }
  const isHighlighted =
    bearingObject.index === "pending" ||
    (bearingObject as DrawenObject).isHighlighted;
  const cursorIsOnMove = bearingObject.index === "pending";
  return (
    <>
      <BearingPositionName
        text={bearingName}
        object={bearingObject}
        updater={x}
      ></BearingPositionName>
      <g
        className={
          (cursorIsOnMove ? classes.move : classes.auto) +
          " " +
          (isHighlighted ? classes.gOutline : "")
        }
        ref={frameRef}
        onMouseDown={(e) => {
          handleDragStart(e, bearingObject.index);
        }}
        onClick={(e) => {
          // handleClick();
        }}
      >
        {bearing}
      </g>
      <Rescaler object={bearingObject} objRef={frameRef} updater={x} />
    </>
  );
}

import { BearingStyleContext } from "features/Context/BearingStyleContext";
import { MouseMoveContext } from "features/Context/MouseMoveContext";
import { PendingContext } from "features/Context/PendingContext";
import { useCallback, useContext, useState } from "react";

const useDragRotate = () => {
  const {
    lastX,
    setLastX,
    lastY,
    setLastY,
    pendingIsRotatable,
    setPendingIsRotatable,
  } = useContext(MouseMoveContext);
  const { pendingObject, isPending, setPendingDeltaAngle } =
    useContext(PendingContext);
  const dist = useContext(BearingStyleContext).width * 0.7;
  const handleRotateStart = (e, playgroundObj) => {
    e.preventDefault();
    if (playgroundObj.index == "pending") {
      //automatically resizable
      setPendingIsRotatable(true);
      setLastX(e.pageX);
      setLastY(-e.pageY);
    }
  };
  const handleRotateEnd = (e) => {
    e.preventDefault();

    setPendingIsRotatable(false);
  };
  const handleRotateMove = useCallback((e) => {
    // in playground

    e.preventDefault();
    if (!pendingIsRotatable) {
      return;
    }
    // pendingWidth, pendingHeight;
    // const xCenterPoint=

    const angle = pendingObject.angle;
    const xControl = Math.cos(((angle + 90) / 360) * 2 * Math.PI) * dist;
    const yControl = Math.sin(((angle + 90) / 360) * 2 * Math.PI) * dist;
    const deltaX = e.pageX - lastX;
    const deltaY = -e.pageY - lastY;

    const xAfter = xControl + deltaX;
    const yAfter = yControl + deltaY;

    let angleAfter = Math.atan2(yAfter, xAfter);
    if (angleAfter - Math.PI / 2 < 0) {
      angleAfter = angleAfter + 2 * Math.PI;
    }
    const deltaAngle =
      ((angleAfter - ((angle + 90) / 360) * 2 * Math.PI) / 2 / Math.PI) * 360;
    // setPendingAngle((angleAfter / 2 / Math.PI) * 360);
    setPendingDeltaAngle(deltaAngle);
    // const dyBefore=lastY-YCenterPoint

    setLastX(e.pageX);
    setLastY(-e.pageY);
  });
  return { handleRotateEnd, handleRotateMove, handleRotateStart };
};

export default useDragRotate;

import { makeStyles } from "@material-ui/core";
import { MountingTree } from "./MountingTree/MountingTree";
import { MeasurementView } from "./MesurementView/MeasurementView";
import BearingEditor from "../Functionality_Bottom/BearingEditor";
import { ControlTabs } from "../BearingEditor/BearingController/Util/ControlTabs";
import {
  LeftPaneContext,
  LeftPaneContextType,
} from "features/Context/LeftPaneContext";
import { useContext } from "react";
import { panelBackground, primaryColor } from "../StyleOnlyComponents/Color";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px",
    paddingBottom: "5px",
    margin: "0px",
  },
  heading: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  height90: {
    overflowY: "scroll",
    overflowX: "hidden",
    position: "relative",
    height: "90%",
    width: "100%",
    background: panelBackground,
  },
  height100: {
    position: "relative",
    height: "100%",
    width: "100%",
    background: panelBackground,
  },
  height10: {
    overflow: "hidden",
    position: "relative",
    height: "10%",
    width: "100%",
  },
  borderTop: {
    borderTop: "solid 1px black",
  },
}));
export function LeftPanel() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { visibleViewLeftPane, setVisibleViewLeftPane } = useContext(
    LeftPaneContext
  ) as LeftPaneContextType;
  const displayElementsView = visibleViewLeftPane === "mountingTreeView";
  const handleViewSwitch = (elementsIsInView: boolean) => {
    if (elementsIsInView) {
      setVisibleViewLeftPane((prev) => "mountingTreeView");
    } else {
      setVisibleViewLeftPane((prev) => "measurementsView");
    }
  };
  return (
    <div className={classes.height100}>
      <div className={classes.height10}>
        <ControlTabs
          isInSliderView={displayElementsView}
          setIsInSliderView={(b: boolean) => handleViewSwitch(b)}
          name1={t("Mechanical System")}
          name2={t("Measurements")}
          strokeColor={"black"}
          strokeColorUnset={"black"}
          underLineColor={primaryColor}
          color="white"
        ></ControlTabs>
      </div>

      {displayElementsView && (
        <div className={classes.height90}>
          <MountingTree></MountingTree>
        </div>
      )}
      {!displayElementsView && (
        <div className={classes.height90}>
          <MeasurementView></MeasurementView>
        </div>
      )}
    </div>
  );
}

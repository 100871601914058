import { getLineFromPart } from "features/Components/BearingEditor/Bearings/util/getLineFromPart";
import { Point } from "features/LinearAlgebra/linearAlgebraModel";
import { distance, middlePoint } from "features/LinearAlgebra/vectorUtils";
import { DrawenObject } from "../types/DrawenObjectModel";
import { LoadObjects } from "../types/LoadModel";

export function getInitialLoadPos(
  parent: DrawenObject,
  loadObjects: LoadObjects
) {
  const parentIndex = parent.index;
  // @ts-ignore
  const parentLine: Line = getLineFromPart(parent);
  //helper comparer func
  function distanceComparer(point1: Point, point2: Point) {
    const distance1 = distance(parentLine[0], point1);
    const distance2 = distance(parentLine[0], point2);
    return distance1 - distance2;
  }
  const loadsUnderParent = Object.values(loadObjects)
    .filter((loadObject) => loadObject.loadTypeWasSelected)
    .filter((loadObject) => loadObject.parentIndex === parentIndex);
  const loadPoints = loadsUnderParent.map((load) => {
    return [load.x, load.y];
  });
  const allPoints: Array<Point> = [...parentLine, ...loadPoints];
  const sortedPoints = allPoints.sort(distanceComparer);
  const newPoint = findNewPoint(sortedPoints);
  return newPoint;
}

export function findNewPoint(sortedArray: Array<Point>) {
  //biggest gap is after the biggestgapIndex
  let biggestGapIndex;
  let biggestGap = 0;
  sortedArray.forEach((current, index) => {
    const next = sortedArray[index + 1];
    if (next) {
      const currentDist = distance(current, next);
      if (currentDist > biggestGap) {
        biggestGapIndex = index;
        biggestGap = currentDist;
      }
    }
  });
  let newPoint = middlePoint(
    // @ts-ignore
    sortedArray[biggestGapIndex],
    // @ts-ignore
    sortedArray[biggestGapIndex + 1]
  );
  const arrLength = sortedArray.length;
  if (arrLength > 2) {
    //check start and end separately
    const gapStart = distance(sortedArray[0], sortedArray[1]);
    if (gapStart >= biggestGap / 2) {
      biggestGap = gapStart;
      newPoint = sortedArray[0];
    }
    const gapEnd = distance(
      sortedArray[arrLength - 1],
      sortedArray[arrLength - 2]
    );
    if (gapEnd >= biggestGap / 2) {
      newPoint = sortedArray[arrLength - 1];
    }
  }

  return newPoint;
}

// const map = {
//     0: 0.5,
//     1: 1,
//     2: 0,
//     3: 3 / 4,
//     4: 1 / 4,
//     5: 1 / 8,
//     6: 3 / 8,
//     7: 5 / 8,
//     8: 7 / 8,
//   };
//   const parentIndex = parent.index;
//   const loadsUnderParent = Object.values(loadObjects).filter(
//     (loadObject) => loadObject.parentIndex == parentIndex
//   );
//   const numberOfLoads = loadsUnderParent.length;
//   let posFrac;
//   if (numberOfLoads > 8) {
//     posFrac = 1 / 4;
//   } else {
//     posFrac = map[numberOfLoads];
//   }
//   const parentLine = getLineFromPart(parent);
//   const newPoint = addV(
//     parentLine[0],
//     sM(posFrac * parent.size.width, getLineDir(parentLine))
//   );

import { LoadNames } from "features/Context/types/ConstructionElementModel";

const useUnits = (loadType: LoadNames): [Array<JSX.Element>, Function] => {
  let units: Array<{ unitSymbol: string; unitFactor: number }> = [];
  if (loadType === "moment") {
    units = [
      { unitSymbol: "Nm", unitFactor: 1 },
      { unitSymbol: "Nmm", unitFactor: 1 / 1000 },
      { unitSymbol: "kNm", unitFactor: 1000 },
      { unitSymbol: "mNm", unitFactor: 1 / 1000 },
    ];
  } else if (loadType === "force") {
    units = [
      { unitSymbol: "N", unitFactor: 1 },
      { unitSymbol: "mN", unitFactor: 1 / 1000 },
      { unitSymbol: "kN", unitFactor: 1000 },
      { unitSymbol: "MN", unitFactor: 1000000 },
    ];
  } else if (loadType === "streckenlast") {
    units = [
      { unitSymbol: "N/m", unitFactor: 1 },
      { unitSymbol: "N/mm", unitFactor: 1000 },
      { unitSymbol: "mN/mm", unitFactor: 1 },
      { unitSymbol: "mN/m", unitFactor: 1 / 1000 },
      { unitSymbol: "kN/m", unitFactor: 1000 },
      { unitSymbol: "MN/m", unitFactor: 1000000 },
    ];
  }
  const unitOptions = units.map((unitObj) => {
    return (
      <option key={unitObj.unitSymbol} value={unitObj.unitSymbol}>
        {unitObj.unitSymbol}
      </option>
    );
  });
  function unitFactor(unitSymbol: string) {
    const obj = units.find((unitObj) => {
      return unitObj.unitSymbol === unitSymbol;
    });
    if (!obj?.unitFactor) {
      return -1;
    }
    return obj.unitFactor;
  }
  return [unitOptions, unitFactor];
};

export { useUnits };

import { getAllLinesFromPart } from "../util/getAllLinesFromPart";
import { intersectOfTwoParts } from "../util/intersectOfTwoParts";
import { getShape } from "../util/getShape";
import LShape from "./LShape";
import TShape from "./TShape";
import XShape from "./XShape";
import { intersectOfTwoLines } from "features/LinearAlgebra/intersectOfTwoLines";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { addV, rotateByDeg } from "features/LinearAlgebra/vectorUtils";
import { mirrorPointOnPoint } from "features/LinearAlgebra/mirrorPoint";
import { makePolygonString } from "features/LinearAlgebra/makePolygonString";

export default function ShapeDecider({
  biggerPart,
  smallerPart,
  child,
  parent,
  strokeWidth,
  strokeColor,
}) {
  const res = intersectOfTwoParts(biggerPart, smallerPart);
  const [lineBigger, outerLineBigger1, outerLineBigger2] =
    getAllLinesFromPart(biggerPart);
  // @ts-ignore
  const [lineSmaller] = getAllLinesFromPart(smallerPart);
  // @ts-ignore
  // @ts-ignore
  const [middleOuter1] = intersectOfTwoLines(
    // @ts-ignore
    lineSmaller,
    outerLineBigger1
  );
  // @ts-ignore
  const [middleOuter2] = intersectOfTwoLines(
    // @ts-ignore
    lineSmaller,
    outerLineBigger2
  );
  // console.log("middleOuters", middleOuter1, middleOuter2);
  const { middlePoint, rr, rl, lr, ll } = res;
  const normalLine = [
    middlePoint,
    // @ts-ignore
    addV(middlePoint, rotateByDeg(getLineDir(lineBigger), 90)),
  ];
  // @ts-ignore
  const [outerPoint1] = intersectOfTwoLines(
    // @ts-ignore
    normalLine,
    outerLineBigger1
  );

  // @ts-ignore
  const [outerPoint2] = intersectOfTwoLines(
    // @ts-ignore
    normalLine,
    outerLineBigger2
  );
  const outerPoint3 = mirrorPointOnPoint(outerPoint1, middleOuter1);
  const outerPoint4 = mirrorPointOnPoint(outerPoint2, middleOuter2);
  const outers = [outerPoint3, outerPoint1, outerPoint4, outerPoint2];
  const {
    isXShape,
    isLShape,
    isTShape,
    startOfBiggerInSmaller,
    startOfSmallerInBigger,
    endOfBiggerInSmaller,
    endOfSmallerInBigger,
  } = getShape(biggerPart, smallerPart);
  const intersects = [ll, lr, rr, rl];

  return (
    <>
      <polygon
        points={makePolygonString(outers)}
        fill={"white"}
        stroke={"white"}
        strokeWidth={strokeWidth + 2}
      />
      <polygon
        points={makePolygonString(intersects)}
        fill={"white"}
        stroke={"white"}
        strokeWidth={strokeWidth + 2}
      />
      {isLShape && (
        <LShape
          {...{
            startOfBiggerInSmaller,
            startOfSmallerInBigger,
            endOfBiggerInSmaller,
            endOfSmallerInBigger,
            intersects,
            outers,
            child,
            parent,
            biggerPart,
            smallerPart,
            strokeWidth,
            strokeColor,
          }}
        />
      )}
      {isTShape && (
        <TShape
          {...{
            startOfBiggerInSmaller,
            startOfSmallerInBigger,
            endOfBiggerInSmaller,
            endOfSmallerInBigger,
            intersects,
            outers,
            biggerPart,
            smallerPart,
            strokeWidth,
            strokeColor,
          }}
        />
      )}
      {isXShape && (
        <XShape
          {...{
            intersects,
            outers,
            biggerPart,
            smallerPart,
            strokeWidth,
            strokeColor,
          }}
        />
      )}
    </>
  );
}

import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import { useContext } from "react";
import DistanceMeasurement from "../MeasurementsVisualization/DistanceMeasurement";

export const useMeasurementObjs = () => {
  // draw all drawen components
  const { measurements } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  if (!measurements) {
    return null;
  }
  const measureComps = Object.values(measurements).map((measure) => {
    const index = measure.index;

    return <DistanceMeasurement index={index} key={index} />;
  });
  return measureComps;
};

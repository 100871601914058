import { useContext, useEffect } from "react";
import { StatusContext, StatusContextType } from "../../Context/StatusContext";
import {
  PendingContext,
  PendingContextType,
} from "../../Context/PendingContext";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { useNextDisabled } from "./useNextDisabled";
import { useChangeToMeasurements } from "./onStatusUpdate/useChangeToMeasurements";
import { useChangeToAngleMeasurements } from "./onStatusUpdate/useChangeToAngleMeasurements";
import { useChangeToSolvingMechanicalProblem } from "./onStatusUpdate/useChangeToSolvingMechanicalProblem";
import { useRecalcHiddenAngleMeasures } from "./onStatusUpdate/useRecalcHiddenAngleMeasures";
import { useRecalcHiddenDistMeasures } from "./onStatusUpdate/useRecalcHiddenDistMeasures";
import { useCreateFreischnittVisualLoads } from "./onStatusUpdate/useCreateFreischnittVisualLoads";
import { useCreateFreischnittCalcLoads } from "./onStatusUpdate/useCreateFreischnittCalcLoads";
import {
  ConsoleContext,
  ConsoleContextType,
} from "features/Context/ConsoleContext";
import { useFakeRun } from "./onStatusUpdate/useFakeRun";
import { StageStartMsg } from "../StyleOnlyComponents/StageStartMsg";
import { Typography } from "@material-ui/core";
import { useLogSvg } from "./onStatusUpdate/useLogSvg";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  flexBox: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "top",
    width: "100%",
  },
  stepperStyle: {
    padding: "2px",
    background: "rgba(0,0,0,0)",
  },
  alternativeLabel: {
    marginTop: "3px!important",
  },
  instructions: {
    fontSize: "12px",
    color: "white",
  },

  button: {
    paddingTop: "8px",
    paddingRight: "10px",
  },
  width80: {
    width: "80%",
  },
  widthButton: {
    width: "160px",
  },
  smalltext: {
    fontSize: "8px",
  },
  innerBtn: {
    width: "100%",
  },
});
export function BuildingStepper() {
  const { t } = useTranslation();
  const { status, setStatus, statusOptions, timingStatus, setTimingStatus } =
    useContext(StatusContext) as StatusContextType;
  const { addEntryToConsole } = useContext(
    ConsoleContext
  ) as ConsoleContextType;
  const { removePendingObject } = useContext(
    PendingContext
  ) as PendingContextType;
  const [isNextButtonDisabled, nextButtonTitle] = useNextDisabled();
  const classes = useStyles();
  const activeStep = status.step;
  const handleSwitchToMeasurementStatus = useChangeToMeasurements();
  const handleSwitchToMeasurementStatusAngle = useChangeToAngleMeasurements();
  const solveMechanicalProblem = useChangeToSolvingMechanicalProblem();
  const recalcHiddenAngleMeasures = useRecalcHiddenAngleMeasures();
  const recalcHiddenDistMeasures = useRecalcHiddenDistMeasures();
  const createFreischnittVisualLoads = useCreateFreischnittVisualLoads();
  const createFreischnittCalcLoads = useCreateFreischnittCalcLoads();
  const logPlaygroundSvg = useLogSvg();
  //This fake run determines if the system is statically determinate
  useFakeRun();
  useEffect(() => {
    if (
      timingStatus === "BeforeRecalculatingMeasures" &&
      status.status === "freischnitt"
    ) {
      recalcHiddenDistMeasures();
      recalcHiddenAngleMeasures();
      setTimingStatus("BeforeCalculatingFreischnitt");
    }
    if (
      timingStatus === "BeforeCalculatingFreischnitt" &&
      status.status === "freischnitt"
    ) {
      createFreischnittVisualLoads();
      createFreischnittCalcLoads();
      setTimingStatus("BeforeCalculatingSolution");
    }
    if (
      timingStatus === "BeforeCalculatingSolution" &&
      status.status === "solution"
    ) {
      solveMechanicalProblem();
      setTimingStatus("AfterCalculatingSolution");
    }
  }, [timingStatus, status]);
  const handleNext = () => {
    setStatus((prev) => statusOptions[prev.step + 1]);
    const nextStatusOption = statusOptions[status.step + 1];
    addEntryToConsole(
      <StageStartMsg
        msg={nextStatusOption.consoleMsg}
        stageName={nextStatusOption.text}
      ></StageStartMsg>
    );

    if (status.status === "system") {
      removePendingObject();
      logPlaygroundSvg();
    } else if (status.status === "load") {
      handleSwitchToMeasurementStatus();
      handleSwitchToMeasurementStatusAngle();
      setTimingStatus("BeforeRecalculatingMeasures");
      logPlaygroundSvg();
    } else if (status.status === "measurements") {
      //maybe timeout here or context with spinner until calculated
      logPlaygroundSvg();
    } else if (status.status === "freischnitt") {
      logPlaygroundSvg();
    }
  };
  const isLastStage = status.step === 4;
  const nexStageName = statusOptions[status.step + 1]
    ? statusOptions[status.step + 1].text
    : "";
  return (
    <div className={`${classes.flexBox}`}>
      <div className={classes.width80}>
        <Stepper
          className={classes.stepperStyle}
          activeStep={activeStep}
          alternativeLabel
        >
          {statusOptions.map((statusObj) => (
            <Step key={statusObj.text}>
              <StepLabel
                classes={{ alternativeLabel: classes.alternativeLabel }}
              >
                {statusObj.text}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div title={nextButtonTitle} className={classes.button}>
        {!isLastStage && (
          <Button
            className={classes.widthButton}
            disabled={isNextButtonDisabled}
            variant="contained"
            color={"primary"}
            onClick={handleNext}
          >
            <div className={classes.innerBtn}>
              <Typography className={classes.smalltext} align="left">
                {t("Proceed to:")}
              </Typography>
              {nexStageName}
            </div>
          </Button>
        )}
        {isLastStage && <div className={classes.widthButton}></div>}
      </div>
    </div>
  );
}

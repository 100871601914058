import { Point } from "./linearAlgebraModel";
import { addV, sM, subV } from "./vectorUtils";

export function mirrorPointOnPoint(
  pointToMirror: Point,
  middlePoint: Point
): Point {
  const connectionVec = subV(middlePoint, pointToMirror);
  return addV(pointToMirror, sM(2, connectionVec));
}

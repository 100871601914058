import { Button, Typography } from "@material-ui/core";
import {
  PlaygroundContext,
  PlaygroundContextType,
} from "features/Context/PlaygroundContext";
import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../../Context/PendingContext";

import { Slider } from "../InputComponents/Slider";
import { ControlAccordion } from "features/Components/Functionality_Right/VisualElements/ControlAccordion";
import { useTranslation } from "react-i18next";

export function WidthControl() {
  const { t } = useTranslation();
  const { pendingObject, setPendingScale } = useContext(
    PendingContext
  ) as PendingContextType;
  const { lastPercentageUsed } = useContext(
    PlaygroundContext
  ) as PlaygroundContextType;

  if (!pendingObject) {
    return null;
  }
  const scale = pendingObject.scale as number;

  const handleWidthChange = (lastPercentageUsed: number) =>
    setPendingScale(lastPercentageUsed);

  const marks = [
    { value: 100, label: "100%" },
    { value: 200, label: "200%" },
    { value: 300, label: "300%" },
    { value: 50, label: "50%" },
    { value: 10, label: "10%" },
  ];

  return (
    <div className={"rectSizeControl"}>
      <ControlAccordion
        titleMainElement={<Typography>{t("Symbol Scale")}</Typography>}
        mainContent={
          <>
            <Slider
              marks={marks}
              ownMarks={true}
              description={""}
              min={10}
              step={1}
              max={300}
              value={scale}
              handleChange={handleWidthChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleWidthChange(lastPercentageUsed);
              }}
            >
              {t("Set to last used value: ") + lastPercentageUsed + "%"}
            </Button>
          </>
        }
        expanded={false}
      ></ControlAccordion>
    </div>
  );
}

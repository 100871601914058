import { createContext } from "react";

export const DrawenLoadContext = createContext<{} | null>(null);

export function DrawenLoadProvider(props: any) {
  // const [loadObjects, setLoadObjects] = useState({});

  return (
    <DrawenLoadContext.Provider value={{}}>
      {props.children}
    </DrawenLoadContext.Provider>
  );
}

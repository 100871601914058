import { DrawenProvider } from "./DrawenContext";
import { PendingProvider } from "./PendingContext";
import { PlaygroundProvider } from "./PlaygroundContext";
import { StatusProvider } from "./StatusContext";
import { PendingLoadProvider } from "./PendingLoadContext";
import { DrawenLoadProvider } from "./DrawenLoadContext";
import { BearingStyleProvider } from "./BearingStyleContext";
import { HighlightProvider } from "./HighlightContext";
import { MouseMoveProvider } from "./MouseMoveContext";
import { LoadStyleProvider } from "./LoadStyleContext";
import { ColorProvider } from "./ColorContext";
import { MeasureDistanceProvider } from "./MeasureDistanceContext";
import { MeasureAngleProvider } from "./MeasureAngleContext";
import { FreischnittProvider } from "./FreischnittContext";
import { ConsoleProvider } from "./ConsoleContext";
import { LeftPaneProvider } from "./LeftPaneContext";
import { FakeRunProvider } from "./FakeRunContext";
import { MechanicalSolutionProvider } from "./MechanicalSolutionContext";
import { HelmetProvider } from "react-helmet-async";

export function UniversalProvider(props: any) {
  return (
    <HelmetProvider>
      <LeftPaneProvider>
        <PlaygroundProvider>
          <ColorProvider>
            <StatusProvider>
              <ConsoleProvider>
                <MeasureDistanceProvider>
                  <MeasureAngleProvider>
                    <DrawenProvider>
                      <PendingProvider>
                        <LoadStyleProvider>
                          <PendingLoadProvider>
                            <DrawenLoadProvider>
                              <BearingStyleProvider>
                                <HighlightProvider>
                                  <MouseMoveProvider>
                                    <FreischnittProvider>
                                      <FakeRunProvider>
                                        <MechanicalSolutionProvider>
                                          {props.children}
                                        </MechanicalSolutionProvider>
                                      </FakeRunProvider>
                                    </FreischnittProvider>
                                  </MouseMoveProvider>
                                </HighlightProvider>
                              </BearingStyleProvider>
                            </DrawenLoadProvider>
                          </PendingLoadProvider>
                        </LoadStyleProvider>
                      </PendingProvider>
                    </DrawenProvider>
                  </MeasureAngleProvider>
                </MeasureDistanceProvider>
              </ConsoleProvider>
            </StatusProvider>
          </ColorProvider>
        </PlaygroundProvider>
      </LeftPaneProvider>
    </HelmetProvider>
  );
}

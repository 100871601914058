import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timestamp: {
    color: "darkgrey",
    fontSize: "10px",
  },
}));

export function TimeStamp(props: any) {
  const classes = useStyles();
  return <div className={classes.timestamp}>--- {props.children}</div>;
}

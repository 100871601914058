import { Input, makeStyles, useTheme } from "@material-ui/core";
import LatexForHTML from "features/Components/Playground/helper/LatexForHTML";
import { inputColor } from "features/Components/StyleOnlyComponents/Color";

const useStyles = (props: any) =>
  makeStyles((theme) => ({
    noBorderRadius: {
      borderRadius: "0px",
    },
    preFactorWrapper: {
      width: props.height * 2.1 + "px",
      transform: "translateY(" + props.height / 8 + "px) ",
    },
    flexPreFactor: {
      display: "flex",
      alignItems: "center",
      position: "relative",
    },
    flexWrapper: {
      display: "flex",
      alignItems: "flex-end",
      marginBottom: "10px",
    },
    loadNameF: {
      width: props.height * 0.8 + "px",
      transform:
        "translateY(" +
        props.height / 3 +
        "px) translateX(-" +
        props.height / 3 +
        "px)",
    },
    loadNameM: {
      width: props.height * 0.8 + "px",
      transform:
        "translateY(" +
        props.height / 3 +
        "px) translateX(-" +
        props.height / 10 +
        "px)",
    },
    input: {
      background: inputColor,
      paddingBottom: "0px",
      paddingTop: "0px",
      marginBottom: "5px",
    },
  }));
export default function SymbolRep({
  handleNameChange,
  handlePreFactorChange,
  preFactor,
  name,
  symbol = "F",
  height = 40,
  disabled = false,
}: {
  handleNameChange: Function;
  handlePreFactorChange: Function;
  preFactor: string;
  name: string;
  symbol?: string;
  height?: number;
  disabled?: boolean;
}) {
  const theme = useTheme();
  const color = disabled ? theme.palette.action.disabled : "black";
  const classes = useStyles({ height })();
  return (
    <div className={classes.flexWrapper}>
      <div className={classes.flexPreFactor}>
        <div className={classes.preFactorWrapper}>
          <Input
            disabled={disabled}
            type="text"
            value={preFactor}
            margin="dense"
            style={{
              height: height * 1.2 + 4,
              fontSize: height + 4 + "px",
              textAlign: "right",
            }}
            inputProps={{
              className: classes.input,
              style: { textAlign: "right" },
            }}
            onChange={(e) => {
              handlePreFactorChange(e.target.value);
            }}
          />
        </div>

        <LatexForHTML
          latex={"\\cdot " + symbol}
          height={height}
          color={color}
        />
      </div>
      <div className={symbol === "F" ? classes.loadNameF : classes.loadNameM}>
        <Input
          disabled={disabled}
          style={{
            height: height / 1.8 + 4,
            fontSize: height / 2 + 4 + "px",
          }}
          inputProps={{
            className: classes.input,
          }}
          type="text"
          value={name}
          onChange={(e) => {
            handleNameChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

import { IconButton } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { saveSvg } from "features/Components/BearingEditor/util/saveSVG";
import { useTranslation } from "react-i18next";

export default function SvgDownloadButton({
  downLoadName = "system.svg",
  elementID = "playground",
  tooltip = "Export the drawen elements as svg image.",
}) {
  const { t } = useTranslation();
  tooltip =
    tooltip === "Export the drawen elements as svg image."
      ? t("Export the drawen elements as svg image.")
      : tooltip;
  const handleClick = () => {
    const svgEl = document.getElementById(elementID);
    if (!svgEl) {
      console.warn("The svg el to download was not found");
      return;
    }
    saveSvg(svgEl, downLoadName);
  };
  return (
    <IconButton title={tooltip} onClick={handleClick}>
      <PhotoCameraIcon />
    </IconButton>
  );
}

import { useCallback, useState } from "react";
import { Mark } from "./MarkModel";
const ABRITRARY_NOT_SNAPPED = -12345;
const useMarkSnapper = (marks: Array<Mark>, snapThreshold = 5) => {
  const markValues = marks.map((markObj) => markObj.value);
  const [lastCommitted, setLastCommitted] = useState<number>(
    ABRITRARY_NOT_SNAPPED
  );
  const snapConverter = useCallback(
    (newVal: number) => {
      if (Math.abs(newVal - lastCommitted) > 2 * snapThreshold) {
        setLastCommitted(ABRITRARY_NOT_SNAPPED);
      }

      let newTemp = newVal;
      markValues.forEach((mark) => {
        if (lastCommitted) {
          if (
            Math.abs(mark - newVal) < snapThreshold &&
            lastCommitted !== mark &&
            Math.abs(lastCommitted - newVal) > snapThreshold
          ) {
            newTemp = mark;
            // setLastValSnappedTo(mark);
          }
        } else {
          if (
            Math.abs(mark - newVal) < snapThreshold &&
            lastCommitted !== mark
          ) {
            newTemp = mark;
            // setLastValSnappedTo(mark);
          }
        }
      });
      return newTemp;
    },
    [lastCommitted]
  );
  return [snapConverter, setLastCommitted];
};

export default useMarkSnapper;

import { Link, Typography, makeStyles } from "@material-ui/core";
import { EquationBundle } from "features/Components/Functionality_Bottom/LatexEquationVisuals/EquationBundle";
import {
  MechanicalSolutionContext,
  MechanicalSolutionContextType,
} from "features/Context/MechanicalSolutionContext";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { issueLink } from "featuresLandingPage/mainContent/CalculatorLimits";
import { useContext } from "react";
import { ErrorMsgSolution } from "./ErrorMsgSolution";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  buttonColor: {
    background: "#820505",
  },
  container: {
    overflow: "scroll",
    height: "100%",
  },
  padding: {
    padding: "10px",
  },
  margin: {
    marginTop: "20px",
  },
});
export default function SolutionVisualizer() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { status } = useContext(StatusContext) as StatusContextType;
  const { unknowns, balanceEquations, solutionEquations, calculationError } =
    useContext(MechanicalSolutionContext) as MechanicalSolutionContextType;
  const isInSolutionMode = status.status === "solution";

  return (
    <div className={classes.container}>
      <div className={classes.padding}>
        <div>
          <EquationBundle
            header={t("Equation Of Equilibrium")}
            equations={balanceEquations}
          ></EquationBundle>
        </div>
        <div className={classes.margin}>
          <EquationBundle
            header={t("Unknown Reaction Loads")}
            equations={unknowns}
            wrap
          ></EquationBundle>
        </div>

        {isInSolutionMode && (
          <div className={classes.margin}>
            <EquationBundle
              header={t("Reaction Loads")}
              equations={solutionEquations}
            ></EquationBundle>
          </div>
        )}
        {calculationError && <ErrorMsgSolution></ErrorMsgSolution>}
      </div>
    </div>
  );
}

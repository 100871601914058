import React, { useContext } from "react";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "../../../Context/PendingLoadContext";

import { SelectionOption } from "./SelectionOption";
import { LoadNames } from "features/Context/types/ConstructionElementModel";
import { makeStyles } from "@material-ui/core";
import { ForceIcon } from "features/Components/Functionality_Left/icons/ForceIcon";
import { StreckenlastIcon } from "features/Components/Functionality_Left/icons/StreckenlastIcon";
import { MomentIcon } from "features/Components/Functionality_Left/icons/MomentIcon";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: "10px",
  },
});

export function LoadTypeSelector() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    deletePendingLoad,
    initializePendingLoad,
    setPendingLoadName,
    setPendingLoadTypeWasSelected,
    pendingLoad,
  } = useContext(PendingLoadContext) as PendingLoadContextType;
  const handleClickCreator = (type: LoadNames) => {
    const loadType = type;
    return () => {
      if (!pendingLoad || pendingLoad.parentIndex === "root") {
        return;
      }
      const parentIndex = pendingLoad.parentIndex;
      deletePendingLoad();
      //@ts-ignore
      initializePendingLoad(parentIndex, loadType);
      // setPendingLoadName(loadType);
      setPendingLoadTypeWasSelected(true);
    };
  };
  // const forceIsSelected = pendingLoad?.loadType === "force";
  // const momentIsSelected = pendingLoad?.loadType === "moment";
  // const streckenlastIsSelected = pendingLoad?.loadType === "streckenlast";
  const forceIsSelected = false;
  const momentIsSelected = false;
  const streckenlastIsSelected = false;

  return (
    <div className={classes.flex}>
      <SelectionOption
        isSelected={forceIsSelected}
        name={t("Force")}
        icon={
          <ForceIcon
            color={forceIsSelected ? "white" : "black"}
            size={40}
          ></ForceIcon>
        }
        handleClick={handleClickCreator("force")}
      ></SelectionOption>
      <SelectionOption
        isSelected={momentIsSelected}
        name={t("Moment")}
        icon={
          <MomentIcon
            color={momentIsSelected ? "white" : "black"}
            size={40}
          ></MomentIcon>
        }
        handleClick={handleClickCreator("moment")}
      ></SelectionOption>
      <SelectionOption
        isSelected={streckenlastIsSelected}
        name={t("Distributed Load")}
        icon={
          <StreckenlastIcon
            color={streckenlastIsSelected ? "white" : "black"}
            size={40}
          ></StreckenlastIcon>
        }
        handleClick={handleClickCreator("streckenlast")}
      ></SelectionOption>
    </div>
    // <FormControl component="fieldset">
    //   <FormLabel component="legend">Belastungsauswahl</FormLabel>
    //   <RadioGroup
    //     row
    //     aria-label="position"
    //     name="position"
    //     value={pendingLoad?.loadType}
    //     onChange={handleChange}
    //   >
    //     <FormControlLabel
    //       value="force"
    //       control={<Radio color="primary" />}
    //       label="Kraft"
    //       labelPlacement="end"
    //     />
    //     <FormControlLabel
    //       value="moment"
    //       control={<Radio color="primary" />}
    //       label="Drehmoment"
    //       labelPlacement="end"
    //     />
    //     <FormControlLabel
    //       value="streckenlast"
    //       control={<Radio color="primary" />}
    //       label="Streckenlast"
    //       labelPlacement="end"
    //     />
    //   </RadioGroup>
    // </FormControl>
  );
}

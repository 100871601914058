import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "features/Context/MeasureDistanceContext";
import { useContext } from "react";
import useDragAnnotation from "../../playgroundObjectHooks/useDragAnnotation";

export const useMeasureAnnotationDragHelper = (measureIndex: MeasureIndex) => {
  const {
    setMeasureAnnotationDraggable,
    removeAllDraggableAnnotations,
    measurements,
  } = useContext(MeasureDistanceContext) as MeasureDistanceContextType;
  const { handleDragAnnotationStart } = useDragAnnotation();

  if (!measurements || !measurements[measureIndex]) {
    return {
      handleClick: (e: React.MouseEvent) => null,
      handleMouseDown: (e: React.MouseEvent) => null,
    };
  }
  const measure = measurements[measureIndex];
  const annotationIsDraggable = measure.annotationIsDraggable;

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    removeAllDraggableAnnotations();
    setMeasureAnnotationDraggable(true, measureIndex);
  };
  const handleMouseDown = (e: React.MouseEvent) => {
    // console.log("onMouseDown", loadObject);

    e.stopPropagation();
    handleDragAnnotationStart(e, annotationIsDraggable);
  };
  return { handleClick, handleMouseDown };
};

import React from "react";

export default function PolyLine(props) {
  const { points, ...rest } = props;
  let pointString = "";
  points.forEach((point) => {
    pointString = pointString + point[0] + ", " + point[1] + " ";
  });
  return <polyline points={pointString} {...rest} />;
}

import { makeStyles } from "@material-ui/core";
import { AngleControl } from "./GeometryControls/AngleControl";
import { GeometryControl } from "./GeometryControls/GeometryControl";
import { StickControl } from "./GeometryControls/StickControl";
import { SubmitFormSystem } from "../SubmitPending/SubmitFormSystem";
const useStyles = makeStyles({
  fullWidth: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  height90: {
    boxSizing: "border-box",
    overflowY: "scroll",
    overflowX: "hidden",
    flexGrow: 1,
    padding: "20px",
  },
  height10: { flexShrink: 0 },
});
export function PartAndBearingControl() {
  const classes = useStyles();
  return (
    <div className={classes.fullWidth}>
      <div className={classes.height90}>
        <GeometryControl></GeometryControl>
        <AngleControl></AngleControl>
        <StickControl />
      </div>
      <div className={classes.height10}>
        <SubmitFormSystem />
      </div>
    </div>
  );
}

import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { useContext } from "react";
import { MomentMirroredIcon } from "features/Components/Functionality_Left/icons/MomentMirroredIcon";
import { MomentIcon } from "features/Components/Functionality_Left/icons/MomentIcon";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles({
  paper: {
    height: "54px",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    borderTop: "solid 1px darkgrey",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  padding: {
    marginLeft: "10px",
  },
});
export default function ToggleOrientation() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { setPendingOrientation, pendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  if (!pendingLoad) {
    return null;
  }

  const handleClickMirror = () => {
    setPendingOrientation(-1);
  };
  const handleClick = () => {
    setPendingOrientation(1);
  };
  const orientationIsNotMirrored = pendingLoad.loadSpec.orientation === 1;
  return (
    <Paper square className={classes.flex + " " + classes.paper}>
      <Typography>{t("Orientation")}</Typography>
      <Button
        disableElevation
        className={classes.padding}
        color={orientationIsNotMirrored ? "primary" : "default"}
        variant={"contained"}
        onClick={() => {
          handleClick();
        }}
      >
        <MomentMirroredIcon
          color={orientationIsNotMirrored ? "White" : "black"}
        ></MomentMirroredIcon>
      </Button>
      <Button
        disableElevation
        className={classes.padding}
        color={!orientationIsNotMirrored ? "primary" : "default"}
        variant={"contained"}
        onClick={() => {
          handleClickMirror();
        }}
      >
        <MomentIcon
          color={!orientationIsNotMirrored ? "White" : "black"}
        ></MomentIcon>
      </Button>
    </Paper>
  );
}

import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "../MeasureDistanceContext";
import { LoadIndex, LoadObjects } from "../types/LoadModel";
import { useContext } from "react";
export const useRemoveMeasurementsFromLoad = () => {
  const { removeMeasurement } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const removeMeasurementFromLoad = (
    index: LoadIndex,
    loadObjects: LoadObjects
  ) => {
    if (!(loadObjects && loadObjects[index])) {
      return;
    }
    //currently only streckenlast has measurements
    if (loadObjects[index].loadType !== "streckenlast") {
      return;
    }
    const streckenlastLoad = loadObjects[index];
    const startMeasureIndex: MeasureIndex =
      streckenlastLoad.loadSpec.startMeasurementIndex;
    const lengthMeasureIndex: MeasureIndex =
      streckenlastLoad.loadSpec.lengthMeasurementIndex;
    const measureIndices = [startMeasureIndex, lengthMeasureIndex];
    removeMeasurement(measureIndices);
  };
  return removeMeasurementFromLoad;
};

import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import {
  MouseMoveContext,
  MouseMoveContextType,
} from "features/Context/MouseMoveContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { Load } from "features/Context/types/LoadModel";
import { useCallback, useContext } from "react";

const useDragAnnotation = () => {
  const {
    loadWithDraggableAnnotation,
    setTextOffset,
    loadHasDraggableAnnotation,
  } = useContext(PendingLoadContext) as PendingLoadContextType;
  const { draggableMeasure, setMeasureAnnotationTextOffset } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const {
    draggableMeasure: draggableAngleMeasure,
    setMeasureAnnotationTextOffset: setAngleMeasureAnnotationTextOffset,
  } = useContext(MeasureAngleContext) as MeasureAngleContextType;
  const { annotationsIsDraggable, setAnnotationsIsDraggable } = useContext(
    MouseMoveContext
  ) as MouseMoveContextType;

  let { lastX, setLastX, lastY, setLastY } = useContext(
    MouseMoveContext
  ) as MouseMoveContextType;
  //@ts-ignore
  const handleMouseMoveAnnotation = useCallback((e) => {
    // in playground

    e.preventDefault();
    if (annotationsIsDraggable) {
      if (!lastX || !lastY) {
        lastX = 0;
        lastY = 0;
      }
      const deltaX = e.pageX - lastX;
      const deltaY = -e.pageY - lastY;
      // const newOffsetX = loadWithDraggableAnnotation.textOffsetX + deltaX;
      // const newOffsetY = loadWithDraggableAnnotation.textOffsetY + deltaY;
      if (loadWithDraggableAnnotation) {
        //load has draggable annotation
        setTextOffset([deltaX, deltaY], loadWithDraggableAnnotation?.index);
      } else if (draggableMeasure) {
        setMeasureAnnotationTextOffset(
          [deltaX, deltaY],
          draggableMeasure?.index
        );
        //measurement has draggable annotation
      } else if (draggableAngleMeasure) {
        setAngleMeasureAnnotationTextOffset(
          [deltaX, deltaY],
          draggableAngleMeasure?.index
        );
      }
      setLastX(e.pageX);
      setLastY(-e.pageY);
    }
  });
  const handleDragLoadStart = (e: React.MouseEvent, load: Load) => {
    e.preventDefault();

    if (loadHasDraggableAnnotation(load)) {
      setAnnotationsIsDraggable(true);
      setLastX(e.pageX);
      setLastY(-e.pageY);
    }
  };
  const handleDragAnnotationStart = (
    e: React.MouseEvent,
    thisAnnotationIsDraggable: boolean
  ) => {
    e.preventDefault();

    if (thisAnnotationIsDraggable) {
      setAnnotationsIsDraggable(true);
      setLastX(e.pageX);
      setLastY(-e.pageY);
    }
  };
  //@ts-ignore
  const handleDragEndAnnotation = useCallback((e) => {
    // in playground
    e.preventDefault();
    setAnnotationsIsDraggable(false);
  });
  return {
    handleDragEndAnnotation,
    handleDragLoadStart,
    handleMouseMoveAnnotation,
    handleDragAnnotationStart,
  };
};

export default useDragAnnotation;

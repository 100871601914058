import PolyLine from "features/Components/Playground/Cords/PolyLine";
import { elongateLine } from "features/LinearAlgebra/elongateLine";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import {
  addV,
  areCloseScalar,
  distance,
  normalize,
  reverseLine,
  scalarProd,
  sM,
  subV,
} from "features/LinearAlgebra/vectorUtils";
import { tShapePointFinder } from "./tShapepointFinder";

export default function TShape({
  startOfBiggerInSmaller,
  startOfSmallerInBigger,
  endOfBiggerInSmaller,
  endOfSmallerInBigger,
  intersects,
  outers,
  biggerPart,
  smallerPart,
  strokeWidth,
  strokeColor,
}) {
  const { straightPoints, cornerPoints, lineT } = tShapePointFinder(
    biggerPart,
    smallerPart,
    startOfBiggerInSmaller,
    startOfSmallerInBigger,
    endOfBiggerInSmaller,
    endOfSmallerInBigger,
    intersects
  );
  const elongation = 3 * strokeWidth;
  const straightLine = elongateLine(
    // @ts-ignore
    reverseLine(elongateLine(straightPoints, elongation)),
    elongation
  );
  // @ts-ignore
  const corner1Elong = elongateLine(cornerPoints, elongation)[1];
  const corner1 = cornerPoints[1];
  const corner2 = cornerPoints[0];
  // @ts-ignore
  const corner2Elong = elongateLine(reverseLine(cornerPoints), elongation)[1];
  let fittingOuter1 = false;
  outers.forEach((outerPoint) => {
    const diffOuterToIntersect = normalize(subV(outerPoint, corner1));
    if (
      // @ts-ignore
      areCloseScalar(scalarProd(getLineDir(lineT), diffOuterToIntersect), 1)
    ) {
      //outer point is in correct direction
      fittingOuter1 = outerPoint;
    }
  });
  if (fittingOuter1 === false) {
    // @ts-ignore
    fittingOuter1 = addV(corner1, sM(elongation, getLineDir(lineT)));
  }

  // @ts-ignore
  const distancer = distance(fittingOuter1, corner1);

  // @ts-ignore
  const fittingOuter2 = addV(corner2, sM(distancer, getLineDir(lineT)));
  return (
    <>
      <PolyLine
        points={straightLine}
        fill="none"
        // stroke={"green"}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
      <PolyLine
        id="corner1"
        points={[corner1Elong, corner1, fittingOuter1]}
        fill="none"
        // stroke={"red"}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
      <PolyLine
        id="corner2"
        points={[corner2Elong, corner2, fittingOuter2]}
        fill="none"
        // stroke={"red"}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="butt"
      />
    </>
  );
}

import { makeStyles, Typography } from "@material-ui/core";
import { ConsoleObj } from "features/Context/ConsoleContext";
import { TimeStamp } from "./TimeStamp";

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "top",
  },
  margin: {
    marginLeft: "15px",
  },
}));

export function ConsoleElement({ consoleObj }: { consoleObj: ConsoleObj }) {
  const classes = useStyles();
  return (
    <>
      <TimeStamp>{consoleObj.time}</TimeStamp>
      <div className={classes.flex}>
        <div className={classes.margin}> {consoleObj.content}</div>
      </div>
    </>
  );
}

import { useContext } from "react";
import { EntitySelector } from "./SystemMode/SelectBar/EntitySelectBar";

import {
  PendingContext,
  PendingContextType,
} from "../../Context/PendingContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "../../Context/PendingLoadContext";
import { StatusContext, StatusContextType } from "../../Context/StatusContext";
import { LoadControl } from "./LoadMode/LoadControl";
import { makeStyles } from "@material-ui/core";
import MeasurementControl from "./MeasurementMode/MeasurementControl";
import SolutionVisualizer from "./MechanicalSolutionMode/SolutionVisualizer";
import { RootBearingControl } from "./SystemMode/RootBearingControl";
import { PartAndBearingControl } from "./SystemMode/PartAndBearingControl";
import { panelBackground } from "../StyleOnlyComponents/Color";
import { LoadSelector } from "./LoadMode/LoadSelector";
const useStyles = makeStyles({
  rightbar: {
    height: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
    background: panelBackground,
  },
  border: {
    border: "2px solid red",
    width: "max-content",
  },
});
export function RightBar() {
  const classes = useStyles();
  const { isPending, isInitialized, pendingObject } = useContext(
    PendingContext
  ) as PendingContextType;
  const { isPendingLoad, pendingLoad } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  const { status } = useContext(StatusContext) as StatusContextType;
  const isInSystemMode = status.status === "system";
  const isInLoadMode = status.status === "load";
  const isInMeasurementMode = status.status === "measurements";
  const isInFreischnittMode = status.status === "freischnitt";
  const isInSolutionMode = status.status === "solution";

  const controls = () => {
    if (isPending && isInSystemMode) {
      const isBearing = pendingObject?.mechanicalType === "bearing";
      if (isBearing && pendingObject.parentIndex === "root") {
        return (
          //BearingControl
          <RootBearingControl></RootBearingControl>
        );
      } else {
        return <PartAndBearingControl></PartAndBearingControl>;
      }
    } else if (
      isPendingLoad &&
      pendingLoad?.loadTypeWasSelected &&
      isInLoadMode
    ) {
      return <LoadControl />;
    } else if (isInMeasurementMode) {
      return <MeasurementControl />;
    } else if (isInFreischnittMode || isInSolutionMode) {
      return <SolutionVisualizer />;
    }
  };
  return (
    <div className={classes.rightbar}>
      {isInitialized &&
        !isPending &&
        pendingObject?.mechanicalType === "bearing" && <EntitySelector />}
      {isPendingLoad && !pendingLoad?.loadTypeWasSelected && isInLoadMode && (
        <LoadSelector />
      )}
      {controls()}
    </div>
  );
}

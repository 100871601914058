import { elongateLine } from "features/LinearAlgebra/elongateLine";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import {
  addV,
  middlePoint,
  rotateByDeg,
  sM,
} from "features/LinearAlgebra/vectorUtils";
import PolyLine from "./PolyLine";

export default function LineArrow({
  line,
  arrowHeadLength = 15,
  color = "black",
  strokeWidth = 2,
  drawLine = true,
  arrowWidth = 5, //range from 0-10
  ...rest
} = {}) {
  const newLine = elongateLine(line, -arrowHeadLength);
  const lineDir = getLineDir(line);
  const normalDir = rotateByDeg(lineDir, 90);
  const point1 = newLine[1];
  const denom = 0.5 + ((10 - arrowWidth) / 5) * 3;
  const point2 = addV(newLine[1], sM(arrowHeadLength / denom, normalDir));
  const point3 = addV(newLine[1], sM(arrowHeadLength, lineDir));
  const point4 = addV(newLine[1], sM(-arrowHeadLength / denom, normalDir));

  return (
    <g id="arrow">
      {drawLine && (
        <PolyLine
          points={newLine}
          {...rest}
          strokeWidth={strokeWidth}
          stroke={color}
        />
      )}
      <PolyLine
        points={[
          point1,
          point2,
          point3,
          point4,
          point1,
          middlePoint(point1, point2),
        ]}
        fill={color}
      />
    </g>
  );
}

import {
  DrawenObject,
  DrawenObjects,
} from "features/Context/types/DrawenObjectModel";

export function getAllParts(drawenObjects: DrawenObjects) {
  const playcedObjs: Array<DrawenObject> = Object.values(drawenObjects)?.filter(
    (obj): obj is DrawenObject => {
      if (obj.index === "root") {
        return false;
      }
      return obj.mechanicalType === "part";
    }
  );
  return playcedObjs;
}

import { makeStyles } from "@material-ui/core";
import {
  addV,
  centerOfPoints,
  getSignedAngleToXAxis,
  normalize,
  sM,
  subV,
} from "features/LinearAlgebra/vectorUtils";
import { useContext } from "react";
import LatexAnnotation from "../helper/LatexAnnotation";
import { createAnnotation } from "../Loads/util/createAnnotation";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { AngleMeasurement } from "features/Context/types/AngleMeasurementModel";
import { ArcWithArrowHead } from "../Cords/ArcWithArrowHead";
import { SvgLine } from "../Cords/SvgLine";
import { useMeasureAngleAnnotationDragHelper } from "../helper/util/useAngleMeasureAnnotationDragHelper";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  notClicked: { cursor: "pointer" },
  clicked: {
    cursor: "move",
  },
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
}));
export default function AngleMeasurementEl({
  index,
  fontSize = 15,
}: {
  index: number;
  fontSize?: number;
}) {
  const classes = useStyles();
  const { angleMeasurements, setIsBeingModified } = useContext(
    MeasureAngleContext
  ) as MeasureAngleContextType;
  const { handleClick, handleMouseDown } =
    useMeasureAngleAnnotationDragHelper(index);
  // TODO- Replace this
  //   const {
  //     handleClick: handleMeasureClick,
  //     handleMouseDown: handleMeasureMouseDown,
  //   } = useMeasureArrowDragHelper(index);
  if (!angleMeasurements) {
    return null;
  }
  const angleMeasurement: AngleMeasurement = angleMeasurements[index];
  if (
    !angleMeasurement ||
    angleMeasurement.isHidden ||
    angleMeasurement.isHiddenByUser ||
    angleMeasurement.isFake
  ) {
    return null;
  }
  const {
    arrowColor,
    annotationColor,
    anglePoints,
    textOffsetX,
    textOffsetY,
    arcRadius,
    unitSymbol,
    preFactor,
    mainSymbol,
    suffix,
    magnitude,
    unitFactor,
    isSymbolic,
    annotationIsDraggable,
    arrowIsDraggable,
    fromType,
    toType,
  } = angleMeasurement;

  const [latexStart, hasFracStart] = createAnnotation(
    "force",
    preFactor,
    unitFactor,
    unitSymbol,
    Math.abs(magnitude),
    suffix,
    isSymbolic,
    mainSymbol
  );

  const handleClickOnMeasure = (e: any) => {
    // handleMeasureClick(e);
    setIsBeingModified(true, index);
  };

  const pivotP = anglePoints.pivotPoint;
  const startP = addV(
    pivotP,
    sM(
      1.4 * arcRadius,
      normalize(subV(anglePoints.startPoint, anglePoints.pivotPoint))
    )
  );
  const endP = addV(
    pivotP,
    sM(
      1.4 * arcRadius,
      normalize(subV(anglePoints.endPoint, anglePoints.pivotPoint))
    )
  );
  // calculate angle between start and end
  // start angle
  const startAngle = getSignedAngleToXAxis(subV(startP, pivotP));
  const endAngle = getSignedAngleToXAxis(subV(endP, pivotP));
  if (!(Math.abs(endAngle - startAngle) > 0)) {
    return null;
  }
  const annotationPoint = centerOfPoints([startP, pivotP, endP]);
  const lineColor = "black";
  const strokeWidth = 2;
  const orientation = endAngle - startAngle > 0 ? 1 : 0;
  const showStartReferenceLine = fromType !== "force";
  const showEndReferenceLine = toType !== "force";
  const isHighlighted = angleMeasurement.isHighlighted;
  return (
    <g id={"Measure" + index}>
      <g
        className={isHighlighted ? classes.gOutline : ""}
        onClick={handleClickOnMeasure}
        onMouseDown={() => {
          /*handleMeasureMouseDown*/
        }}
      >
        <ArcWithArrowHead
          // angleLocal={+1}
          radius={arcRadius}
          cx={anglePoints.pivotPoint[0]}
          cy={anglePoints.pivotPoint[1]}
          angle={endAngle - startAngle}
          startAngle={startAngle}
          peakAngle={30}
          color={arrowColor}
          strokeWidth={1}
          orientation={orientation}
          strokeDashArray="1,0"
          size={2}
        ></ArcWithArrowHead>
        {/* <PolyLine points={[pivotP, endP, startP]}></PolyLine> */}
        {showStartReferenceLine && (
          <SvgLine
            point1={pivotP}
            point2={startP}
            fill={lineColor}
            stroke={lineColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          ></SvgLine>
        )}
        {showEndReferenceLine && (
          <SvgLine
            point1={pivotP}
            point2={endP}
            fill={lineColor}
            stroke={lineColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          ></SvgLine>
        )}
        {/* //TODO maybe implement a drag box with rgba0000 */}
      </g>

      <LatexAnnotation
        id={"measureAnnotation" + index}
        latex={latexStart}
        textOffsetX={textOffsetX}
        textOffsetY={textOffsetY}
        x={annotationPoint[0] + textOffsetX}
        color={annotationColor}
        y={annotationPoint[1] + textOffsetY}
        hasFrac={hasFracStart}
        height={fontSize}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        isClicked={annotationIsDraggable}
      />
    </g>
  );
}

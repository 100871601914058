import { useState, createContext } from "react";
import { Status } from "./types/StatusModel";
import { useTranslation } from "react-i18next";

export const StatusContext = createContext<StatusContextType | null>(null);
export type StatusContextType = {
  status: Status;
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  statusOptions: Array<Status>;
  timingStatus: TimingStatus;
  setTimingStatus: React.Dispatch<React.SetStateAction<TimingStatus>>;
};
export type TimingStatus =
  | "BeforeCalculatingMeasures"
  | "BeforeRecalculatingMeasures"
  | "BeforeCalculatingFreischnitt"
  | "BeforeCalculatingSolution"
  | "AfterCalculatingSolution";
export function StatusProvider(props: any) {
  const { t } = useTranslation();
  const [timingStatus, setTimingStatus] = useState<TimingStatus>(
    "BeforeCalculatingMeasures"
  );

  const statusOptions = [
    {
      step: 0,
      status: "system",
      text: t("System"),
      consoleMsg: t(
        "Please compose a system out of supports and beams. On the left you can add the elements."
      ),
    },
    {
      step: 1,
      status: "load",
      text: t("Loads"),
      consoleMsg: t(
        "Please add loads to your system. You can add them to each beam in the tree on the left."
      ),
    },
    {
      step: 2,
      status: "measurements",
      text: t("MeasurementsStage"),
      consoleMsg: t(
        "In this stage you can rename, hide or rescale measurements. Also consider to switch between symbolic and numeric measurements."
      ),
    },
    {
      step: 3,
      status: "freischnitt",
      text: t("Free Body Diagram"),
      consoleMsg: t(
        "The free body diagram of the system was created. Switch on the bearing visualization with the Checkbox below. Finally you can go to the next stage to calculate the reaction forces."
      ),
    },
    {
      step: 4,
      status: "solution",
      text: t("Reaction Loads"),
      consoleMsg: t("Finally there is the solution!"),
    },
  ];
  const [status, setStatus] = useState(statusOptions[0]);

  return (
    <StatusContext.Provider
      value={{
        status: status,
        setStatus: setStatus,
        statusOptions: statusOptions,
        timingStatus,
        setTimingStatus,
      }}
    >
      {props.children}
    </StatusContext.Provider>
  );
}

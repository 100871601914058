import { makeStyles } from "@material-ui/core";
import {
  BearingStyleContext,
  BearingStyleContextType,
} from "features/Context/BearingStyleContext";
import React, { useState, useCallback, useContext } from "react";
import PartRescaler from "../Functionality/PartRescaler";
import useDragResize from "../playgroundObjectHooks/useDragResize";
import { useObj } from "../playgroundObjectHooks/useObj";
import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  pointer: { cursor: "pointer" },
  move: {
    cursor: "move",
  },
  auto: {
    cursor: "auto",
  },
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
}));
export function Stab(props: any) {
  const classes = useStyles();
  const obj = useObj(props);
  const { strokeWidth, strokeColor, bodyColor } = useContext(
    BearingStyleContext
  ) as BearingStyleContextType;
  const { drawenObjects } = useContext(DrawenContext) as DrawenContextType;
  const { handleResizeStart } = useDragResize();
  if (!obj || !drawenObjects || obj.index === undefined) {
    return null;
  }
  const isPending = obj.index === "pending";
  //@ts-ignore
  const isHighlighted =
    (obj.index !== undefined && //@ts-ignore
      drawenObjects[obj.index] && //@ts-ignore
      drawenObjects[obj.index].isHighlighted) ||
    isPending;
  //@ts-ignore
  const w = obj.size?.width; // get width and height
  //@ts-ignore
  const h = obj.size?.height;
  const width = w;
  const height = h;

  const x = obj.x;
  const y = obj.y;
  //@ts-ignore
  const angle = -obj.angle; // the visual svg angle is the opposite of the mathematical angle
  //@ts-ignore
  const xRefOffset = obj.xRefRatio * w;
  //@ts-ignore
  const yRefOffset = obj.yRefRatio * h;
  //rescaler
  const offset = 5;

  return (
    <g
      className={isHighlighted ? classes.gOutline : ""}
      id="Stab"
      transform={
        "translate(" +
        x +
        "," +
        y +
        ") scale(1 -1) translate(" +
        -xRefOffset +
        "," +
        -yRefOffset +
        ") rotate(" +
        angle +
        "," +
        0 +
        "," +
        h / 2 +
        ")"
      }
      onMouseDown={(e) => props.handleDragStart(e, props.name)}
    >
      <rect
        id="Rectangle"
        className={isPending ? classes.move : classes.auto}
        x={0}
        y={0}
        // x={0 + strokeWidth / 2}
        // y={0 + strokeWidth / 2}
        width={w}
        height={h}
        // width={w - strokeWidth / 2}
        // height={h - strokeWidth / 2}
        fill={bodyColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />

      {/* {obj?.index === "pending" && (
        <PartRescaler
          object={obj}
          x={0}
          y={0}
          {...{ handleResizeStart, offset, width, height }}
        />
      )} */}
    </g>
  );
}

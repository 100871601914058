import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: "bold",
  },
}));

export function Bold(props: any) {
  const classes = useStyles();
  return <span className={classes.bold}>{props.children}</span>;
}

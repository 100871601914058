import React, { useState, createContext } from "react";
import { ResizeDir } from "./types/MouseMoveModel";

export const MouseMoveContext = createContext<MouseMoveContextType | null>(
  null
);
export type MouseMoveContextType = {
  lastX: null | number;
  setLastX: React.Dispatch<React.SetStateAction<number | null>>;
  lastY: null | number;
  setLastY: React.Dispatch<React.SetStateAction<number | null>>;
  setPendingBBox: (width: number, height: number) => void;
  pendingWidth: number;
  pendingHeight: number;
  pendingIsDraggable: boolean;
  setPendingIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  pendingIsRotatable: boolean;
  setPendingIsRotatable: React.Dispatch<React.SetStateAction<boolean>>;
  pendingIsResizable: boolean;
  setPendingIsResizable: React.Dispatch<React.SetStateAction<boolean>>;
  lastResizeDir: ResizeDir | "";
  setLastResizeDir: React.Dispatch<React.SetStateAction<ResizeDir | "">>;
  pendingLoadIsDraggable: boolean;
  setPendingLoadIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  consoleIsDraggable: boolean;
  setConsoleIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  annotationsIsDraggable: boolean;
  setAnnotationsIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
  anyLinearDragIsHappening: boolean;
  measurementArrowIsDraggable: boolean;
  setMeasurementArrowIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
};
export function MouseMoveProvider(props: any) {
  const [lastX, setLastX] = useState<number | null>(null);
  const [lastY, setLastY] = useState<number | null>(null);
  const [lastResizeDir, setLastResizeDir] = useState<ResizeDir | "">("");
  const [consoleIsDraggable, setConsoleIsDraggable] = useState(false);
  const [pendingWidth, setPendingWidth] = useState(100);
  const [pendingHeight, setPendingHeight] = useState(100);
  const [pendingIsDraggable, setPendingIsDraggable] = useState(false);
  const [pendingLoadIsDraggable, setPendingLoadIsDraggable] = useState(false);
  const [annotationsIsDraggable, setAnnotationsIsDraggable] = useState(false);
  const [measurementArrowIsDraggable, setMeasurementArrowIsDraggable] =
    useState(false);
  const [pendingIsRotatable, setPendingIsRotatable] = useState(false);
  const [pendingIsResizable, setPendingIsResizable] = useState(false);
  const anyLinearDragIsHappening =
    pendingIsDraggable ||
    pendingLoadIsDraggable ||
    annotationsIsDraggable ||
    measurementArrowIsDraggable;
  const setPendingBBox = (width: number, height: number) => {
    setPendingWidth(width);
    setPendingHeight(height);
  };

  return (
    <MouseMoveContext.Provider
      value={{
        lastX,
        setLastX,
        lastY,
        setLastY,
        setPendingBBox,
        pendingWidth,
        pendingHeight,
        pendingIsDraggable,
        setPendingIsDraggable,
        pendingIsRotatable,
        setPendingIsRotatable,
        pendingIsResizable,
        setPendingIsResizable,
        lastResizeDir,
        setLastResizeDir,
        pendingLoadIsDraggable,
        setPendingLoadIsDraggable,
        annotationsIsDraggable,
        setAnnotationsIsDraggable,
        anyLinearDragIsHappening,
        measurementArrowIsDraggable,
        setMeasurementArrowIsDraggable,
        consoleIsDraggable,
        setConsoleIsDraggable,
      }}
    >
      {props.children}
    </MouseMoveContext.Provider>
  );
}

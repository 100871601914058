import React, { useState, useContext } from "react";
import { PlaygroundContext } from "../../../Context/PlaygroundContext";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles, Box, Chip } from "@material-ui/core";
import { useZoomFunctions } from "features/Components/Playground/playgroundObjectHooks/useZoomFunctions";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles({
  smallerButton: {
    padding: "2.5px",
  },
});
export function ZoomAndPan() {
  const style = useStyle();
  const { resetZoom } = useZoomFunctions();
  const { isZooming, setIsZooming, isPaning, setIsPaning, currentZoom } =
    useContext(PlaygroundContext);
  const [valuesArray, setValuesArray] = useState([]);
  const handleChange = (event, newValuesArray) => {
    // console.log(newValuesArray)
    setValuesArray(newValuesArray);

    if (newValuesArray.find((item) => item == "zooming")) {
      // find zooming and paning in buttongroup
      setIsZooming(true);
    } else {
      setIsZooming(false);
    }
    if (newValuesArray.find((item) => item == "paning")) {
      setIsPaning(true);
    } else {
      setIsPaning(false);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="ZoomANdPan">
      <Box m={1}>
        <Chip
          label={Math.round(currentZoom * 100) / 100}
          variant="outlined"
          onClick={() => {
            resetZoom();
          }}
        />
        <ToggleButtonGroup
          exclusive={false}
          value={valuesArray}
          onChange={handleChange}
        >
          <ToggleButton
            className={style.smallerButton}
            size="small"
            value="zooming"
          >
            {t("Zooming")}
          </ToggleButton>
          <ToggleButton
            className={style.smallerButton}
            size="small"
            value="paning"
          >
            {t("Paning")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </div>
  );
}

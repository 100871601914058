import { MeasureDistanceContext } from "features/Context/MeasureDistanceContext";
import React, { useContext } from "react";
/**
 * This controller  is dispalyed on the right side.
 * It controls the measurements when in status 3
 * @returns
 */
export default function MeasurementControl() {
  // const { getCurrentlyModified } = useContext(MeasureDistanceContext);
  // const currentlyModifiedMeasurement = getCurrentlyModified();
  return (
    <div>
      {/* MeasurementControl */}
      {/* {currentlyModifiedMeasurement?.index} */}
      {/* {currentlyModifiedMeasurement?.isDraggable} */}
    </div>
  );
}

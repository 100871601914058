import { Load } from "features/Context/types/LoadModel";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { Line, Point } from "features/LinearAlgebra/linearAlgebraModel";
import {
  addV,
  distance,
  middlePoint,
  normalize,
  rotateByDeg,
  sM,
} from "features/LinearAlgebra/vectorUtils";
import DistanceArrow from "../Cords/DistanceArrow";
import DistanceMeasurement from "../MeasurementsVisualization/DistanceMeasurement";
import LineArrow from "../Cords/LineArrow";
import PolyLine from "../Cords/PolyLine";
import LatexAnnotation from "../helper/LatexAnnotation";
import { useLoadAnnotationDragHelper } from "../helper/util/useLoadAnnotationDragHelper";
import { createAnnotation } from "./util/createAnnotation";
import { makeStyles } from "@material-ui/core";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
}));
export default function Streckenlast({
  startUnitSymbol = "N/m",
  endUnitSymbol = "N/m",

  startUnitFactor = 1,
  endUnitFactor = 1,
  startPreFactor = "1",
  endPreFactor = "1",

  startIsSymbolic = true,
  endIsSymbolic = true,
  startSuffix = "1",
  endSuffix = "1",

  //magnitude is actual load
  startMagnitude = 50,
  endMagnitude = 100,
  //height is visual
  startHeight = 50,
  endHeight = 50,
  startPos = [0, 0],
  endPos = [0, 0],

  startMeasureSymbol = "a",
  lengthMeasureSymbol = "a",

  startMeasureSuffix = "",
  lengthMeasureSuffix = "",
  startMeasureMagnitude = 0,
  lengthMeasureMagnitude = 0,

  startMeasureIsSymbolic = true,
  lengthMeasureIsSymbolic = true,

  startMeasureUnitSymbol = "m",
  lengthMeasureUnitSymbol = "m",

  startMeasureUnitFactor = 1,
  lengthMeasureUnitFactor = 1,
  lengthMeasurementIndex = -1,
  startMeasurementIndex = -1,
  startTextOffsetX = 0,
  startTextOffsetY = 0,
  endTextOffsetX = 0,
  endTextOffsetY = 0,
  startMeasureTextOffsetX = 0,
  startMeasureTextOffsetY = 0,
  lengthMeasureTextOffsetX = 0,
  lengthMeasureTextOffsetY = 0,

  roughDistBetweenArrows = 30,
  color1 = "black",
  color2 = "black",
  textColor = "black",
  fontSize = 15,
  strokeWidth = 2,
  size = 2,
  textOffsetX = 0,
  textOffsetY = 0,
  loadObject = null,
  isHighlighted = false,
}: {
  startUnitSymbol: string;
  endUnitSymbol: string;

  startUnitFactor: number;
  endUnitFactor: number;
  startPreFactor: string;
  endPreFactor: string;

  startIsSymbolic: boolean;
  endIsSymbolic: boolean;
  startSuffix: string;
  endSuffix: string;

  //magnitude is actual load
  startMagnitude: number;
  endMagnitude: number;
  //height is visual
  startHeight: number;
  endHeight: number;
  startPos: Point;
  endPos: Point;

  startMeasureSymbol?: string;
  lengthMeasureSymbol?: string;

  startMeasureSuffix?: string;
  lengthMeasureSuffix?: string;
  startMeasureMagnitude?: number;
  lengthMeasureMagnitude?: number;

  startMeasureIsSymbolic?: boolean;
  lengthMeasureIsSymbolic?: boolean;

  startMeasureUnitSymbol?: string;
  lengthMeasureUnitSymbol?: string;

  startMeasureUnitFactor?: number;
  lengthMeasureUnitFactor?: number;
  lengthMeasurementIndex: number;
  startMeasurementIndex: number;
  startTextOffsetX: number;
  startTextOffsetY: number;
  endTextOffsetX: number;
  endTextOffsetY: number;
  startMeasureTextOffsetX?: number;
  startMeasureTextOffsetY?: number;
  lengthMeasureTextOffsetX?: number;
  lengthMeasureTextOffsetY?: number;

  roughDistBetweenArrows: number;
  color1: string;
  color2: string;
  textColor: string;
  fontSize?: number;
  strokeWidth?: number;
  size?: number;
  textOffsetX?: number;
  textOffsetY?: number;
  loadObject: Load | null;
  isHighlighted?: boolean;
}) {
  const classes = useStyles();
  const lineBottom: Line = [startPos, endPos];
  const lineDirBottom = getLineDir(lineBottom);

  const normalDir = normalize(rotateByDeg(lineDirBottom, 90));
  const topLeftPoint = addV(startPos, sM(startHeight, normalDir));
  const topRightPoint = addV(endPos, sM(endHeight, normalDir));
  const lineTop: Line = [topLeftPoint, topRightPoint];
  const lineDirTop = getLineDir(lineTop);
  const distanceBottom = distance(startPos, endPos);
  const distanceTop = distance(topLeftPoint, topRightPoint);
  const numberOfSpacesInLoad = Math.round(
    distanceBottom / roughDistBetweenArrows
  );
  const numberOfArrowsBetween = numberOfSpacesInLoad - 1;
  const actualDistBetweenArrowsBottom = distanceBottom / numberOfSpacesInLoad;
  const actualDistBetweenArrowsTop = distanceTop / numberOfSpacesInLoad;

  let forceLines = [[topLeftPoint, startPos]];
  for (let i = 1; i <= numberOfArrowsBetween; i++) {
    const newPointBottom = addV(
      startPos,
      sM(i * actualDistBetweenArrowsBottom, lineDirBottom)
    );
    const newPointTop = addV(
      topLeftPoint,
      sM(i * actualDistBetweenArrowsTop, lineDirTop)
    );
    forceLines.push([newPointTop, newPointBottom]);
  }
  forceLines = [...forceLines, [topRightPoint, endPos]];
  const lineList = forceLines.map((line) => {
    const distLine = distance(line[0], line[1]);
    const lineIsLongEnough = distLine > 10;
    return lineIsLongEnough ? (
      <LineArrow
        key={JSON.stringify(line)}
        line={line}
        color={color1}
        arrowHeadLength={10}
        strokeWidth={2}
      />
    ) : (
      ""
    );
  });
  const horizontalLineDir = getLineDir(lineBottom);
  const distOfMeasure = 8;
  const topLeftMeasure = addV(
    topLeftPoint,
    sM(-distOfMeasure, horizontalLineDir)
  );
  const topRightMeasure = addV(
    topRightPoint,
    sM(distOfMeasure, horizontalLineDir)
  );

  const bottomLeftMeasure = addV(
    startPos,
    sM(-distOfMeasure, horizontalLineDir)
  );

  const bottomRightMeasure = addV(endPos, sM(distOfMeasure, horizontalLineDir));
  const latexOffset = 10;
  const rightLatexPoint = addV(
    middlePoint(bottomRightMeasure, topRightMeasure),
    sM(latexOffset, horizontalLineDir)
  );
  const leftLatexPoint = addV(
    middlePoint(bottomLeftMeasure, topLeftMeasure),
    sM(-3 * latexOffset, horizontalLineDir)
  );
  const {
    // @ts-ignore
    handleClickStartHeight,
    // @ts-ignore
    handleClickEndHeight,
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleClickStartMeasurement,
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleClickLengthMeasurement,
    // @ts-ignore
    handleMouseDown,
  } = useLoadAnnotationDragHelper(loadObject);

  const [latexStart, hasFracStart] = createAnnotation(
    loadObject?.loadType,
    startPreFactor,
    startUnitFactor,
    startUnitSymbol,
    startMagnitude,
    startSuffix,
    startIsSymbolic
  );
  const [latexEnd, hasFracEnd] = createAnnotation(
    loadObject?.loadType,
    endPreFactor,
    endUnitFactor,
    endUnitSymbol,
    endMagnitude,
    endSuffix,
    endIsSymbolic
  );
  const startIsClicked = loadObject?.loadSpec?.startAnnotationIsDraggable
    ? true
    : false;
  const endIsClicked = loadObject?.loadSpec?.endAnnotationIsDraggable
    ? true
    : false;
  return (
    <g id="Streckenlast">
      <g className={isHighlighted ? classes.gOutline : ""}>
        {lineList}
        <PolyLine
          points={[topLeftPoint, topRightPoint]}
          stroke={color1}
          strokeLinecap={"square"}
          strokeWidth={2}
        />
      </g>
      <g id="lengthmeasurements">
        <DistanceMeasurement index={lengthMeasurementIndex} />
        <DistanceMeasurement index={startMeasurementIndex} />
      </g>
      <g id="measuresHeight">
        <DistanceArrow
          points={[bottomLeftMeasure, topLeftMeasure]}
          color={color2}
          arrowHeadLength={15}
        />
        <DistanceArrow
          points={[bottomRightMeasure, topRightMeasure]}
          color={color2}
          arrowHeadLength={15}
        />
        <LatexAnnotation
          id="left"
          latex={latexStart}
          textOffsetX={startTextOffsetX}
          textOffsetY={startTextOffsetY}
          x={leftLatexPoint[0] + startTextOffsetX}
          color={textColor}
          y={leftLatexPoint[1] + startTextOffsetY}
          hasFrac={hasFracStart}
          height={fontSize}
          onClick={handleClickStartHeight}
          onMouseDown={handleMouseDown}
          isClicked={startIsClicked}
        />
        <LatexAnnotation
          id="right"
          latex={latexEnd}
          textOffsetX={endTextOffsetX}
          textOffsetY={endTextOffsetY}
          x={rightLatexPoint[0] + endTextOffsetX}
          color={textColor}
          y={rightLatexPoint[1] + endTextOffsetY}
          hasFrac={hasFracEnd}
          height={fontSize}
          onClick={handleClickEndHeight}
          onMouseDown={handleMouseDown}
          isClicked={endIsClicked}
        />
      </g>
    </g>
  );
}

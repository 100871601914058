import { ArcPolar } from "../Cords/ArcPolar";
import PolyLine from "../Cords/PolyLine";
import { useLoadAnnotationDragHelper } from "../helper/util/useLoadAnnotationDragHelper";
import LatexAnnotation from "../helper/LatexAnnotation";
import { makeStyles } from "@material-ui/core";
import { createAnnotation } from "./util/createAnnotation";
import {
  addV,
  middlePoint,
  rotateByDeg,
  sM,
} from "features/LinearAlgebra/vectorUtils";
import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { elongateLine } from "features/LinearAlgebra/elongateLine";
import { Line, Point } from "features/LinearAlgebra/linearAlgebraModel";
import { Load, LoadColor } from "features/Context/types/LoadModel";
import {
  highlightOffset,
  highlightWidth,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  auto: { cursor: "auto" },
  move: {
    cursor: "move",
  },
  gOutline: {
    outline: "solid " + highlightWidth + "px " + primaryColor,
    outlineOffset: highlightOffset + "px",
  },
}));
//orientation : +1 counterclockwise; -1 clockwise
export function Moment({
  x = 0,
  y = 0,
  color = "black",
  size = 1,
  orientation = 1,
  textSuffix = "",
  fontSize = 15,
  strokeWidth = 4,
  textOffsetX = 0,
  textOffsetY = 0,
  angleSpan = 220,
  loadObject = null,
  isSymbolic = true,
  magnitude = 0,
  isHighlighted = false,
}: {
  x: number;
  y: number;
  color?: string;
  size?: number;
  orientation: number;
  textSuffix?: string;
  fontSize?: number;
  strokeWidth?: number;
  textOffsetX?: number;
  textOffsetY?: number;
  angleSpan?: number;
  loadObject: LoadColor | null;
  isSymbolic?: boolean;
  magnitude?: number;
  isHighlighted?: boolean;
}) {
  const classes = useStyles();

  const angle = angleSpan;
  const startAngle = -90 + (360 - angle) / 2;
  const radius = 10 + size * 22;
  const xM = x - fontSize / 2;
  const yM = y + radius + 2;
  //     p2
  //      \
  //-----p1-->p4
  //      /
  //      p3

  let arrowSideLength = 2.5 * size + 10;
  let x1;
  let y1;

  let point1: Point, point2: Point, point3: Point, point4: Point;
  const centerPoint: Point = [x, y];
  if (orientation === 1) {
    x1 = Math.cos(toRad(startAngle + angle)) * radius + x;
    y1 = Math.sin(toRad(startAngle + angle)) * radius + y;
  } else {
    x1 = Math.cos(toRad(startAngle)) * radius + x;
    y1 = Math.sin(toRad(startAngle)) * radius + y;
  }
  point1 = [x1, y1];
  const centerToP1: Line = [centerPoint, point1];
  const arrowDir = rotateByDeg(getLineDir(centerToP1), 90);

  point4 = addV(point1, sM(arrowSideLength * orientation, arrowDir));
  point2 = elongateLine(centerToP1, arrowSideLength / 3)[1];
  point3 = elongateLine(centerToP1, -arrowSideLength / 3)[1];

  const loadObj = loadObject as any as Load;
  const cursorIsOnMove = loadObj?.isPending ? true : false;

  const preFactor = loadObj.preFactor;
  const unitFactor = loadObj.loadSpec.unitFactor;
  const unitSymbol = loadObj.loadSpec.unitSymbol;
  const [latex, hasFrac] = createAnnotation(
    loadObj.loadType,
    preFactor,
    unitFactor,
    unitSymbol,
    magnitude,
    textSuffix,
    isSymbolic
  );
  // @ts-ignore
  const { handleClick, handleMouseDown } = useLoadAnnotationDragHelper(loadObj);
  console.log(isHighlighted);
  return (
    <g className="moment">
      {/* <TextElement
        x={xM + textOffsetX}
        y={yM + textOffsetY}
        color={color}
        fontSize={fontSize}
        margin={3}
        angle={0}
        text={"M" + textSuffix}
      /> */}
      <g
        className={
          (cursorIsOnMove ? classes.move : classes.auto) +
          " " +
          (isHighlighted ? classes.gOutline : "")
        }
      >
        <PolyLine
          points={[point2, point4, point3, point2, middlePoint(point2, point4)]}
          fill={color}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <ArcPolar
          startAngle={startAngle}
          cx={x}
          cy={y}
          radius={radius}
          color={color}
          strokeWidth={strokeWidth}
          orientation={1}
          angle={angle}
        />
      </g>

      <LatexAnnotation
        latex={latex}
        x={xM + textOffsetX}
        color={color}
        y={yM + textOffsetY + 15}
        hasFrac={hasFrac}
        {...{ textOffsetY, textOffsetX }}
        height={fontSize}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        isClicked={loadObj?.annotationIsDraggable ? true : false}
      />
    </g>
  );
}
//{angleLocal,strokeDashArray="1,0",peakAngle,size,startAngle=0,angle,cx,cy,radius,color="black",strokeWidth=6,orientation=0}={}
function toRad(angleInDegree: number) {
  return (angleInDegree / 360) * 2 * Math.PI;
}

import { Typography, makeStyles } from "@material-ui/core";
import { LinkToCalculator } from "./LinkToCalculator";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  calculatorButton: {
    width: "300px",
    height: "190px",
    flexShrink: 0,
  },
  outer: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  text: {
    float: "left",
    padding: "5px",
    marginLeft: "10px",
  },
  bold: {
    fontWeight: "bold",
  },
}));

export function Abstract() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <div className={classes.outer}>
      <div className={classes.calculatorButton}>
        <LinkToCalculator></LinkToCalculator>
      </div>
      <Typography className={classes.text}>
        {t(
          "This calculator is designed to find the support reactions of mechanical systems. The novelty of this calculator is that it can work with"
        )}
        <span className={classes.bold}>{t(" symbolical loads")}</span>{" "}
        {t("and")} <span className={classes.bold}>{t("lengths")}</span>
        {t(
          ". In addition numerical calculations are supported. Just try it out yourself and create and calculate your first structure now!"
        )}
      </Typography>
    </div>
  );
}

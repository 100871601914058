import { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../Context/PendingContext";
import { CartesianGlobal } from "./positionControl/CartesianGlobal";

export function PositionControl() {
  const { pendingObject } = useContext(PendingContext) as PendingContextType;

  if (!pendingObject) {
    return null;
  }
  const posReferenceIndex = pendingObject.posReferenceIndex;

  return (
    <div className={"PositionControl"}>
      <CartesianGlobal posReferenceIndex={posReferenceIndex} />
    </div>
  );
}

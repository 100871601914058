import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { CordsArrow } from "../Cords/CordsArrow";
import LatexSvg from "./LatexSvg";
import { primaryColor } from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles((theme) => ({
  notClicked: { cursor: "pointer" },
  clicked: {
    cursor: "move",
  },
}));
export default function LatexAnnotation({
  id = "",
  latex = "F_{1}",
  x = 0,
  y = 0,
  height = 40,
  color = "red",
  onClick = () => {},
  onMouseDown = () => {},
  isClicked = false,
  hasFrac = false,
  textOffsetX = 0,
  textOffsetY = 0,
}: {
  id?: string;
  latex: string;
  x: number;
  y: number;
  height?: number;
  color?: string;
  onClick: (e: React.MouseEvent) => any;
  onMouseDown: (e: React.MouseEvent) => any;
  isClicked?: boolean;
  hasFrac?: boolean;
  textOffsetX?: number;
  textOffsetY?: number;
}) {
  const classes = useStyles();
  const heightTotal = hasFrac ? 2 * height : height;
  const [width, setWidth] = useState(heightTotal);
  const margin = 5;
  const [mouseIsInside, setMouseIsInside] = useState(false);
  const highlightColor = primaryColor;
  // const arrowLength = 20;
  return (
    <>
      <LatexSvg
        latex={latex}
        x={x}
        color={color}
        y={y}
        height={heightTotal}
        widthReturner={setWidth}
      />

      <g transform={"translate(" + x + " " + y + ") scale(1 -1)"}>
        {isClicked && (
          <>
            <g
              transform={
                "translate(" +
                -textOffsetX +
                " " +
                textOffsetY +
                ") scale(1 -1)"
              }
            >
              <circle x={0} y={0} r={2} fill={highlightColor} />
            </g>
            {/* <CordsArrow
              color={highlightColor}
              strokeWidth={2}
              cartesian={{
                originX: width / 2,
                originY: -2 * margin,
                destX: width / 2,
                destY: -arrowLength,
              }}
            />
            <CordsArrow
              color={highlightColor}
              strokeWidth={2}
              cartesian={{
                originX: width / 2,
                originY: heightTotal + 2 * margin,
                destX: width / 2,
                destY: heightTotal + arrowLength,
              }}
            />
            <CordsArrow
              color={highlightColor}
              strokeWidth={2}
              cartesian={{
                originX: -2 * margin,
                originY: heightTotal / 2,
                destX: -arrowLength,
                destY: heightTotal / 2,
              }}
            />
            <CordsArrow
              color={highlightColor}
              strokeWidth={2}
              cartesian={{
                originX: 2 * margin + width,
                originY: heightTotal / 2,
                destX: width + arrowLength,
                destY: heightTotal / 2,
              }} 
            />*/}
          </>
        )}
        <rect
          className={isClicked ? classes.clicked : classes.notClicked}
          onClick={(e) => {
            onClick(e);
          }}
          onMouseDown={(e) => {
            onMouseDown(e);
          }}
          onMouseEnter={() => {
            setMouseIsInside(true);
          }}
          onMouseLeave={() => {
            setMouseIsInside(false);
          }}
          x={0 - margin / 2}
          y={0 - margin / 2}
          stroke={isClicked || mouseIsInside ? highlightColor : "rgba(0,0,0,0)"}
          strokeWidth={2}
          //   strokeDasharray="4 4 "
          fill="rgba(0,0,0,0)"
          width={width + margin}
          height={heightTotal + margin}
        />
      </g>
    </>
  );
}

import HtmlDistanceArrow from "features/Components/Functionality_Right/LoadMode/util/HtmlDistanceArrow";

export function DistMeasureIcon({
  size = 40,
  strokeWidth = 1,
  color = "black",
}: {
  size?: number;
  strokeWidth?: number;
  color?: string;
}) {
  return (
    <HtmlDistanceArrow
      width={size}
      height={0}
      strokeWidth={strokeWidth}
      color={color}
    ></HtmlDistanceArrow>
  );
}

import { useContext } from "react";
import { useMounted } from "./useMounted";
import {
  PendingContext,
  PendingContextType,
} from "../../../Context/PendingContext";
import {
  PlaygroundContext,
  PlaygroundContextType,
} from "features/Context/PlaygroundContext";
import SubmitButtons from "./SubmitButtons";
import { SubmitWrapper } from "./SubmitWrapper";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export function SubmitFormSystem() {
  const { t } = useTranslation();
  const { removePendingObject, savePendingObject, pendingObject } = useContext(
    PendingContext
  ) as PendingContextType;
  const pendingIsProperlyMounted = useMounted();
  const { setLastPercentageUsed } = useContext(
    PlaygroundContext
  ) as PlaygroundContextType;

  const handleSave = () => {
    if (pendingIsProperlyMounted) {
      savePendingObject();
      if (pendingObject?.mechanicalType === "bearing") {
        if (pendingObject) {
          setLastPercentageUsed(pendingObject.scale as number);
        }
      }
    }
  };

  return (
    <>
      <SubmitWrapper isPending={true}>
        <SubmitButtons
          submitTitle={
            pendingIsProperlyMounted
              ? t("Elements are properly mounted.")
              : t("Elements are not properly mounted.")
          }
          onCancel={removePendingObject}
          onSave={handleSave}
          saveDisabled={!pendingIsProperlyMounted}
          submitString={<Typography>{t("Save Pending")}</Typography>}
          cancelString={t("Delete Pending")}
        />
      </SubmitWrapper>
    </>
  );
}

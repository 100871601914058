import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
  MeasureIndex,
} from "features/Context/MeasureDistanceContext";
import { AttachMentPoint } from "features/Context/types/ConstructionElementModel";
import {
  DistanceMeasurement,
  DistanceMeasurementObjects,
} from "features/Context/types/DistanceMeasurementModel";
import { Point } from "features/LinearAlgebra/linearAlgebraModel";
import {
  areClose,
  areCloseScalar,
  distance,
} from "features/LinearAlgebra/vectorUtils";
import { useContext } from "react";
import { checkIfDistMeasureShouldBeHidden } from "./util/checkIfDistMeasureShouldBeHidden";
import {
  lSubEnd,
  lSubStart,
} from "features/Components/LatexUtils/latexParsingHelp";

export const useRecalcHiddenDistMeasures = (): Function => {
  const {
    measurements,
    getHidden,
    setIsSymbolic,
    setMagnitude,
    setMainSymbol,
    setSuffix,
  } = useContext(MeasureDistanceContext) as MeasureDistanceContextType;
  const hiddenMeasures = getHidden();
  /**
   * this function recalculates the hidden distance measurements
   * this happens after the meausrements phase where the meausres are fixed.
   * the function for example sets the length to 0,when this is the reason the measurement is hidden.
   * Another reason might be that other measures add up to the questioned measurements.
   * In this case the magnitudes of the other measures need to e combined to get the questioned measure.
   */
  const recalcHiddenDistMeasures = () => {
    hiddenMeasures?.forEach((hiddenMeasure) => {
      const initialLine = hiddenMeasure.initialLine;
      const measureIsZero = checkIfDistMeasureShouldBeHidden(initialLine);
      if (measureIsZero) {
        setIsSymbolic(hiddenMeasure.index, false);
        setMagnitude(hiddenMeasure.index, 0);
        return;
      }
      //measure must be calculated by other measures eg l_1+l_2
      let measureStringContainer: Array<string> = [];
      recursivelyGetMeasureStringToPos(
        initialLine[0],
        initialLine[1],
        measurements as DistanceMeasurement,
        hiddenMeasure.index,
        "",
        measureStringContainer,
        true
      );
      if (measureStringContainer[0]) {
        const compoundedMeasure = measureStringContainer[0];
        setIsSymbolic(hiddenMeasure.index, true);
        setMainSymbol(hiddenMeasure.index, compoundedMeasure);
        setSuffix(hiddenMeasure.index, "");
      } else {
        console.warn(
          "Something went wrong when calculating the compound measure"
        );
      }
      // start must be traced to underlying  part start
      // end of measure must be traced to underlying part end
      // first case: measurement is a partMeasure
      // in this case the measure must be provided by streckenlastMeasure
    });
  };
  return recalcHiddenDistMeasures;
};
/**
 * This function recusively generates the measurement string between to points, while excluding certain measurements.
 *      +---------------l---------------+
 * E.G. +------50*m-----+-------l2------+
 *      1               2               3
 * The recursive measure between 1 and 3 with excluding "l" should come out to the string: "50*m+l2"
 * @param fromPos
 * @param toPos
 * @param measures
 * @param measureIndexToExlude this should usually be the index for which one wants to calculate the substitute length
 * @param currentMeasureString this is used to recursively track the measurements
 * @param foundMeasureStringContainer this array is a container where the found measurement string is placed in. If no string was found the container stays empty
 */
export function recursivelyGetMeasureStringToPos(
  fromPos: Point,
  toPos: Point,
  measures: DistanceMeasurementObjects,
  measureIndexToExlude: MeasureIndex = -1, //default no measure index is excluded
  currentMeasureString: string = "",
  foundMeasureStringContainer: Array<string> = [],
  doNotConsiderHiddenMeasures: boolean = true,
  startPosTracker: Array<Point> = []
): void {
  for (const startPos of startPosTracker) {
    if (areCloseScalar(distance(startPos, fromPos), 0)) {
      //when we are back at the first startPos we should not run into a loop
      return;
    }
  }
  startPosTracker.push([...fromPos]);

  const allMeasureInfoConnectedToFromPos = getAllMeasuresAtPoint(
    fromPos,
    measures
  );
  if (allMeasureInfoConnectedToFromPos.length === 0) {
    return;
  }
  for (let i = 0; i < allMeasureInfoConnectedToFromPos.length; i++) {
    if (
      allMeasureInfoConnectedToFromPos[i].measure.index === measureIndexToExlude
    ) {
      if (i < allMeasureInfoConnectedToFromPos.length - 1) {
        // skip this measurement because we want to calculate it
        continue;
      } else {
        //end because we skipped the last measurement
        return;
      }
    }
    if (
      doNotConsiderHiddenMeasures &&
      allMeasureInfoConnectedToFromPos[i].measure.isHidden
    ) {
      if (i < allMeasureInfoConnectedToFromPos.length - 1) {
        // skip this measurement because we to skip hidden measures
        continue;
      } else {
        //this hidden measure is the last so we can directly return
        return;
      }
    }
    const measureInfo = allMeasureInfoConnectedToFromPos[i];
    const measureStartsAtFromPos =
      measureInfo.attachmentPointOfMeasure === "start";
    const measure = measureInfo.measure;
    const symbolicMeasureString =
      measure.suffix !== "" //when the suffix is empty the measurement was already calculated recursively and cant have a suffix
        ? measure.preFactor +
          "*" +
          measure.mainSymbol +
          lSubStart +
          measure.suffix +
          lSubEnd
        : measure.mainSymbol;
    const numericMeasureString = measure.unitFactor * measure.magnitude + "*m";
    const measureString = measure.isSymbolic
      ? symbolicMeasureString
      : numericMeasureString;
    if (measureStartsAtFromPos) {
      const newMeasureString = currentMeasureString + "+1*" + measureString;
      if (areClose(measure.initialLine[1], toPos)) {
        //we are at the end of recursion
        foundMeasureStringContainer[0] = newMeasureString;
        return;
      } else {
        recursivelyGetMeasureStringToPos(
          measure.initialLine[1],
          toPos,
          measures,
          measureIndexToExlude,
          newMeasureString,
          foundMeasureStringContainer,
          doNotConsiderHiddenMeasures,
          startPosTracker
        );
      }
    }
    if (!measureStartsAtFromPos) {
      //measure gos from end to start
      const newMeasureString = currentMeasureString + "+(-1)*" + measureString;
      if (areClose(measure.initialLine[0], toPos)) {
        //we are at the end of recursion
        //when the measure is from end to start we need to inverse the measurement
        foundMeasureStringContainer[0] = newMeasureString;
        return;
      } else {
        //more recursion
        recursivelyGetMeasureStringToPos(
          measure.initialLine[0],
          toPos,
          measures,
          measureIndexToExlude,
          newMeasureString,
          foundMeasureStringContainer,
          doNotConsiderHiddenMeasures,
          startPosTracker
        );
      }
    }
  }
}
/**
 * This function return all measures at a certain point
 * @param point
 * @param measures
 */
export function getAllMeasuresAtPoint(
  point: Point,
  measures: DistanceMeasurementObjects
): Array<{
  measure: DistanceMeasurement;
  attachmentPointOfMeasure: AttachMentPoint;
}> {
  let measurementInfo: Array<{
    measure: DistanceMeasurement;
    attachmentPointOfMeasure: AttachMentPoint;
  }> = [];
  Object.values(measures).forEach((measure) => {
    if (areClose(measure.initialLine[0], point)) {
      measurementInfo.push({
        measure: measure,
        attachmentPointOfMeasure: "start",
      });
    } else if (areClose(measure.initialLine[1], point)) {
      measurementInfo.push({
        measure: measure,
        attachmentPointOfMeasure: "end",
      });
    }
  });
  return measurementInfo;
}

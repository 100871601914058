export function ArcPolar({
  strokeDashArray = "1,0",
  startAngle = 0,
  angle,
  cx,
  cy,
  radius,
  color = "black",
  strokeWidth = 6,
  orientation = 0,
}: {
  strokeDashArray?: string;
  startAngle?: number;
  angle: number;
  cx: number;
  cy: number;
  radius: number;
  color?: string;
  strokeWidth?: number;
  orientation?: number;
}) {
  const x1 = Math.cos(toRad(startAngle)) * radius + cx;

  const y1 = Math.sin(toRad(startAngle)) * radius + cy;

  const x2 = Math.cos(toRad(startAngle + angle - 0.001)) * radius + cx;
  const y2 = Math.sin(toRad(startAngle + angle - 0.001)) * radius + cy;

  const isSpitzerWinkel = Math.abs(angle) < 180 ? "0" : "1";
  console.log(angle, startAngle, isSpitzerWinkel);
  return (
    <g id="arc" transform={"scale(1 1)"}>
      <path
        d={`M ${x1} ${y1} A ${radius} ${radius} 0 ${isSpitzerWinkel} ${
          orientation /*clockwise? 0 or 1*/
        }  ${x2} ${y2}`}
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDashArray}
        fill="none"
        strokeLinecap="round"
      />
    </g>
  );
}

function toRad(angleInDegree: number) {
  return (angleInDegree / 360) * 2 * Math.PI;
}

import React, { useState, useCallback, useContext } from "react";

import { DrawenContext } from "../../../../../Context/DrawenContext";

export function useReferenceOptions(props) {
  const { drawenObjects } = useContext(DrawenContext);
  const possibleReferenceObjects = [
    ...Object.values(drawenObjects).map((drawenObj) => {
      return {
        name: drawenObj.type + drawenObj.index,
        index: drawenObj.index,
      };
    }),
  ];

  const refOptions = possibleReferenceObjects.map((obj) => {
    return (
      <option key={obj.index + obj.name + ""} value={obj.index}>
        {obj.name}
      </option>
    );
  });

  return refOptions;
}

import React, { useState, useCallback } from "react";
import { makeStyles, Slider as MuiSlider, TextField } from "@material-ui/core";
import useMarkSnapper from "../util/useMarkSnapper";
import { inputColor } from "features/Components/StyleOnlyComponents/Color";
const useStyles = makeStyles({
  slider: {
    width: "100%",
  },
  sliderWrapper: {
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  textFieldRow: {
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    justifyContent: "space-between",
  },
  textFieldWrapper: {
    width: "50px",
  },
  width: {
    width: "100%",
    marginBottom: "10px",
  },
  input: {
    background: inputColor,
  },
});
export function RangedInput({
  step = 1,
  sliderOrientation = "horizontal",
  handleChange,
  description = false,
  min,
  max,
  value = [0, 1],
  marks = [],
  ownMarks = false,
} = {}) {
  const classes = useStyles();

  const marksCustom = ownMarks
    ? marks
    : marks.concat([
        { value: min, label: Math.round(min) },
        { value: max, label: Math.round(max) },
      ]);
  //for first values in array
  const [snapConvert1, setLastCommitted1] = useMarkSnapper(
    marksCustom,
    max / 30
  );
  //for second values in array
  const [snapConvert2, setLastCommitted2] = useMarkSnapper(
    marksCustom,
    max / 30
  );

  const handleChangeSlider = (e, newArray) => {
    handleChange(snapConvert1(newArray));
  };
  const handleChangeCommitted = (e, newArray) => {
    setLastCommitted1(snapConvert1(newArray));
  };

  return (
    <div className={classes.width}>
      {description && (
        <p
          className={" sliderDescription sliderDescription" + sliderOrientation}
        >
          {description}{" "}
        </p>
      )}

      <div className={classes.sliderWrapper}>
        <MuiSlider
          className={classes.slider}
          onChangeCommitted={(e, newVal) => handleChangeCommitted(e, newVal)}
          onChange={(e, newVal) => handleChangeSlider(e, newVal)}
          min={min}
          step={step}
          max={max}
          marks={marksCustom}
          value={value}
        />
      </div>
      <div className={classes.textFieldRow}>
        <div className={classes.textFieldWrapper}>
          <TextField
            type="number"
            onChange={(e, newVal) =>
              handleChangeSlider(e, [parseInt(e.target.value), value[1]])
            }
            className={"numberInput numberInput" + sliderOrientation}
            inputProps={{
              className: classes.input,
              min: min,
              step: step,
              max: max,
            }}
            value={value[0]}
          />
        </div>
        <div className={classes.textFieldWrapper}>
          <TextField
            type="number"
            onChange={(e, newVal) =>
              handleChangeSlider(e, [value[0], parseInt(e.target.value)])
            }
            className={"numberInput numberInput" + sliderOrientation}
            inputProps={{
              className: classes.input,
              min: min,
              step: step,
              max: max,
            }}
            value={value[1]}
          />
        </div>
      </div>
    </div>
  );
}

import { Link, Typography } from "@material-ui/core";
import { issueLink } from "featuresLandingPage/mainContent/CalculatorLimits";
import { UnhappyLosLagerIcon } from "../SystemMode/SelectBar/Selectables/UnhappyLosLagerIcon";
import { useTranslation } from "react-i18next";
export function ErrorMsgSolution() {
  const { t } = useTranslation();
  return (
    <div>
      <UnhappyLosLagerIcon size={50}></UnhappyLosLagerIcon>
      <Typography>
        {t(
          "Something went wrong solving the balance Equations. Please report the issue on the"
        )}
        <Link href={issueLink}> Github Issue Tracker </Link>
      </Typography>
    </div>
  );
}

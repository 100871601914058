import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography,
  makeStyles,
} from "@material-ui/core";
import HighlightIcon from "@material-ui/icons/Highlight";
import { AngleMeasurement } from "features/Context/types/AngleMeasurementModel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AngleMeasureIcon from "../icons/AngleMeasureIcon";
import { convertToLatex } from "features/Components/LatexUtils/convertToLatex";
import {
  lSubEnd,
  lSubStart,
} from "features/Components/LatexUtils/latexParsingHelp";
import LatexForHTML from "features/Components/Playground/helper/LatexForHTML";
import { roundIfSmall } from "features/LinearAlgebra/roundIfSmall";
import { MagnitudeRep } from "features/Components/Functionality_Right/LoadMode/util/MagnitudeRep";
import { useMeasurementUnits } from "./useMeasurementUnits";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import { useContext, useState } from "react";
import { Tag } from "features/Components/StyleOnlyComponents/Tag";
import { useHighlight } from "features/Components/Playground/playgroundObjectHooks/useHighlight";
import {
  accordionRootBackground,
  primaryColor,
} from "features/Components/StyleOnlyComponents/Color";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { ControlAccordion } from "features/Components/Functionality_Right/VisualElements/ControlAccordion";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px",
    paddingBottom: "5px",
    paddingTop: "10px",
    margin: "0px",
    background: accordionRootBackground,
  },
  fullWidth: {
    width: "100%",
  },
  flex: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  verticalFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
  smallPad: {
    padding: "0px",
    minHeight: "20px",
    overflow: "hidden",
  },
  latex: {
    padding: "10px",
  },
  leftPad: {
    paddingLeft: "10px",
  },
  smallMargin: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  expanded: {
    marginTop: "0px",
    marginBottom: "0px",
    padding: "0px",
    minHeight: "20px",
    overflow: "hidden",
  },
  negMargin: {
    marginTop: "-15px",
    marginBottom: "-15px",
    marginLeft: "12px",
    marginRight: "0px",
    height: "24px",
    width: "24px",
    overflow: "hidden",
    position: "relative",
  },
  widthIcon: {
    paddingLeft: "10px",
    width: "50px",
    marginRight: "-5px",
  },
  tagPlaceholder: {
    width: "40px",
    marginRight: "5px",
  },
  widthLatex: {},
  rotate: {
    transform: "rotate(90deg)",
  },
}));
export function AngleMeasurementControl({
  angleMeasurement,
}: {
  angleMeasurement: AngleMeasurement;
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    setHiddenByUser,
    setMagnitude,
    setUnit,
    getSignedAngleOfMeasurement,
  } = useContext(MeasureAngleContext) as MeasureAngleContextType;
  const { handleMouseEnter, handleMouseLeave } = useHighlight(
    "angleMeasurement",
    angleMeasurement.index
  );
  const { status } = useContext(StatusContext) as StatusContextType;

  const [unitOptions, unitFactor] = useMeasurementUnits("angle");
  if (angleMeasurement.isHidden) {
    return null;
  }
  const isHighlighted = angleMeasurement.isHighlighted;
  const symbolicString =
    angleMeasurement.mainSymbol + lSubStart + angleMeasurement.suffix + lSubEnd;
  const numericAngle = angleMeasurement.magnitude / angleMeasurement.unitFactor;
  const angle = getSignedAngleOfMeasurement(angleMeasurement.index);
  const sign = Math.sign(angle || angle === 0 ? angle : 1);
  const unsignedAngle = roundIfSmall(numericAngle / sign, 0.000001);
  const numericAngleWithUnit = unsignedAngle + "" + angleMeasurement.unitSymbol;
  const measureString = angleMeasurement.isSymbolic
    ? symbolicString
    : numericAngleWithUnit;
  const latex = convertToLatex(measureString);

  const handleMagnitudeChange = (number: string) => {
    setMagnitude(
      angleMeasurement.index,
      sign * parseFloat(number) * angleMeasurement.unitFactor
    );
  };
  const handleUnitChange = (unit: "rad" | "°") => {
    setUnit(angleMeasurement.index, unit);
    // setPendingLoadMagnitude(magnitude / unitFactorConverter(unit));
  };
  const handleShowMeasureChange = (newShowMeasure: boolean) => {
    setHiddenByUser(angleMeasurement.index, !newShowMeasure);
    // setPendingLoadMagnitude(magnitude / unitFactorConverter(unit));
  };
  const iconColor = isHighlighted ? primaryColor : "black";
  const measureInterActionIsDisabled = status.status !== "measurements";
  return (
    <div
      className={classes.fullWidth}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <ControlAccordion
        titleMainElement={
          <div className={classes.fullWidth + " " + classes.flex}>
            <div className={classes.widthIcon}>
              <AngleMeasureIcon
                color={iconColor}
                size={22}
                title={t("Angle Measurement")}
              ></AngleMeasureIcon>
            </div>

            <div className={classes.latex + " " + classes.widthLatex}>
              <LatexForHTML height={12} latex={latex}></LatexForHTML>
            </div>
            <div className={classes.tagPlaceholder}>
              {angleMeasurement.isHiddenByUser && (
                <Tag text={t("HIDDEN")}></Tag>
              )}
            </div>
          </div>
        }
        mainContent={
          <div className={classes.verticalFlex}>
            <div className={classes.leftPad}>
              <MagnitudeRep
                handleMagnitudeChange={handleMagnitudeChange}
                handleUnitChange={handleUnitChange}
                magnitude={unsignedAngle}
                unitFactor={angleMeasurement.unitFactor}
                unit={angleMeasurement.unitSymbol === "°" ? "°" : "rad"}
                unitOptionEl={unitOptions}
                disabled={measureInterActionIsDisabled}
                thin={false}
              ></MagnitudeRep>
            </div>

            <div className={classes.flex}>
              <Checkbox
                checked={!angleMeasurement.isHiddenByUser}
                onChange={(e, checked) => handleShowMeasureChange(checked)}
                color="primary"
              ></Checkbox>
              <Typography>{t("Show Measure")}</Typography>
            </div>
          </div>
        }
        expanded={false}
      ></ControlAccordion>
    </div>
  );
}

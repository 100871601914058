import HomeIcon from "@material-ui/icons/Home";
import { landingPageRoute } from "index";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
export function HomeButton() {
  return (
    <Link to={landingPageRoute}>
      <IconButton>
        <HomeIcon></HomeIcon>
      </IconButton>
    </Link>
  );
}

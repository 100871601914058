import { LoadNames } from "features/Context/types/ConstructionElementModel";
import { preFactorToLatex } from "../../helper/util/preFactorToLatex";
import { areCloseScalar } from "features/LinearAlgebra/vectorUtils";
const createAnnotation = (
  loadType: null | undefined | LoadNames = "force",
  preFactor: string | undefined | null,
  unitFactor: number | null | undefined,
  unitSymbol: string | null | undefined,
  magnitude: number | null | undefined,
  textSuffix: string | null | undefined,
  isSymbolic: boolean | undefined,
  baseSymbol: boolean | string = false
) => {
  if (!preFactor) {
    return ["", false];
  }
  let [prefix, hasFrac] = preFactorToLatex(preFactor);
  let baseName;
  if (!baseSymbol) {
    if (loadType === "force") {
      baseName = "F";
    } else if (loadType === "moment") {
      baseName = "M";
    } else if (loadType === "streckenlast") {
      baseName = "q";
    }
  } else {
    baseName = baseSymbol;
  }

  if (!isSymbolic) {
    hasFrac = false;
  }

  let latex = "";
  if (isSymbolic)
    latex =
      preFactor === "0" ? "" : `${prefix}${baseName}_{\\text{${textSuffix}}}`;
  else {
    if (!magnitude || !unitFactor) {
      latex = "0";
    } else {
      const neumericvalue = magnitude;
      const numericValue = areCloseScalar(
        neumericvalue,
        Math.round(neumericvalue)
      )
        ? Math.round(neumericvalue)
        : neumericvalue;
      latex = `${numericValue} \\operatorname{${unitSymbol}}
      `;
    }
  }
  return [latex, hasFrac];
};
export { createAnnotation };

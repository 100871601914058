import { Hidden } from "@material-ui/core";
import { UniversalProvider } from "features/Context/UniversalProvider";
import { Footer } from "featuresLandingPage/footer/Footer";
import { Header } from "featuresLandingPage/header/Header";
import { MainContent } from "featuresLandingPage/mainContent/MainContent";
import { MobilePage } from "featuresLandingPage/Mobile/MobilePage";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
export function LandingPage() {
  const { i18n, t } = useTranslation();
  document.documentElement.lang = i18n.language;
  const description = t(
    "Symbolical Reaction Calculator for Mechanical Systems."
  );

  return (
    <div>
      <UniversalProvider>
        {/*@ts-ignore*/}
        <Hidden only={["xs", "sm"]}>
          <Helmet>
            <title>{t("title")}</title>
            <meta name="description" content={description} />
            <meta name="author" content="Dominik Fletschinger" />
            <meta property="og:type" content={"Website"} />
            <meta property="og:title" content={t("title")} />
            <meta property="og:description" content={description} />
          </Helmet>
          <Header></Header>
          <MainContent></MainContent>
          <Footer></Footer>
        </Hidden>
        <MobilePage></MobilePage>
      </UniversalProvider>
    </div>
  );
}

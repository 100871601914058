import {
  DrawenContext,
  DrawenContextType,
} from "features/Context/DrawenContext";

import {
  FreischnittContext,
  FreischnittContextType,
} from "features/Context/FreischnittContext";
import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import {
  StatusContext,
  StatusContextType,
} from "features/Context/StatusContext";
import { bearingTypes } from "features/GlobalData/bearingTypes";
import { useContext, useEffect } from "react";
import { useChangeToAngleMeasurements } from "./useChangeToAngleMeasurements";
import { useChangeToMeasurements } from "./useChangeToMeasurements";
import { useRecalcHiddenAngleMeasures } from "./useRecalcHiddenAngleMeasures";
import { useRecalcHiddenDistMeasures } from "./useRecalcHiddenDistMeasures";
import { useCreateFreischnittCalcLoads } from "./useCreateFreischnittCalcLoads";
import { useChangeToSolvingMechanicalProblem } from "./useChangeToSolvingMechanicalProblem";
import {
  MeasureAngleContext,
  MeasureAngleContextType,
} from "features/Context/MeasureAngleContext";
import {
  MeasureDistanceContext,
  MeasureDistanceContextType,
} from "features/Context/MeasureDistanceContext";
import {
  FakeRunContext,
  FakeRunContextType,
} from "features/Context/FakeRunContext";

export const useFakeRun = () => {
  const { drawenIndex, deleteCounter, getAllParts, getAllBearings } =
    useContext(DrawenContext) as DrawenContextType;
  const { status } = useContext(StatusContext) as StatusContextType;
  const {
    loadObjects,
    initializePendingLoad,
    deletePendingLoad,
    resetHighestLoadIndex,
  } = useContext(PendingLoadContext) as PendingLoadContextType;
  const { freischnittLoads, removeAllFreischnittLoads } = useContext(
    FreischnittContext
  ) as FreischnittContextType;
  const { angleMeasurements, removeAllFakeAngleMeasurements } = useContext(
    MeasureAngleContext
  ) as MeasureAngleContextType;
  const { measurements, removeAllFakeMeasurements } = useContext(
    MeasureDistanceContext
  ) as MeasureDistanceContextType;
  const { lastFakeRunCompleted, setLastFakeRunCompleted } = useContext(
    FakeRunContext
  ) as FakeRunContextType;
  const changeToFakeAngleMeasurement = useChangeToAngleMeasurements(true);
  const changeToFakeMeasurement = useChangeToMeasurements(true);
  const recalcFakeAngleMeasures = useRecalcHiddenAngleMeasures();
  const recalcFakeDistMeasures = useRecalcHiddenDistMeasures();
  const createFakeFreischnittLoads = useCreateFreischnittCalcLoads(true);
  const calculateFakeSolution = useChangeToSolvingMechanicalProblem(true);
  //The following 3 effects are executed after each other (in the best case)
  useEffect((): void => {
    //Here is the entry point for a fake calculation:

    //Check if status is system
    if (!(status.status === "system")) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }

    //check if has part
    const allParts = getAllParts();
    if (!allParts || allParts.length === 0) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }

    const anyPart = allParts[0];
    // check if intern bearing has no children
    const bearings = getAllBearings();
    if (!bearings || bearings.length === 0) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }

    for (const bearing of bearings) {
      if (bearingTypes[bearing.type].connectorType === "intern") {
        if (bearing.children.length === 0 || !bearing.children) {
          //internal ocnnector has no children
          setLastFakeRunCompleted((prev) => false);
          return;
        }
      }
    }

    // add fake load to part
    initializePendingLoad(anyPart.index, "force", true);
  }, [drawenIndex, deleteCounter]);
  useEffect(() => {
    //Here is the entry point after a fake load was added
    //Check if status is system

    if (!(status.status === "system")) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }

    // Check if load is fake
    if (!loadObjects || Object.values(loadObjects).length === 0) {
      return;
    }

    const load = Object.values(loadObjects)[0];
    if (!load.isFake) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }

    //ChangeToAngleMeasurements(isFake=true)
    changeToFakeAngleMeasurement();
    //ChangeToMeasurements(isFake=true)
    changeToFakeMeasurement();
    //Recalc distance Measures
    recalcFakeDistMeasures();
    //Recalc angle Measures
    recalcFakeAngleMeasures();
  }, [loadObjects]);
  useEffect(() => {
    if (!(status.status === "system")) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }
    //Create FreischnittCalcLoads
    createFakeFreischnittLoads();
  }, [angleMeasurements, measurements]);

  useEffect(() => {
    //Here is the entry point after a fake load was added to the FreischnittContext
    //Check if status is system

    if (Object.values(freischnittLoads).length === 0) {
      return;
    }
    if (!(status.status === "system")) {
      setLastFakeRunCompleted((prev) => false);
      return;
    }

    //useSolveMechanical(isfake=true)
    calculateFakeSolution();

    //delete fake loads
    deletePendingLoad();
    //reset the loadIndex
    resetHighestLoadIndex();
    removeAllFakeAngleMeasurements();
    removeAllFakeMeasurements();
    //delete fake freischnitt
    removeAllFreischnittLoads();
  }, [freischnittLoads]);
};

import { useContext, useEffect } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../Context/PendingContext";
import { DrawenObjectMechanicalType } from "../../../Context/types/ConstructionElementModel";
import {
  StatusContext,
  StatusContextType,
} from "../../../Context/StatusContext";
import { Button, makeStyles } from "@material-ui/core";
import { mountingTreeStyles } from "./mountingTreeStyles";
import AddButton from "./AddButton";
import { useTranslation } from "react-i18next";
//@ts-ignore
const useStyles = makeStyles(mountingTreeStyles);

export function AddToTree(props: any) {
  //props:{parentIndex,type("connectorAdder / volumeAdder")}
  const { t } = useTranslation();
  const classes = useStyles();
  const { status } = useContext(StatusContext) as StatusContextType;
  const isInSystemMode = status.status === "system" ? true : false;
  const {
    initializePendingObject,
    fillPendingObject,
    isInitialized,
    pendingObject,
    isPending,
  } = useContext(PendingContext) as PendingContextType;

  let typeName = "";
  let mechanicalType: DrawenObjectMechanicalType = "part";
  if (props.type === "connectorAdder") {
    mechanicalType = "bearing";
    typeName = t("Support");
  } else if (props.type === "volumeAdder") {
    mechanicalType = "part";
    typeName = t("Beam");
  }
  if (!mechanicalType) {
    return <></>;
  }

  const handleClick = () => {
    //hier nues pending object kreieren wenn in system status

    initializePendingObject({
      parentIndex: props.parentIndex,
      mechanicalType: mechanicalType,
      color: "black",
    });
    if (props.type === "volumeAdder") {
      fillPendingObject({
        type: "stab",
        mechanicalType: "part",
        color: "black",
      });
    }
    //when it is a part the use effect hook should fully fill the pending object
  };

  const lineStyle =
    props.type === "volumeAdder"
      ? classes.horizontalVerticalLine
      : classes.horizontalLine;
  const isDisabled = isPending || isInitialized;
  return (
    <>
      {isInSystemMode && (
        <div className={classes.tabbedButton}>
          <div className={lineStyle}></div>
          <AddButton
            title={
              isDisabled
                ? t("Finish the current element before adding another one!")
                : t("add element")
            }
            handleClick={handleClick}
            disabled={isDisabled}
            name={typeName}
          />
        </div>
      )}
    </>
  );
}

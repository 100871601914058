import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { Load } from "features/Context/types/LoadModel";
import { useContext } from "react";
import useDragAnnotation from "../../playgroundObjectHooks/useDragAnnotation";
const nullFunctions = [
  () => null,
  () => null,
  () => null,
  () => null,
  () => null,
];
const useLoadAnnotationDragHelper = (
  loadObject: Load | undefined | null | false
) => {
  const { setLoadAnnotationDraggable, removeAllDraggableLoads } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;

  const { handleDragLoadStart } = useDragAnnotation();
  if (!loadObject) {
    return nullFunctions;
  }
  if (loadObject.loadType === "force" || loadObject.loadType === "moment") {
    const handleClick = (e: MouseEvent) => {
      e.stopPropagation();
      removeAllDraggableLoads();
      setLoadAnnotationDraggable(true, loadObject.index);
    };
    const handleMouseDown = (e: React.MouseEvent) => {
      e.stopPropagation();
      handleDragLoadStart(e, loadObject);
    };
    return { handleClick, handleMouseDown };
  } else if (loadObject.loadType === "streckenlast") {
    const handleClickStartHeight = (e: MouseEvent) => {
      e.stopPropagation();
      removeAllDraggableLoads();
      setLoadAnnotationDraggable(true, loadObject.index, "startHeight");
    };
    const handleClickEndHeight = (e: MouseEvent) => {
      e.stopPropagation();
      removeAllDraggableLoads();
      setLoadAnnotationDraggable(true, loadObject.index, "endHeight");
    };
    const handleClickStartMeasurement = (e: MouseEvent) => {
      e.stopPropagation();
      removeAllDraggableLoads();
      setLoadAnnotationDraggable(true, loadObject.index, "startMeasure");
    };
    const handleClickLengthMeasurement = (e: MouseEvent) => {
      e.stopPropagation();
      removeAllDraggableLoads();
      setLoadAnnotationDraggable(true, loadObject.index, "lengthMeasure");
    };
    const handleMouseDown = (e: React.MouseEvent) => {
      e.stopPropagation();
      handleDragLoadStart(e, loadObject);
    };
    return {
      handleClickStartHeight,
      handleClickEndHeight,
      handleClickStartMeasurement,
      handleClickLengthMeasurement,
      handleMouseDown,
    };
  } else {
    return nullFunctions;
  }
};

export { useLoadAnnotationDragHelper };

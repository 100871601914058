import {
  PendingLoadContext,
  PendingLoadContextType,
} from "features/Context/PendingLoadContext";
import { useContext } from "react";
import Load from "../Load";

export const useLoadObjs = () => {
  // draw all drawen components
  const { loadObjects } = useContext(
    PendingLoadContext
  ) as PendingLoadContextType;
  if (!loadObjects) {
    return null;
  }
  const loadComponents = Object.values(loadObjects)
    .filter((load) => load.loadTypeWasSelected)
    .map((obj) => {
      return <Load loadObject={obj} key={obj.index} />;
    });
  return loadComponents;
};

import { IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@material-ui/icons/Language";
const useStyles = (props: any) =>
  makeStyles((theme) => ({
    shortName: {
      position: "absolute",
      fontSize: "10px",
      fontWeight: "bold",
      top: "4px",
      right: "4px",
      color: props.color,
    },
    white: { color: props.color },
  }));
const lngs: any = {
  en: { nativeName: "English", shortName: "EN" },
  de: { nativeName: "Deutsch", shortName: "DE" },
};
export function LanguageSwitchButton({ color = "white" }: { color?: string }) {
  const classes = useStyles({ color })();
  const { t, i18n } = useTranslation();
  const title =
    i18n.language === "de" ? "Switch to English" : "Switch to German";
  const newLang = i18n.language === "de" ? "en" : "de";
  return (
    <div>
      <IconButton title={title} onClick={() => i18n.changeLanguage(newLang)}>
        <div className={classes.shortName}>
          {lngs[i18n.resolvedLanguage].shortName}
        </div>
        <LanguageIcon className={classes.white} fontSize="small"></LanguageIcon>
      </IconButton>
    </div>
  );
}

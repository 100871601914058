import { Line, Point } from "./linearAlgebraModel";

export function intersectOfTwoLines(
  line1: Line,
  line2: Line,
  firstTime = true
): [Point, number, number] {
  const [[x1S, y1S], [x1E, y1E]] = line1;
  const [[x2S, y2S], [x2E, y2E]] = line2;
  //E=Ende, S = Start
  const term1 = ((x2E - x2S) / (x1E - x1S)) * (y1E - y1S) - (y2E - y2S);
  const term2 = y2S - y1S - ((x2S - x1S) / (x1E - x1S)) * (y1E - y1S);

  //intersection point is at fraction s *distance between first (1) given points
  let s = term2 / term1;

  //intersection point is at fraction t *distance between second (2) given points
  let t = (x2S - x1S + s * (x2E - x2S)) / (x1E - x1S);
  let intersectPointX = x1S + t * (x1E - x1S);
  let intersectPointY = y1S + t * (y1E - y1S);
  if ((isNaN(t) || isNaN(s)) && firstTime) {
    // if division by zero calc other way round
    return intersectOfTwoLines(
      [
        [x2S, y2S],
        [x2E, y2E],
      ],
      [
        [x1S, y1S],
        [x1E, y1E],
      ],
      (firstTime = false)
    );
  }
  if (firstTime) {
    return [[intersectPointX, intersectPointY], s, t];
  } else {
    return [[intersectPointX, intersectPointY], t, s];
  }
}

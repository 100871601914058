import { Stab } from "./Parts/Stab";
import useDrag from "./playgroundObjectHooks/useDrag";
import { PartType } from "features/Context/types/ConstructionElementModel";
import { ObjectIndex } from "features/Context/types/DrawenObjectModel";

export function Part(props: { type: PartType; name: ObjectIndex }) {
  const { handleDragStart } = useDrag();

  const part = (): JSX.Element | null => {
    if (props.type === "stab") {
      return <Stab name={props.name} handleDragStart={handleDragStart} />;
    } else {
      return null;
    }
  };

  return <>{part()}</>;
}

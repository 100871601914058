import { makeStyles, Paper } from "@material-ui/core";

import {
  primaryColor,
  primaryColorDark,
} from "features/Components/StyleOnlyComponents/Color";
const useStyles = (props: any) =>
  makeStyles({
    paper: {
      width: props.width,
      height: props.height,
      margin: "5px",
      cursor: "pointer",
      padding: "5px",
      color: props.color,
      background: props.background,
      "&:hover": {
        background: props.backgroundHover,
      },
    },
    center: {
      padding: "5px",
      display: "flex",
      justifyContent: "center",
    },
  });

export function SelectionOption({
  name,
  isSelected = false,
  handleClick,
  icon = <div></div>,
  height = "100px",
  width = "70%",
}: {
  name: string;
  handleClick: Function;
  isSelected?: boolean;
  icon: JSX.Element;
  height?: string;
  width?: string;
}) {
  const background = isSelected ? primaryColor : "white";
  const backgroundHover = isSelected ? primaryColorDark : "rgba(0,0,0,0.2)";
  const color = isSelected ? "white" : "black";
  const classes = useStyles({
    background,
    color,
    backgroundHover,
    height,
    width,
  })();

  return (
    <Paper
      elevation={2}
      className={classes.paper}
      onClick={() => {
        handleClick();
      }}
    >
      {Boolean(name) && <div className={classes.center}>{name}</div>}
      <div className={classes.center}>{icon}</div>
    </Paper>
  );
}

import { BearingType } from "features/Context/types/ConstructionElementModel";

export function Schiebeverbindung({
  x = 0,
  y = 0,
  xCenter = 0,
  yCenter = 0,
  strokeWidth = 3,
  strokeColor = "black",
  width = 100,
  angle = 0,
  dJoint = 30,
  frameThickness = 10,
  groupRef,
  jointFillColor = "white",
  bodyColor = "white",
  measureToCenter = false,
  scale = 100,
  setCenter = false,
}: {
  x: number;
  y: number;
  xCenter?: number;
  yCenter?: number;
  strokeWidth?: number;
  strokeColor?: string;
  width: number;
  angle: number;
  dJoint?: number;
  frameThickness?: number;
  groupRef: React.MutableRefObject<any>;
  jointFillColor?: string;
  bodyColor?: string;
  measureToCenter?: boolean;
  scale?: number;
  setCenter?: false | ((x: number, y: number, name: BearingType) => void);
}) {
  const xJoint = width / 2;
  const yJoint = dJoint / 2 + strokeWidth + frameThickness;

  const xOuterFrame = strokeWidth / 2;
  const yOuterFrame = strokeWidth / 2;
  const widthOuterFrame = width - strokeWidth;
  const heightOuterFrame = frameThickness * 2 + strokeWidth + dJoint;

  const xInnerFrame = strokeWidth / 2 + frameThickness;
  const yInnerFrame = strokeWidth / 2 + frameThickness;
  const widthInnerFrame = width - strokeWidth - 2 * frameThickness;
  const heightInnerFrame =
    frameThickness * 2 + strokeWidth + dJoint - 2 * frameThickness;

  const xCenterCond = xCenter + width / 2;
  const yCenterCond = yCenter + yJoint;
  // const yCenterCond = yCenter + width / 2;
  const dezScale = scale / 100;

  let xTransLate;
  let yTransLate;
  if (measureToCenter) {
    xTransLate = x - dezScale * xCenterCond;
    yTransLate = y - dezScale * yCenterCond;
  } else {
    xTransLate = x;
    yTransLate = y;
  }
  return (
    <g
      ref={groupRef}
      id="LosLager"
      transform={`  translate(${xTransLate},${yTransLate}) scale(${dezScale})  rotate(${angle} ${xCenterCond} ${yCenterCond} )`}
    >
      <rect
        x={xOuterFrame}
        y={yOuterFrame}
        width={widthOuterFrame}
        height={heightOuterFrame}
        // stroke={strokeColor}
        // strokeWidth={strokeWidth}
        fill="white"
      />
      <rect
        id="Joint"
        x={xJoint - (width - 2 * frameThickness) / 4}
        y={yJoint - dJoint / 2}
        width={(width - 2 * frameThickness) / 2}
        height={dJoint}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        fill={jointFillColor}
      />
      <path
        fillRule="evenodd"
        fill={bodyColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        d={`M ${xOuterFrame},${yOuterFrame} h ${widthOuterFrame} v ${heightOuterFrame} h ${-widthOuterFrame} z M ${xInnerFrame},${yInnerFrame} h ${widthInnerFrame} v ${heightInnerFrame} h ${-widthInnerFrame} Z`}
      />
    </g>
  );
}

import { makeStyles } from "@material-ui/core";
import {
  primaryColor,
  primaryColorDark,
} from "features/Components/StyleOnlyComponents/Color";
import {
  DrawenObject,
  PendingObject,
} from "features/Context/types/DrawenObjectModel";
import { useState } from "react";
const useStyles = makeStyles({
  rotateThumb: {
    cursor: "pointer",
  },
});
export default function Rotater({
  x,
  y,
  xCenter,
  yCenter,
  handleRotateStart,
  object,
}: {
  x: number;
  y: number;
  xCenter: number;
  yCenter: number;
  handleRotateStart: (
    e: React.MouseEvent,
    object: DrawenObject | PendingObject
  ) => any;
  object: DrawenObject | PendingObject;
}) {
  const classes = useStyles();
  const [color, setColor] = useState(primaryColor);
  const handleMouseEnter = () => {
    setColor(primaryColorDark);
  };
  const handleMouseLeave = () => {
    setColor(primaryColor);
  };
  return (
    <>
      <line
        id="Vertical Line"
        x1={x}
        x2={xCenter + (xCenter - x)}
        y1={y}
        y2={yCenter - y + yCenter}
        stroke={color}
      />
      <line
        id="Horizontal Line"
        x1={xCenter + (yCenter - y)}
        x2={xCenter - (yCenter - y)}
        y1={yCenter - (xCenter - x)}
        y2={yCenter + (xCenter - x)}
        stroke={color}
      />
      <g
        className={classes.rotateThumb}
        onMouseDown={(e) => {
          handleRotateStart(e, object);
        }}
      >
        <circle
          cx={x}
          cy={y}
          r={15}
          fill={color}
          stroke="none"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <g
          transform={`rotate(180 ${x} ${y}) translate(${x + 12} ${
            y - 12
          }) scale(-1 1)  `}
        >
          <path
            stroke="none"
            fill="white"
            d="M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z"
          ></path>
        </g>
      </g>
    </>
  );
}

import React, { useContext } from "react";
import {
  PendingContext,
  PendingContextType,
} from "../../../../../Context/PendingContext";
import { makeStyles, Paper } from "@material-ui/core";
import MechanicalIcon from "./MechanicalIcon";
import { DrawenObjectType } from "features/Context/types/ConstructionElementModel";

const useStyles = makeStyles({
  paper: {
    width: "70%",
    height: "100px",
    margin: "5px",
    cursor: "pointer",
    padding: "5px",
    "&:hover": {
      background: "rgba(0,0,0,0.2)",
    },
  },
  center: {
    padding: "5px",
    display: "flex",
    justifyContent: "center",
  },
});

export function BearingShowPiece({
  name,
  type,
}: {
  name: string;
  type: DrawenObjectType;
}) {
  // useContext to set chosen bearing
  const classes = useStyles();
  const { fillPendingObject } = useContext(
    PendingContext
  ) as PendingContextType;

  const handleClick = () => {
    fillPendingObject({
      type: type,
      mechanicalType: "bearing",
      color: "black",
    });
  };

  return (
    <Paper elevation={2} className={classes.paper} onClick={handleClick}>
      <div className={classes.center}>{name}</div>
      <div className={classes.center}>
        <MechanicalIcon type={type} size={50} strokeWidth={5} />
      </div>
    </Paper>
  );
}

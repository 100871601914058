import React, { useState, useCallback, useContext, useMemo } from "react";
import { PendingContext } from "../../../../../Context/PendingContext";

import { Slider } from "../InputComponents/Slider";

export function RadiusControl(props) {
  const { pendingObject, setPendingSize } = useContext(PendingContext);

  const radius = useMemo(
    () => pendingObject.size.radius,
    [pendingObject.size.radius]
  );

  const handleRadiusChange = useCallback(
    (radius) => {
      setPendingSize({ radius: radius });
    },
    [setPendingSize]
  );

  return (
    <div className={"rectSizeControl"}>
      <div className="angleAndHorizontal">
        <Slider
          sliderOrientation={"horizontal"}
          description={"Radius"}
          min={2}
          max={560}
          name={props.name}
          value={radius}
          handleChange={handleRadiusChange}
        />
      </div>
    </div>
  );
}

import { Vector2 } from "features/LinearAlgebra/linearAlgebraModel";
import { LoadNames } from "./ConstructionElementModel";
import { Index } from "./DrawenObjectModel";
export type LoadObjects = {
  [key: string | number]: Load;
};
export const getLoadMainSymbol = (load: Load): string => {
  if (load.loadType === "force") {
    return "F";
  } else if (load.loadType === "moment") {
    return "M";
  } else {
    //streckenlast
    return "q";
  }
};
export type MomentOrientation = -1 | 1;
export type LoadIndex = number;
export type Load = {
  index: LoadIndex;
  isPending: boolean;
  isFreischnittGhostLoad: boolean; // these Loads are just there to replace the bearings at the Freischnitt stage
  parentIndex: Index;
  pos: Vector2;
  x: number;
  y: number;
  angle: number;
  loadType: LoadNames;
  loadSpec: any;
  color1: string;
  color2: string;
  textColor: string;
  fontSize: number;
  annotationIsDraggable: boolean;
  textOffsetX: number;
  textOffsetY: number;
  size: number;
  preFactor: string;
  isSymbolic: boolean;
  measurements: Array<any>;
  magnitude: number; // magnitude is always in SI units
  name: string | number;
  isFake: boolean;
  isHighlighted: boolean;
  isHiddenByUser: boolean;
  loadTypeWasSelected: boolean;
};
export type LoadColor = {
  color1?: string;
  color2?: string;
  textColor?: string;
};

import { CordsArrow } from "./CordsArrow";
import LatexSvg from "../helper/LatexSvg";

export function CoordinateSystem({
  color = "black",
  x = 0,
  y = 0,
  angle = 0,
  size = 2,
  strokeWidth = 6,
  hasAnnotation = true,
}) {
  const length = strokeWidth * size;

  const polarOriginE1 = {
    originX: x,
    originY: y,
    angle: angle,
    length: length,
  };
  const polarOriginE2 = {
    originX: x,
    originY: y,
    angle: angle + 90,
    length: length,
  };

  const fontSize = Math.max(strokeWidth * 2, 12);
  const distToArrow = 1.8;
  const fractionOfArrow = 0.3;
  const dxME1 = Math.cos((angle * 2 * 3.1415) / 360) * length * fractionOfArrow;
  const dyME1 = Math.sin((angle * 2 * 3.1415) / 360) * length * fractionOfArrow;
  const dxME2 = Math.sin((angle * 2 * 3.1415) / 360) * length * fractionOfArrow;
  const dyME2 = Math.cos((angle * 2 * 3.1415) / 360) * length * fractionOfArrow;

  const xME1 = x + dxME1; // mittelpnkte der jewiligen koordinatenpfeile
  const yME1 = y + dyME1;
  const xME2 = x - dxME2;
  const yME2 = y + dyME2;

  //font soze heuristic
  const dxTextE1 =
    Math.sin((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
    Math.cos((angle * 2 * 3.1415) / 360) * fontSize * distToArrow -
    fontSize / 2;
  const dyTextE1 =
    Math.cos((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
    Math.sin((angle * 2 * 3.1415) / 360) * fontSize * distToArrow;
  const dxTextE2 =
    Math.cos((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
    Math.sin((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
    fontSize / 2;
  const dyTextE2 =
    Math.sin((angle * 2 * 3.1415) / 360) * fontSize * distToArrow +
    Math.cos((angle * 2 * 3.1415) / 360) * fontSize * distToArrow;

  const xTextE1 = xME1 + dxTextE1;
  const yTextE1 = yME1 + dyTextE1;
  const xTextE2 = xME2 - dxTextE2;
  const yTextE2 = yME2 + dyTextE2;

  return (
    <g id="CoordinateSystem">
      <CordsArrow
        peakAngle={25}
        color={color}
        strokeWidth={strokeWidth}
        polarOrigin={polarOriginE1}
      />

      <CordsArrow
        peakAngle={25}
        color={color}
        strokeWidth={strokeWidth}
        polarOrigin={polarOriginE2}
      />
      {/* <g
        transform={
          "translate(" +
          xTextE1 +
          " " +
          yTextE1 +
          ") rotate(" +
          -180 +
          ") scale(" +
          -1 +
          " " +
          1 +
          ")"
        }
      >
        <text x={0} y={0} fill={color} fontSize={fontSize+"px"} >e1</text>
      </g> */}
      {hasAnnotation && (
        <LatexSvg
          latex={"e_1"}
          // latex={"\\boldsymbol{e_2}"}
          x={xTextE1}
          color={color}
          y={yTextE1}
          height={fontSize}
        />
      )}
      {hasAnnotation && (
        <LatexSvg
          latex={"e_2"}
          x={xTextE2}
          color={color}
          y={yTextE2}
          height={fontSize}
        />
      )}
      {/* <g
        transform={
          "translate(" +
          xTextE2 +
          " " +
          yTextE2 +
          ") rotate(" +
          -180 +
          ") scale(" +
          -1 +
          " " +
          1 +
          ")"
        }
      >
        <text x={0} y={0} fill={color} fontSize={fontSize + "px"}>
          e2
        </text>
      </g> */}

      <circle cx={x} cy={y} r={2 * strokeWidth} fill={color} />
    </g>
  );
}

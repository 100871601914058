import { DrawenObject } from "features/Context/types/DrawenObjectModel";
import { LoadObjects } from "features/Context/types/LoadModel";

/**
 *
 * @param loadObjects LoadObject
 * @param part
 * @returns the force and moment Loadobjects that are mounted on the specified part
 */
export function getForcesAndMomentsFromPart(
  loadObjects: LoadObjects,
  part: DrawenObject
) {
  const index = part.index;
  const loadsOnPart = Object.values(loadObjects).filter((load) => {
    if (load.loadType === "force" || load.loadType === "moment") {
      return load.parentIndex === index;
    }
  });
  return loadsOnPart;
}
/**
 *
 * @param loadObjects LoadObject
 * @param part
 * @returns the force and moment Loadobjects that are mounted on the specified part
 */
export function getForcesFromPart(
  loadObjects: LoadObjects,
  part: DrawenObject
) {
  const index = part.index;
  const loadsOnPart = Object.values(loadObjects).filter((load) => {
    if (load.loadType === "force") {
      return load.parentIndex === index;
    }
  });
  return loadsOnPart;
}
/**
 *
 * @param loadObjects
 * @param part
 * @returns the streckenlast LoadObjects that are mounted on the part
 */
export function getStreckenlastFromPart(
  loadObjects: LoadObjects,
  part: DrawenObject
) {
  const index = part.index;
  const loadsOnPart = Object.values(loadObjects).filter((load) => {
    if (load.loadType === "streckenlast") {
      return load.parentIndex === index;
    }
  });
  return loadsOnPart;
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { accordionRootBackground } from "features/Components/StyleOnlyComponents/Color";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    padding: "0px",
    paddingBottom: "10px",
    paddingTop: "10px",
    margin: "0px",
    backgroundColor: accordionRootBackground,
  },
  border: {
    borderTop: "solid 1px darkgrey",
  },
  fullWidth: {
    width: "100%",
  },
  fullWidthOuter: {
    width: "100%",
    borderTop: "solid 1px darkgrey",
  },
  flex: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  mainContainer: { paddingLeft: "10px", paddingRight: "10px", width: "100%" },
}));
export function ControlAccordion({
  titleStartElement = <div></div>,
  titleMainElement,
  titleEndElement = <div></div>,
  mainContent,
  expanded,
  customIsExpanded = null,
  customToggleIsExpanded = () => {},
}: {
  titleStartElement?: JSX.Element;
  titleMainElement: JSX.Element;
  titleEndElement?: JSX.Element;
  mainContent: JSX.Element;
  expanded: boolean;
  customIsExpanded?: boolean | null;
  customToggleIsExpanded?: (e: any) => void;
}) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(expanded);
  const handleToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={classes.fullWidthOuter}>
      <Accordion
        expanded={customIsExpanded === null ? isOpen : customIsExpanded}
        square
        elevation={0}
        className={classes.fullWidth}
        onChange={
          customIsExpanded === null ? handleToggleOpen : customToggleIsExpanded
        }
      >
        <AccordionSummary
          //   classes={{ root: classes.border, expanded: classes.border }}
          expandIcon={
            <div>
              <ExpandMoreIcon />
            </div>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={classes.fullWidth + " " + classes.flex}>
            <div>{titleStartElement}</div>

            <div>{titleMainElement}</div>
            <div>{titleEndElement}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.root, // class name, e.g. `classes-nesting-root-x`
          }}
        >
          <div className={classes.mainContainer}>{mainContent}</div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

import PolyLine from "features/Components/Playground/Cords/PolyLine";
import { elongateLine } from "features/LinearAlgebra/elongateLine";
import {
  getAngleDegBetweenVectors,
  subV,
} from "features/LinearAlgebra/vectorUtils";

export default function XShape({
  intersects,
  outers,
  biggerPart,
  smallerPart,
  strokeWidth,
  strokeColor,
}) {
  const polyLines = intersects.map((intersectionPoint, index, arr) => {
    let otherPoints = [...arr];
    otherPoints.splice(index, 1);
    const elongate1 = elongateLine(
      [otherPoints[0], intersectionPoint],
      2 * strokeWidth
    )[1];
    const elongate2 = elongateLine(
      [otherPoints[1], intersectionPoint],
      2 * strokeWidth
    )[1];
    const elongate3 = elongateLine(
      [otherPoints[2], intersectionPoint],
      2 * strokeWidth
    )[1];

    const diff1 = subV(elongate1, intersectionPoint);
    const diff2 = subV(elongate2, intersectionPoint);
    const diff3 = subV(elongate3, intersectionPoint);

    const angle12 = Math.abs(getAngleDegBetweenVectors(diff1, diff2));
    const angle13 = Math.abs(getAngleDegBetweenVectors(diff1, diff3));
    const angle23 = Math.abs(getAngleDegBetweenVectors(diff3, diff2));
    const maxAngle = Math.max(angle12, angle13, angle23);
    if (angle13 < maxAngle && angle23 < maxAngle) {
      return (
        <PolyLine
          id="first"
          points={[elongate1, intersectionPoint, elongate2]}
          fill="none"
          key={intersectionPoint[0] * intersectionPoint[1]}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="butt"
        />
      );
    } else if (angle12 < maxAngle && angle23 < maxAngle) {
      return (
        <PolyLine
          id="second"
          points={[elongate1, intersectionPoint, elongate3]}
          fill="none"
          key={intersectionPoint[0] * intersectionPoint[1]}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="butt"
        />
      );
    } else {
      return (
        <PolyLine
          points={[elongate3, intersectionPoint, elongate2]}
          fill="none"
          key={intersectionPoint[0] * intersectionPoint[1]}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeLinecap="butt"
        />
      );
    }
  });
  return <>{polyLines}</>;
}

import { Hidden } from "@material-ui/core";
import { Footer } from "featuresLandingPage/footer/Footer";
import { MainContentMobile } from "./mainContentMobile";
import { MobileNotSupported } from "./MobileNotSupported";

export function MobilePage() {
  return (
    <>
      {/*@ts-ignore*/}
      <Hidden only={["md", "lg", "xl"]}>
        <MobileNotSupported></MobileNotSupported>

        <MainContentMobile></MainContentMobile>
        <Footer></Footer>
      </Hidden>
    </>
  );
}

import { useState, createContext, useContext } from "react";
import { StatusContext, StatusContextType } from "./StatusContext";
import { StageStartMsg } from "features/Components/StyleOnlyComponents/StageStartMsg";

export const ConsoleContext = createContext<ConsoleContextType | null>(null);
export type ConsoleObj = { content: JSX.Element | string; time: string };
export type ConsoleObjs = Array<ConsoleObj>;
export type ConsoleContextType = {
  console: ConsoleObjs;
  addEntryToConsole: (entry: JSX.Element) => void;
};

export function ConsoleProvider(props: any) {
  const { statusOptions } = useContext(StatusContext) as StatusContextType;
  const FIRST_CONSOLE_ENTRY = (
    <StageStartMsg
      msg={statusOptions[0].consoleMsg}
      stageName={statusOptions[0].text}
    ></StageStartMsg>
  );
  const [console, setConsole] = useState<Array<ConsoleObj>>([
    { content: FIRST_CONSOLE_ENTRY, time: "" },
  ]);
  const addEntryToConsole = (entry: JSX.Element): void => {
    const currentTime: string = new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    setConsole((prev) => {
      return [...prev, { time: currentTime, content: entry }];
    });
  };
  return (
    <ConsoleContext.Provider value={{ console, addEntryToConsole }}>
      {props.children}
    </ConsoleContext.Provider>
  );
}

import { getLineDir } from "features/LinearAlgebra/getLineDir";
import { intersectOfTwoLines } from "features/LinearAlgebra/intersectOfTwoLines";
import {
  normalize,
  rotateByDeg,
  scalarProd,
  subV,
} from "features/LinearAlgebra/vectorUtils";
import { getLineFromPart } from "./getLineFromPart";
import { GeometricalPart } from "./PartType";

export function getShape(part1: GeometricalPart, part2: GeometricalPart) {
  const biggerPart = part1.size.height > part2.size.height ? part1 : part2;
  const smallerPart = part1.size.height < part2.size.height ? part1 : part2;
  const lineBigger = getLineFromPart(biggerPart);
  const lineSmaller = getLineFromPart(smallerPart);
  const smallHeight = smallerPart.size.height;
  const bigHeight = biggerPart.size.height;

  //s is fraction of Bigger
  //t is fraction of Smaller
  // @ts-ignore
  const [intersect] = intersectOfTwoLines(lineBigger, lineSmaller);

  const [startBigger, endBigger] = lineBigger;
  const [startSmaller, endSmaller] = lineSmaller;

  const points = [
    [startBigger, startSmaller],
    [endBigger, endSmaller],
  ];
  //startOfbiggerInSmaller,startOfsmallerInBiggerstart, EndOfbiggerInSmaller, EndOfsmallerInBigger
  let areEndPointsInOtherPart: Array<boolean> = [];
  points.forEach((pointArr) => {
    const outerPointsBigger = pointArr[0];
    const outerPointsSmaller = pointArr[1];
    const helperVecBigger = subV(intersect, outerPointsBigger);
    const helperVecSmaller = subV(intersect, outerPointsSmaller);

    const normalBigger = normalize(rotateByDeg(getLineDir(lineBigger), 90));
    const normalSmaller = normalize(rotateByDeg(getLineDir(lineSmaller), 90));

    const halfHeightProjectionSmallHeight = Math.abs(
      scalarProd(helperVecBigger, normalSmaller)
    );
    const halfHeightProjectionBigHeight = Math.abs(
      scalarProd(helperVecSmaller, normalBigger)
    );

    const biggerEndsInSmaller =
      halfHeightProjectionSmallHeight <= smallHeight / 2;
    const smallerEndsInBigger = halfHeightProjectionBigHeight <= bigHeight / 2;
    areEndPointsInOtherPart.push(biggerEndsInSmaller, smallerEndsInBigger);
  });
  const [
    startOfBiggerInSmaller,
    startOfSmallerInBigger,
    endOfBiggerInSmaller,
    endOfSmallerInBigger,
  ] = areEndPointsInOtherPart;
  let isLShape = false;
  let isTShape = false;
  let isXShape = false;

  if (startOfBiggerInSmaller || endOfBiggerInSmaller) {
    if (startOfSmallerInBigger || endOfSmallerInBigger) {
      isLShape = true;
    } else {
      isTShape = true;
    }
  } else if (startOfSmallerInBigger || endOfSmallerInBigger) {
    isTShape = true;
  } else {
    isXShape = true;
  }
  return {
    isXShape,
    isLShape,
    isTShape,
    startOfBiggerInSmaller,
    startOfSmallerInBigger,
    endOfBiggerInSmaller,
    endOfSmallerInBigger,
  };
}
